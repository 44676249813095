import { DatePicker, FieldEditor, Text } from "components";
import dayjs, { Dayjs } from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Prospect } from "models/prospect.model";
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import {
  selectProspect,
  selectProspectLoading,
} from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import { fDate } from "utils/time.util";

interface Props {
  name: string;
  placeholder: string;
  textClass?: string;
}

export const ProspectDatePicker = ({ name, textClass, placeholder }: Props) => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectProspectLoading);
  const prospect = useAppSelector(selectProspect);
  const currentItem = prospect[name as keyof Prospect];
  const [roundCloseDay, setRoundCloseDay] = useState<Dayjs | null>(null);

  const handleUpdateField = async (data: FieldValues) => {
    try {
      if (!prospect?.id) {
        dispatch(prospectActions.createProspect(data));
      } else {
        dispatch(
          prospectActions.updateProspect({
            prospectUpdateId: prospect?.id,
            input: data,
          })
        );
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  const handleSaveData = () => {
    if (roundCloseDay) {
      const timeStamp = dayjs(roundCloseDay).valueOf();
      const data = {
        [name]: timeStamp,
      };
      handleUpdateField(data);
    }
  };

  useEffect(() => {
    if (currentItem) {
      setRoundCloseDay(dayjs(currentItem as number));
    }
  }, [currentItem]);

  return (
    <FieldEditor
      loading={submitLoading}
      onHandle={handleSaveData}
      textElement={
        <Text size="md" color="#6A6D84" className={textClass}>
          {fDate(currentItem as number, "	MMM D, YYYY") || placeholder}
        </Text>
      }
      inputElement={
        <DatePicker
          value={roundCloseDay}
          onchange={(newValue) => setRoundCloseDay(newValue)}
          placeholder={placeholder}
        />
      }
    ></FieldEditor>
  );
};
