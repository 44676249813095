import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const UpdateIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72112 3.50628L5.65221 3.55242L5.70503 3.61636L6.2214 4.2414L6.2214 4.2414C6.47776 4.55167 6.29005 5.02411 5.89063 5.0738L2.31728 5.5186L2.31727 5.5186C1.94615 5.56484 1.65311 5.20884 1.76817 4.85402L1.76817 4.85401L2.8787 1.42824L2.8787 1.42824C3.00279 1.04531 3.50218 0.95003 3.75863 1.26039L4.63902 2.32605L4.68295 2.37922L4.73961 2.33989C6.34398 1.22628 8.29329 0.573535 10.3928 0.573535C15.8742 0.573535 20.3178 5.01711 20.3178 10.4985C20.3178 10.6883 20.3125 10.877 20.3019 11.0643C20.2784 11.4825 19.9203 11.8024 19.5022 11.7789C19.084 11.7553 18.7641 11.3973 18.7876 10.9791C18.7966 10.8201 18.8011 10.6599 18.8011 10.4985C18.8011 5.85474 15.0366 2.0902 10.3928 2.0902C8.66295 2.0902 7.05704 2.61178 5.72112 3.50628ZM14.6717 17.6821L14.7406 17.636L14.6878 17.5721L14.1714 16.947L14.1136 16.9948L14.1714 16.947C13.915 16.6367 14.1027 16.1643 14.5022 16.1146L14.4929 16.0402L14.5022 16.1146L18.0755 15.6698C18.4466 15.6236 18.7397 15.9796 18.6246 16.3344L17.5141 19.7602L17.5854 19.7833L17.5141 19.7602C17.39 20.1431 16.8906 20.2384 16.6342 19.928L15.7545 18.8632L15.7105 18.81L15.6539 18.8494C14.0495 19.963 12.0995 20.6149 10 20.6149C4.51858 20.6149 0.075 16.1713 0.075 10.6899C0.075 10.5001 0.0803376 10.3114 0.0908779 10.1241C0.114407 9.70593 0.472461 9.38603 0.890616 9.40955C1.30877 9.43308 1.62868 9.79114 1.60515 10.2093C1.5962 10.3683 1.59167 10.5285 1.59167 10.6899C1.59167 15.3337 5.35621 19.0982 10 19.0982C11.7298 19.0982 13.3358 18.5766 14.6717 17.6821ZM13.2583 11C13.2583 12.7996 11.7995 14.2584 9.99999 14.2584C8.20047 14.2584 6.74166 12.7996 6.74166 11C6.74166 9.20051 8.20047 7.74171 9.99999 7.74171C11.7995 7.74171 13.2583 9.20051 13.2583 11ZM9.99999 12.7417C10.9619 12.7417 11.7417 11.9619 11.7417 11C11.7417 10.0381 10.9619 9.25837 9.99999 9.25837C9.0381 9.25837 8.25833 10.0381 8.25833 11C8.25833 11.9619 9.0381 12.7417 9.99999 12.7417Z"
        fill={color}
        stroke="white"
        strokeWidth="0.15"
      />
    </svg>
  );
};
