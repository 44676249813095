import { Box } from "@mui/material";
import styled from "styled-components";
import * as yup from "yup";

import { OrganisationFieldInput, SectionWrapper } from "components";

const stringYup = yup.string().nullable(true);

export const AboutSection = () => {
  return (
    <SectionWrapper title="About">
      <Container>
        <Box>
          <OrganisationFieldInput
            fieldType="text-area"
            fieldName="Company Description"
            name="description"
            placeholder="Type some words here..."
            yupSchema={stringYup}
            maxLength={1400}
          />
          <Box className="explain-text">
            Tell us about your Company, Product, and your Goals
          </Box>
        </Box>
        <OrganisationFieldInput
          fieldName="Link to video pitch"
          name="videoUrl"
          placeholder="https://"
          yupSchema={stringYup}
          tooltipText="Recommended duration of your video pitch 2-3 minutes"
        />
        <OrganisationFieldInput
          fieldName="Website"
          name="websiteUrl"
          placeholder="https://"
          yupSchema={stringYup}
        />
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .explain-text {
    margin-top: 2px;
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 12px;
  }
`;
