import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  Stack,
  createFilterOptions,
  dialogClasses,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { Button, ButtonOutline, Text } from "components";
import { Combobox } from "components/Feature/Combobox";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useDebounce } from "hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import {
  Asset,
  AssetType,
  CreateAssetLinkParams,
  CreateAssetPseudoParams,
} from "models/report.model";
import {
  selectAssetsFuzzySearch,
  selectReportLoading,
} from "store/report/report.selector";
import { reportActions } from "store/report/report.slice";
const filter = createFilterOptions<any>();
interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedAsset?: Asset;
}

export const AddNewAssetModal = ({ isOpen, onClose, selectedAsset }: Props) => {
  const [input, setInput] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const dispatch = useAppDispatch();
  const debouncedValue = useDebounce(input);
  const loading = useAppSelector(selectReportLoading);
  const assetsFuzzySearch = useAppSelector(selectAssetsFuzzySearch);

  const schema = yup.object().shape({
    toId: yup.string(),
    displayName: yup.string().required(),
    websiteUrl: yup.string().required(),
    assetType: yup.string(),
  });

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    trigger,
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const assetType = watch("assetType");

  const handleClose = () => {
    reset();
    onClose();
    setInput("");
  };

  const submitForm = (data: FieldValues) => {
    if (selectedAsset) {
      const pseudoData: CreateAssetPseudoParams & { assetId: string } = {
        assetId: data.id,
        input: {
          displayName: data.displayName,
          websiteUrl: data.websiteUrl,
        },
        assetType: data.__typename,
      };
      dispatch(
        reportActions.updateAssetPseudo({
          data: pseudoData,
          callback: () => {
            handleClose();
          },
        })
      );
    } else {
      const linkData: CreateAssetLinkParams = {
        toId: data.toId,
        assetType: data.assetType,
      };

      const pseudoData: CreateAssetPseudoParams = {
        input: {
          displayName: data.displayName,
          websiteUrl: data.websiteUrl,
        },
        assetType: data.assetType,
      };

      dispatch(
        reportActions.createAsset({
          data:
            data.assetType === AssetType.AssetTypesNetwork
              ? linkData
              : pseudoData,
          type: data.assetType,
          callback: handleClose,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      reportActions.getAssetsFuzzyByName({
        lookup: debouncedValue,
        onLoad: (loading) => setSearchLoading(loading),
      })
    );
  }, [debouncedValue, dispatch]);

  useEffect(() => {
    if (selectedAsset) {
      Object.keys(selectedAsset).map((key) => {
        setValue(key, selectedAsset[key as keyof Asset]);
      });
    } else {
      reset();
    }
  }, [reset, selectedAsset, setValue]);

  return (
    <BaseDialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <HeaderBox>{selectedAsset ? "Update asset" : "Add new asset"}</HeaderBox>

      <FormWrapper onSubmit={handleSubmit(submitForm)}>
        {selectedAsset ? (
          <GroupTextField
            fieldName="Company name"
            name="displayName"
            placeholder="Start-Up Inc."
            formRef={register}
            error={errors.displayName?.message as string}
          />
        ) : (
          <Stack spacing="5px">
            <Text size="md" fontWeight={600}>
              Company name
            </Text>

            <Combobox
              id="combobox-asset-fuzzy-search"
              freeSolo
              options={assetsFuzzySearch.map((item) => ({
                id: item.id,
                displayName: item.displayName,
                websiteUrl: item.websiteUrl,
                label: item.displayName,
                assetType: item.type,
              }))}
              onInputChange={(value) => setInput(value)}
              error={errors.displayName?.message as string}
              onchange={(value) => {
                setValue("toId", value.id);
                setValue("displayName", value.displayName);
                setValue("assetType", value.assetType);
                setValue("websiteUrl", value.websiteUrl || "");

                trigger("displayName");
              }}
              placeholder="Start-Up Inc."
              loading={searchLoading}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                );
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                const isExisting = options.some(
                  (option) => inputValue === option.label
                );

                if (inputValue && !isExisting) {
                  filtered.push({
                    id: "asset-pseudo-option",
                    label: `${inputValue} (New company)`,
                    inputValue,
                    displayName: inputValue,
                    assetType: AssetType.PseudoOrganisation,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.label;
              }}
            />
          </Stack>
        )}

        <GroupTextField
          fieldName="Website Link"
          name="websiteUrl"
          placeholder="https://..."
          formRef={register}
          error={errors.websiteUrl?.message as string}
          disabled={assetType === AssetType.AssetTypesNetwork}
        />
        <FooterBox>
          <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>
          <Button type="submit" loading={loading}>
            {selectedAsset ? "Update asset" : "Add asset"}
          </Button>
        </FooterBox>
      </FormWrapper>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 0px 32px;
  margin-top: 30px;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-width: 500px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: calc(100vw - 60px);
  }
`;

const FooterBox = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid #ebecef;

  & button,
  .button-wrapper {
    width: 100%;
  }
`;
