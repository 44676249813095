import styled from "styled-components";

import { CreateIcon } from "assets/images/common";
import { Avatar, Button } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";

interface Props {
  handleClick: () => void;
}

export const InputSection = ({ handleClick }: Props) => {
  const { displayName, logoUrl } = useAppSelector(selectOrganisation);

  return (
    <Wrapper onClick={handleClick}>
      <Container>
        <Avatar src={logoUrl} name={displayName} size={48} />

        <div className="text">What’s new?</div>

        <Button icon={<CreateIcon />}>
          <span>Create my Update</span>
        </Button>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  border: 1px solid #d8d9df;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.01);
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .text {
    flex: 1;
    color: #a5a7b4;
    line-height: 150%;
  }

  .button-wrapper button span {
    ${(props) => props.theme.breakpoints.down("sm")} {
      display: none;
    }
  }
`;
