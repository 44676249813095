import styled from "styled-components/macro";
import SignInForm from "./components/SignInFrom";
import { SignInHeader } from "./components/SignInStuff";
import SignInSocial from "./components/SignInSocial";
import { LineText } from "components";

export const SignInPage = () => {
  return (
    <Wrapper>
      <SignInHeader />
      {false && <SignInSocial />}
      {false && <LineText>Or</LineText>}
      <SignInForm />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 30px;
    border-radius: 16px;
  }
`;
