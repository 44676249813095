import { useState } from "react";

import WFounderImg from "assets/images/common/workspace-founder.png";
import WInvestorIcon from "assets/images/common/workspace-investor.png";
import WManagerIcon from "assets/images/common/workspace-manager.png";
import { Text } from "components";
import styled from "styled-components/macro";
import { AddWorkspaceModal } from "./AddWorkspaceModal";
import { WORKSPACE_TYPE } from "models/auth.model";
import { useTheme } from "@mui/material";

const LIST_WS_DATA = [
  {
    image: WFounderImg,
    name: "Founder",
    type: WORKSPACE_TYPE.FOUNDER,
    status: true,
  },
  {
    image: WManagerIcon,
    name: "Portfolio Manager",
    type: WORKSPACE_TYPE.PORTFOLIO,
    status: true,
  },
  {
    image: WInvestorIcon,
    name: "Investor",
    type: WORKSPACE_TYPE.INVESTOR,
    status: true,
  },
];

export const CreateSpace = () => {
  const theme = useTheme();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [workspaceModalType, setWorkspaceModalType] =
    useState<WORKSPACE_TYPE | null>(null);

  return (
    <Wrapper>
      <Text size="xl">Create new space</Text>

      <ListCard>
        {LIST_WS_DATA.map((item, index) => (
          <WorkspaceCard
            key={index}
            onClick={() => {
              if (item.status) {
                setWorkspaceModalType(item.type);
                setIsOpenModal(true);
              }
            }}
          >
            <img src={item.image} />

            <Text size="xl">{item.name}</Text>

            <Text
              size="md"
              color={item.status ? theme.palette.primary.main : "#A5A7B4"}
              fontWeight={item.status ? 600 : 400}
            >
              {item.status ? "Get Started" : "Coming soon..."}
            </Text>
          </WorkspaceCard>
        ))}
      </ListCard>

      {workspaceModalType && (
        <AddWorkspaceModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          type={workspaceModalType}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const ListCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: center;
  }
`;

const WorkspaceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  gap: 20px;
  width: 320px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #d8d9df;
  box-shadow: 0px 3px 5px rgba(5, 10, 48, 0.05);
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.02);
    background-color: #fafbfc;
  }

  img {
    width: 100%;
  }
`;
