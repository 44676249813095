import "@fontsource/inter";
import { CssBaseline } from "@mui/material";
import { createTheme, Palette, ThemeProvider } from "@mui/material/styles";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { NotificationModal, NotificationToast, PageLoading } from "components";
import { AUTH_MODE, FIREBASE_CONFIG } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { DashboardLayout, GuestLayout, UserLayout } from "layouts";
import { AUTH_MODE_TYPE, WORKSPACE_TYPE } from "models/auth.model";

import {
  selectAuthMode,
  selectUserToken,
  selectWorkspaceType,
  selectWorkspaceUpdate,
} from "store/auth/auth.selector";
import { authActions } from "store/auth/auth.slice";
import { chatActions } from "store/chat/chat.slice";
import {
  selectAppConfig,
  selectNamespaceId,
} from "store/global/global.selector";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { selectActiveWorkspace } from "store/workspace/workspace.selector";
import { workspaceActions } from "store/workspace/workspace.slice";
import { themeOption } from "styles/theme.config";
import { getNextUserRole } from "utils/app.util";
import { db, getFirebaseToken } from "utils/firebase.util";
import { LocalStorage } from "utils/localStorage.util";
import { parseToken } from "utils/system.util";
import { AdminRoutePage } from "views/main/AdminView/AdminRoutePage";
import { CompanyRoutePage } from "views/main/Company/CompanyRoutePage";
import { PortfolioPage } from "views/main/Portfolio/PortfolioPage";
import { store } from "./store/rootStore";
import {
  AdminOrganisationPage,
  AdminViewPage,
  AppCenterPage,
  Chats,
  CompanyFundDetailPage,
  CompanyFundraising,
  CompanyFundraisingDetail,
  CompanyFundsPage,
  CompanyPreview,
  CompanySettingsPage,
  CompanyTeam,
  CompanyUpdates,
  DealFlowPage,
  ForgotPasswordPage,
  FounderScore,
  FundraisingPage,
  FundsDetailPage,
  FundsPage,
  GenerateReport,
  InsightsAssets,
  InsightsOpportunities,
  InsightsPage,
  Investors,
  MarketplacePage,
  MFTrustScore,
  ProspectPage,
  ReportDetail,
  ReportsPage,
  SignInPage,
  SignUpPage,
  UpdatesPage,
  WorkspacesPage,
} from "./views";

const app = initializeApp(FIREBASE_CONFIG);
export const analytics = getAnalytics(app);

const AppRouter = () => {
  const authMode = useAppSelector(selectAuthMode);
  const appConfig = useAppSelector(selectAppConfig);
  const workspaceType = useAppSelector(selectWorkspaceType);
  const isUpdateWorkspace = useAppSelector(selectWorkspaceUpdate);
  const namespaceId = useAppSelector(selectNamespaceId);
  const authUserToken = useAppSelector(selectUserToken);

  const dispatch = useAppDispatch();

  const getWorkspaceRedirectPath = () => {
    if (workspaceType === WORKSPACE_TYPE.INVESTOR) return "/deal-flow";
    if (workspaceType === WORKSPACE_TYPE.FOUNDER)
      return isUpdateWorkspace ? "/company/preview" : "/company/settings";
    if (workspaceType === WORKSPACE_TYPE.PORTFOLIO) return "insights/assets";
    return "/";
  };

  useEffect(() => {
    logEvent(analytics, "app initialized");
    getFirebaseToken().then((token) => {
      const tokenObject = parseToken(token as string);
      const currentMode = LocalStorage.get(AUTH_MODE);
      const nextMode = getNextUserRole(tokenObject, appConfig.id, currentMode);

      if (token !== authUserToken) {
        dispatch(authActions.userUpdateToken(token as string));
      }
      if (currentMode !== nextMode) {
        dispatch(authActions.userUpdateRole(nextMode));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appConfig.id]);

  const GuestRouter = () => (
    <GuestLayout>
      <Routes>
        <Route path="/" element={<Navigate to="/sign-in" replace />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="*" element={<Navigate to="/sign-in" replace />} />
      </Routes>
    </GuestLayout>
  );

  const UserRouter = () => (
    <UserLayout>
      <Routes>
        <Route path="/" element={<Navigate to="/workspace" replace />} />
        <Route path="/workspace" element={<WorkspacesPage />} />
        <Route path="*" element={<Navigate to="/workspace" replace />} />
      </Routes>
    </UserLayout>
  );

  const WorkspaceRouter = () => {
    const dispatch = useAppDispatch();
    const workspaceType = useAppSelector(selectWorkspaceType);
    const { id: organisationId } = useAppSelector(selectOrganisation);
    const { id: workspaceId } = useAppSelector(selectActiveWorkspace);

    useEffect(() => {
      let userId = "";

      dispatch(workspaceActions.getWorkspace());

      if (workspaceType === WORKSPACE_TYPE.FOUNDER) {
        dispatch(organisationActions.getCompanyOrganisation());

        if (organisationId) {
          userId = organisationId;
          dispatch(chatActions.setUserId(organisationId));
        }
      }

      if (workspaceType === WORKSPACE_TYPE.INVESTOR) {
        if (workspaceId) {
          userId = workspaceId;
          dispatch(chatActions.setUserId(workspaceId));
        }
      }

      if (!userId) return;

      const docRef = doc(db, "users", userId);
      const handleUpdateStatus = async (status: boolean) => {
        const docData = await getDoc(docRef);

        if (docData.exists()) {
          await updateDoc(docRef, {
            isOnline: status,
          });
        }
      };

      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          handleUpdateStatus(true);
        }
      });

      window.addEventListener("beforeunload", () => {
        handleUpdateStatus(false);
      });

      return () => {
        unsubscribe();
        handleUpdateStatus(false);
        dispatch(organisationActions.clearOrganisation());
      };
    }, [dispatch, organisationId, workspaceId, workspaceType]);

    return (
      <DashboardLayout>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={getWorkspaceRedirectPath()} replace />}
          />
          <Route path="/deal-flow" element={<DealFlowPage />} />
          <Route path="/deal-flow/:id" element={<CompanyPreview />} />
          <Route path="/funds" element={<FundsPage />} />
          <Route path="/funds/:id" element={<FundsDetailPage />} />
          <Route path="/company-backup" element={<ProspectPage />} />
          <Route path="/company" element={<CompanyRoutePage />}>
            <Route path="preview" element={<CompanyPreview />} />
            <Route path="settings" element={<CompanySettingsPage />} />
            <Route path="team" element={<CompanyTeam />} />
            <Route path="fundraising" element={<CompanyFundraising />} />
            <Route
              path="fundraising/:fundraisingId"
              element={<CompanyFundraisingDetail />}
            />
            <Route path="funds" element={<CompanyFundsPage />} />
            <Route path="funds/:fundId" element={<CompanyFundDetailPage />} />
            <Route path="updates" element={<CompanyUpdates />} />
            <Route path="mf-score" element={<MFTrustScore />} />
            <Route path="founder-score" element={<FounderScore />} />
          </Route>
          <Route path="/investors" element={<Investors />} />
          <Route path="/chats/:id?" element={<Chats />} />
          <Route path="/updates" element={<UpdatesPage />} />
          {false && <Route path="/portfolio" element={<PortfolioPage />} />}
          {false && <Route path="/insights" element={<InsightsPage />} />}
          {false && (
            <Route path="/insights/company" element={<InsightsPage />} />
          )}
          <Route path="/insights/assets" element={<InsightsAssets />} />
          <Route
            path="/insights/assets/generate-report/:id?"
            element={<GenerateReport />}
          />
          <Route path="/insights/reports" element={<ReportsPage />} />

          <Route path="/insights/reports/:id" element={<ReportDetail />} />
          {false && (
            <Route
              path="/insights/opportunities"
              element={<InsightsOpportunities />}
            />
          )}
          <Route path="/app-center" element={<AppCenterPage />} />
          <Route path="/market-place" element={<MarketplacePage />} />
          <Route
            path="*"
            element={<Navigate to={getWorkspaceRedirectPath()} replace />}
          />
        </Routes>
      </DashboardLayout>
    );
  };

  const AdminRouter = () => {
    useEffect(() => {
      dispatch(chatActions.setUserId(namespaceId));
      const docRef = doc(db, "users", namespaceId);
      const handleUpdateStatus = async (status: boolean) => {
        const docData = await getDoc(docRef);

        if (docData.exists()) {
          await updateDoc(docRef, {
            isOnline: status,
          });
        }
      };

      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          handleUpdateStatus(true);
        }
      });

      window.addEventListener("beforeunload", () => {
        handleUpdateStatus(false);
      });

      return () => {
        unsubscribe();
        handleUpdateStatus(false);
      };
    }, []);

    return (
      <DashboardLayout>
        <Routes>
          <Route path="/" element={<Navigate to="/admin/company" replace />} />
          <Route path="/admin" element={<AdminRoutePage />}>
            <Route path="admin-view" element={<AdminViewPage />} />
            <Route
              path="admin-view/:id"
              element={<ProspectPage isAdmin={true} />}
            />
            <Route path="fundraising" element={<FundraisingPage />} />
            <Route
              path="fundraising/:fundraisingId"
              element={<CompanyFundraisingDetail />}
            />
            <Route path="company" element={<AdminOrganisationPage />} />
            <Route
              path="company/:id/settings"
              element={<CompanySettingsPage />}
            />
            <Route path="company/:id/team" element={<CompanyTeam />} />
            <Route
              path="company/:id/fundraising"
              element={<CompanyFundraising />}
            />
            <Route
              path="company/:id/fundraising/:fundraisingId"
              element={<CompanyFundraisingDetail />}
            />
          </Route>

          <Route path="/chats/:id?" element={<Chats />} />
          <Route path="*" element={<Navigate to="/admin/company" replace />} />
        </Routes>
      </DashboardLayout>
    );
  };

  const RedirectRouter = () => {
    switch (authMode) {
      case AUTH_MODE_TYPE.ADMIN:
      case AUTH_MODE_TYPE.ADMIN_OWNER:
        return <AdminRouter />;
      case AUTH_MODE_TYPE.USER:
      case AUTH_MODE_TYPE.ADMIN_WORK_AS_USER:
        return <UserRouter />;
      case AUTH_MODE_TYPE.WORKSPACE:
      case AUTH_MODE_TYPE.ADMIN_WORK_AS_WORKSPACE:
        return <WorkspaceRouter />;
      default:
        return <GuestRouter />;
    }
  };

  return (
    <Router>
      <RedirectRouter />
    </Router>
  );
};

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const [themeOptions, setThemeOptions] = useState(themeOption);
  const appConfig = useAppSelector(selectAppConfig);
  const { themeConfig, description, brandName, bannerLogo, faviLogo } =
    appConfig;

  useEffect(() => {
    setThemeOptions({ ...themeOption, palette: themeConfig as Palette });
  }, [themeConfig]);

  return (
    <ThemeProvider theme={createTheme(themeOptions)}>
      <Helmet>
        <title>{brandName}</title>
        <link rel="icon" href={faviLogo || bannerLogo} />
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </ThemeProvider>
  );
};

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeWrapper>
          <CssBaseline />
          <NotificationToast />
          <PageLoading />
          <AppRouter />
          <NotificationModal />
        </ThemeWrapper>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
