import { MultiFilter, OneSelectFilter } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import _ from "lodash";
import { FilterOptions, SelectItem } from "models/common.model";
import { Prospect } from "models/prospect.model";
import { useEffect, useState } from "react";
import {
  selectCountFilter,
  selectFilterOptions,
  selectFeeds,
} from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";

interface Props {
  title: string;
  resName: string;
  name: string;
  callApi: () => Promise<any> | null;
  type: string;
}

export const ProspectItemFilter = ({
  resName,
  name,
  callApi,
  title,
  type,
}: Props) => {
  const dispatch = useAppDispatch();
  const [listData, setListData] = useState<SelectItem[]>([]);
  const countFeed = useAppSelector(selectCountFilter);
  const globalFilterOptions = useAppSelector(selectFilterOptions);
  const feeds = useAppSelector(selectFeeds);
  const [sliderData, setSliderData] = useState({
    defaultPoint: 0,
    totalItem: 0,
  });

  const applyFilter = (data: FilterOptions, isApply: boolean) => {
    dispatch(feedActions.filterFeed({ filter: data, isApply }));
  };

  useEffect(() => {
    if (name === "mfScore") {
      let tempMin = 100;
      const pointItems = feeds.filter((i) => i.mfScore);

      pointItems.forEach((i) => {
        if (i.mfScore && i.mfScore < tempMin) tempMin = i.mfScore;
      });
      setSliderData({
        defaultPoint: tempMin,
        totalItem: pointItems?.length || 0,
      });
    } else if (name === "country") {
      const tempArr: SelectItem[] = [];
      feeds
        .filter((i) => i.country)
        .map((i) => i.country)
        .forEach((item) => {
          if (!tempArr.find((i) => i.id === item.id)) tempArr.push(item);
        });
      setListData(_.sortBy(tempArr, "name"));
    } else if (name === "sector" || name === "impactValues") {
      const tempArr: SelectItem[] = [];
      feeds
        .filter((i) => i?.[name] && i[name].length)
        .map((i) => i[name])
        .forEach((arr) => {
          arr.map((item: SelectItem) => {
            if (!tempArr.find((i) => i.id === item.id)) tempArr.push(item);
          });
        });
      setListData(_.sortBy(tempArr, "name"));
    } else {
      if (callApi) {
        const getData = async () => {
          const data = await callApi();
          setListData(data?.[resName as keyof Prospect]);
        };

        getData();
      }
    }
  }, [callApi, feeds, name, resName]);

  if (type === "MULTI_SELECT") {
    return (
      <MultiFilter
        title={title}
        name={name}
        data={listData}
        filterCount={countFeed}
        filterValues={globalFilterOptions[name]}
        onFilter={applyFilter}
      />
    );
  }

  if (type === "ONE_SELECT" || type === "ARRANGE")
    return (
      <OneSelectFilter
        title={title}
        name={name}
        data={listData}
        filterCount={countFeed}
        onFilter={applyFilter}
        type={type}
        filterValues={globalFilterOptions[name]}
        sliderData={sliderData}
      />
    );

  return null;
};
