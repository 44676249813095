import { RefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { NavBar, ScrollToWrapper } from "components";
import { useAppDispatch, useAppParams, useAppSelector } from "hooks/useHook";
import { WORKSPACE_TYPE } from "models/auth.model";
import { selectWorkspaceType } from "store/auth/auth.selector";
import { selectFeedDetail, selectFeeds } from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { selectPosts } from "store/post/post.selector";
import { scrollIntoView } from "utils/system.util";
import {
  AboutSection,
  InfoSection,
  MFScoreSection,
  MediaSection,
  OverviewSection,
  PitchDeckSection,
  TeamMembersSection,
  TrustScoreBar,
  UpdatesSection,
} from "./components";

export const CompanyPreview = () => {
  const [activeSection, setActiveSection] =
    useState<RefObject<HTMLDivElement>>();

  const dispatch = useAppDispatch();

  const { id } = useAppParams();
  const { displayName, logoUrl } = useAppSelector(selectOrganisation);
  const workspaceType = useAppSelector(selectWorkspaceType);
  const selectedFeed = useAppSelector(selectFeedDetail);
  const feeds = useAppSelector(selectFeeds);
  const posts = useAppSelector(selectPosts);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const updatesRef = useRef<HTMLDivElement>(null);

  const handleActiveSection = (activeSection: RefObject<HTMLDivElement>) => {
    setActiveSection(activeSection);

    setTimeout(() => {
      setActiveSection(undefined);
    }, 1000);
  };

  const navList = [
    { title: "Overview", ref: overviewRef },
    { title: "About", ref: aboutRef },
    { title: "Team", ref: teamRef },
    { title: "Updates", ref: updatesRef, hidden: !posts?.length },
  ];

  useEffect(() => {
    if (workspaceType === WORKSPACE_TYPE.INVESTOR && id) {
      if (!feeds.length) {
        dispatch(feedActions.getFeed(id));
      } else {
        dispatch(feedActions.selectFeed(id));
      }
    } else {
      dispatch(organisationActions.getCompanyOrganisation());
    }
  }, [dispatch, feeds.length, id, workspaceType]);

  useEffect(() => {
    if (selectedFeed.id) {
      dispatch(organisationActions.getOrganisationSuccess(selectedFeed as any));
    }
  }, [dispatch, selectedFeed]);

  useEffect(() => {
    scrollIntoView(wrapperRef);
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Helmet>
        <title>{`${displayName ? `${displayName} | ` : ""} MyFoundry`}</title>
        <link rel="icon" href={logoUrl} />
        <meta property="description" content={`${displayName} Company Brief`} />
      </Helmet>

      <MainBox>
        <LeftBox>
          <InfoSection />

          <NavBar navList={navList} handleActiveSection={handleActiveSection} />

          <MediaSection />

          <ScrollToWrapper ref={overviewRef} activeRef={activeSection}>
            <OverviewSection />
          </ScrollToWrapper>

          <PitchDeckSection />

          <ScrollToWrapper ref={aboutRef} activeRef={activeSection}>
            <AboutSection />
          </ScrollToWrapper>

          <MFScoreSection />

          <ScrollToWrapper ref={teamRef} activeRef={activeSection}>
            <TeamMembersSection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={updatesRef} activeRef={activeSection}>
            <UpdatesSection />
          </ScrollToWrapper>
        </LeftBox>

        <RightBox>
          <TrustScoreBar />
        </RightBox>
      </MainBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const MainBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }
`;

const LeftBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
  max-width: calc(100% - 382px);

  ${(props) => props.theme.breakpoints.down("lg")} {
    max-width: 100%;
  }
`;

const RightBox = styled.div`
  width: 352px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
