import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AddTeamMemberParams,
  CreateOrganisationParams,
  FounderAssessment,
  FounderScoreResult,
  Organisation,
  SubmitFounderScoreParams,
  SubmitTrustScoreQuestionaryParams,
  TeamMember,
  TrustScoreQuestion,
  UpdateOrganisationParams,
} from "models/organisation.model";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

export interface OrganisationState {
  organisations: Organisation[];
  selectedOrganisation: Organisation;
  loading: boolean;
  trustScoreQuestions: TrustScoreQuestion[];
  founderAssesment: FounderAssessment;
}

export const initialState: OrganisationState = {
  organisations: [],
  selectedOrganisation: {} as Organisation,
  loading: false,
  trustScoreQuestions: [],
  founderAssesment: {
    items: [],
  } as unknown as FounderAssessment,
};

const authSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    getOrganisations() {},
    getOrganisationsSuccess(state, _action: PayloadAction<Organisation[]>) {
      state.organisations = _action.payload;
    },
    getCompanyOrganisation() {},
    getCompanyOrganisationSuccess(state, _action: PayloadAction<Organisation>) {
      state.selectedOrganisation = _action.payload;
    },
    getOrganisation(
      _,
      _action: PayloadAction<{ id: string; isAdmin?: boolean }>
    ) {},
    getOrganisationSuccess(state, _action: PayloadAction<Organisation>) {
      state.selectedOrganisation = _action.payload;
    },
    updateOrganisation(
      state,
      _action: PayloadAction<{
        organisationId: string;
        input: UpdateOrganisationParams;
        fieldUpdate?: string;
        isAdmin?: boolean;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    updateOrganisationSuccess(
      state,
      _action: PayloadAction<{
        organisation: Organisation;
        fieldUpdate?: string;
      }>
    ) {
      const { organisation, fieldUpdate } = _action.payload;

      if (fieldUpdate) {
        state.selectedOrganisation = {
          ...state.selectedOrganisation,

          [fieldUpdate]: organisation[fieldUpdate as keyof Organisation],
        };
      } else {
        state.selectedOrganisation = organisation;
      }

      state.loading = false;
    },
    updateOrganisationFailed(state) {
      state.loading = false;
    },
    createOrganisation(_, _action: PayloadAction<CreateOrganisationParams>) {},
    createOrganisationSuccess(state, _action: PayloadAction<Organisation>) {
      state.selectedOrganisation = _action.payload;
    },
    addTeamMember(state, _action: PayloadAction<AddTeamMemberParams>) {
      state.loading = true;
    },
    addTeamMemberSuccess(state, _action: PayloadAction<TeamMember>) {
      state.selectedOrganisation.teamMembers.unshift(_action.payload);
      state.loading = false;
    },
    addTeamMemberFailed(state) {
      state.loading = false;
    },
    reorderTeamMember(
      _,
      _action: PayloadAction<{
        organisationId: string;
        teamMembers: UpdateOrganisationParams["teamMembers"];
      }>
    ) {},
    submitOrganisation(
      state,
      _action: PayloadAction<{ organisationId: string; callback?: () => void }>
    ) {
      state.loading = true;
    },
    submitOrganisationSuccess(state, _action: PayloadAction<Organisation>) {
      state.selectedOrganisation = _action.payload;
      state.loading = false;
    },
    submitOrganisationFailed(state) {
      state.loading = false;
    },
    getOrganisationsAdmin() {},
    getOrganisationsAdminSuccess(
      state,
      _action: PayloadAction<Organisation[]>
    ) {
      state.organisations = _action.payload;
    },
    clearOrganisation(state) {
      state.organisations = [];
      state.selectedOrganisation = {} as Organisation;
    },
    unPublishOrganisation(
      state,
      _action: PayloadAction<{ organisationId: string; callback?: () => void }>
    ) {
      state.loading = true;
    },
    unPublishOrganisationSuccess(state, _action: PayloadAction<Organisation>) {
      state.selectedOrganisation = _action.payload;
      state.loading = false;
    },
    unPublishOrganisationFailed(state) {
      state.loading = false;
    },
    getTrustScoreQuestions() {},
    getTrustScoreQuestionsSuccess(
      state,
      _action: PayloadAction<TrustScoreQuestion[]>
    ) {
      state.trustScoreQuestions = _action.payload;
    },
    getFounderScoreQuestions() {},
    getFounderScoreQuestionsSuccess(
      state,
      _action: PayloadAction<FounderAssessment>
    ) {
      state.founderAssesment = _action.payload;
    },
    submitTrustScore(
      state,
      _action: PayloadAction<{
        organisationId: string;
        input: SubmitTrustScoreQuestionaryParams;
        callback?: (score: number) => void;
      }>
    ) {
      state.loading = true;
    },
    submitTrustScoreSuccess(state) {
      state.loading = false;
    },
    submitTrustScoreFailed(state) {
      state.loading = false;
    },
    submitFounderScore(
      state,
      _action: PayloadAction<{
        assesment: SubmitFounderScoreParams;
        callback?: (scoreResult: FounderScoreResult[]) => void;
      }>
    ) {
      state.loading = true;
    },
    submitFounderScoreSuccess(state) {
      state.loading = false;
    },
    submitFounderScoreFailed(state) {
      state.loading = false;
    },
  },
});
export const {
  actions: organisationActions,
  reducer: organisationReducer,
  name: sliceKey,
} = authSlice;
