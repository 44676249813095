import { useState } from "react";
import styled from "styled-components";

import { PageTitle, Warning } from "components";
import {
  FilterBar,
  InvestorItem,
  ReachOutModal,
  SortSection,
} from "./components";

export const Investors = () => {
  const [selectedInvestor, setSelectedInvestor] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <Wrapper>
      <PageTitle>Investors</PageTitle>

      <FilterBar />

      <Warning
        text="As a Freemium user, you can contact only 5 investors per month. You can
        upgrade your profile by subscribing to a payed version and get full
        access to the full database of 140K investors"
        hasButton
        buttonText="Sign now"
      />

      <SortSection />

      <InvestorList>
        {[...Array(4)].map((_, index) => (
          <InvestorItem
            key={index}
            handleReachOut={(item) => {
              setIsOpenModal(true);
              setSelectedInvestor(item);
            }}
          />
        ))}
      </InvestorList>

      <ReachOutModal
        selected={selectedInvestor}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const InvestorList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-top: 32px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
