import styled from "styled-components/macro";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const PageWrapper = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  position: relative;
  padding: 30px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;
