import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const AppCenterIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.65 10.575C13.5167 10.575 13.3917 10.554 13.275 10.512C13.1583 10.4707 13.05 10.4 12.95 10.3L8.7 6.05C8.6 5.95 8.52933 5.84167 8.488 5.725C8.446 5.60833 8.425 5.48333 8.425 5.35C8.425 5.21667 8.446 5.09167 8.488 4.975C8.52933 4.85833 8.6 4.75 8.7 4.65L12.95 0.4C13.05 0.3 13.1583 0.229333 13.275 0.188C13.3917 0.146 13.5167 0.125 13.65 0.125C13.7833 0.125 13.9083 0.146 14.025 0.188C14.1417 0.229333 14.25 0.3 14.35 0.4L18.6 4.65C18.7 4.75 18.7707 4.85833 18.812 4.975C18.854 5.09167 18.875 5.21667 18.875 5.35C18.875 5.48333 18.854 5.60833 18.812 5.725C18.7707 5.84167 18.7 5.95 18.6 6.05L14.35 10.3C14.25 10.4 14.1417 10.4707 14.025 10.512C13.9083 10.554 13.7833 10.575 13.65 10.575ZM1 9C0.716667 9 0.479333 8.904 0.288 8.712C0.0960001 8.52067 0 8.28333 0 8V2C0 1.71667 0.0960001 1.479 0.288 1.287C0.479333 1.09567 0.716667 1 1 1H7C7.28333 1 7.521 1.09567 7.713 1.287C7.90433 1.479 8 1.71667 8 2V8C8 8.28333 7.90433 8.52067 7.713 8.712C7.521 8.904 7.28333 9 7 9H1ZM11 19C10.7167 19 10.4793 18.904 10.288 18.712C10.096 18.5207 10 18.2833 10 18V12C10 11.7167 10.096 11.479 10.288 11.287C10.4793 11.0957 10.7167 11 11 11H17C17.2833 11 17.5207 11.0957 17.712 11.287C17.904 11.479 18 11.7167 18 12V18C18 18.2833 17.904 18.5207 17.712 18.712C17.5207 18.904 17.2833 19 17 19H11ZM1 19C0.716667 19 0.479333 18.904 0.288 18.712C0.0960001 18.5207 0 18.2833 0 18V12C0 11.7167 0.0960001 11.479 0.288 11.287C0.479333 11.0957 0.716667 11 1 11H7C7.28333 11 7.521 11.0957 7.713 11.287C7.90433 11.479 8 11.7167 8 12V18C8 18.2833 7.90433 18.5207 7.713 18.712C7.521 18.904 7.28333 19 7 19H1ZM2 7H6V3H2V7ZM13.675 8.2L16.5 5.375L13.675 2.55L10.85 5.375L13.675 8.2ZM12 17H16V13H12V17ZM2 17H6V13H2V17Z"
        fill={color}
      />
    </svg>
  );
};
