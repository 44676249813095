import { omit } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import styled from "styled-components";

import { Box } from "@mui/material";
import organisationApi from "api/organisation.api";
import prospectApi from "api/prospect.api";
import { BasicTable, MultiSelect, Text, TextField } from "components";
import { Combobox } from "components/Feature/Combobox";
import { useAppDispatch } from "hooks/useHook";
import { Option, SelectItem, TBD } from "models/common.model";
import { Asset } from "models/report.model";
import { reportActions } from "store/report/report.slice";
import { getLatestFundraising } from "utils/system.util";

interface Props {
  title: string;
  data?: Asset[];
  id?: string;
  isPreview?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  register?: UseFormRegister<FieldValues>;
  control?: Control<FieldValues>;
  remainAsset?: Option[];
  updateRemainAsset?: () => void;
  assetList?: Asset[];
}

const CONFIG = [
  {
    name: "#",
    width: "auto",
  },
  {
    name: "Company",
    width: "auto",
  },
  {
    name: "Country",
    width: "auto",
  },
  {
    name: "Sector",
    width: "auto",
  },
  {
    name: "Stage",
    width: "auto",
  },
  {
    name: "Vintage year",
    width: "auto",
  },
  {
    name: "Capital invested",
    width: "auto",
  },
  {
    name: "IRR",
    width: "auto",
  },
  {
    name: "Annual Rev",
    width: "auto",
  },
  {
    name: "MRR",
    width: "auto",
  },
  {
    name: "RRR",
    width: "auto",
  },
  {
    name: "CBR",
    width: "auto",
  },
  {
    name: "MF Trust Score",
    width: 80,
  },
  {
    name: "Entrepreneurial Score",
    width: "auto",
  },
];

export const ReportTable = ({
  id,
  title,
  isPreview,
  data,
  setValue,
  register,
  control,
  remainAsset,
  updateRemainAsset,
  assetList,
}: Props) => {
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [countries, setCountries] = useState<Option[]>([]);
  const [rounds, setRounds] = useState<Option[]>([]);
  const [sectors, setSectors] = useState<SelectItem[]>([]);

  const dispatch = useAppDispatch();

  const vintages = useMemo(() => {
    return [...Array(10)].map((_, index) => {
      const year = new Date().getFullYear() - 9 + index;

      return { label: year.toString(), value: year.toString() };
    });
  }, []);

  const getCountries = async () => {
    try {
      const { countries } = await prospectApi.countriesGet();

      setCountries(countries);
    } catch (err) {
      //throw err as Error;
    }
  };

  const getRounds = async () => {
    try {
      const { fundingRounds } = await prospectApi.fundingRounds();

      setRounds(fundingRounds);
    } catch (err) {
      //throw err as Error;
    }
  };

  const getSectors = async () => {
    try {
      const { marketSectors } = await prospectApi.sectorsGet();

      setSectors(marketSectors);
    } catch (err) {
      //throw err as Error;
    }
  };

  const handleChangeAsset = useCallback(
    async (name: string, id?: string) => {
      if (!id) {
        if (setValue) {
          setValue(`${name}.id`, undefined);
          setValue(`${name}.displayName`, undefined);
          setValue(`${name}.country`, undefined);
          setValue(`${name}.sector`, undefined);
          setValue(`${name}.currentRound`, undefined);
          setValue(`${name}.link`, undefined);
          setValue(`${name}.assetType`, undefined);
          setValue(`${name}.CBR`, "");
          setValue(`${name}.IRR`, "");
          setValue(`${name}.MRR`, "");
          setValue(`${name}.RRR`, "");
          setValue(`${name}.annualRevenue`, "");
          setValue(`${name}.capitalInvested`, "");
          setValue(`${name}.mfScore`, "");
          setValue(`${name}.vintageYear`, "");
          setValue(`${name}.entrepreneurialScore`, "");
          setValue(`${name}.assetType`, "");

          setTimeout(() => {
            updateRemainAsset && updateRemainAsset();
          }, 300);
        }
        return;
      }

      let matches = (assetList || []).find((a) => a?.id === id);

      if (matches?.link) {
        const {
          organisationGet: { organisation },
        } = await organisationApi.organisationGet(matches.link.id);

        const { currentRound } = getLatestFundraising(
          organisation.fundraisingProjects
        );

        matches = {
          ...matches,
          country: organisation.country,
          sector: organisation.sector,
          currentRound,
          annualRevenue: organisation.annualRevenue,
          MRR: organisation.MRR,
          mfScore: Math.round(organisation.mfScore),
        };
      }

      if (matches && setValue) {
        setValue(name, matches);
        setValue(`${name}.country`, matches.country);
        setValue(`${name}.sector`, matches.sector);
        setValue(`${name}.currentRound`, matches.currentRound);
        setTimeout(() => {
          updateRemainAsset && updateRemainAsset();
        }, 300);
      }
    },
    [assetList, setValue, updateRemainAsset]
  );

  useEffect(() => {
    getCountries();
    getRounds();
    getSectors();
  }, []);

  useEffect(() => {
    dispatch(reportActions.getAssets());
  }, [dispatch]);

  useEffect(() => {
    const listData = isPreview && data ? data : [...Array(5).fill({})];

    const renderTableData = listData.map((asset, index) => {
      return {
        "#": (
          <GroupCell>
            <Text size="md">{index + 1}</Text>
          </GroupCell>
        ),
        company: (
          <GroupCell sx={{ ".combobox-wrapper": { width: "200px" } }}>
            {isPreview ? (
              <Text className="table-text text-wrap" size="md">
                {asset.displayName}
              </Text>
            ) : (
              <Controller
                name={`${id}[${index}].displayName`}
                control={control}
                render={({ field }) => (
                  <Combobox
                    id="combobox-displayName"
                    options={remainAsset || []}
                    placeholder="Fill data"
                    onClear={() => {
                      handleChangeAsset(`${id}[${index}]`);
                    }}
                    onchange={(option) => {
                      handleChangeAsset(`${id}[${index}]`, option.id);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    value={field.value || null}
                    isOptionEqualToValue={(option, value) => {
                      return option.value === value;
                    }}
                  />
                )}
              />
            )}
          </GroupCell>
        ),
        country: (
          <GroupCell sx={{ ".combobox-wrapper": { width: "180px" } }}>
            {isPreview ? (
              <Text className="table-text text-wrap" size="md">
                {asset.country?.name}
              </Text>
            ) : (
              <Controller
                name={`${id}[${index}].country`}
                control={control}
                render={({ field }) => (
                  <Combobox
                    id="combobox-country"
                    options={countries}
                    placeholder="Fill data"
                    onchange={(option) => {
                      field.onChange(option);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    getOptionLabel={(option) => option.name}
                    value={field.value || null}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                  />
                )}
              />
            )}
          </GroupCell>
        ),
        sector: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text text-wrap" size="md">
                {asset.sector?.map((i: SelectItem) => i?.name).join(", ")}
              </Text>
            ) : (
              <Controller
                name={`${id}[${index}].sector`}
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    name={`${id}[${index}].sector`}
                    select={field.value}
                    data={sectors}
                    onSave={() => null}
                    handleSelected={(data) => {
                      const value = data.map((option) => omit(option, "check"));
                      field.onChange(value);
                    }}
                    popoverElement={
                      <MultiSelectBox>
                        {field.value?.length ? (
                          <Text
                            className="table-text text-line-clamp"
                            size="md"
                          >
                            {field.value
                              .map((i: SelectItem) => i.name)
                              .join(", ")}
                          </Text>
                        ) : (
                          <Text size="md" fontSize="16px" color="#a5a7b4">
                            Fill data
                          </Text>
                        )}
                      </MultiSelectBox>
                    }
                  />
                )}
              />
            )}
          </GroupCell>
        ),
        currentRound: (
          <GroupCell sx={{ ".combobox-wrapper": { width: "160px" } }}>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.currentRound?.name}
              </Text>
            ) : (
              <Controller
                name={`${id}[${index}].currentRound`}
                control={control}
                render={({ field }) => (
                  <Combobox
                    id="combobox-currentRound"
                    options={rounds}
                    placeholder="Fill data"
                    onchange={(option) => {
                      field.onChange(option);
                    }}
                    value={field.value || null}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                  />
                )}
              />
            )}
          </GroupCell>
        ),
        vintageYear: (
          <GroupCell sx={{ ".combobox-wrapper": { width: "120px" } }}>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.vintageYear}
              </Text>
            ) : (
              <Controller
                name={`${id}[${index}].vintageYear`}
                control={control}
                render={({ field }) => (
                  <Combobox
                    id="combobox-vintageYear"
                    options={vintages}
                    onchange={(option) => {
                      field.onChange(option.value);
                    }}
                    placeholder="Fill data"
                    value={field.value || null}
                    isOptionEqualToValue={(option, value) => {
                      return option.value === value;
                    }}
                  />
                )}
              />
            )}
          </GroupCell>
        ),
        capitalInvested: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.capitalInvested || "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].capitalInvested`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
        IRR: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.IRR || "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].IRR`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
        annualRevenue: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.annualRevenue || "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].annualRevenue`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
        MRR: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.MRR || "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].MRR`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
        RRR: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.RRR || "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].RRR`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
        CBR: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.CBR || "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].CBR`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
        mfScore: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.mfScore ? `${asset.mfScore}%` : "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].mfScore`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
        entrepreneurialScore: (
          <GroupCell>
            {isPreview ? (
              <Text className="table-text" size="md">
                {asset.entrepreneurialScore
                  ? asset.entrepreneurialScore / 100
                  : "n.a."}
              </Text>
            ) : (
              <TextField
                type="number"
                placeholder="Fill data"
                name={`${id}[${index}].entrepreneurialScore`}
                formRef={register}
              />
            )}
          </GroupCell>
        ),
      };
    });

    setTableData(renderTableData);
  }, [
    control,
    countries,
    data,
    handleChangeAsset,
    id,
    isPreview,
    register,
    rounds,
    sectors,
    vintages,
    remainAsset,
  ]);

  return (
    <Wrapper>
      <BasicTable config={CONFIG} data={tableData} title={title} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 10px 0;

  .MuiTableCell-head > div {
    font-size: 14px;
    padding-right: 16px;
  }

  .MuiTable-root {
    .MuiAutocomplete-popper {
      position: fixed !important;
      z-index: 1;
    }
  }
`;

const GroupCell = styled(Box)`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
  padding-right: 16px;

  & > div {
    padding: 0px;
  }

  .text-field-wrapper {
    width: 120px;
  }

  .table-text {
    white-space: nowrap;

    &.text-wrap {
      white-space: normal;
    }

    &.text-line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 200px;
      white-space: normal;
      word-break: break-all;
    }
  }
`;

const MultiSelectBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 180px;
  height: 48px;
  border: 1px solid #ebecef;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 16px;
  cursor: pointer;

  .table-text {
    color: #373b59;
  }
`;
