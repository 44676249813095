import { SortFeature } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { SortItem } from "models/common.model";
import { selectSortSelected } from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";

interface Props {
  data: SortItem[];
}

export const ProspectSort = ({ data }: Props) => {
  const dispatch = useAppDispatch();
  const sortSelected = useAppSelector(selectSortSelected);

  const handleSelectSort = (item: SortItem) => {
    dispatch(feedActions.sortFeedsFE(item));
  };

  return (
    <SortFeature
      onSelect={handleSelectSort}
      selectedItem={sortSelected || data[0]}
      data={data}
    />
  );
};
