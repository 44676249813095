import { useEffect, useState } from "react";
import styled from "styled-components";

import ConfirmModal from "components/Feature/ConfirmModal";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Post } from "models/post.model";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { selectPostLoading, selectPosts } from "store/post/post.selector";
import { postActions } from "store/post/post.slice";
import {
  CreateNewUpdateModal,
  DownloadBar,
  InputSection,
  UpdatePost,
} from "./components";

const LIMIT = 6;

export const CompanyUpdates = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | undefined>();
  const [deletePostId, setDeletePostId] = useState<string | undefined>();
  const [page, setPage] = useState(1);

  const dispatch = useAppDispatch();
  const posts = useAppSelector(selectPosts);
  const { id: organisationId } = useAppSelector(selectOrganisation);
  const loading = useAppSelector(selectPostLoading);

  const deletePost = () => {
    if (deletePostId) {
      dispatch(
        postActions.deletePost({
          postId: deletePostId,
          organisationId,
          callback: () => {
            setDeletePostId(undefined);
          },
        })
      );
    }
  };

  const handleSelectPost = (data: Post) => {
    setIsOpenModal(true);
    setSelectedPost(data);
  };

  const handleLoadMoreItem = () => {
    setPage((prePage) => prePage + 1);
  };

  useEffect(() => {
    //dispatch(postActions.getPosts({ organisationId }));

    dispatch(
      postActions.getPosts({
        filter: {
          namespace: "Own",
          postedById: organisationId,
          status: null,
        },
      })
    );
  }, [dispatch, organisationId]);

  useEffect(() => {
    dispatch(organisationActions.getCompanyOrganisation());
  }, [dispatch]);

  useEffect(() => {
    const pageContainerElem =
      document.getElementsByClassName("page-container")?.[0];
    pageContainerElem &&
      pageContainerElem?.addEventListener("scroll", handleLoadMoreItem);
    return () =>
      pageContainerElem?.removeEventListener("scroll", handleLoadMoreItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Wrapper>
      <LeftBox>
        <InputSection handleClick={() => setIsOpenModal(true)} />

        <ListWrapper>
          {posts.slice(0, page * LIMIT).map((item) => (
            <UpdatePost
              key={item.id}
              data={item}
              deletePost={(id) => setDeletePostId(id)}
              handleSelectPost={handleSelectPost}
            />
          ))}
        </ListWrapper>
      </LeftBox>

      <RightBox>
        <DownloadBar />
      </RightBox>

      <CreateNewUpdateModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setSelectedPost(undefined);
        }}
        selectedPost={selectedPost}
      />

      <ConfirmModal
        title={"Are you sure to delete this post?"}
        isOpen={!!deletePostId}
        onClose={() => setDeletePostId(undefined)}
        callback={deletePost}
        loading={loading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const LeftBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
`;

const RightBox = styled.div`
  width: 352px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
