/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CachedResName, FixedData } from "models/cached.model";

export interface CachedState {
  marketSectors: FixedData;
  impactValues: FixedData;
  businessTypes: FixedData;
  productTypes: FixedData;
  countries: FixedData;
  fundingRounds: FixedData;
  fundingInstruments: FixedData;
  ticketSizeRanges: FixedData;
  currencies: FixedData;
}
export const initialState: CachedState = {
  marketSectors: { data: [] },
  impactValues: { data: [] },
  businessTypes: { data: [] },
  productTypes: { data: [] },
  countries: { data: [] },
  fundingRounds: { data: [] },
  fundingInstruments: { data: [] },
  ticketSizeRanges: { data: [] },
  currencies: { data: [] },
};

const cachedSlice = createSlice({
  name: "cached",
  initialState,
  reducers: {
    getFixedData(
      _,
      _action: PayloadAction<{
        resName: CachedResName;
        updateAt?: number;
        callApi: () => void;
      }>
    ) {},
    getFixedDataSuccess(
      state,
      _action: PayloadAction<{
        resName: CachedResName;
        data: FixedData["data"];
      }>
    ) {
      state[_action.payload.resName as keyof CachedState] = {
        data: _action.payload.data,
        updateAt: new Date().getTime(),
      };
    },
  },
});
export const {
  actions: cachedActions,
  reducer: cachedReducer,
  name: sliceKey,
} = cachedSlice;
