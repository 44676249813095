import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import commonApi from "api/common.api";
import { WarningIcon } from "assets/images/common";
import { ScrollToWrapper, Warning } from "components";
import ConfirmModal from "components/Feature/ConfirmModal";
import { PATH } from "constants/app.const";
import { NOTI_CODE } from "constants/firestore.const";
import { useAppDispatch, useAppParams, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { selectAuthMode } from "store/auth/auth.selector";
import { selectNamespaceId } from "store/global/global.selector";
import { selectImpacts, selectSectors } from "store/cached/cached.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import {
  selectOrganisation,
  selectOrganisationLoading,
  selectOrganisationProgress,
  selectPossibleEditOrganisation,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { db } from "utils/firebase.util";
import { scrollIntoView } from "utils/system.util";
import { ProgressSection, StatusBar } from "../components";
import {
  AboutSection,
  AdditionalInfoSection,
  AdminStatusSection,
  BusinessSection,
  CompanySection,
  HeaderSection,
  LocationSection,
  SelectSection,
} from "./components";
import { StatusIndicator } from "models/common.model";

export const CompanySettingsPage = () => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [activeSection, setActiveSection] =
    useState<RefObject<HTMLDivElement>>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id: organisationParamsId } = useAppParams();

  const namespaceId = useAppSelector(selectNamespaceId);
  const { status, id, message, displayName, logoUrl, fundraisingProjects } =
    useAppSelector(selectOrganisation);
  const organisationProgress = useAppSelector(selectOrganisationProgress);
  const confirmLoading = useAppSelector(selectOrganisationLoading);
  const possibleEditOrganisation = useAppSelector(
    selectPossibleEditOrganisation
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = [AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(
    authMode
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const companyRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const sectorsRef = useRef<HTMLDivElement>(null);
  const impactsRef = useRef<HTMLDivElement>(null);
  const businessRef = useRef<HTMLDivElement>(null);
  const locationRef = useRef<HTMLDivElement>(null);
  const additionalRef = useRef<HTMLDivElement>(null);

  const isCompleteSettings = !organisationProgress.find(
    (item) => !item.isComplete
  );

  const handleActiveSection = (activeSection: RefObject<HTMLDivElement>) => {
    setActiveSection(activeSection);

    setTimeout(() => {
      setActiveSection(undefined);
    }, 1000);
  };
  const progressList = organisationProgress.map((item) => ({
    ...item,
    onClick: () => {
      switch (item.label) {
        case "Logo":
        case "Cover": {
          scrollIntoView(headerRef);
          handleActiveSection(headerRef);
          break;
        }
        case "My Company": {
          scrollIntoView(companyRef);
          handleActiveSection(companyRef);
          break;
        }
        case "About": {
          scrollIntoView(aboutRef);
          handleActiveSection(aboutRef);
          break;
        }
        case "Sectors": {
          scrollIntoView(sectorsRef);
          handleActiveSection(sectorsRef);
          break;
        }
        case "Impacts": {
          scrollIntoView(impactsRef);
          handleActiveSection(impactsRef);
          break;
        }
        case "Business": {
          scrollIntoView(businessRef);
          handleActiveSection(businessRef);
          break;
        }
        case "Location": {
          scrollIntoView(locationRef);
          handleActiveSection(locationRef);
          break;
        }
        case "Additional Info": {
          scrollIntoView(additionalRef);
          handleActiveSection(additionalRef);
          break;
        }
        case "Team": {
          navigate(isAdmin ? `/admin-company/${id}/team` : PATH.COMPANY_TEAM);
          break;
        }
      }
    },
  }));

  const getButtonText = () => {
    if (status === StatusIndicator.PUBLISHED) {
      return "Unpublish and request edit";
    }

    if (isCompleteSettings) {
      return "Publish my company";
    }

    return "Preview";
  };

  const handleSubmitOrganisation = () => {
    if (!namespaceId) return;
    const onDone = async (newStatus: StatusIndicator) => {
      setIsOpenConfirmModal(false);

      const userRef = doc(db, "users", id);
      const userData = await getDoc(userRef);

      if (!userData.exists()) {
        await setDoc(userRef, {
          id,
          namespaceId,
          displayName,
          avatar: logoUrl,
          type: "founder",
        });
      }

      const notificationRef = collection(db, "notifications");

      await addDoc(notificationRef, {
        code: `${NOTI_CODE.REVIEW_ORG}__${id}__${id}__${newStatus}`,
        receiverId: namespaceId,
        namespaceId: namespaceId,
        createdAt: new Date().valueOf(),
        isRead: false,
        unreadCount: 1,
      });

      // When user submit ORG to review then submit FUND to review too
      if (
        newStatus === StatusIndicator.REVIEW &&
        status === StatusIndicator.FLAGGED &&
        fundraisingProjects[0]?.status === StatusIndicator.FLAGGED
      ) {
        dispatch(
          fundraisingActions.submitFundraising({
            id: fundraisingProjects[0].id,
            callback: async () => {
              await addDoc(notificationRef, {
                code: `${NOTI_CODE.REVIEW_FUND}__${id}__${fundraisingProjects[0].id}__${StatusIndicator.REVIEW}`,
                receiverId: namespaceId,
                namespaceId: namespaceId,
                createdAt: new Date().valueOf(),
                isRead: false,
                unreadCount: 1,
              });
            },
          })
        );
      }

      // When user unpublish ORG then unpublish FUND too
      // if (
      //   newStatus === StatusIndicator.DRAFT &&
      //   status === StatusIndicator.PUBLISHED &&
      //   fundraisingProjects[0]?.status === StatusIndicator.PUBLISHED
      // ) {
      //   dispatch(
      //     fundraisingActions.submitFundraising({
      //       id: fundraisingProjects[0].id,
      //       callback: async () => {
      //         await addDoc(notificationRef, {
      //           namespaceId,
      //           code: `${NOTI_CODE.REVIEW_FUND}__${id}__${fundraisingProjects[0].id}__${StatusIndicator.REVIEW}`,
      //           receiverId: namespaceId,
      //           createdAt: new Date().valueOf(),
      //           isRead: false,
      //           unreadCount: 1,
      //         });
      //       },
      //     })
      //   );
      // }
    };

    if (status === StatusIndicator.PUBLISHED) {
      dispatch(
        organisationActions.unPublishOrganisation({
          organisationId: id,
          callback: () => onDone(StatusIndicator.DRAFT),
        })
      );
      return;
    }

    if (isCompleteSettings) {
      dispatch(
        organisationActions.submitOrganisation({
          organisationId: id,
          callback: () => onDone(StatusIndicator.REVIEW),
        })
      );
      return;
    }
  };

  useEffect(() => {
    if (isAdmin && organisationParamsId) {
      dispatch(
        organisationActions.getOrganisation({
          id: organisationParamsId,
          isAdmin,
        })
      );
    } else {
      dispatch(organisationActions.getCompanyOrganisation());
    }
  }, [dispatch, isAdmin, organisationParamsId]);

  return (
    <Wrapper>
      <StatusBar status={status} />

      {status === StatusIndicator.FLAGGED && !isAdmin && (
        <Warning
          icon={<WarningIcon />}
          text={`Your Company request was rejected by the moderator.${
            message ? ` "${message}"` : ""
          }`}
          backgroundColor="#fff1f0"
          color="#b4453d"
        />
      )}

      <MainBox>
        <LeftBox>
          {isAdmin && <AdminStatusSection />}

          <ScrollToWrapper ref={headerRef} activeRef={activeSection}>
            <HeaderSection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={companyRef} activeRef={activeSection}>
            <CompanySection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={aboutRef} activeRef={activeSection}>
            <AboutSection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={sectorsRef} activeRef={activeSection}>
            <SelectSection
              title="Sectors"
              subTitle="Select at least 1 sector to describe your company"
              name="sector"
              resName="marketSectors"
              callApi={commonApi.sectorsGet}
              selector={selectSectors}
            />
          </ScrollToWrapper>

          <ScrollToWrapper ref={impactsRef} activeRef={activeSection}>
            <SelectSection
              title="Impacts"
              subTitle="Select at least 1 impact to describe your company"
              name="impactValues"
              resName="impactValues"
              callApi={commonApi.impactsGet}
              selector={selectImpacts}
            />
          </ScrollToWrapper>

          <ScrollToWrapper ref={businessRef} activeRef={activeSection}>
            <BusinessSection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={locationRef} activeRef={activeSection}>
            <LocationSection />
          </ScrollToWrapper>

          <ScrollToWrapper ref={additionalRef} activeRef={activeSection}>
            <AdditionalInfoSection />
          </ScrollToWrapper>
        </LeftBox>

        <RightBox>
          <ProgressSection
            progressList={progressList}
            completeText="All done! Your Company Settings are completed!"
            tooltipText="You need to complete all the sections below in order to be able to expose your company to the network and open a Funding round attached to your profile."
            buttonText={getButtonText()}
            buttonDisabled={
              status !== StatusIndicator.PUBLISHED && !possibleEditOrganisation
            }
            handleClickButton={
              !isCompleteSettings && status !== StatusIndicator.PUBLISHED
                ? () => navigate(PATH.COMPANY_PREVIEW)
                : () => setIsOpenConfirmModal(true)
            }
            hideFooter={isAdmin}
          />
        </RightBox>
      </MainBox>

      <ConfirmModal
        title={
          status === StatusIndicator.PUBLISHED
            ? "Unpublish and request edit"
            : "Publish my company"
        }
        subTitle={
          status === StatusIndicator.PUBLISHED
            ? "You are about to Unpublish your company. Once you update needed information, you can submit your Organisation for validation and Publish it again."
            : "You are about to Publish your Company and expose it to our pool of Investors"
        }
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        callback={handleSubmitOrganisation}
        loading={confirmLoading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const MainBox = styled.div`
  display: grid;
  grid-template-columns: auto 352px;
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr;
  }
`;

const LeftBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
`;

const RightBox = styled.div`
  width: 352px;

  & > div {
    @media (min-height: 700px) {
      position: sticky;
      top: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
