import { Slider, SliderProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

interface Props extends SliderProps {
  onMoveController: (value: number | number[]) => void;
}

export const SliderBar = ({ value, onMoveController, ...props }: Props) => {
  const [point, setPoint] = useState(value);

  const handleOnMoveEnd = () => {
    onMoveController(point as number);
  };

  useEffect(() => {
    setPoint(value);
  }, [value]);

  return (
    <SliderWrapper
      value={point}
      aria-label="Default"
      valueLabelDisplay="auto"
      onChange={(_, value) => setPoint(value as number)}
      onMouseUp={handleOnMoveEnd}
      {...props}
    />
  );
};

const SliderWrapper = styled(Slider)`
  &.MuiSlider-root {
    height: 8px;
    color: ${(props) => props.theme.palette.primary.main};
  }
  .MuiSlider-thumb {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
