/** Global config */
export const DATE_TIME_FORMAT = "DD/MM/YYYY H:mm";
export const DATE_FORMAT = "DD/MM/YYYY";

/** LocalStore keys */
export const USER_TOKEN = "USER_TOKEN";
export const AUTH_MODE = "AUTH_MODE";
export const WORKSPACE_TYPE_KEY = "WORKSPACE_TYPE_KEY";
export const WORKSPACE_UPDATE = "WORKSPACE_UPDATE";
export const NEW_WORKSPACES = "NEW_WORKSPACES";
export const WORKSPACE_ID = "WORKSPACE_ID";
export const LANGUAGE = "LANGUAGE";
export const APP_CONFIG = "APP_CONFIG";

export const SECRET_KEY = "MONOCO-CRYPTOJS-SECRET-KEY-01072023";

/** Enviroment */
export const ENV = {
  GRAPHQL_URI: process.env.REACT_APP_GRAPHQL_URI,
  GRAPHQL_KEY: process.env.REACT_APP_APP_KEY,
  AUTH_KEY: process.env.REACT_APP_FB_API_KEY,
  AUTH_DOMAIN: process.env.REACT_APP_FB_AUTH_DOMAIN,
  PROJECT_ID: process.env.REACT_APP_FB_PROJECT_ID,
  STORAGE_BUCKET: process.env.REACT_APP_FB_STORAGE_BUCKET,
  MESSAGING_SENDER_ID: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  APP_ID: process.env.REACT_APP_FB_APP_ID,
  MEASUREMENT_ID: process.env.REACT_APP_FB_MEASUREMENT_ID,
  GOOGLE_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  APP_CONFIG: process.env.REACT_APP_CONFIG,
};

/**Router */
export const PATH = {
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  WORKSPACES: "workspaces",
  DASHBOARD: "/deal-flow",
  ADMIN_VIEW: "/admin/admin-view",
  COMPANY_PREVIEW: "/company/preview",
  COMPANY_SETTINGS: "/company/settings",
  COMPANY_TEAM: "/company/team",
  COMPANY_MF_SCORE: "/company/mf-score",
  COMPANY_FUNDRAISING: "/company/fundraising",
  COMPANY_FUNDS: "/company/funds",
  ADMIN_COMPANY: "/admin/company",
  ADMIN_FUNDRAISING: "/admin/fundraising",
};

/** FirebaseConfig */
export const FIREBASE_CONFIG = {
  apiKey: ENV.AUTH_KEY,
  authDomain: ENV.AUTH_DOMAIN,
  projectId: ENV.PROJECT_ID,
  storageBucket: ENV.STORAGE_BUCKET,
  messagingSenderId: ENV.MESSAGING_SENDER_ID,
  appId: ENV.APP_ID,
  measurementId: ENV.MEASUREMENT_ID,
};

/** TIME LIMIT */
export const LIMIT_TIME_AUTHEN = 10000;
export const LIMIT_FIXED_DATA_FETCHING = 4 * 60 * 60 * 1000; // 4 hours

export const SUPPER_ADMIN_ROLE = ["admin", "owner"];
export const MB = 1024 * 1024;

export const ADMIN_ID = "super-admin";
export const LIMIT_ROOM_FETCHING = 20;
export const LIMIT_MESSAGE_FETCHING = 50;
export const LIMIT_NOTIFICATION_FETCHING = 20;

/** Roles */
export enum Roles {
  admin = "admin",
  owner = "owner",
  editor = "editor",
  user = "user",
}

/** Chart */
export const CHART_BACKGROUND_COLOR = [
  "#373B59",
  "#6A6D84",
  "#A5A7B4",
  "#D8D9DF",
  "#ECE2D1",
  "#D4BF99",
];

export const defaultTheme = {
  primary: {
    main: "#C9AF7F",
    dark: "#796A4D",
    light: "#C9AF7F",
  },
  secondary: {
    main: "#373B59",
    light: "#6A6D84",
    dark: "#050A30",
  },
  error: {
    main: "#D25C54",
  },
  success: {
    main: "#3B864C",
  },
  primaryText: {
    main: "#373B59",
    light: "#6A6D84",
    dark: "#050A30",
  },
};

export const defaultAppConfig = {
  projectId: "wl-myfoundry",
  id: "dc81d15f-218d-4bbc-b22e-aa16c4974a6e",
  bannerLogo: "/banner-logo.svg",
  headerLogo: "/header-logo.png",
  faviLogo: "/favicon.ico",
  avatarLogo: "/header-logo.png",
  brandName: "MYFOUNDRY",
  brandUrl: "https://www.monacofoundry.com",
  brandLabel: "https://www.monacofoundry.com",
  title: "Startup Ecosystem on Autopilot",
  description:
    "Monaco Foundry is changing the world of private investing. Our ecosystem makes it possible for more founders to get funding and more investors to participate in private investments with more trust.",
  themeConfig: defaultTheme,
  updateFeed: { active: false },
  lockMFT: { active: false },
  namespaceFilter: { active: true, value: "Own" },
  feedPromote: {
    active: false,
    value: "https://stripe.com/payments/checkout",
  },
  menuTab: [
    {
      active: false,
      value: "/admin/admin-view",
    },
    {
      active: false,
      value: "/funds",
    },
  ],
};
