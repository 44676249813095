import styled from "styled-components";

import { Text } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import _ from "lodash";
import {
  selectCountFilter,
  selectFilterOptions,
} from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";

export const FavoriteFilter = () => {
  const dispatch = useAppDispatch();
  const countFeed = useAppSelector(selectCountFilter);
  const { favorites } = useAppSelector(selectFilterOptions);
  const isActive = !_.isEmpty(favorites);

  const handleFilter = () => {
    dispatch(
      feedActions.filterFeed({
        filter: { favorites: isActive ? [] : ["apply"] },
        isApply: true,
      })
    );
  };

  return (
    <Filter className={isActive ? "active" : undefined} onClick={handleFilter}>
      <Text size="md">Favorites</Text>

      {isActive && <NumberBox>{countFeed}</NumberBox>}
    </Filter>
  );
};

const NumberBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #b4bac4;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 0 8px;
  background-color: #ffffff;
  border: 1px solid #d8d9df;
  gap: 6px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: #d8d9df2f;
  }

  ${NumberBox} {
    background: #ebecf0;
  }

  &.active {
    background-color: #d8d9df;

    ${NumberBox} {
      background-color: #b4bac4;
    }
  }
`;
