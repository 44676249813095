import { Avatar } from "components";
import styled from "styled-components";

export const InfoSection = () => {
  return (
    <Wrapper>
      <div className="info">
        <Avatar
          src="https://bit.ly/ryan-florence"
          name="Spectre Biotech"
          size={48}
          rounded="12px"
        />

        <div className="name">Spectre Biotech</div>
      </div>

      <div className="description">
        The new brain imaging processing standard
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .info {
    display: flex;
    align-items: center;
    gap: 24px;

    .name {
      color: ${(props) => props.theme.palette.secondary.dark};
      font-size: 31px;
      font-weight: 700;
    }
  }

  .description {
    margin-top: 16px;
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 20px;
  }
`;
