import { FormControlUnstyled, FormControlUnstyledProps } from "@mui/base";
import { SearchIcon } from "assets/images/common";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

interface Props extends FormControlUnstyledProps {
  onSearch: (value: string) => void;
  autoSearch: boolean;
  defaultValue?: string;
  placeholder?: string;
}

export const SearchBox = ({
  onSearch,
  autoSearch,
  placeholder = "Search company name",
  defaultValue,
  ...props
}: Props) => {
  const [search, setSearch] = useState<string>(defaultValue || "");
  const typingTimeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearch(value);
    }, 300);
  };

  const handleSubmit = () => {
    if (!autoSearch) {
      onSearch(search);
    }
  };

  useEffect(() => {
    if (autoSearch) {
      onSearch(search);
    }
  }, [autoSearch, onSearch, search]);

  return (
    <Form onSubmit={handleSubmit} {...props} className="search-box">
      <SearchIcon />
      <SearchInput
        defaultValue={defaultValue}
        onChange={handleOnChange}
        placeholder={placeholder}
      />
    </Form>
  );
};

const Form = styled(FormControlUnstyled)`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #ebecef;
  border-radius: 4px;
  max-height: 44px;
  padding: 0 20px;
  overflow: hidden;
  & fieldset {
    border: none;
  }
`;

const SearchInput = styled.input`
  line-height: 48px;
  border: 0;
  outline: none;
  font-size: 14px;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};

  &::placeholder {
    color: #a5a7b4;
  }
`;
