import { Chart } from "chart.js/auto";
import { useEffect, useRef } from "react";
import styled from "styled-components";

import { CHART_BACKGROUND_COLOR } from "constants/app.const";

interface Props {
  labels: string[];
  data: number[];
  othersTooltipData?: { [key: string]: number };
}

export const PieChart = ({ labels, data, othersTooltipData }: Props) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"pie"> | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "pie",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: CHART_BACKGROUND_COLOR,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
              },
            },
            tooltip: {
              callbacks: {
                footer: (item) => {
                  if (item[0].label === "Others" && othersTooltipData) {
                    return Object.entries(othersTooltipData).map(
                      ([key, value]) => `${key}: ${value}`
                    );
                  }
                },
              },
            },
          },
        },
      });
    }
  }, [data, labels, othersTooltipData]);

  return (
    <Wrapper>
      <canvas ref={chartRef} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
