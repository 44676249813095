import styled from "styled-components/macro";

import { SuccessIcon } from "assets/images/common";
import { Button, Text } from "components";
import { RadarChart } from "components/UI/Others/RadarChart";
import { FounderScoreResult } from "models/organisation.model";
import { useState } from "react";

interface Props {
  scoreResult: FounderScoreResult[];
}

const chartStyleConfig = {
  backgroundColor: "#050a302b",
  borderColor: "#050a30",
  pointBackgroundColor: "#050a30",
  pointBorderColor: "#fff",
  pointHoverBackgroundColor: "#fff",
  pointHoverBorderColor: "#050a30",
};

export const ThankYouScreen = ({ scoreResult }: Props) => {
  const [isShowDiagram, setIsShowDiagram] = useState(false);
  const chartData = {
    labels: scoreResult.map((item) => item.text),
    data: scoreResult.map((item) => Math.round(item.normalized_score * 100)),
    chartStyleConfig,
    othersTooltipData: scoreResult.map((item) => {
      return {
        label: item.text,
        value: item.feedback,
      };
    }),
  };

  const founderAverageScore =
    (scoreResult.reduce((acc, item) => acc + item.normalized_score, 0) /
      scoreResult?.length) *
    100;

  return (
    <Wrapper className="form-item">
      {!isShowDiagram && (
        <>
          <SuccessIcon />
          <TextWrapper>
            <Text size="lg">
              The Founder Score (out of 100%) is:{" "}
              <span className="score">{Math.round(founderAverageScore)}%</span>
              <span>{` `}</span>
              <span
                className="link-text"
                onClick={() => setIsShowDiagram(true)}
              >
                {`(Display the result as a diagram)`}
              </span>
            </Text>

            {/* <Text size="lg">
              This result will be automatically displayed on your Company brief.
            </Text>

            <Text size="lg">
              The breakdown report will be added to your Company brief within 48
              hours.
            </Text> */}
          </TextWrapper>
        </>
      )}

      {isShowDiagram && (
        <ChartWrapper>
          <Button
            className="back-button"
            onClick={() => setIsShowDiagram(false)}
          >
            Back
          </Button>
          {chartData.data && chartData.data.length && (
            <RadarChart {...chartData} />
          )}
        </ChartWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: 100%;
  padding: 20px;

  & > svg {
    width: 140px;
    height: 140px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;

  .link-text {
    font-weight: 600;
    color: #373b59;
    text-decoration: underline;
    cursor: pointer;
  }

  .score {
    color: #58a369;
    font-size: 24px;
    font-weight: 700;
  }
`;

const ChartWrapper = styled.div`
  position: relative;
  width: calc(78% - 100px);
  max-width: 1200px;

  .back-button {
    position: absolute;
    top: 20%;
    left: -20%;
  }
`;
