import { useEffect } from "react";
import styled from "styled-components";
import * as yup from "yup";

import commonApi from "api/common.api";
import {
  FundraisingDropdown,
  FundraisingFieldInput,
  SectionWrapper,
} from "components";
import { FundraisingDatePicker } from "components/Business/Fundraising/FundrasingDatePicker";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import {
  selectFundingInstruments,
  selectFundingRounds,
} from "store/cached/cached.selector";
import { cachedActions } from "store/cached/cached.slice";
import { SelectSection } from "./SelectSection";

export const RasingInfoSection = () => {
  const dispatch = useAppDispatch();
  const fixedFundingRounds = useAppSelector(selectFundingRounds);

  const rounds = fixedFundingRounds.data.map((i) => ({
    ...i,
    label: i.name,
  }));

  const numberYup = yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .min(0, "The field value must be greater than or equal to 0.");

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName: "fundingRounds",
        callApi: commonApi.fundingRounds,
        updateAt: fixedFundingRounds.updateAt,
      })
    );
  }, [dispatch, fixedFundingRounds.updateAt]);

  return (
    <SectionWrapper title="Raising Info">
      <Container>
        <Form>
          <FundraisingFieldInput
            fieldType="number"
            fieldName="Raising amount"
            name="roundSize"
            placeholder="Fill Raising Amount"
            yupSchema={numberYup.min(
              1,
              "The amount you are raising should be greater than 0"
            )}
          />

          <FundraisingDropdown
            fieldName="Current Round"
            name="currentRound"
            placeholder="Fill Current Round"
            freeSolo={false}
            options={rounds}
          />

          <SelectSection
            subTitle="Instrument"
            name="instrument"
            resName="fundingInstruments"
            callApi={commonApi.fundingInstruments}
            selector={selectFundingInstruments}
            inline
          />

          <FundraisingFieldInput
            fieldType="number"
            fieldName="Minimum Ticket Size"
            name="minTicketSize"
            placeholder="Fill Ticket Size"
            yupSchema={numberYup.min(
              100000,
              "The minimum ticket size allowed on MyFoundry platform is 100 000"
            )}
          />

          <FundraisingDatePicker
            fieldName="Fundraising round end date"
            name="roundCloseDate"
            placeholder="Fill Fundraising round end date"
          />
        </Form>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;
