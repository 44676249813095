import { yupResolver } from "@hookform/resolvers/yup";
import { linkClasses } from "@mui/material";
import { Button, GroupText, Link } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useAppDispatch } from "hooks/useHook";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { globalActions } from "store/global/global.slice";
import styled from "styled-components/macro";
import * as yup from "yup";

const ForgotPasswordForm = () => {
  const dispatch = useAppDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(decodeURI(search)), [search]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgotPassword = async (data: FieldValues) => {
    const { email } = data;
    try {
      setSubmitLoading(true);
      await sendPasswordResetEmail(getAuth(), email);
      setSubmitLoading(false);
      dispatch(
        globalActions.pushNotification({
          message:
            "The reset password link is sent to email successfully. Please check your email!",
          type: "success",
        })
      );
    } catch (err) {
      setSubmitLoading(false);
      dispatch(
        globalActions.pushNotification({
          message: "Something went wrong. Please try again!",
          type: "error",
        })
      );
      //throw err as Error;
    }
  };

  useEffect(() => {
    const email = query.get("email");

    if (email) {
      setValue("email", email);
    }
  }, [query, setValue]);

  return (
    <Wrapper onSubmit={handleSubmit(handleForgotPassword)}>
      <GroupTextField
        fieldName="Work email"
        placeholder="email@company.com"
        name="email"
        formRef={register}
        error={errors.email?.message as string}
      />

      <Footer>
        <GroupText>
          <Link href="/sign-in">Back to Login</Link>
        </GroupText>
        <Button type="submit" loading={submitLoading}>
          Send request
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default ForgotPasswordForm;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 30px;
  .${linkClasses.root} {
    color: #a5a7b4;
    text-decoration-color: #a5a7b4;
  }

  & button[type="submit"] {
    width: 200px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
`;
