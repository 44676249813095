import { useAppSelector } from "hooks/useHook";
import { selectPageLoading } from "store/global/global.selector";
import styled from "styled-components/macro";

export const PageLoading = () => {
  const pageLoading = useAppSelector(selectPageLoading);

  return <Wrapper>{pageLoading && <LoadingAnimation />}</Wrapper>;
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
`;

const LoadingAnimation = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  &::after {
    content: "";
    left: 50%;
    top: 50%;
    position: absolute;

    display: inline-block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 2px solid rgba(0, 92, 95, 0.2);
    border-top-color: #005c5f;
    animation: spin 0.8s ease-in-out infinite;
    -webkit-animation: spin 0.8s ease-in-out infinite;
  }
  &::before {
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
  }

  @keyframes spin {
    from {
      transform: translate(-50%, -50%) rotate(0);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
