import styled from "styled-components/macro";
import LeftSideBar from "./components/LeftSideBar";
import TopBar from "./components/TopBar";

interface Props {
  children: React.ReactNode;
}

export const UserLayout: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <TopBar />
      <Container>{children}</Container>
    </Wrapper>
  );
};

export const DashboardLayout: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <TopBar />
      <LeftSideBar />
      <Container>{children}</Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding-top: 64px;
  background-color: #ffffff;
  height: 100vh;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding-left: 0;
  }
`;
const Container = styled.div.attrs({
  className: "page-container",
})`
  flex-basis: 100%;
  height: 100%;
  overflow-y: auto;
`;
