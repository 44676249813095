import { createSelector } from "@reduxjs/toolkit";
import appConfigFromJson from "../../appConfig.json";
import { GlobalState, initialState } from "./global.slice";

const selectDomain = (state: { global: GlobalState }) =>
  state.global || initialState;

export const selectPageLoading = createSelector(
  [selectDomain],
  (globalState) => globalState.loading
);

export const selectIsExpandMenu = createSelector(
  [selectDomain],
  (globalState) => globalState.isExpandMenu
);

export const selectNotification = createSelector(
  [selectDomain],
  (globalState) => globalState.notification
);

export const selectNotificationModal = createSelector(
  [selectDomain],
  (globalState) => globalState.notificationModal
);

export const selectAppConfig = createSelector(
  [selectDomain],
  (globalState) => globalState.appConfig
);

export const selectNamespaceId = createSelector(
  [selectDomain],
  (globalState) => globalState.appConfig.id || appConfigFromJson.id
);

export const selectHeaderSearch = createSelector(
  [selectDomain],
  (globalState) => globalState.headerSearch
);
