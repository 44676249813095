import { ToggleButton } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Prospect } from "models/prospect.model";
import { selectPageLoading } from "store/global/global.selector";
import { globalActions } from "store/global/global.slice";
import { selectProspect } from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";

interface Props {
  label?: string;
  name: string;
  forLabel?: boolean;
}

export const ProspectToggleButton = ({ label, name, forLabel }: Props) => {
  const dispatch = useAppDispatch();
  const prospect = useAppSelector(selectProspect);
  const value = prospect[name as keyof Prospect] as boolean;
  const loading = useAppSelector(selectPageLoading);

  const handleUpdateField = (value: boolean) => {
    try {
      dispatch(globalActions.setPageLoading(true));
      const data = {
        [name]: value,
      };
      if (!prospect?.id) {
        dispatch(prospectActions.createProspect(data));
      } else {
        dispatch(
          prospectActions.updateProspect({
            prospectUpdateId: prospect?.id,
            input: data,
          })
        );
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  return (
    <ToggleButton
      label={forLabel ? (value ? label : " ") : label}
      disabled={loading}
      value={value}
      onchange={handleUpdateField}
    />
  );
};
