import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const VideoIcon = ({ color = "#6A6D84" }: Props) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.05 11.5L13.2 8.85C13.5167 8.66667 13.675 8.38333 13.675 8C13.675 7.61667 13.5167 7.33333 13.2 7.15L9.05 4.5C8.71667 4.28333 8.375 4.26667 8.025 4.45C7.675 4.63333 7.5 4.925 7.5 5.325V10.675C7.5 11.075 7.675 11.3667 8.025 11.55C8.375 11.7333 8.71667 11.7167 9.05 11.5ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM2 14H18V2H2V14Z"
        fill={color}
      />
    </svg>
  );
};
