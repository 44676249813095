import { yupResolver } from "@hookform/resolvers/yup";
import { FieldEditor, Text } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { SelectItemTask } from "models/common.model";
import { Prospect } from "models/prospect.model";
import { useEffect, useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { globalActions } from "store/global/global.slice";
import {
  selectProspect,
  selectProspectLoading,
} from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";
import { uploadFromBlobAsync } from "utils/firebase.util";
import {
  validateMaxSizeFile,
  validateVideoDurationTime,
} from "utils/system.util";
import * as yup from "yup";

interface Props {
  name: string;
  yupSchema: yup.AnySchema;
  placeholder?: string;
  textClass?: string;
  unit?: string;
  linkName?: string;
  accept?: string;
}

const EXCEPTION_FIELDS = ["mfScore"];

export const ProspectMediaEditor = ({
  name,
  yupSchema,
  placeholder,
  textClass,
  unit,
  linkName,
  accept = "audio/*, image/*, application/pdf",
}: Props) => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectProspectLoading);
  const prospect = useAppSelector(selectProspect);
  const fieldData = prospect[name as keyof Prospect];
  const [file, setFile] = useState<File | undefined>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const schema = yup.object().shape({ [name]: yupSchema });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue(name, fieldData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData, name, setValue, prospect[name as keyof Prospect]]);

  const getFolderName = (fileType: string) => {
    if (name === "mfScoreUrl") return "report";
    if (fileType.includes("image")) return "image";
    if (fileType.includes("pdf")) return "pdf";
    if (fileType.includes("video")) return "video";
    return "";
  };

  const handleFileBrowser = async (file: File | undefined) => {
    if (file) {
      let validData = {
        isValid: true,
        errorMessage: "",
      };

      if (file.type.includes("video")) {
        validData = await validateVideoDurationTime(file);
      } else {
        validData = validateMaxSizeFile(file);
      }

      const { isValid, errorMessage } = validData;

      if (isValid) {
        setFile(file);
      } else {
        setFile(undefined);
        dispatch(
          globalActions.showNotificationModal({
            isShow: true,
            title: errorMessage,
            onSubmit: (onClose) => {
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              onClose();
            },
          })
        );
      }
      return;
    }
  };

  const handleUploadFile = async () => {
    if (prospect?.id && file && getFolderName(file.type)) {
      try {
        dispatch(globalActions.setPageLoading(true));
        uploadFromBlobAsync({
          blob: file,
          name: prospect.id,
          folder: `CompanyBrief/${getFolderName(file.type)}`,
        }).then((url) => {
          dispatch(
            prospectActions.updateProspect({
              prospectUpdateId: prospect.id,
              input: {
                [name]: url,
              },
            })
          );
          dispatch(globalActions.setPageLoading(false));
        });
      } catch (error) {
        dispatch(globalActions.setPageLoading(false));
      }
    }
  };

  const handleUpdateField = async (
    data: FieldValues | { [key: string]: SelectItemTask[] }
  ) => {
    try {
      if (!prospect?.id) {
        dispatch(prospectActions.createProspect(data));
      } else {
        if (EXCEPTION_FIELDS.includes(name)) {
          dispatch(
            prospectActions.setProspectMfScore({
              prospectSetMfScoreId: prospect.id,
              input: data as { mfScore: number },
            })
          );
        } else {
          dispatch(
            prospectActions.updateProspect({
              prospectUpdateId: prospect.id,
              input: data,
            })
          );
        }
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  return (
    <FieldEditor
      loading={submitLoading}
      onHandle={file ? handleUploadFile : handleSubmit(handleUpdateField)}
      textElement={
        linkName ? (
          <Link className={textClass}>
            {linkName === name ? getValues(name) || placeholder : linkName}
          </Link>
        ) : (
          <Text size={"lg"} className={`display-text ${textClass}`}>
            {getValues(name) ||
              prospect[name as keyof Prospect] ||
              placeholder ||
              "Fill data"}
            {`${(getValues(name) && unit) || ""}`}
          </Text>
        )
      }
      inputElement={
        <UploadFileWrapper>
          <Text size="md">You can upload your file or put the link</Text>
          <UploadFileButton
            ref={fileInputRef}
            type="file"
            name="Upload file"
            accept={accept}
            onChange={(event) => handleFileBrowser(event?.target?.files?.[0])}
          />
          <GroupTextField
            name={name}
            placeholder="Please fill data"
            type="input"
            formRef={register}
            error={errors?.[name]?.message as string}
          />
        </UploadFileWrapper>
      }
    ></FieldEditor>
  );
};

const Link = styled.div`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.secondary.main};
`;

const UploadFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  padding: 20px;
`;

const UploadFileButton = styled.input`
  display: inline-block;
  border: 1px solid #ebecef;
  border-radius: 16px;
  padding: 12px 20px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 14px;
  font-weight: 500;
`;
