import { Alert, Snackbar, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { selectNotification } from "store/global/global.selector";
import { globalActions } from "store/global/global.slice";

export const NotificationToast = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotification);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(globalActions.pushNotification(undefined));
  };

  if (!notification) return null;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={!!notification}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <AlertWrapper onClose={handleClose} severity={notification?.type}>
        {notification?.message}
      </AlertWrapper>
    </Snackbar>
  );
};

const AlertWrapper = styled(Alert)`
  max-width: 380px;
  width: 100%;
  height: fit-content;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  box-shadow: 0px 3px 5px rgb(5 10 48 / 5%);
`;
