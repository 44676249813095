import styled from "styled-components";

import { Avatar, SectionWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";

export const TeamMembersSection = () => {
  const { teamMembers } = useAppSelector(selectOrganisation);

  return (
    <SectionWrapper title="Team Members">
      <Container>
        {teamMembers?.map((member) => (
          <Member key={member.id}>
            <div className="info">
              <Avatar
                src={member.photoUrl}
                name={member.displayName}
                size={64}
              />

              <div className="text">
                <div className="text__name">{member.displayName}</div>
                <div className="text__role">{member.role}</div>
              </div>
            </div>

            <Text size="sm" className="light-text" fontSize="16px">
              {member.bio}
            </Text>
          </Member>
        ))}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > div:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
`;

const Member = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #ebecef;

  .light-text {
    color: ${(props) => props.theme.palette.text.secondary};
  }

  .info {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    .text {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__name {
        color: ${(props) => props.theme.palette.text.dark};
        font-size: 20px;
        font-weight: 700;
      }

      &__role {
        color: ${(props) => props.theme.palette.text.secondary};
        font-size: 16px;
      }
    }
  }
`;
