import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { marketplaceApi } from "api/marketplace.api";
import { PageTitle, SortFeature } from "components";
import { useAppDispatch } from "hooks/useHook";
import { SortItem } from "models/common.model";
import { MarketplaceItem } from "models/marketplace.model";
import { globalActions } from "store/global/global.slice";
import { ListItem } from "./components";

const SORT_DATA: SortItem[] = [
  {
    id: "0",
    name: "Relevant",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "",
    order: "desc",
  },
];

export const MarketplacePage = () => {
  const [marketplaceList, setMarketplaceList] = useState<MarketplaceItem[]>([]);

  const dispatch = useAppDispatch();

  const getMarketplaceList = useCallback(async () => {
    dispatch(globalActions.setPageLoading(true));

    try {
      const { marketplaceGet } = await marketplaceApi.getMarketplace();

      setMarketplaceList(marketplaceGet);
      dispatch(globalActions.setPageLoading(false));
    } catch (_) {
      dispatch(globalActions.setPageLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getMarketplaceList();
  }, [getMarketplaceList]);

  return (
    <Wrapper>
      <PageTitle>Marketplace</PageTitle>

      <SortBar>
        <div className="result">{marketplaceList.length} results</div>

        <SortFeature
          data={SORT_DATA}
          selectedItem={SORT_DATA[0]}
          onSelect={() => null}
        />
      </SortBar>

      <ListWrapper>
        {marketplaceList.map((item) => (
          <ListItem key={item.id} data={item} />
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const SortBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .result {
    color: #7c8698;
    font-size: 16px;
  }
`;

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
`;
