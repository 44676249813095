import { IconButton, Popover } from "@mui/material";
import { EditIcon } from "assets/images/common";
import { ProspectStatus } from "models/prospect.model";
import { useRef, useState } from "react";
import styled from "styled-components/macro";

interface Props {
  data: string[];
  selectedItem: string;
  onSelect: (value: ProspectStatus) => void;
  className?: string;
}

export const CustomSelect = ({
  data = [],
  selectedItem,
  onSelect,
  className,
}: Props) => {
  const [openDropBox, setOpenDropBox] = useState(false);
  const dropBoxsRef = useRef(null);

  return (
    <Wrapper className={className}>
      <DisplayBox
        className={selectedItem}
        onClick={() => setOpenDropBox(!openDropBox)}
        ref={dropBoxsRef}
      >
        {selectedItem || "Unset"}
        <IconWrapper>
          <EditIcon />
        </IconWrapper>
      </DisplayBox>
      <Popover
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: "180px",
            borderRadius: "16px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
        anchorEl={dropBoxsRef.current}
        onClose={() => {
          setOpenDropBox(!openDropBox);
        }}
        open={openDropBox}
      >
        <PopoverWrapper>
          {data.map((item) => (
            <Item
              key={item}
              onClick={() => {
                onSelect(item as ProspectStatus);
                setOpenDropBox(false);
              }}
              className={item}
            >
              {item}
            </Item>
          ))}
        </PopoverWrapper>
      </Popover>
    </Wrapper>
  );
};

const DisplayBox = styled.div`
  width: 100%;
  line-height: 30px;
  padding: 4px 12px;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.5s;
  position: relative;

  &.draft {
    background-color: #ebecef;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  &.review {
    background-color: rgba(253, 209, 80, 0.1);
    color: #ca9600;
  }
  &.published {
    background-color: rgba(52, 168, 83, 0.1);
    color: #2a8944;
  }
  &.flagged {
    background-color: #d23b3b4b;
    color: #d23b3bf3;
  }

  &:hover {
    transform: scaleX(1.05);
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding-right: 32px;
`;

const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  gap: 20px;
`;

const Item = styled.div`
  background-color: #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  line-height: 40px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;

  &.draft {
    background-color: #ebecef;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  &.review {
    background-color: rgba(253, 209, 80, 0.1);
    color: #ca9600;
  }
  &.published {
    background-color: rgba(52, 168, 83, 0.1);
    color: #2a8944;
  }
  &.flagged {
    background-color: #d23b3b4b;
    color: #d23b3bf3;
  }

  &:hover {
    transform: scaleX(1.1);
  }
`;

const IconWrapper = styled(IconButton)`
  position: absolute;
  top: -26px;
  left: -10px;

  svg {
    width: 18px;
    height: auto;
  }
`;
