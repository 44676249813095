import { HTMLAttributes } from "react";
import styled from "styled-components/macro";

interface StyledProps {
  size: "sm" | "md" | "lg" | "xl" | "xxl";
  color?: string;
  fontWeight?: number;
  fontSize?: string;
  limit?: number;
}
interface Props extends StyledProps, HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const mapStyle: {
  [key: string]: any;
} = {
  ["sm"]: { s: "12px", w: 400 },
  ["md"]: { s: "14px", w: 500 },
  ["lg"]: { s: "18px", w: 600 },
  ["xl"]: { s: "24px", w: 600 },
  ["xxl"]: { s: "32px", w: 700 },
};

export const Text = ({ children, size = "md", limit, ...props }: Props) => {
  return (
    <Wrapper
      className={limit ? "truncate" : ""}
      size={size}
      limit={limit}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* color: ${(props) => props.theme.palette.text.primary}; */
  color: ${(props: StyledProps) => props.color};
  font-size: ${(props: StyledProps) =>
    props.fontSize || mapStyle[props.size].s};
  font-weight: ${(props: StyledProps) =>
    props.fontWeight || mapStyle[props.size].w};
  white-space: pre-wrap;
  word-break: break-word;

  &.truncate {
    display: -webkit-box;
    -webkit-line-clamp: ${(props: StyledProps) => props.limit};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
