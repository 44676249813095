import { MultiSelect, Text } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { SelectItem, SelectItemTask } from "models/common.model";
import { Prospect } from "models/prospect.model";
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { selectProspect } from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";

interface Props {
  name: string;
  resName: string;
  callApi: () => Promise<any>;
}

export const ProspectMultiSelect = ({ name, resName, callApi }: Props) => {
  const dispatch = useAppDispatch();
  const [listData, setListData] = useState<SelectItem[]>([]);
  const prospect = useAppSelector(selectProspect);
  const selectedList = prospect[name as keyof Prospect] as SelectItem[];

  const handleSelect = async (
    data: FieldValues | { [key: string]: SelectItemTask[] }
  ) => {
    try {
      if (prospect?.id) {
        dispatch(
          prospectActions.updateProspect({
            prospectUpdateId: prospect.id,
            input: data,
          })
        );
      } else {
        dispatch(prospectActions.createProspect(data));
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  useEffect(() => {
    if (callApi) {
      const getData = async () => {
        const data = await callApi();
        setListData(data?.[resName as keyof Prospect]);
      };

      getData();
    }
  }, [callApi, resName]);

  return (
    <MultiSelect
      name={name}
      select={selectedList}
      data={listData}
      onSave={handleSelect}
    >
      <Text size={"lg"} className="display-text">
        {selectedList?.map((i) => i?.name).join(", ") || "Select data"}
      </Text>
    </MultiSelect>
  );
};
