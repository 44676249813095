import dayjs from "dayjs";
import _ from "lodash";
import { RefObject } from "react";

import { MB } from "constants/app.const";
import { Message } from "models/chat.model";
import { FileTypes, StatusIndicator, ValidFileType } from "models/common.model";
import { Fundraising } from "models/fundraising.model";
import { Currency, TeamMember } from "models/organisation.model";
import { Feed } from "models/prospect.model";

export const humanFileSize = (size: number) => {
  let i = -1;
  const byteUnits = [" KB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    size = size / 1024;
    i++;
  } while (size > 1024);

  return Math.max(size, 0.1).toFixed(1) + byteUnits[i];
};

export const humanTimeType = (second: number) => {
  const oneMinute = 60;
  const oneHour = oneMinute * 60;

  if (second <= oneMinute) return `${second} seconds`;
  if (second <= oneHour) return `${Math.floor(second / oneMinute)} minutes`;
  return `${Math.floor(second / oneHour)} hours`;
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1) || "";

export const parseToken = (token: string) => {
  if (!token) return null;
  const base64Url = token?.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getBreadcrumbs = (pathName = "") => {
  const listNode = pathName.split("/");
  listNode.shift();
  listNode.pop();
  if (listNode.length <= 1) return listNode;
  return [listNode[0], `${listNode[0]}/${listNode[1]}`];
};

export const getUrlExtension = (url: string) =>
  url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();

export const getFileNameFromURL = (url: string) => {
  const parts = decodeURIComponent(url.split("?")[0]).split("/");
  const lastPart = parts[parts.length - 1];
  return lastPart.replace(/\.[^/.]+$/, "");
};

export const getUrlFileType = (url: string) => {
  /** Check video */
  if (
    ["youtu.be", "youtube.com", "vimeo.com", "fb.watch", "%2Fvideo%2F"].find(
      (item) => url?.includes(item)
    )
  )
    return FileTypes.VIDEO;

  /** Check IMAGE*/
  if (
    ["png", "jpg", "jpeg", "pjpeg", "svg", "webp", "%2Fimage%2F"].find((item) =>
      url?.includes(item)
    )
  )
    return FileTypes.IMAGE;

  /** Check file from embed source*/
  if (["drive.google.com"].find((item) => url?.includes(item)))
    return FileTypes.IFRAME;

  /** Check PDF */
  if (
    [
      "CompanyBrief%2Fpdf%2F",
      "CompanyBrief%2Freport%2F",
      "%2Fpdf%2F",
      "%2Freport%2F",
    ].find((item) => url?.includes(item))
  )
    return FileTypes.PDF;

  return FileTypes.UNKNOW;
};

export const checkArrayMix = (arr1: string[], arr2: string[]) => {
  return !!arr1.find((item) => {
    return arr2.find((child) => child === item);
  });
};

export const LimitText = (text: string, limit: number) =>
  text?.length > limit ? `${text.slice(0, limit)}...` : text;

export const numberWithSpaces = (num: unknown) => {
  if (typeof num === "number" || typeof num === "string") {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return (num as string)?.toString();
};

export const validateMaxSizeFile = (
  file: File,
  maxSize = 100 * MB
): ValidFileType => {
  const { size } = file;
  if (size > maxSize)
    return {
      isValid: false,
      errorMessage: `The size of the file must be less than ${humanFileSize(
        maxSize
      )}. Please try with other file.`,
    };

  return {
    isValid: true,
    errorMessage: "",
  };
};

export const validateVideoDurationTime = (
  file: File,
  maxSecond = 2 * 60
): Promise<ValidFileType> =>
  new Promise((resolve) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    const src = URL.createObjectURL(file);
    video.src = src;

    video.onloadedmetadata = function () {
      const duration = video.duration;
      window.URL.revokeObjectURL(video.src);
      if (duration > maxSecond) {
        resolve({
          isValid: false,
          errorMessage: `The length of the video must be less than ${humanTimeType(
            maxSecond
          )}. Please try with other video.`,
        });
      } else {
        resolve({
          isValid: true,
          errorMessage: "",
        });
      }
    };
  });

export const stringAvatar = (name: string) => {
  return `${name.split(" ")?.[0]?.[0] || ""}${name.split(" ")?.[1]?.[0] || ""}`;
};

export const convertToK = (num: number): string => {
  if (num >= 1000) {
    const suffixes = ["", "K", "M", "B", "T"];
    const tier = (Math.log10(num) / 3) | 0;
    const suffix = suffixes[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = num / scale;
    const scaledString = scaled.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return `${scaledString}${suffix}`;
  }
  return num.toString();
};

export const handleGroupedMessages = (messages: Message[]) => {
  const firstMessage = messages[0];

  if (firstMessage) {
    const groupMessages = messages.slice(1).reduce(
      (accGroupMsgs, msg) => {
        const lastMessages = accGroupMsgs.slice(-1)[0];
        const lastMsg = lastMessages[0];
        const diffMinutes = Math.abs(
          dayjs(lastMsg.createdAt).diff(dayjs(msg.createdAt), "minutes")
        );

        if (lastMsg.senderId === msg.senderId && diffMinutes < 3) {
          lastMessages.push(msg);
          accGroupMsgs[accGroupMsgs.length - 1] = lastMessages;
        } else {
          accGroupMsgs.push([msg]);
        }

        return accGroupMsgs;
      },
      [[firstMessage]]
    );

    return groupMessages;
  }

  return [];
};

export const hasValue = (value: any) => {
  if (typeof value === "number" && value !== -1) return true;
  if (typeof value === "boolean") return true;

  return !_.isEmpty(value);
};

export const getLatestFundraising = (fundraisingProjects: Fundraising[]) => {
  return (
    fundraisingProjects?.find(
      (item) => item.status === StatusIndicator.PUBLISHED
    ) || ({} as Fundraising)
  );
};

export const scrollIntoView = (ref: RefObject<HTMLDivElement>) => {
  ref.current?.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

export const reorderTeamMembers = (teamMembers: TeamMember[]) => {
  const sortedTeamMember = _.sortBy(teamMembers, [
    (obj) => !obj.order,
    "order",
  ]);

  sortedTeamMember.forEach((member, index) => {
    if (!!member.order && member.order - 1 !== index) {
      sortedTeamMember.splice(index, 1);
      sortedTeamMember.splice(member.order - 1, 0, member);
    }
  });

  return sortedTeamMember;
};

export const convertFeed = (feed: Feed) => {
  if (feed.__typename === "Organisation") {
    const latestFundraising = getLatestFundraising(feed.fundraisingProjects);
    const {
      roundSize,
      currentRound,
      roundCloseDate,
      instrument,
      minTicketSize,
      contactEmail,
      pitchDeckUrl,
    } = latestFundraising;

    return {
      ...feed,
      roundSize,
      currentRound,
      roundCloseDate,
      instrument,
      minTicketSize,
      contactEmail,
      pitchDeckUrl,
      preFundedAmount: feed?.preFunded ? feed.preFundedAmount : -1,
    };
  }

  if (feed.__typename === "Prospect") {
    return {
      ...feed,
      instrument: [feed.instrument],
    };
  }

  return feed;
};

export const formatCurrency = (amount: number, currency?: Currency) => {
  return `${numberWithSpaces(!amount || amount === -1 ? 0 : amount)} ${
    currency?.symbol || "€"
  }`;
};

export const findUIElement = (
  queryString: string,
  timeLimit: number,
  timeLoop: number
) => {
  return new Promise((resolve, reject) => {
    let loopCount = 0;
    const maxLoops = timeLimit / timeLoop;

    const interval = setInterval(() => {
      const ulElement = document.querySelector(queryString);
      if (ulElement) {
        clearInterval(interval);
        resolve(ulElement);
      }

      loopCount++;
      if (loopCount === maxLoops) {
        clearInterval(interval);
        reject(new Error("Element not found within the specified time limit."));
      }
    }, timeLoop);
  });
};
