import { createSelector } from "@reduxjs/toolkit";
import { ChatState, initialState } from "./chat.slice";

const selectDomain = (state: { chat: ChatState }) => state.chat || initialState;

export const selectSelectedChat = createSelector(
  [selectDomain],
  (state) => state.selectedChat
);

export const selectSelectedGuest = createSelector(
  [selectDomain],
  (state) => state.selectedGuest
);

export const selectSearchTex = createSelector(
  [selectDomain],
  (state) => state.searchText
);

export const selectNotificationList = createSelector(
  [selectDomain],
  (state) => state.notificationList
);

export const selectUserId = createSelector(
  [selectDomain],
  (state) => state.userId
);
