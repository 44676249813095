import { Modal, ModalProps, styled } from "@mui/material";

export const BaseModal = ({ children, ...props }: ModalProps) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

const Wrapper = styled(Modal)`
  padding: 20px;
  max-width: 500px;
`;
