import * as React from "react";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components/macro";
interface Props {
  value: Dayjs | null;
  onchange: (value: React.SetStateAction<Dayjs | null>) => void;
  placeholder?: string;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  error?: string;
}

export const DatePicker = ({
  value,
  onchange,
  placeholder,
  minDate,
  maxDate,
  disabled,
  error,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerWrapper>
        <MuiDatePicker
          className={error && "error"}
          PopperProps={{ placement: "bottom-end" }}
          value={value}
          onChange={onchange}
          disabled={disabled}
          inputFormat="DD/MM/YYYY"
          minDate={minDate}
          maxDate={maxDate}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error}
              inputProps={{
                ...params.inputProps,
                placeholder,
              }}
            />
          )}
        />
      </DatePickerWrapper>
    </LocalizationProvider>
  );
};

const DatePickerWrapper = styled.div`
  background-color: white;
  width: 100%;

  .MuiFormControl-root {
    width: 100%;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #d8d9df;
    }

    .MuiOutlinedInput-notchedOutline {
    }
  }

  .error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }
`;
