import {
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { RadioButtonData } from "models/common.model";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components/macro";

interface Props extends CheckboxProps {
  groupLabel?: string;
  groupName: string;
  data: RadioButtonData[];
  handleOnSelect: Dispatch<SetStateAction<string>>;
}

export const GroupRadioButton = ({
  groupLabel,
  groupName,
  data,
  handleOnSelect,
}: Props) => {
  return (
    <RadioButtonWrapper>
      {groupLabel && (
        <FormLabel id={`radio-buttons-group-label ${groupName}`}>
          {groupLabel}
        </FormLabel>
      )}

      <RadioGroup
        aria-labelledby={`radio-buttons-group-label ${groupName}`}
        defaultValue="female"
        name={groupName}
      >
        {data.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
            onChange={() => handleOnSelect(item.value)}
          />
        ))}
      </RadioGroup>
    </RadioButtonWrapper>
  );
};

const RadioButtonWrapper = styled(FormControl)`
  .MuiFormControlLabel-label {
    font-size: 14px;
    color: ${(props) => props.theme.palette.secondary.light};
  }
  .Mui-checked {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
