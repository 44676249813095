import { Stack } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

import { Button, Text } from "components";
import { TrustScoreQuestion } from "models/organisation.model";

interface Props {
  ordinalNumber: number;
  data: TrustScoreQuestion;
  isSubmit?: boolean;
  submitLoading?: boolean;
  onSelect: (value: number) => void;
  onNext: () => void;
}

export const FormSlice = ({
  ordinalNumber,
  data,
  isSubmit,
  submitLoading,
  onSelect,
  onNext,
}: Props) => {
  const [value, setValue] = useState<number | null>(null);
  const [showError, setShowError] = useState(false);
  const { question, options } = data;

  const handleSelect = (value: number) => {
    setValue(value);
    setShowError(false);

    onSelect(value);
    !isSubmit && setTimeout(() => onNext(), 350);
  };

  return (
    <Wrapper className="form-item">
      <MainBox spacing="16px">
        <Text className="question" size="lg" fontSize="20px">
          {ordinalNumber}. {question}
        </Text>

        <Stack spacing="12px">
          {options.map((option, index) => (
            <Option
              key={option.value}
              className={value === option.value ? "selected" : undefined}
              onClick={() => handleSelect(option.value)}
            >
              <span>{String.fromCharCode(65 + index)}.</span> {option.label}
            </Option>
          ))}

          {showError && (
            <Text size="md" color="#eb5a46">
              Required!
            </Text>
          )}
        </Stack>

        <Button disabled={!value} onClick={onNext} loading={submitLoading}>
          {isSubmit ? "Submit" : "Next"}
        </Button>
      </MainBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
`;

const MainBox = styled(Stack)`
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  padding: 30px 0px;

  .question {
    ${(props) => props.theme.breakpoints.down("sm")} {
      font-size: 16px;
    }
  }

  .button-wrapper button {
    min-width: 80px;
    margin-bottom: 30px;
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 50px;
  padding: 8px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 4px;
  background-color: #ffffff80;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;

  &.selected {
    color: #ffffff;
    background: ${(props) => props.theme.palette.secondary.main};
  }

  &:hover {
    transform: scaleX(1.01);
  }

  & > span {
    font-weight: 600;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`;
