import { Chart } from "chart.js/auto";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { convertToK } from "utils/system.util";

interface Props {
  labels: string[];
  data: number[];
  hideGrid?: boolean;
  hideTick?: boolean;
  maxTicksLimit?: number;
  stepSize?: number;
}

export const LineChart = ({
  labels,
  data,
  hideGrid = false,
  hideTick = false,
  maxTicksLimit,
  stepSize,
}: Props) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"line"> | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              data,
              fill: true,
              borderWidth: 2,
              borderColor: "#C9AF7F",
              backgroundColor: "rgba(212, 191, 153, 0.3)",
              tension: 0.1,
              pointRadius: 0,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: !hideGrid,
                color: "#ebecef",
                drawTicks: false,
              },
              ticks: {
                display: !hideTick,
              },
            },
            y: {
              grid: {
                display: !hideGrid,
                color: "#ebecef",
                drawTicks: false,
              },
              ticks: {
                display: !hideTick,
                maxTicksLimit,
                stepSize,
                callback: (value) => {
                  if (value === 0) {
                    return "";
                  } else {
                    return convertToK(value as number);
                  }
                },
              },
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data, hideGrid, hideTick, labels, maxTicksLimit, stepSize]);

  return (
    <Wrapper>
      <canvas ref={chartRef} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &,
  canvas {
    width: 100%;
  }
`;
