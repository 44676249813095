import styled from "styled-components";
import * as yup from "yup";

import { OrganisationFieldInput, SectionWrapper } from "components";

const stringYup = yup.string().nullable();

export const CompanySection = () => {
  return (
    <SectionWrapper title="My company">
      <Container>
        <OrganisationFieldInput
          fieldName="Company Name"
          name="displayName"
          placeholder="Set company name"
          yupSchema={stringYup}
        />

        <OrganisationFieldInput
          fieldType="text-area"
          fieldName="Company slogan"
          name="slogan"
          placeholder="Type some words here..."
          yupSchema={stringYup}
        />
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
