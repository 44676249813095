import { Stack, useMediaQuery, useTheme } from "@mui/material";
import _ from "lodash";
import { useRef } from "react";
import styled from "styled-components/macro";

import { PrintIcon, ShareIcon } from "assets/images/common";
import { Avatar, ButtonOutline, SectionWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";

export const InfoSection = () => {
  const { logoUrl, posterUrl, displayName, slogan } =
    useAppSelector(selectOrganisation);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const desktopPosterUrl =
    posterUrl?.split(" ").find((url) => _.includes(url, "desktop")) || "";
  const mobilePosterUrl =
    posterUrl?.split(" ").find((url) => _.includes(url, "mobile")) || "";

  const coverRef = useRef<HTMLDivElement>(null);

  return posterUrl ? (
    <SectionWrapper>
      <Container>
        <Header>
          <CoverWrapper ref={coverRef}>
            {posterUrl ? (
              <CoverImage
                src={
                  isMobileView
                    ? mobilePosterUrl || desktopPosterUrl
                    : desktopPosterUrl || mobilePosterUrl
                }
              />
            ) : (
              <CoverDefault />
            )}
          </CoverWrapper>

          <AvatarWrapper>
            <Avatar src={logoUrl} name={displayName} size={96} rounded="12px" />
          </AvatarWrapper>

          {false && (
            <ActionWrapper>
              <ButtonOutline icon={<PrintIcon />}>Print</ButtonOutline>

              <ButtonOutline icon={<ShareIcon />}>Share</ButtonOutline>
            </ActionWrapper>
          )}
        </Header>

        <Footer>
          <Text className="name" size="xxl" fontSize="31px">
            {displayName}
          </Text>

          {slogan && (
            <Text size="sm" className="slogan" fontSize="16px">
              {slogan}
            </Text>
          )}
        </Footer>
      </Container>
    </SectionWrapper>
  ) : (
    <Stack spacing="16px">
      <Stack direction="row" gap="24px">
        <Avatar src={logoUrl} name={displayName} size={96} rounded="12px" />
        <Text className="name" size="xxl" fontSize="31px">
          {displayName}
        </Text>
      </Stack>

      <Text size="sm" className="slogan" fontSize="16px">
        {slogan}
      </Text>
    </Stack>
  );
};

const Container = styled.div`
  margin: -24px;
  border-radius: 12px;
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  margin-bottom: 48px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: 96px;
  }
`;

const CoverWrapper = styled.div`
  height: auto;
  min-height: 100px;
  padding: 0 4px;
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CoverDefault = styled.div`
  width: 100%;
  height: 100%;
  background: #f4f5f7;
`;

const Footer = styled.div`
  padding: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  .name {
    margin-bottom: 16px;
    color: ${(props) => props.theme.palette.text.dark};

    ${(props) => props.theme.breakpoints.down("sm")} {
      margin-bottom: 12px;
      font-size: 24px;
    }
  }

  .slogan {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 16px;
  }
`;

const ActionWrapper = styled.div`
  position: absolute;
  right: 32px;
  bottom: -56px;
  display: flex;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    bottom: -104px;
    left: 20px;
    width: 100%;
  }

  .MuiButton-root {
    height: 40px;
    padding: 0px 16px;
  }
`;

const AvatarWrapper = styled.div`
  position: absolute;
  left: 32px;
  bottom: -44px;
  background: #fff;
  border-radius: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    left: 20px;
  }
`;
