import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, dialogClasses } from "@mui/material";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { Button, ButtonOutline } from "components";
import { RatingField } from "components/UI/Form/Input/RatingField";
import { TextArea } from "components/UI/Form/Input/TextArea";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const RateModal = ({ isOpen, onClose }: Props) => {
  const schema = yup.object().shape({
    rating: yup.number().required(),
    feedback: yup.string(),
  });

  const {
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <BaseDialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <HeaderBox>How did it go with this Investor?</HeaderBox>

      <Wrapper>
        <RatingField
          fieldName="Please rate"
          handleChange={(rating) => setValue("rating", rating)}
        />

        <TextArea
          fieldName="Share your feedback"
          name="feedback"
          placeholder="Type some words here..."
          minRows={3}
          maxLength={400}
          hasCount
          formRef={register}
          watch={watch}
          error={errors.feedback?.message as string}
        />
      </Wrapper>

      <FooterBox>
        <ButtonOutline onClick={handleClose}>Maybe Later</ButtonOutline>

        <Button disabled={!watch("rating")}>Rate Now</Button>
      </FooterBox>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 32px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: calc(100vw - 60px);
  }
`;

const FooterBox = styled.div`
  display: flex;
  gap: 16px;
  margin: 0px 32px 32px;
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid #ebecef;

  & button,
  .button-wrapper {
    width: 100%;
  }
`;
