import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { GroupText, SearchBox, Text } from "components";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { feedActions } from "store/feed/feed.slice";
import {
  selectAppConfig,
  selectHeaderSearch,
} from "store/global/global.selector";
import { globalActions } from "store/global/global.slice";
import { prospectActions } from "store/prospect/prospect.slice";
import { selectActiveWorkspaceName } from "store/workspace/workspace.selector";
import styled from "styled-components/macro";
import Breadcrumbs from "./Breadcrumbs";
import MobileMenu from "./MobileMenu";
import TopBarTool from "./TopBarTool";

const TopBar = () => {
  const dispatch = useAppDispatch();
  const activeWorkspaceName = useAppSelector(selectActiveWorkspaceName);
  const appConfig = useAppSelector(selectAppConfig);
  const searchText = useAppSelector(selectHeaderSearch);
  const location = useLocation();
  const isInvestorDashboard = location?.pathname === PATH.DASHBOARD;
  const { headerLogo, brandName } = appConfig;

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(globalActions.updateHeaderSearch(value));
    },
    [dispatch]
  );

  const handleSearchCompany = (value: string) => {
    dispatch(prospectActions.searchProspect(value));
  };

  const handleSearchFeed = (value: string) => {
    dispatch(
      feedActions.filterFeed({
        filter: { displayName: [value] },
        isApply: true,
      })
    );
  };

  useEffect(() => {
    if (isInvestorDashboard) {
      handleSearchFeed(searchText);
    } else {
      handleSearchCompany(searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, isInvestorDashboard]);

  return (
    <Wrapper>
      <MainLogo>
        <MobileMenu />
        {headerLogo ? <LogoWrapper src={headerLogo} /> : <LogoPlaceholder />}
        <LogoText>{brandName} </LogoText>
      </MainLogo>
      <BarContent>
        <Breadcrumbs />
        <GroupText>
          {[PATH.DASHBOARD, PATH.ADMIN_VIEW].includes(location?.pathname) && (
            <InLineBox>
              <Text size="lg">{activeWorkspaceName || "Spaces"}</Text>
              <SearchBox
                autoSearch={true}
                defaultValue={searchText}
                onSearch={handleSearch}
              />
            </InLineBox>
          )}
          <TopBarTool />
        </GroupText>
      </BarContent>
    </Wrapper>
  );
};

export default TopBar;

const InLineBox = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const BarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex-grow: 1;
  .search-box {
    width: 320px;
  }
`;

const MainLogo = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
`;

const LogoWrapper = styled.img`
  width: auto;
  height: 36px;
  border-radius: 4px;
`;

const LogoPlaceholder = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: #ebecef;
`;

const LogoText = styled.div`
  color: ${(props) => props.theme.palette.primaryText.dark};
  font-family: "Times New Roman", Times, serif;
  font-size: 24px;
  font-weight: 600;
  user-select: none;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
  padding: 30px 20px;
  background-color: white;
  z-index: 99;
  box-shadow: 0px 3px 5px rgb(5 10 48 / 5%);
  .mobile-menu {
    display: none;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    .mobile-menu {
      display: flex;
    }
    ${InLineBox} {
      display: none;
    }
    ${BarContent} {
      justify-content: flex-end;
    }
    ${MainLogo} {
      justify-content: space-between;
      flex-basis: 50%;
      & > *:nth-child(3) {
        display: none;
      }
    }
    .header-breadcrumbs {
      display: none;
    }
  }
`;
