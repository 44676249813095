import { SectionWrapper } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { MediaFrame } from "../../components";

export const MFScoreSection = () => {
  const { mfScoreUrl } = useAppSelector(selectOrganisation);

  return mfScoreUrl ? (
    <SectionWrapper title="MF Trust Score Report">
      <MediaFrame url={mfScoreUrl} />
    </SectionWrapper>
  ) : null;
};
