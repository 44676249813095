import { Box } from "@mui/material";
import styled from "styled-components";

import { DownloadIcon } from "assets/images/common";
import { ButtonOutline, SectionWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { formatCurrency } from "utils/system.util";
import { Flag } from "../../components";

export const OverviewSection = () => {
  const {
    preFundedAmount,
    annualRevenue,
    MRR,
    country,
    sector,
    impactValues,
    businessType,
    productType,
    websiteUrl,
    currency,
  } = useAppSelector(selectOrganisation);

  return (
    <SectionWrapper title="Overview">
      <Container>
        <SectionWrapper>
          <CurrencyWrapper>
            <CurrencyItem>
              <Text size="md">Previously Raised:</Text>
              <Text size="md">{formatCurrency(preFundedAmount, currency)}</Text>
            </CurrencyItem>

            <CurrencyItem>
              <Text size="md">Annual Revenue:</Text>
              <Text size="md">{formatCurrency(annualRevenue, currency)}</Text>
            </CurrencyItem>

            <CurrencyItem>
              <Text size="md">MRR:</Text>
              <Text size="md">{formatCurrency(MRR, currency)}</Text>
            </CurrencyItem>
          </CurrencyWrapper>
        </SectionWrapper>

        <Box>
          {country && (
            <Item>
              <Text size="md">Location</Text>

              <FlagWrapper>
                <Flag text={country.name} colorSchema="gray" />
              </FlagWrapper>
            </Item>
          )}

          {sector?.length > 0 && (
            <Item>
              <Text size="md">Sector</Text>

              <FlagWrapper>
                {sector.map((item) => (
                  <Flag key={item.id} text={item.name} colorSchema="gray" />
                ))}
              </FlagWrapper>
            </Item>
          )}

          {impactValues?.length > 0 && (
            <Item>
              <Text size="md">Impact</Text>

              <FlagWrapper>
                {impactValues.map((item) => (
                  <Flag key={item.id} text={item.name} colorSchema="gray" />
                ))}
              </FlagWrapper>
            </Item>
          )}

          {businessType?.length > 0 && (
            <Item>
              <Text size="md">Business Type</Text>

              <FlagWrapper>
                {businessType.map((item) => (
                  <Flag key={item.id} text={item.name} colorSchema="gray" />
                ))}
              </FlagWrapper>
            </Item>
          )}

          {productType?.length > 0 && (
            <Item>
              <Text size="md">Product Type</Text>

              <FlagWrapper>
                {productType.map((item) => (
                  <Flag key={item.id} text={item.name} colorSchema="gray" />
                ))}
              </FlagWrapper>
            </Item>
          )}

          {websiteUrl && (
            <Item>
              <Text size="md">Website</Text>

              <Box>
                <Link href={websiteUrl} target="_blank">
                  {websiteUrl}
                </Link>
              </Box>
            </Item>
          )}
        </Box>

        {false && (
          <ButtonOutline icon={<DownloadIcon />}>Download Deck</ButtonOutline>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiButton-root {
    width: fit-content;
    padding: 0px 24px;
    border: none;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px 24px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 16px;

  & > div:first-child {
    flex-shrink: 0;
    width: 45%;

    ${(props) => props.theme.breakpoints.down("sm")} {
      flex-shrink: 1;
    }
  }

  & > div:last-child {
    width: 55%;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 100%;
    }
  }
`;

const FlagWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const CurrencyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const CurrencyItem = styled.div`
  & > div:first-child {
    margin-bottom: 8px;
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 16px;
    text-wrap: nowrap;
  }

  & > div:last-child {
    color: ${(props) => props.theme.palette.text.primary};
    font-size: 16px;
    font-weight: 600;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
  }
`;
