import { call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import workspaceApi from "api/workspace.api";
import { CreateWorkspaceParams } from "models/workspace.model";
import { authActions } from "store/auth/auth.slice";
import { globalActions } from "store/global/global.slice";
import { deleteFolder } from "utils/firebase.util";
import { workspaceActions } from "./workspace.slice";

function* getWorkspace() {
  try {
    const { workspaceGet } = yield call(workspaceApi.workspaceGet);
    yield put(workspaceActions.getWorkspaceSuccess(workspaceGet));
  } catch (err) {
    //throw err as Error;
  }
}

function* getWorkspaces() {
  try {
    yield put(globalActions.setPageLoading(true));
    const { workspacesGet } = yield call(workspaceApi.workspacesGet);
    if (workspacesGet) {
      yield put(workspaceActions.getWorkspacesSuccess(workspacesGet));
    }
  } catch (err) {
    //throw err as Error;
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* createWorkspace(action: PayloadAction<CreateWorkspaceParams>) {
  try {
    const { workspaceCreate } = yield call(
      workspaceApi.workspaceCreate,
      action.payload
    );
    if (workspaceCreate) {
      yield put(workspaceActions.createWorkspaceSuccess(workspaceCreate));
    }
  } catch (err) {
    yield put(
      globalActions.pushNotification({
        type: "error",
        message: "Create workspace failed",
      })
    );
    yield put(workspaceActions.createWorkspaceFailed());
    //throw err as Error;
  }
}

function* deleteWorkspace(action: PayloadAction<{ organisationId?: string }>) {
  try {
    yield put(globalActions.setPageLoading(true));

    const { organisationId } = action.payload;
    if (organisationId) {
      yield call(deleteFolder, `workspaces/${organisationId}`);
    }
    yield call(workspaceApi.workspaceRemove);
    yield put(authActions.workspaceLogout());

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(
      globalActions.pushNotification({
        type: "error",
        message: "Delete workspace failed",
      })
    );
    yield put(globalActions.setPageLoading(false));
  }
}

function* updateWorkspace(
  action: PayloadAction<{
    input: CreateWorkspaceParams;
    callback?: () => void;
  }>
) {
  try {
    const { input, callback } = action.payload;
    const { workspaceUpdate } = yield call(workspaceApi.workspaceUpdate, input);

    if (workspaceUpdate) {
      yield put(workspaceActions.updateWorkspaceSuccess(workspaceUpdate));
    }

    callback && callback();
  } catch (_) {
    yield put(
      globalActions.pushNotification({
        type: "error",
        message: "Update workspace failed",
      })
    );
    yield put(workspaceActions.updateWorkspaceFailed());
  }
}

export default function* workspaceSaga() {
  yield takeLatest(workspaceActions.getWorkspaces.type, getWorkspaces);
  yield takeLatest(workspaceActions.getWorkspace.type, getWorkspace);
  yield takeLatest(workspaceActions.createWorkspace.type, createWorkspace);
  yield takeLatest(workspaceActions.deleteWorkspace.type, deleteWorkspace);
  yield takeLatest(workspaceActions.updateWorkspace.type, updateWorkspace);
}
