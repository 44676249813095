import {
  IconButton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { QuestionIcon } from "assets/images/common";
import styled from "styled-components";

interface Data {
  title?: string;
  content?: string;
}

interface Props {
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  data: Data;
}

const TooltipContainer = ({ title, content }: Data) => (
  <NoticeBoard>
    {title && <div className="title">{title}</div>}

    <div className="content">{content}</div>
  </NoticeBoard>
);

export const NoticeTooltip = ({ placement, data }: Props) => {
  return (
    <TooltipWrapper
      placement={placement}
      title={<TooltipContainer {...data} />}
    >
      <IconButton>
        <QuestionIcon />
      </IconButton>
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "8px 16px",
    borderRadius: "4px",
    backgroundColor: "#373b59",
  },
}));

const NoticeBoard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  color: #ffffff;

  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .content {
    font-size: 14px;
  }

  svg {
    width: 16px;
    height: auto;
  }
`;
