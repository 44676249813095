import { gql } from "graphql-request";
import {
  CreateAssetLinkParams,
  CreateAssetPseudoParams,
  DeleteAssetParams,
  Report,
} from "models/report.model";

import { graphqlRequest } from "utils/graphQL.util";

const reportApi = {
  assetListGet() {
    const query = gql`
      query AssetListGet {
        assetListGet {
          ... on AssetListSuccess {
            assets {
              __typename
              id
              ... on AssetOrganisation {
                displayName
                websiteUrl
                link {
                  type
                  id
                }
              }
              ... on PseudoOrganisation {
                displayName
                websiteUrl
              }
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query);
  },

  assetFuzzySearch(lookup: string) {
    const variables = { lookup };

    const query = gql`
      query AssetFuzzySearchSuccess($lookup: String!) {
        assetFuzzySearch(lookup: $lookup) {
          ... on AssetFuzzySearchSuccess {
            results {
              id
              websiteUrl
              type
              displayName
            }
          }
          ... on AssetFuzzySearchError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  assetCreateLink(data: CreateAssetLinkParams) {
    const query = gql`
      mutation AssetCreateLink($toId: ID!, $assetType: assetTypesNetwork!) {
        assetCreateLink(toId: $toId, assetType: $assetType) {
          ... on AssetSuccess {
            asset {
              __typename
              id
              ... on PseudoOrganisation {
                displayName
                websiteUrl
              }
              ... on AssetOrganisation {
                displayName
                websiteUrl
                link {
                  type
                  id
                }
              }
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, data);
  },

  assetCreatePseudo(data: CreateAssetPseudoParams) {
    const query = gql`
      mutation AssetCreatePseudo(
        $assetType: pseudoAssetType!
        $input: assetPseudoInput
      ) {
        assetCreatePseudo(assetType: $assetType, input: $input) {
          ... on AssetSuccess {
            asset {
              __typename
              id
              ... on PseudoOrganisation {
                displayName
                websiteUrl
              }
              ... on AssetOrganisation {
                displayName
                websiteUrl
                link {
                  type
                  id
                }
              }
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, data);
  },

  assetDeleteLink(data: DeleteAssetParams) {
    const query = gql`
      mutation AssetDeleteLink($assetId: ID!, $assetType: assetType!) {
        assetDeleteLink(assetId: $assetId, assetType: $assetType) {
          ... on AssetDeleteSuccess {
            assetId
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, data);
  },

  assetDeletePseudo(data: DeleteAssetParams) {
    const query = gql`
      mutation AssetDeletePseudo($assetId: ID!, $assetType: pseudoAssetType!) {
        assetDeletePseudo(assetId: $assetId, assetType: $assetType) {
          ... on AssetDeleteSuccess {
            assetId
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, data);
  },

  assetUpdatePseudo(data: CreateAssetPseudoParams & { assetId: string }) {
    const query = gql`
      mutation AssetUpdatePseudo(
        $assetId: ID!
        $assetType: pseudoAssetType!
        $input: assetPseudoInput
      ) {
        assetUpdatePseudo(
          assetId: $assetId
          assetType: $assetType
          input: $input
        ) {
          ... on AssetSuccess {
            asset {
              __typename
              id
              ... on PseudoOrganisation {
                displayName
                websiteUrl
              }
              ... on AssetOrganisation {
                displayName
                websiteUrl
                link {
                  type
                  id
                }
              }
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, data);
  },

  assetReportCreate(data: Report) {
    const variables = {
      input: data,
    };

    const query = gql`
      mutation AssetReportCreate($input: assetReportCreateInput) {
        assetReportCreate(input: $input) {
          ... on AssetReportSuccess {
            report {
              id
              displayName
              data
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  assetReportUpdate(data: Report) {
    const { id, ...rest } = data;

    const variables = {
      assetReportId: id,
      input: rest,
    };

    const query = gql`
      mutation AssetReportUpdate(
        $assetReportId: ID!
        $input: assetReportUpdateInput
      ) {
        assetReportUpdate(assetReportId: $assetReportId, input: $input) {
          ... on AssetReportSuccess {
            report {
              id
              displayName
              data
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  assetReportDelete(assetReportId: string) {
    const variables = {
      assetReportId,
    };

    const query = gql`
      mutation AssetReportDelete($assetReportId: ID!) {
        assetReportDelete(assetReportId: $assetReportId) {
          ... on AssetReportDeleteSuccess {
            assetReportId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
          ... on AssetError {
            message
            resourceId
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  assetReportListGet() {
    const query = gql`
      query AssetReportListGet {
        assetReportListGet {
          ... on AssetReportListSuccess {
            reports {
              id
              displayName
              data
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query);
  },

  assetReportGet(assetReportId: string) {
    const variables = {
      assetReportId,
    };

    const query = gql`
      query AssetReportGet($assetReportId: ID!) {
        assetReportGet(assetReportId: $assetReportId) {
          ... on AssetReportSuccess {
            report {
              id
              displayName
              data
            }
          }
          ... on AssetError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },
};

export default reportApi;
