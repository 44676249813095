/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _, { keyBy } from "lodash";
import { FilterOptions, SelectItem, SortItem } from "models/common.model";
import { FavoriteParams, Prospect } from "models/prospect.model";
import { checkArrayMix } from "utils/system.util";

export interface FeedState {
  feeds: Prospect[];
  limit: number;
  filterFeeds: Prospect[];
  filterOptions: { [key: string]: string[] };
  countFilter: number;
  selectedFeed: Prospect;
  sortSelected: SortItem | undefined;
  loading: boolean;
}
const emptyFilterOptions = {
  sector: [],
  impactValues: [],
  country: [],
  currentRound: [],
  mfScore: [],
  instrument: [],
  minTicketSize: [],
  favorites: [],
  isGlobal: [],
};

export const initialState: FeedState = {
  feeds: [],
  limit: 10,
  filterFeeds: [],
  filterOptions: _.cloneDeep(emptyFilterOptions),
  countFilter: 0,
  selectedFeed: {} as Prospect,
  sortSelected: undefined,
  loading: false,
};

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    sortFeedsFE(state, _action: PayloadAction<SortItem>) {
      const { byKey, order } = _action.payload;
      if (byKey) {
        const filterArr = _.orderBy(
          state.filterFeeds.map((i) => ({
            ...i,
            [byKey]: i[byKey as keyof Prospect] || 0,
          })),
          [byKey],
          [order as "asc" | "desc"]
        );
        state.filterFeeds = filterArr as Prospect[];
      } else {
        state.filterFeeds = state.feeds;
      }
      state.sortSelected = _action.payload;
    },
    sortFeeds(_, _action: PayloadAction<SortItem>) {},
    sortFeedsSuccess(
      state,
      _action: PayloadAction<{ feeds: Prospect[]; sortSelected: SortItem }>
    ) {
      const { feeds, sortSelected } = _action.payload;
      state.feeds = feeds;
      state.filterFeeds = feeds;
      state.sortSelected = sortSelected;
    },
    getFeeds(_, _action: PayloadAction<SortItem | undefined>) {},
    getFeedsSuccess(state, _action: PayloadAction<Prospect[]>) {
      state.feeds = _action.payload;
      state.filterFeeds = _action.payload;
      state.countFilter = _action.payload.length;
    },
    selectFeed(state, _action: PayloadAction<string>) {
      const selectedFeed = state.feeds.find((i) => i.id === _action.payload);
      if (selectedFeed) {
        state.selectedFeed = selectedFeed;
      }
    },
    unselectFeed(state) {
      state.selectedFeed = {} as Prospect;
    },
    getFeed(_, _action: PayloadAction<string>) {},
    getFeedSuccess(state, _action: PayloadAction<Prospect>) {
      state.selectedFeed = _action.payload;
    },
    clearFilter(state) {
      state.countFilter = state.feeds?.length;
      state.filterFeeds = state.feeds;
      state.filterOptions = _.cloneDeep(emptyFilterOptions);
    },
    filterFeed(
      state,
      _action: PayloadAction<{ filter: FilterOptions; isApply: boolean }>
    ) {
      const { filter, isApply } = _action.payload;
      const fullFeed = state.feeds;
      const tempOptions = { ...state.filterOptions, ...filter };
      if (checkFilterOptionsEmpty(tempOptions)) {
        state.countFilter = fullFeed.length;
        if (isApply) state.filterFeeds = fullFeed;
      } else {
        let filterFeed = fullFeed;
        const filterKeys = Object.keys(tempOptions);
        filterKeys.forEach((key) => {
          switch (key) {
            case "impactValues":
            case "sector":
            case "instrument":
              if (tempOptions[key]?.length) {
                filterFeed = filterFeed.filter((feedItem) => {
                  const filterValues =
                    (feedItem[key as keyof Prospect] as SelectItem[]) || [];
                  const arr1 = filterValues.map((i) => i?.id);
                  const arr2 = tempOptions[key];
                  return checkArrayMix(arr1, arr2);
                });
              }
              break;
            case "currentRound":
            case "country":
              if (tempOptions[key]?.length) {
                filterFeed = filterFeed.filter((feedItem) => {
                  const data = feedItem[key]?.id;
                  const filterValues = ([{ id: data }] as SelectItem[]) || [];
                  const arr1 = filterValues.map((i) => i?.id);
                  const arr2 = tempOptions[key];
                  return checkArrayMix(arr1, arr2);
                });
              }
              break;
            case "mfScore":
              if (tempOptions[key]?.length) {
                filterFeed = filterFeed.filter((feedItem) => {
                  const dataScore = feedItem[key];
                  const filterScore = Number(tempOptions[key][0]);
                  return dataScore >= filterScore;
                });
              }
              break;
            case "minTicketSize":
              if (tempOptions[key]?.length) {
                filterFeed = filterFeed.filter((feedItem) => {
                  const dataSizeNumber = Number(feedItem[key]);
                  return tempOptions[key].find((rangeValue) => {
                    const [min, max] = rangeValue.split("-");
                    let check = true;
                    if (min) {
                      check = check && Number(min) <= dataSizeNumber;
                    }
                    if (max) {
                      check = check && Number(max) >= dataSizeNumber;
                    }
                    return check;
                  });
                });
              }
              break;
            // case "minTicketSize":
            //   if (tempOptions[key]?.length) {
            //     const itemMinSize = Number(tempOptions[key][0]);
            //     const itemMaxSize = Number(tempOptions[key][1]);
            //     if (itemMinSize || itemMaxSize) {
            //       filterFeed = filterFeed.filter((feedItem) => {
            //         const dataSizeNumber = Number(feedItem[key]);
            //         let check = true;
            //         if (itemMinSize) {
            //           check = check && itemMinSize <= dataSizeNumber;
            //         }
            //         if (itemMaxSize) {
            //           check = check && itemMaxSize >= dataSizeNumber;
            //         }
            //         return check;
            //       });
            //     }
            //   }
            //   break;

            case "prevPartOf":
              if (tempOptions[key]?.length) {
                const itemData = tempOptions[key][0];
                if (itemData) {
                  filterFeed = filterFeed.filter((feedItem) => {
                    return feedItem[key] && feedItem[key].includes(itemData);
                  });
                }
              }
              break;
            case "displayName":
              if (tempOptions[key]?.length) {
                const textSearch = tempOptions[key][0];
                if (textSearch) {
                  filterFeed = filterFeed.filter((feedItem) => {
                    const searchArr = textSearch.split(" ").filter((i) => !!i);
                    return (
                      feedItem[key] &&
                      searchArr.find((text) =>
                        feedItem[key]
                          ?.toLocaleLowerCase()
                          ?.includes(text?.toLocaleLowerCase())
                      )
                    );
                  });
                }
              }
              break;
            case "favorites":
              if (tempOptions[key]?.length) {
                filterFeed = filterFeed.filter(
                  (feedItem) => !!feedItem.fav_person
                );
              }
              break;
          }
        });
        state.countFilter = filterFeed?.length;
        if (isApply) state.filterFeeds = filterFeed;
      }
      state.filterOptions = tempOptions;
    },
    customFavorite(
      state,
      _action: PayloadAction<{
        type: "create" | "remove";
        input: FavoriteParams;
      }>
    ) {
      state.loading = true;
    },
    customFavoriteSuccess(state, _action: PayloadAction<boolean>) {
      state.loading = false;
      state.selectedFeed = {
        ...state.selectedFeed,
        fav_person: _action.payload,
      };
    },
    customFavoriteFailed(state) {
      state.loading = false;
    },
    increaseLimit(state, action: PayloadAction<number>) {
      const pushLimit = state.limit + action.payload;
      const limit =
        pushLimit < state.feeds?.length ? pushLimit : state.feeds?.length;
      state.limit = limit;
    },
  },
});
export const {
  actions: feedActions,
  reducer: feedReducer,
  name: sliceKey,
} = feedSlice;

const checkFilterOptionsEmpty = (filterOptions: FilterOptions) => {
  const itemHasValue = Object.keys(filterOptions).find((key) => {
    if (filterOptions[key].length) return true;
  });
  return !itemHasValue;
};
