import { useEffect, useState } from "react";
import styled from "styled-components";

import { CheckBox, SectionWrapper } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import _ from "lodash";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectOrganisation,
  selectPossibleEditOrganisation,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";

export const AdditionalInfoSection = () => {
  const [isChecked, setIsChecked] = useState<boolean | null>(null);

  const dispatch = useAppDispatch();
  const { acceptMA, id } = useAppSelector(selectOrganisation);
  const isPossibleEditOrganisationRaw = useAppSelector(
    selectPossibleEditOrganisation
  );
  const isPossibleEditOrganisation = _.isBoolean(acceptMA)
    ? isPossibleEditOrganisationRaw
    : true;
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = [AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(
    authMode
  );

  const handleUpdateAcceptMA = async (checked: boolean) => {
    const data = {
      acceptMA: checked,
    };

    if (id) {
      dispatch(
        organisationActions.updateOrganisation({
          organisationId: id,
          input: data,
        })
      );
    }
  };

  useEffect(() => {
    setIsChecked(acceptMA);
  }, [acceptMA]);

  return (
    <SectionWrapper title="Additional Info">
      <Container>
        <TextWrapper>
          <div>
            Monaco Foundry works closely with M&A boutique firms looking for new
            innovation that could positively impact the world. Often earlier
            stage acquisitions would be bolted-on to bigger entities that could
            accelerate research or go-to-market.
          </div>

          <div>
            Are you open to considering selling a controlling stake in your
            startup at the right price, to a company that you felt could help
            accelerate your vision?
          </div>
        </TextWrapper>

        <CheckBox
          disabled={isAdmin || !isPossibleEditOrganisation}
          mainsize={22}
          label="I am open to considering selling a controlling interest in my startup at the right price, to a company that I feel could help accelerate my vision"
          checked={_.isBoolean(isChecked) && isChecked}
          onChange={(e) => {
            const checked = e.target.checked;

            if (checked) {
              setIsChecked(checked);
              handleUpdateAcceptMA(checked);
            }
          }}
        />

        <CheckBox
          disabled={isAdmin || !isPossibleEditOrganisation}
          mainsize={22}
          label="No, I'm not interested"
          checked={_.isBoolean(isChecked) && !isChecked}
          onChange={(e) => {
            const checked = e.target.checked;

            if (checked) {
              setIsChecked(!checked);
              handleUpdateAcceptMA(!checked);
            }
          }}
        />
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
`;
