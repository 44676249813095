/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CustomPostParam, GetListPostParam, Post } from "models/post.model";

export interface PostState {
  loading: boolean;
  posts: Post[];
}
export const initialState: PostState = {
  loading: false,
  posts: [],
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    getPosts(_, _action: PayloadAction<GetListPostParam>) {},
    getPostsSuccess(state, _action: PayloadAction<Post[]>) {
      state.posts = _action.payload;
    },
    createPost(
      state,
      _action: PayloadAction<{
        organisationId: string;
        input: CustomPostParam;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    createPostSuccess(state, _action: PayloadAction<Post>) {
      state.loading = false;
      state.posts = [...state.posts, _action.payload];
    },
    createPostFailed(state) {
      state.loading = false;
    },
    updatePost(
      state,
      _action: PayloadAction<{
        postId: string;
        input: CustomPostParam;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    updatePostSuccess(state, _action: PayloadAction<Post>) {
      state.loading = false;
      const updatedIndex = state.posts.findIndex(
        (p) => p.id === _action.payload.id
      );
      state.posts[updatedIndex] = _action.payload;
    },
    updatePostFailed(state) {
      state.loading = false;
    },
    deletePost(
      state,
      _action: PayloadAction<{
        postId: string;
        organisationId: string;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    deletePostSuccess(state, _action: PayloadAction<string>) {
      const deletedIndex = state.posts.findIndex(
        (p) => p.id === _action.payload
      );

      state.posts.splice(deletedIndex, 1);
      state.loading = false;
    },
    deletePostFailed(state) {
      state.loading = false;
    },
    clearPosts(state) {
      state.posts = [];
    },
  },
});
export const {
  actions: postActions,
  reducer: postReducer,
  name: sliceKey,
} = postSlice;
