/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChatIcon,
  CompanyIcon,
  DealFlowIcon,
  InsightsIcon,
  ListViewIcon,
  UpdateIcon,
} from "assets/images/common";
import { WORKSPACE_TYPE } from "models/auth.model";
import { Roles } from "./app.const";
import appConfigFromJson from "../appConfig.json";

export interface MenuNode {
  name: string;
  icon: (props: React.HTMLAttributes<SVGSVGElement>) => JSX.Element;
  route: (params?: { [key: string]: string }) => string;
  userRoles: Roles[];
  workspaceRoles: Roles[];
  workspaceType?: WORKSPACE_TYPE[];
  subs?: Omit<MenuNode, "icon">[];
  forceHide?: boolean;
}

export const MENU_DATA: MenuNode[] = [
  {
    name: "Admin view",
    icon: ListViewIcon,
    route: () => "/admin/admin-view",
    userRoles: [Roles.admin],
    workspaceRoles: [],
    forceHide: !appConfigFromJson?.menuTab?.find(
      (i) => i.value === "/admin-view"
    )?.active,
  },
  // {
  //   name: "Fundraising",
  //   icon: ListViewIcon,
  //   route: () => "/admin/fundraising",
  //   userRoles: [Roles.admin],
  //   workspaceRoles: [],
  // },
  {
    name: "To publish",
    icon: ListViewIcon,
    route: () => "/admin/company",
    userRoles: [Roles.admin],
    workspaceRoles: [],
  },
  {
    name: "Company",
    icon: CompanyIcon,
    route: (params) => `/admin/company/${params?.companyId}/settings`,
    userRoles: [Roles.admin],
    subs: [
      {
        name: "Settings",
        route: (params) => `/admin/company/${params?.companyId}/settings`,
        userRoles: [Roles.admin],
        workspaceRoles: [],
      },
      {
        name: "Team",
        route: (params) => `/admin/company/${params?.companyId}/team`,
        userRoles: [Roles.admin],
        workspaceRoles: [],
      },
      {
        name: "Fundraising",
        route: (params) => `/admin/company/${params?.companyId}/fundraising`,
        userRoles: [Roles.admin],
        workspaceRoles: [],
      },
    ],
    workspaceRoles: [],
  },
  // {
  //   name: "Config",
  //   icon: InsightsIcon,
  //   route: () => "/admin-config",
  //   userRoles: [Roles.admin],
  //   workspaceRoles: [],
  // },
  {
    name: "Company",
    icon: CompanyIcon,
    route: () => "/company/preview",
    userRoles: [Roles.user],
    workspaceRoles: [Roles.owner, Roles.editor],
    workspaceType: [WORKSPACE_TYPE.FOUNDER],
    subs: [
      {
        name: "Preview",
        route: () => "/company/preview",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      {
        name: "Settings",
        route: () => "/company/settings",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      {
        name: "Team",
        route: () => "/company/team",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      {
        name: "MF Trust Score",
        route: () => "/company/mf-score",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      {
        name: "Founder Score",
        route: () => "/company/founder-score",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      {
        name: "Fundraising",
        route: () => "/company/fundraising",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      // {
      //   name: "Funds",
      //   route: () => "/company/funds",
      //   userRoles: [Roles.user],
      //   workspaceRoles: [Roles.owner, Roles.editor],
      // },
      // {
      //   name: "Data room",
      //   route: "/company/data-room",
      //   userRoles: [Roles.user],
      //   workspaceRoles: [Roles.owner, Roles.editor],
      // },
      {
        name: "Updates",
        route: () => "/company/updates",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
    ],
  },
  // {
  //   name: "Insights",
  //   icon: InsightsIcon,
  //   route: "/insights",
  //   userRoles: [Roles.user],
  //   workspaceRoles: [Roles.owner, Roles.editor],
  //   workspaceType: [WORKSPACE_TYPE.FOUNDER],
  // },
  // {
  //   name: "Investors",
  //   icon: InvestorsIcon,
  //   route: "/investors",
  //   userRoles: [Roles.user],
  //   workspaceRoles: [Roles.owner, Roles.editor],
  //   workspaceType: [WORKSPACE_TYPE.FOUNDER],
  // },
  {
    name: "Deal flow",
    icon: DealFlowIcon,
    route: () => "/deal-flow",
    userRoles: [Roles.user],
    workspaceRoles: [Roles.owner, Roles.editor],
    workspaceType: [WORKSPACE_TYPE.INVESTOR],
  },
  {
    name: "Funds",
    icon: ListViewIcon,
    route: () => "/funds",
    userRoles: [Roles.user],
    workspaceRoles: [Roles.owner, Roles.editor],
    workspaceType: [WORKSPACE_TYPE.INVESTOR],
    forceHide: !appConfigFromJson?.menuTab?.find((i) => i.value === "/funds")
      ?.active,
  },
  {
    name: "Updates",
    icon: UpdateIcon,
    route: () => "/updates",
    userRoles: [Roles.user],
    workspaceRoles: [Roles.owner, Roles.editor],
    workspaceType: [WORKSPACE_TYPE.INVESTOR],
    forceHide: !appConfigFromJson?.updateFeed?.active,
  },
  {
    name: "Chats",
    icon: ChatIcon,
    route: () => "/chats",
    userRoles: [Roles.user, Roles.admin],
    workspaceRoles: [Roles.owner, Roles.editor],
    workspaceType: [WORKSPACE_TYPE.FOUNDER, WORKSPACE_TYPE.INVESTOR],
  },
  {
    name: "Insights",
    icon: InsightsIcon,
    route: () => "/insights/assets",
    userRoles: [Roles.user],
    workspaceRoles: [Roles.owner, Roles.editor],
    workspaceType: [WORKSPACE_TYPE.PORTFOLIO],
    subs: [
      // {
      //   name: "Company",
      //   route: "/insights/company",
      //   userRoles: [Roles.user],
      //   workspaceRoles: [Roles.owner, Roles.editor],
      // },
      {
        name: "Assets",
        route: () => "/insights/assets",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      {
        name: "Reports",
        route: () => "/insights/reports",
        userRoles: [Roles.user],
        workspaceRoles: [Roles.owner, Roles.editor],
      },
      // {
      //   name: "Opportunities",
      //   route: "/insights/opportunities",
      //   userRoles: [Roles.user],
      //   workspaceRoles: [Roles.owner, Roles.editor],
      // },
    ],
  },
];
