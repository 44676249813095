import styled from "styled-components";

import { Avatar } from "components";

export const SocialPost = () => {
  return (
    <Container>
      <Header>
        <Avatar
          src="https://bit.ly/ryan-florence"
          name="Spectre Biotech"
          size={64}
        />

        <InfoWrapper>
          <div className="name">Spectre Biotech</div>
          <div className="sub">4 days ago</div>
        </InfoWrapper>
      </Header>

      <Text>
        {
          "Antony Branco Lopes is looking forward to seeing you at the WAICF - World AI Cannes Festival ! Thanks to the MONACO FOUNDRY family for their 360 degree support <3 Scott Young, Marie Herylovich, Shanka J., Fabrice Marquet, Brian Frederiksen"
        }
      </Text>

      <Image src="https://placebeard.it/640x360" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0px;
  border-top: 1px solid #ebecef;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 700;
    font-size: 20px;
  }

  .sub {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 16px;
  }
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
`;

const Image = styled.img`
  margin-top: 12px;
  border-radius: 8px;
`;
