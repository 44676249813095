import styled from "styled-components/macro";
import SignUpForm from "./components/SignUpFrom";
import { SignUpFooter, SignUpHeader } from "./components/SignUpStuff";

export const SignUpPage = () => {
  return (
    <Wrapper>
      <SignUpHeader />
      <SignUpForm />
      {false && <SignUpFooter />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 30px;
    border-radius: 16px;
  }
`;
