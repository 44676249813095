import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { useEffect, useState } from "react";
import { selectPosts } from "store/post/post.selector";
import { postActions } from "store/post/post.slice";
import styled from "styled-components";
import { UpdatePost } from "../Company/CompanyUpdates/components";
import { Text } from "components";

const LIMIT = 12;

export const UpdatesPage = () => {
  const [page, setPage] = useState(1);
  const posts = useAppSelector(selectPosts);
  const dispatch = useAppDispatch();

  const handleLoadMoreItem = () => {
    setPage((prePage) => prePage + 1);
  };

  useEffect(() => {
    dispatch(
      postActions.getPosts({
        filter: {
          namespace: "Own",
          postedById: null,
          status: null,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const pageContainerElem =
      document.getElementsByClassName("page-container")?.[0];
    pageContainerElem &&
      pageContainerElem?.addEventListener("scroll", handleLoadMoreItem);
    return () =>
      pageContainerElem?.removeEventListener("scroll", handleLoadMoreItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!posts || !posts?.length)
    return (
      <Wrapper>
        <Text size="lg" fontSize="20px" color="#373b59">
          The current moment has no updates.
        </Text>
      </Wrapper>
    );

  return (
    <Wrapper>
      <ListWrapper>
        {posts.slice(0, page * LIMIT).map((item) => (
          <UpdatePost key={item.id} data={item} isGuestView={true} />
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1120px;
  width: 100%;
`;
