import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import fundraisingApi from "api/fundraising.api";
import { CustomFundraisingParams, Fundraising } from "models/fundraising.model";
import { globalActions } from "store/global/global.slice";
import { errorNotification, successNotification } from "utils/app.util";
import { fundraisingActions } from "./fundraising.slice";
import { deleteFolder } from "utils/firebase.util";
import { StatusIndicator } from "models/common.model";

function* getFundraisingList(action: PayloadAction<{ id: string }>) {
  try {
    yield put(globalActions.setPageLoading(true));

    const { fundraisingProjectsGet } = yield call(
      fundraisingApi.fundraisingProjectsGet,
      action.payload.id
    );

    if (fundraisingProjectsGet) {
      const { fundraisingProjects } = fundraisingProjectsGet;

      yield put(
        fundraisingActions.getFundraisingListSuccess(fundraisingProjects || [])
      );
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getFundraising(
  action: PayloadAction<{ id: string; isAdmin?: boolean }>
) {
  try {
    yield put(globalActions.setPageLoading(true));
    const { id, isAdmin } = action.payload;
    const { fundraisingProjectGet, fundraisingProjectGetADMIN } = yield call(
      isAdmin
        ? fundraisingApi.fundraisingProjectGetADMIN
        : fundraisingApi.fundraisingProjectGet,
      id
    );

    if (fundraisingProjectGet || fundraisingProjectGetADMIN) {
      const { fundraisingProject } =
        fundraisingProjectGet || fundraisingProjectGetADMIN;

      yield put(fundraisingActions.getFundraisingSuccess(fundraisingProject));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* updateFundraising(
  action: PayloadAction<{
    id: string;
    input: CustomFundraisingParams;
    isAdmin?: boolean;
    callback?: () => void;
  }>
) {
  try {
    // yield put(globalActions.setPageLoading(true));

    const { id, input, isAdmin, callback } = action.payload;

    const apiCall = isAdmin
      ? fundraisingApi.fundraisingProjectAdmin
      : fundraisingApi.fundraisingProjectUpdate;

    const { fundraisingProjectUpdate, fundraisingProjectADMIN } = yield call(
      apiCall,
      id,
      input
    );

    const response = isAdmin
      ? fundraisingProjectADMIN
      : fundraisingProjectUpdate;

    if (response) {
      const { fundraisingProject } = response;

      yield put(
        fundraisingActions.updateFundraisingSuccess(fundraisingProject)
      );
    }

    yield put(globalActions.setPageLoading(false));
    callback && callback();
  } catch (err) {
    yield put(fundraisingActions.updateFundraisingFailed());
    yield put(errorNotification("Update Fundraising failed"));
    yield put(globalActions.setPageLoading(false));
  }
}

function* createFundraising(
  action: PayloadAction<{
    id: string;
    callback?: (fundraisingId: string) => void;
  }>
) {
  try {
    // yield put(globalActions.setPageLoading(true));

    const { id, callback } = action.payload;

    const { fundraisingProjectCreate } = yield call(
      fundraisingApi.fundraisingProjectCreate,
      id,
      {}
    );

    if (fundraisingProjectCreate) {
      const { fundraisingProject } = fundraisingProjectCreate;

      yield put(
        fundraisingActions.createFundraisingSuccess(fundraisingProject)
      );
      callback && callback(fundraisingProject.id);
    }

    yield put(globalActions.setPageLoading(false));
  } catch (err) {
    yield put(
      globalActions.pushNotification({
        type: "error",
        message: "Create fundraising failed",
      })
    );
    yield put(fundraisingActions.createFundraisingFailed());
    yield put(globalActions.setPageLoading(false));

    //throw err as Error;
  }
}

function* deleteFundraising(
  action: PayloadAction<{
    organisationId: string;
    fundraisingProjectId: string;
    callback?: () => void;
  }>
) {
  try {
    yield put(fundraisingActions.setLoading(true));

    const { organisationId, fundraisingProjectId, callback } = action.payload;

    yield call(
      deleteFolder,
      `workspaces/${organisationId}/fundraising/${fundraisingProjectId}`
    );
    const { fundraisingProjectDelete } = yield call(
      fundraisingApi.fundraisingProjectDelete,
      fundraisingProjectId
    );

    if (fundraisingProjectDelete) {
      yield put(fundraisingActions.getFundraisingList({ id: organisationId }));
    }

    callback && callback();
    yield put(fundraisingActions.setLoading(false));
    yield put(globalActions.setPageLoading(false));
  } catch (err) {
    yield put(
      globalActions.pushNotification({
        type: "error",
        message: "Delete fundraising failed",
      })
    );
    yield put(globalActions.setPageLoading(false));
  }
}

function* getFundraisingListAdmin() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { fundraisingProjectListADMIN } = yield call(
      fundraisingApi.fundraisingProjectListAdmin
    );

    if (fundraisingProjectListADMIN) {
      const { fundraisingProspects } = fundraisingProjectListADMIN;

      const fundraisingList = fundraisingProspects.map(
        (item: { fundraisingProject: Fundraising }) =>
          item["fundraisingProject"]
      );

      yield put(fundraisingActions.getFundraisingListSuccess(fundraisingList));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getLatestFundraising(action: PayloadAction<{ id: string }>) {
  try {
    yield put(globalActions.setPageLoading(true));

    const { fundraisingProjectsGet } = yield call(
      fundraisingApi.fundraisingProjectsGet,
      action.payload.id
    );

    if (fundraisingProjectsGet) {
      const { fundraisingProjects } = fundraisingProjectsGet;
      const latestFundraising = (fundraisingProjects as Fundraising[]).find(
        (item) => item.status === StatusIndicator.PUBLISHED
      );

      yield put(
        fundraisingActions.getLatestFundraisingSuccess(
          latestFundraising || ({} as Fundraising)
        )
      );
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* submitFundraising(
  action: PayloadAction<{ id: string; callback?: () => void }>
) {
  try {
    yield put(fundraisingActions.setLoading(true));

    const { id, callback } = action.payload;

    const { fundraisingProjectSubmit } = yield call(
      fundraisingApi.fundraisingProjectSubmit,
      id
    );

    if (fundraisingProjectSubmit) {
      const { fundraisingProject } = fundraisingProjectSubmit;

      yield put(
        fundraisingActions.submitFundraisingSuccess(fundraisingProject)
      );
    }
    yield put(successNotification("Submit fundraising successfully"));
    yield put(fundraisingActions.setLoading(false));
    callback && callback();
  } catch (_) {
    yield put(fundraisingActions.submitFundraisingFailed());
    yield put(errorNotification("Submit fundraising failed"));
    yield put(fundraisingActions.setLoading(false));
  }
}

export default function* fundraisingSaga() {
  yield takeLatest(
    fundraisingActions.getFundraisingList.type,
    getFundraisingList
  );
  yield takeLatest(fundraisingActions.getFundraising.type, getFundraising);
  yield takeLatest(
    fundraisingActions.updateFundraising.type,
    updateFundraising
  );
  yield takeLatest(
    fundraisingActions.createFundraising.type,
    createFundraising
  );
  yield takeLatest(
    fundraisingActions.deleteFundraising.type,
    deleteFundraising
  );
  yield takeLatest(
    fundraisingActions.getFundraisingListAdmin.type,
    getFundraisingListAdmin
  );
  yield takeLatest(
    fundraisingActions.getLatestFundraising.type,
    getLatestFundraising
  );
  yield takeLatest(
    fundraisingActions.submitFundraising.type,
    submitFundraising
  );
}
