import { useState } from "react";
import styled from "styled-components/macro";

import { Button } from "components";
import { AccreditedInvestorModal, CreateSpace, MySpace } from "./components";

export const WorkspacesPage = () => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <MySpace />
      <CreateSpace />
      {false && <Button onClick={() => setOpen(true)}>Open modal</Button>}
      {false && (
        <AccreditedInvestorModal isOpen={open} onClose={() => setOpen(false)} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 40px clamp(20px, 10vw, 100px);
  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 40px clamp(20px, 10vw, 40px);
  }
`;
