import { StepBar } from "components";
import styled from "styled-components/macro";

const STEPS = ["Draft", "Review", "Published"];

interface Props {
  status: string;
}

export const StatusBar = ({ status }: Props) => {
  const getStepIndex = () => {
    switch (status) {
      case "draft":
        return 0;
      case "review":
      case "flagged":
        return 1;
      case "published":
        return 3;
    }

    return 0;
  };

  return (
    <SterBarWrapper>
      <StepBar activeStep={getStepIndex()} steps={STEPS} />
    </SterBarWrapper>
  );
};

const SterBarWrapper = styled.div`
  margin: 0px -8px;
  background-color: #ffffff;
`;
