import styled from "styled-components";

interface Props {
  colorSchema: "green" | "gray" | "yellow" | "red" | "outline";
  text: string;
}

interface FlagProps {
  background: string;
  color: string;
  border?: string;
}

export const Flag = ({ colorSchema, text }: Props) => {
  const getStyles = (colorSchema: Props["colorSchema"]) => {
    switch (colorSchema) {
      case "green":
        return { background: "#E5F1E7", color: "#3b864c" };
      case "gray":
        return { background: "#EBECEF", color: "#373b59" };
      case "yellow":
        return { background: "#F7F2EB", color: "#c9af7f" };
      case "red":
        return { background: "#FFF1F0", color: "#D25C54" };
      case "outline":
        return {
          border: "1px solid #d8d9df",
          background: "#ffffff",
          color: "#373b59",
        };
    }
  };

  return <Wrapper {...getStyles(colorSchema)}>{text}</Wrapper>;
};

const Wrapper = styled.div`
  width: fit-content;
  padding: 4px 12px;
  border-radius: 4px;
  border: ${({ border }: FlagProps) => border || "none"};
  background: ${({ background }: FlagProps) => background};
  color: ${({ color }: FlagProps) => color};
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;
