import dayjs from "dayjs";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { PATH } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { selectNotificationList } from "store/chat/chat.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";

export const AdminRoutePage = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotificationList);
  const { id } = useAppSelector(selectOrganisation);
  const { pathname } = useLocation();

  useEffect(() => {
    const newNotification = notifications.find(
      (n) => n.isReviewOrg && !n.isRead
    );

    if (newNotification) {
      const { orgId, createdAt } = newNotification;

      const diff = dayjs().diff(dayjs(createdAt), "seconds");

      if (diff <= 30) {
        if (pathname === PATH.ADMIN_COMPANY) {
          dispatch(organisationActions.getOrganisationsAdmin());
        }
        if (pathname === `${PATH.ADMIN_COMPANY}/${orgId}/settings`) {
          dispatch(
            organisationActions.getOrganisation({
              id: orgId,
              isAdmin: true,
            })
          );
        }
      }
    }
  }, [dispatch, notifications, pathname]);

  useEffect(() => {
    const newNotification = notifications.find(
      (n) => n.isReviewFund && !n.isRead
    );

    if (newNotification) {
      const { fundId, createdAt } = newNotification;

      const diff = dayjs().diff(dayjs(createdAt), "seconds");

      if (diff <= 30) {
        if (pathname === PATH.ADMIN_FUNDRAISING) {
          dispatch(fundraisingActions.getFundraisingListAdmin());
        }
        if (pathname === `${PATH.ADMIN_FUNDRAISING}/${fundId}`) {
          dispatch(fundraisingActions.getFundraising({ id: fundId }));
        }
        if (pathname === `${PATH.ADMIN_COMPANY}/${id}/fundraising`) {
          dispatch(
            organisationActions.getOrganisation({
              id,
              isAdmin: true,
            })
          );
        }
        if (pathname === `${PATH.ADMIN_COMPANY}/${id}/fundraising/${fundId}`) {
          dispatch(fundraisingActions.getFundraising({ id: fundId }));
        }
      }
    }
  }, [dispatch, pathname, notifications, id]);

  return <Outlet />;
};
