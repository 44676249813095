import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { StatusIndicator } from "models/common.model";
import { hasValue } from "utils/system.util";
import { FundraisingState, initialState } from "./fundraising.slice";

const selectDomain = (state: { fundraising: FundraisingState }) =>
  state.fundraising || initialState;

export const selectFundraisingList = createSelector(
  [selectDomain],
  (state) => state.fundraisingList
);

export const selectFundraising = createSelector(
  [selectDomain],
  (state) => state.selectedFundraising || {}
);

export const selectLatestFundraising = createSelector(
  [selectDomain],
  (state) => state.latestFundraising
);

export const selectFundraisingLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectPossibleEditFundraising = createSelector(
  [selectFundraising],
  ({ status }) =>
    !_.includes(
      [
        StatusIndicator.REVIEW,
        StatusIndicator.PUBLISHED,
        StatusIndicator.ARCHIVED,
      ],
      status as StatusIndicator
    )
);

export const selectFundraisingProgress = createSelector(
  [selectFundraising],
  (state) => {
    if (!state.id) return [];

    const {
      roundSize,
      currentRound,
      instrument,
      minTicketSize,
      roundCloseDate,
      pitchDeckUrl,
      videoUrl,
      contactEmail,
      contactTel,
    } = state;

    return [
      {
        label: "Raising Info",
        isComplete:
          hasValue(roundSize) &&
          hasValue(currentRound) &&
          hasValue(instrument) &&
          hasValue(minTicketSize) &&
          hasValue(roundCloseDate),
      },
      {
        label: "Pitch Deck",
        isComplete: hasValue(pitchDeckUrl) || hasValue(videoUrl),
      },
      {
        label: "Contacts for investors",
        isComplete: hasValue(contactEmail) && hasValue(contactTel),
      },
    ];
  }
);
