import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ReportIcon = ({ color = "#606C82" }: Props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5718 5.17097L13.8932 4.50789L11.3041 7.17796C10.8307 7.66611 10.0632 7.66612 9.58987 7.17796L7.05303 4.56184L2.71881 9.03151C2.43479 9.32441 1.9743 9.32441 1.69029 9.03151C1.40627 8.73862 1.40627 8.26375 1.69029 7.97085L6.19593 3.32441C6.6693 2.83625 7.43677 2.83625 7.91013 3.32441L10.447 5.94052L12.8251 3.48809L12.125 2.82137C11.8328 2.54086 11.9539 2.04549 12.3383 1.94844L15.337 1.19121C15.6935 1.10069 16.0283 1.42243 15.9626 1.79186L15.4109 4.89871C15.3403 5.29643 14.8648 5.45223 14.5718 5.17097ZM6.81061 12.7512C6.40894 12.7512 6.08333 12.4154 6.08333 12.0012L6.08333 8.00119C6.08333 7.58698 6.40894 7.25119 6.81061 7.25119C7.21227 7.25119 7.53788 7.58698 7.53788 8.00119L7.53788 12.0012C7.53788 12.4154 7.21227 12.7512 6.81061 12.7512ZM1.7197 12.0012C1.7197 12.4154 2.04531 12.7512 2.44697 12.7512C2.84863 12.7512 3.17424 12.4154 3.17424 12.0012V11.0012C3.17424 10.587 2.84863 10.2512 2.44697 10.2512C2.04531 10.2512 1.7197 10.587 1.7197 11.0012L1.7197 12.0012ZM1.47727 14.2512C1.07561 14.2512 0.75 14.587 0.75 15.0012C0.75 15.4154 1.07561 15.7512 1.47727 15.7512H16.0227C16.4244 15.7512 16.75 15.4154 16.75 15.0012C16.75 14.587 16.4244 14.2512 16.0227 14.2512H1.47727ZM9.96212 12.0012C9.96212 12.4154 10.2877 12.7512 10.6894 12.7512C11.0911 12.7512 11.4167 12.4154 11.4167 12.0012V10.0012C11.4167 9.58698 11.0911 9.25119 10.6894 9.25119C10.2877 9.25119 9.96212 9.58698 9.96212 10.0012V12.0012ZM15.053 12.7512C14.6514 12.7512 14.3258 12.4154 14.3258 12.0012L14.3258 8.00119C14.3258 7.58698 14.6514 7.25119 15.053 7.25119C15.4547 7.25119 15.7803 7.58698 15.7803 8.00119L15.7803 12.0012C15.7803 12.4154 15.4547 12.7512 15.053 12.7512Z"
        fill={color}
      />
    </svg>
  );
};
