import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const TriangleDownIcon = ({ color = "#C2C7CF" }: Props) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6247 6.00024C6.25948 6.29242 5.74052 6.29242 5.37531 6.00024L0.726087 2.28087C-0.0120506 1.69036 0.405503 0.5 1.35078 0.5L10.6492 0.5C11.5945 0.5 12.0121 1.69036 11.2739 2.28087L6.6247 6.00024Z"
        fill={color}
      />
    </svg>
  );
};
