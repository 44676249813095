import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const EditIcon = ({ color = "#6A6D84" }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7453 1.7103C16.8173 3.01365 16.532 5.07074 15.1046 6.33144L7.85005 13.586C7.4847 13.9642 7.02802 14.2388 6.53754 14.3751L3.00023 15.3369C2.72416 15.4305 2.43701 15.3725 2.24975 15.1852C2.06249 14.998 2.00447 14.7108 2.0981 14.4348L3.05986 10.8975C3.20059 10.4084 3.47441 9.953 3.84899 9.58494L11.1035 2.33039C12.3754 0.900236 14.4435 0.623964 15.7453 1.7103ZM6.50143 12.2834C6.36138 12.4284 6.19553 12.5234 6.0391 12.5676L4.42987 13.0051L4.86459 11.4063C4.91411 11.2408 5.01418 11.0687 5.16313 10.9224L5.17482 10.9108L8.33555 7.75004C8.45669 8.03396 8.63716 8.30681 8.88612 8.55576C9.13225 8.8019 9.40175 8.98109 9.68221 9.10219L6.51273 12.2717L6.50143 12.2834ZM10.2792 6.12966L12.9725 3.43645L12.985 3.42413C13.3075 3.11331 13.7912 3.08757 14.0727 3.36914C14.2124 3.50458 14.284 3.69786 14.2714 3.90507C14.2587 4.11229 14.1628 4.31582 14.0054 4.46942L11.3122 7.16264C10.9898 7.48505 10.4972 7.51519 10.2119 7.22994C9.92669 6.94469 9.95683 6.45208 10.2792 6.12966Z"
        fill={color}
      />
      <path
        d="M0.9375 18.7492C0.9375 18.2315 1.29364 17.8117 1.73295 17.8117H17.642C18.0814 17.8117 18.4375 18.2315 18.4375 18.7492C18.4375 19.267 18.0814 19.6867 17.642 19.6867H1.73295C1.29364 19.6867 0.9375 19.267 0.9375 18.7492Z"
        fill={color}
      />
    </svg>
  );
};
