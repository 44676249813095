import {
  IconButton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { InputHTMLAttributes } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components/macro";

import { NoticeIcon } from "assets/images/common";
import { Text } from "components/UI/Typography/Text";
import "react-phone-number-input/style.css";
import { capitalizeFirstLetter } from "utils/system.util";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  fieldName?: string;
  error?: string;
  defaultCountry?: any;
  control?: Control<FieldValues>;
}

export const PhoneNumberField: React.FC<Props> = ({
  fieldName,
  error,
  defaultCountry = "US",
  control,
  ...props
}) => {
  return (
    <Wrapper>
      {fieldName && (
        <Text size="md" fontWeight={600}>
          {fieldName}
        </Text>
      )}

      <Controller
        name={props.name || ""}
        control={control}
        render={({ field }) => (
          <PhoneInput
            className={error && "error"}
            defaultCountry={defaultCountry}
            international
            {...props}
            {...field}
            onBlur={(e) => {
              field.onBlur();
              props.onBlur && props.onBlur(e as any);
            }}
          />
        )}
      />

      {error && (
        <Error className="field-error">
          <ErrorTooltip
            title={capitalizeFirstLetter(error)}
            placement="top-end"
            sx={{ textTransform: "capitalize" }}
          >
            <IconButton>
              <NoticeIcon />
            </IconButton>
          </ErrorTooltip>
        </Error>
      )}
    </Wrapper>
  );
};

const Error = styled.div`
  position: absolute;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FAFBFC",
    color: "#eb5a46",
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > ${Error} {
    right: 4px;
    bottom: 4px;
  }

  .PhoneInput {
    height: 48px;
    padding-left: 16px;
    border: 1px solid #ebecef;
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.7s;

    &.error {
      border-color: ${(props) => props.theme.palette.error.main};
    }

    .PhoneInputInput {
      border: none;
      color: ${(props) => props.theme.palette.primaryText.main};
      font-size: 16px;
      height: 100%;
      padding-left: 16px;

      &::placeholder {
        color: #a5a7b4;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        color: rgba(0, 0, 0, 0.38);
        background: unset;
      }
    }

    .PhoneInputCountryIcon {
      box-shadow: none;
    }

    .PhoneInputCountrySelectArrow {
      width: 8px;
      height: 8px;
      margin-bottom: 4px;
      margin-left: 10px;
      border-color: ${(props) => props.theme.palette.secondary.light};
      border-right-width: 2px;
      border-bottom-width: 2px;
    }
  }
`;
