import { PageWrapper } from "components";
import { Helmet } from "react-helmet-async";
import { FilterBar } from "./components/FilterBar";
import { ListCard } from "./components/ListCard";
export const DealFlowPage = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>MyFoundry</title>
        <link rel="icon" href="/logo.png" />
      </Helmet>
      <FilterBar />
      <ListCard />
    </PageWrapper>
  );
};
