import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultAppConfig, ENV } from "constants/app.const";
import { AppConfig, FunctionType } from "models/common.model";
import appConfigFromJson from "../../appConfig.json";
interface Notification {
  message: string;
  type: "error" | "warning" | "info" | "success";
}
export interface GlobalState {
  loading: boolean;
  isExpandMenu: boolean;
  notification: Notification | undefined;
  notificationModal: {
    isShow: boolean;
    title: string;
    onSubmit?: FunctionType<[FunctionType]>;
  };
  appConfig: AppConfig;
  headerSearch: string;
}
const getImageUrl = (name: string | undefined) =>
  name ? `${ENV.APP_CONFIG}/${name}` : undefined;

const APP_CONFIG_REMOTE = ENV.APP_CONFIG
  ? ({
      ...appConfigFromJson,
      bannerLogo: getImageUrl(appConfigFromJson?.bannerLogo || "/logo.png"),
      headerLogo: getImageUrl(appConfigFromJson?.headerLogo || "/logo.png"),
      parnerLogo: getImageUrl(
        (appConfigFromJson as unknown as AppConfig)?.parnerLogo
      ),
      faviLogo: getImageUrl(
        (appConfigFromJson as unknown as AppConfig)?.faviLogo
      ),
      avatarLogo: getImageUrl(
        (appConfigFromJson as unknown as AppConfig)?.avatarLogo
      ),
    } as unknown as AppConfig)
  : (defaultAppConfig as unknown as AppConfig);

export const initialState: GlobalState = {
  loading: false,
  isExpandMenu: false,
  notification: undefined,
  notificationModal: {
    isShow: false,
    title: "",
  },
  appConfig: APP_CONFIG_REMOTE,
  headerSearch: "",
};

export const pageLoadingSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setPageLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setIsExpandMenu(state, action: PayloadAction<boolean>) {
      state.isExpandMenu = action.payload;
    },
    pushNotification(state, action: PayloadAction<Notification | undefined>) {
      state.notification = action.payload;
    },
    showNotificationModal(
      state,
      action: PayloadAction<GlobalState["notificationModal"]>
    ) {
      state.notificationModal = action.payload;
    },
    updateAppConfig(state, action: PayloadAction<AppConfig>) {
      state.appConfig = action.payload;
    },
    updateHeaderSearch(state, action: PayloadAction<string>) {
      state.headerSearch = action.payload;
    },
  },
});

export const {
  actions: globalActions,
  reducer: globalReducer,
  name: sliceKey,
} = pageLoadingSlice;
