import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, dialogClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";

import { Avatar, Button, ButtonOutline } from "components";
import { TextArea } from "components/UI/Form/Input/TextArea";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { CustomPostParam, Post } from "models/post.model";
import { globalActions } from "store/global/global.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { selectPostLoading } from "store/post/post.selector";
import { postActions } from "store/post/post.slice";
import { uploadFromBlobAsync } from "utils/firebase.util";
import { AttachmentSection } from "./AttachmentSection";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedPost?: Post;
}

export const CreateNewUpdateModal = ({
  isOpen,
  onClose,
  selectedPost,
}: Props) => {
  const [postImage, setPostImage] = useState<Blob | undefined>(undefined);

  const dispatch = useAppDispatch();
  const {
    id: organisationId,
    displayName,
    logoUrl,
  } = useAppSelector(selectOrganisation);
  const loading = useAppSelector(selectPostLoading);

  const schema = yup.object().shape({
    title: yup.string().required(),
    body: yup.string().required(),
  });

  const postId = selectedPost?.id;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const createPost = (data: CustomPostParam) => {
    dispatch(
      postActions.createPost({
        organisationId,
        input: { ...data, postImage },
        callback: handleClose,
      })
    );
  };

  const updatePost = async (data: CustomPostParam) => {
    if (!postId) return;

    if (postImage && postId) {
      try {
        dispatch(globalActions.setPageLoading(true));

        const url = await uploadFromBlobAsync({
          blob: postImage,
          name: "post-image",
          folder: `workspaces/${organisationId}/posts/${postId}/image`,
        });

        data.attachment = url as string;
        setPostImage(undefined);
        dispatch(globalActions.setPageLoading(false));
      } catch (_) {
        setPostImage(undefined);
        dispatch(globalActions.setPageLoading(false));
      }
    }

    dispatch(
      postActions.updatePost({
        postId,
        input: data,
        callback: handleClose,
      })
    );
  };

  const onSubmit = async (formData: FieldValues) => {
    const { title, body, attachment } = formData;

    const input = {
      title,
      body,
      attachment,
    };

    selectedPost ? updatePost(input) : createPost(input);
  };

  useEffect(() => {
    if (selectedPost) {
      Object.keys(selectedPost).map((key) => {
        setValue(key, selectedPost[key as keyof Post]);
      });
    } else {
      reset();
    }
  }, [reset, selectedPost, setValue]);

  return (
    <BaseDialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <HeaderBox>{selectedPost ? "Update post" : "Create new post"}</HeaderBox>

      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <InfoWrapper>
          <Avatar src={logoUrl} name={displayName} size={40} />

          {displayName}
        </InfoWrapper>

        <TextArea
          fieldName="Title"
          name="title"
          placeholder="Type some words here..."
          minRows={3}
          maxLength={140}
          hasCount
          formRef={register}
          watch={watch}
          error={errors.title?.message as string}
          autoFocus
        />

        <TextArea
          fieldName="Text"
          name="body"
          placeholder="Type some words here..."
          minRows={3}
          maxLength={400}
          hasCount
          formRef={register}
          watch={watch}
          error={errors.body?.message as string}
        />

        <AttachmentSection
          name="attachment"
          formRef={register}
          error={errors.attachment?.message as string}
          reset={reset}
          watch={watch}
          handleImage={(image) => setPostImage(image)}
        />

        <FooterBox>
          <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>

          <Button type="submit" disabled={!isValid} loading={loading}>
            {selectedPost ? "Update" : "Publish"}
          </Button>
        </FooterBox>
      </Wrapper>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 32px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 20px 20px 0px;
  }
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: calc(100vw - 64px);
    padding: 20px;
  }

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 20px;
  font-weight: 700;
`;

const FooterBox = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid #ebecef;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }

  & button,
  .button-wrapper {
    width: 100%;
  }
`;
