import prospectApi from "api/prospect.api";
import {
  ProspectFieldEditor,
  ProspectMultiSelect,
  ProspectToggleButton,
  Text,
} from "components";
import { useAppSelector } from "hooks/useHook";
import { selectProspect } from "store/prospect/prospect.selector";
import styled from "styled-components/macro";
import * as yup from "yup";

const selectData = [
  {
    title: "Sector",
    name: "sector",
    callApi: prospectApi.sectorsGet,
    resName: "marketSectors",
  },
  {
    title: "Impact",
    name: "impactValues",
    callApi: prospectApi.impactsGet,
    resName: "impactValues",
  },
  {
    title: "Business Type",
    name: "businessType",
    callApi: prospectApi.businessTypesGet,
    resName: "businessTypes",
  },
  {
    title: "Product Type",
    name: "productType",
    callApi: prospectApi.productTypesGet,
    resName: "productTypes",
  },
];

const textData = [
  {
    title: "Previously part of",
    name: "prevPartOf",
    yupSchema: yup.string().nullable(true),
  },
  {
    title: "Annual Revenue",
    name: "annualRevenue",
    yupSchema: yup.number(),
    unit: "€",
  },
  {
    title: "MRR",
    name: "MRR",
    yupSchema: yup.number(),
    unit: "€",
  },
  {
    title: "Previously Raised",
    name: "preFundedAmount",
    yupSchema: yup.number(),
    unit: "€",
  },
  {
    title: "Currently Raising",
    name: "roundSize",
    yupSchema: yup.number(),
    unit: "€",
  },
];

interface Props {
  isAdmin?: boolean;
}

export const BusinessOverview = ({ isAdmin }: Props) => {
  const { dataroomReady } = useAppSelector(selectProspect);
  return (
    <Wrapper>
      <Text size="xl" color="#050A30">
        Business Overview
      </Text>
      <CategoryWrapper>
        {selectData.map((item) => (
          <CategoryItem key={item.title}>
            <Text size={"lg"}>{item.title}</Text>
            <ProspectMultiSelect
              name={item.name}
              resName={item.resName}
              callApi={item.callApi}
            />
          </CategoryItem>
        ))}
        {textData.map((item) => (
          <CategoryItem key={item.title}>
            <Text size={"lg"}>{item.title}</Text>
            <ProspectFieldEditor
              name={item.name}
              yupSchema={item.yupSchema}
              unit={item?.unit}
            />
          </CategoryItem>
        ))}

        {!isAdmin && dataroomReady && (
          <CategoryItem key="dataroomReady">
            <Text size={"lg"}>Dataroom</Text>
            <Text size={"lg"}>Ready</Text>
          </CategoryItem>
        )}
        {isAdmin && (
          <CategoryItem key="dataroomReady">
            <Text size={"lg"}>Dataroom</Text>
            <ProspectToggleButton
              label="Ready"
              forLabel={true}
              name="dataroomReady"
            />
          </CategoryItem>
        )}
      </CategoryWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  border: 0 solid #ebecef;
  border-top-width: 1px;
  padding: 16px 0;

  & > div {
    flex-basis: 50%;
    text-align: left;
    font-size: 16px;

    &:first-child {
      font-weight: 400;
    }
  }

  .MuiFormControlLabel-root {
    width: fit-content;
  }
`;
