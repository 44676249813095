import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { Button, SectionWrapper } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppSelector } from "hooks/useHook";
import { selectFundraising } from "store/fundraising/fundraising.selector";
import { PATH } from "constants/app.const";

export const AdminOrganisationSection = () => {
  const { organisation } = useAppSelector(selectFundraising);
  const navigate = useNavigate();

  return (
    <SectionWrapper title="Organisation">
      <GroupTextField
        fieldName="Company Name"
        value={organisation?.displayName || ""}
        disabled
      />

      <Box mt="16px">
        <Button
          onClick={() =>
            organisation &&
            navigate(`${PATH.ADMIN_COMPANY}/${organisation.id}/settings`)
          }
        >
          Go to organisation
        </Button>
      </Box>
    </SectionWrapper>
  );
};
