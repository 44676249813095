import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const PitchDeckIcon = ({ color = "#6A6D84" }: Props) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 17.9996H2C1.45 17.9996 0.979333 17.8039 0.588 17.4126C0.196 17.0206 0 16.5496 0 15.9996V3.99961C0 3.44961 0.196 2.97894 0.588 2.58761C0.979333 2.19561 1.45 1.99961 2 1.99961H7.975V3.99961H2V15.9996H18V12.0246H20V15.9996C20 16.5496 19.8043 17.0206 19.413 17.4126C19.021 17.8039 18.55 17.9996 18 17.9996ZM2 15.9996V3.99961V12.0246V11.3496V15.9996ZM13.45 6.54961L11 5.44961C10.8 5.36628 10.7 5.21628 10.7 4.99961C10.7 4.78294 10.8 4.63294 11 4.54961L13.45 3.44961L14.55 1.02461C14.6333 0.82461 14.7833 0.724609 15 0.724609C15.2167 0.724609 15.3667 0.82461 15.45 1.02461L16.55 3.44961L18.975 4.54961C19.175 4.63294 19.275 4.78294 19.275 4.99961C19.275 5.21628 19.175 5.36628 18.975 5.44961L16.55 6.54961L15.45 8.99961C15.3667 9.19961 15.2167 9.29961 15 9.29961C14.7833 9.29961 14.6333 9.19961 14.55 8.99961L13.45 6.54961Z"
        fill={color}
      />
    </svg>
  );
};
