import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ListViewIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12V2C0 1.45 0.195667 0.979333 0.587 0.588C0.979 0.196 1.45 0 2 0H16C16.55 0 17.021 0.196 17.413 0.588C17.8043 0.979333 18 1.45 18 2V12C18 12.55 17.8043 13.021 17.413 13.413C17.021 13.8043 16.55 14 16 14H2C1.45 14 0.979 13.8043 0.587 13.413C0.195667 13.021 0 12.55 0 12ZM2 4H4V2H2V4ZM6 4H16V2H6V4ZM6 8H16V6H6V8ZM6 12H16V10H6V12ZM2 12H4V10H2V12ZM2 8H4V6H2V8Z"
        fill={color}
      />
    </svg>
  );
};
