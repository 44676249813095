import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const CheckIcon = ({ color = "#228B22" }: Props) => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7265 0.771922C11.9901 1.03553 11.9901 1.46291 11.7265 1.72652L4.87422 8.57881L0.646922 4.35152C0.383318 4.08791 0.383318 3.66053 0.646922 3.39692C0.910526 3.13332 1.33791 3.13332 1.60152 3.39692L4.87422 6.66962L10.7719 0.771922C11.0355 0.508318 11.4629 0.508318 11.7265 0.771922Z"
        fill={color}
      />
    </svg>
  );
};
