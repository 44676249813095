import prospectApi from "api/prospect.api";
import { PitchDeckIcon, VideoIcon } from "assets/images/common";
import { LinkIcon, LocationIcon } from "assets/images/company";
import {
  ImageEditor,
  ProspectCombobox,
  ProspectFieldEditor,
  ProspectMediaEditor,
  ProspectMediaFrame,
} from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Country, ProspectMultiSelectType } from "models/prospect.model";
import { useEffect, useState } from "react";
import { globalActions } from "store/global/global.slice";
import { selectProspect } from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";
import { uploadFromBlobAsync } from "utils/firebase.util";
import * as yup from "yup";

const stringYup = yup.string().nullable(true);

export const Header = () => {
  const dispatch = useAppDispatch();
  const [countries, setCountries] = useState<Country[]>([]);
  const [logoImage, setLogoImage] = useState<Blob | undefined>(undefined);
  const { logoUrl, id, videoUrl, pitchDeckUrl, mfScoreUrl } =
    useAppSelector(selectProspect);
  const [mediaTab, setMediaTab] = useState<"VIDEO" | "DECK" | "MFSCORE">(
    "VIDEO"
  );
  const [isFirstSet, setIsFirstSet] = useState(true);

  const getListCountry = async () => {
    const { countries } = await prospectApi.countriesGet();
    const countriesData = countries?.map((i: Country) => ({
      ...i,
      label: i.name,
    }));
    setCountries(countriesData);
  };

  useEffect(() => {
    if (isFirstSet) {
      if (!videoUrl && pitchDeckUrl) setMediaTab("DECK");
      else if (!videoUrl && !pitchDeckUrl && mfScoreUrl) {
        setMediaTab("MFSCORE");
      }

      setTimeout(() => {
        setIsFirstSet(false);
      }, 10000);
    }
  }, [isFirstSet, mfScoreUrl, pitchDeckUrl, videoUrl]);

  useEffect(() => {
    getListCountry();
  }, []);

  useEffect(() => {
    if (logoImage && id) {
      dispatch(globalActions.setPageLoading(true));
      try {
        uploadFromBlobAsync({
          blob: logoImage,
          name: id,
          folder: "avatars",
        }).then((url) => {
          const data = {
            logoUrl: url as string,
          };
          dispatch(
            prospectActions.updateProspect({
              prospectUpdateId: id,
              input: data,
            })
          );
          dispatch(globalActions.setPageLoading(false));
        });
      } catch (error) {
        dispatch(
          globalActions.pushNotification({
            type: "error",
            message: "Update logo failed",
          })
        );
        dispatch(globalActions.setPageLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoImage]);

  return (
    <Wrapper>
      <Title>
        {id ? (
          <ImageEditor
            className="logo-editor"
            onSave={setLogoImage}
            url={logoUrl}
            height={200}
            width={200}
            ignoreCrop={false}
          />
        ) : (
          <LogoPlaceholder />
        )}

        <ProspectFieldEditor
          name="displayName"
          placeholder="Set company name"
          yupSchema={stringYup}
          textClass="title-text"
        />
      </Title>
      <ProspectFieldEditor
        name="slogan"
        placeholder="Set slogan"
        yupSchema={stringYup}
        textClass="logo-text"
      />
      <GroupText>
        <HorizontalGroup>
          <TextIcon>
            <LocationIcon />
            <ProspectCombobox
              name="country"
              dataList={countries as ProspectMultiSelectType[]}
              placeholder="Select country"
              textClass="md-text"
            />
          </TextIcon>
          <TextIcon>
            <LinkIcon />
            <ProspectFieldEditor
              name="websiteUrl"
              placeholder="Set web address"
              yupSchema={stringYup}
              textClass="md-text"
              linkName="websiteUrl"
            />
          </TextIcon>
        </HorizontalGroup>

        {videoUrl && mediaTab === "VIDEO" && (
          <ProspectMediaFrame name="videoUrl" />
        )}

        {pitchDeckUrl && mediaTab === "DECK" && (
          <ProspectMediaFrame name="pitchDeckUrl" />
        )}

        {mfScoreUrl && mediaTab === "MFSCORE" && (
          <ProspectMediaFrame name="mfScoreUrl" />
        )}

        <HorizontalGroup>
          <TextIcon
            className={mediaTab === "VIDEO" ? "active-item" : "disable-item"}
            onClick={() => setMediaTab("VIDEO")}
          >
            <VideoIcon color={mediaTab === "VIDEO" ? "#6A6D84" : "#A5A7B4"} />
            <ProspectMediaEditor
              name="videoUrl"
              placeholder="Set video link"
              yupSchema={stringYup}
              textClass="md-text"
              linkName="Video Presentation"
              accept="video/*"
            />
          </TextIcon>
          <TextIcon
            className={mediaTab === "DECK" ? "active-item" : "disable-item"}
            onClick={() => setMediaTab("DECK")}
          >
            <PitchDeckIcon
              color={mediaTab === "DECK" ? "#6A6D84" : "#A5A7B4"}
            />
            <ProspectMediaEditor
              name="pitchDeckUrl"
              placeholder="Set Pitch deck link"
              yupSchema={stringYup}
              textClass="md-text"
              linkName="Pitch deck"
              accept="image/*, application/pdf"
            />
          </TextIcon>
          <TextIcon
            className={mediaTab === "MFSCORE" ? "active-item" : "disable-item"}
            onClick={() => setMediaTab("MFSCORE")}
          >
            <PitchDeckIcon
              color={mediaTab === "MFSCORE" ? "#6A6D84" : "#A5A7B4"}
            />
            <ProspectMediaEditor
              name="mfScoreUrl"
              placeholder="Set MF Trust Score link"
              yupSchema={stringYup}
              textClass="md-text"
              linkName="MF Trust Score report"
              accept="image/*, application/pdf"
            />
          </TextIcon>
        </HorizontalGroup>
      </GroupText>
    </Wrapper>
  );
};

const HorizontalGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const GroupText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-top: 15px;
`;

const TextIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title-text {
    font-size: 30px;
    font-weight: 700;
    color: ${(props) => props.theme.palette.secondary.dark};
  }

  .logo-text {
    font-size: 18px;
    font-weight: 400;
    color: ${(props) => props.theme.palette.secondary.main};
  }

  .disable-item {
    .md-text {
      color: #a5a7b4;
    }
  }

  .md-text {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 5px;

  .logo-editor {
    background-color: #f4f5f7;
  }

  .image-view {
    img {
      height: 50px;
      width: auto;
    }
  }
`;

const LogoPlaceholder = styled.div`
  height: 50px;
  width: 50px;
  background-color: #ebecef;
`;
