/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { CustomFundParams, Fund } from "models/fund.model";

export interface FundState {
  fundList: Fund[];
  selectedFund: Fund;
  loading: boolean;
}

export const initialState: FundState = {
  fundList: [],
  selectedFund: {} as Fund,
  loading: false,
};

const fundSlice = createSlice({
  name: "fund",
  initialState,
  reducers: {
    setLoading(state, _action: PayloadAction<boolean>) {
      state.loading = _action.payload;
    },
    getFundList(_, _action: PayloadAction<{ organisationId: string }>) {},
    getFundListSuccess(state, _action: PayloadAction<Fund[]>) {
      state.fundList = _action.payload;
    },
    getFund(_, _action: PayloadAction<{ fundId: string }>) {},
    getFundSuccess(state, _action: PayloadAction<Fund>) {
      state.selectedFund = _action.payload;
    },
    createFund(
      _,
      _action: PayloadAction<{
        organisationId: string;
        callback?: (fundId: string) => void;
      }>
    ) {},
    createFundSuccess(state, _action: PayloadAction<Fund>) {
      state.fundList.push(_action.payload);
    },
    updateFund(
      _,
      _action: PayloadAction<{
        fundId: string;
        input: CustomFundParams;
        callback?: () => void;
      }>
    ) {},
    updateFundSuccess(state, _action: PayloadAction<Fund>) {
      state.selectedFund = _action.payload;
    },
    submitFund(
      _,
      _action: PayloadAction<{ fundId: string; callback?: () => void }>
    ) {},
    submitFundSuccess(state, _action: PayloadAction<Fund>) {
      state.selectedFund = _action.payload;
    },
    unPublishFund(
      _,
      _action: PayloadAction<{ fundId: string; callback?: () => void }>
    ) {},
    unPublishFundSuccess(state, _action: PayloadAction<Fund>) {
      state.selectedFund = _action.payload;
    },
    deleteFund(
      _,
      _action: PayloadAction<{
        fundId: string;
        organisationId: string;
        callback?: () => void;
      }>
    ) {},
    deleteFundSuccess(state, _action: PayloadAction<{ fundId: string }>) {
      state.fundList = state.fundList.filter(
        (i) => i.id !== _action.payload.fundId
      );
    },
  },
});

export const {
  actions: fundActions,
  reducer: fundReducer,
  name: sliceKey,
} = fundSlice;
