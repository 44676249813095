import { Link as LinkMui, LinkProps, styled } from "@mui/material";
interface Props extends LinkProps {
  children: React.ReactNode;
}

export const Link = ({ children, ...props }: Props) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

const Wrapper = styled(LinkMui)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.9;
  }
`;
