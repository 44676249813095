import { call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import prospectApi from "api/prospect.api";
import { QueryAction } from "models/common.model";
import {
  CustomProspectParams,
  Prospect,
  ProspectStatus,
  ProspectTeamMemberParams,
} from "models/prospect.model";
import { globalActions } from "store/global/global.slice";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { errorNotification, successNotification } from "utils/app.util";
import { prospectActions } from "./prospect.slice";
import { uploadFromBlobAsync } from "utils/firebase.util";

function* getProspects(action: PayloadAction<{ isAdmin?: boolean }>) {
  try {
    yield put(globalActions.setPageLoading(true));
    const { isAdmin } = action.payload;
    const apiCall = isAdmin
      ? prospectApi.prospectsGetAdmin
      : prospectApi.prospectsGet;
    const { prospectsGet, prospectsGetAdmin } = yield call(apiCall);
    yield put(
      prospectActions.getProspectsSuccess(
        isAdmin ? prospectsGetAdmin : prospectsGet
      )
    );
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getCompanyProspect() {
  try {
    yield put(globalActions.setPageLoading(true));
    const { prospectsGet }: { prospectsGet: Prospect[] } = yield call(
      prospectApi.prospectsGet
    );

    if (prospectsGet && prospectsGet?.length) {
      const { prospectGet } = yield call(
        prospectApi.prospectGet,
        prospectsGet[0]?.id
      );
      yield put(prospectActions.getProspectSuccess(prospectGet));
    } else {
      const { prospectCreate } = yield call(prospectApi.prospectCreate, {});

      if (prospectCreate) {
        yield put(prospectActions.createProspectSuccess(prospectCreate));
      }
    }
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getProspect(action: PayloadAction<{ id: string; isAdmin: boolean }>) {
  try {
    yield put(globalActions.setPageLoading(true));
    const { id, isAdmin } = action.payload;
    if (id) {
      const callApi = isAdmin
        ? prospectApi.prospectGetAdmin
        : prospectApi.prospectGet;
      const { prospectGet, prospectGetAdmin } = yield call(callApi, id);
      if (prospectGet || prospectGetAdmin) {
        yield put(
          prospectActions.getProspectSuccess(
            isAdmin ? prospectGetAdmin : prospectGet
          )
        );
      }
    }
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* createProspect(action: PayloadAction<CustomProspectParams>) {
  try {
    const { prospectCreate } = yield call(
      prospectApi.prospectCreate,
      action.payload
    );
    if (prospectCreate) {
      yield put(prospectActions.createProspectSuccess(prospectCreate));
    }
    //yield put(successNotification("Create Prospect successfully"));
  } catch (err) {
    yield put(prospectActions.createProspectFailed());
    yield put(errorNotification("Create Prospect failed"));
  }
}

function* updateProspect(
  action: PayloadAction<{
    prospectUpdateId: string;
    input: CustomProspectParams;
    fieldUpdate?: string;
  }>
) {
  try {
    const { prospectUpdateId, input, fieldUpdate } = action.payload;
    const { prospectUpdate } = yield call(
      prospectApi.prospectUpdate,
      prospectUpdateId,
      input
    );
    if (prospectUpdate) {
      yield put(
        prospectActions.updateProspectSuccess({
          data: prospectUpdate,
          fieldUpdate,
        })
      );
    }
    // yield put(successNotification("Update Prospect successfully"));
    yield put(globalActions.setPageLoading(false));
  } catch (err) {
    yield put(prospectActions.updateProspectFailed());
    yield put(errorNotification("Update Prospect failed"));
    yield put(globalActions.setPageLoading(false));
  }
}

function* setProspectStatus(
  action: PayloadAction<{
    prospectSetStatusId: string;
    input: {
      status: ProspectStatus;
    };
  }>
) {
  try {
    yield put(globalActions.setPageLoading(true));
    const { prospectSetStatusId, input } = action.payload;
    const { prospectSetStatus } = yield call(
      prospectApi.prospectSetStatus,
      prospectSetStatusId,
      input
    );
    if (prospectSetStatus) {
      yield put(prospectActions.setProspectStatusSuccess(prospectSetStatus));
    }
    //yield put(successNotification("Update status successfully"));
  } catch (err) {
    yield put(prospectActions.setProspectStatusFailed());
    yield put(errorNotification("Update status failed"));
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* setProspectMfScore(
  action: PayloadAction<{
    prospectSetMfScoreId: string;
    input: {
      mfScore: number;
    };
  }>
) {
  try {
    const { prospectSetMfScoreId, input } = action.payload;
    const { prospectSetMfScore } = yield call(
      prospectApi.prospectSetMfScore,
      prospectSetMfScoreId,
      input
    );
    if (prospectSetMfScore) {
      yield put(prospectActions.setProspectMfScoreSuccess(prospectSetMfScore));
    }
    //yield put(successNotification("Update status successfully"));
  } catch (err) {
    yield put(errorNotification("Update status failed"));
  }
}

function* customTeamMember(
  action: PayloadAction<{
    prospectCustomTeamMemberId: string;
    data: ProspectTeamMemberParams | null;
    type: QueryAction;
    avatarImage?: Blob;
    isAdmin?: boolean;
    teamMemberId?: string;
    callback?: () => void;
  }>
) {
  try {
    const {
      prospectCustomTeamMemberId,
      data,
      type,
      avatarImage,
      teamMemberId,
      isAdmin,
      callback,
    } = action.payload;

    const { prospectAddTeamMember } = yield call(
      prospectApi.prospectCustomTeamMember,
      prospectCustomTeamMemberId,
      data,
      type,
      teamMemberId
    );

    if (type === "CREATE") {
      const { teamMembers } = prospectAddTeamMember;
      const newMember = teamMembers[0];

      if (avatarImage) {
        const url: string = yield call(uploadFromBlobAsync, {
          blob: avatarImage,
          name: newMember.id,
          folder: "avatars",
        });

        yield call(
          prospectApi.prospectCustomTeamMember,
          prospectCustomTeamMemberId,
          { photoUrl: url } as ProspectTeamMemberParams,
          "UPDATE",
          newMember.id
        );

        newMember.photoUrl = url;
      }

      yield put(prospectActions.customTeamMemberSuccess(teamMembers));
    } else {
      yield put(
        prospectActions.getProspect({ id: prospectCustomTeamMemberId, isAdmin })
      );
    }

    callback && callback();

    //yield put(successNotification("Update Prospect team member successfully"));
  } catch (err) {
    yield put(prospectActions.customTeamMemberFailed());
    yield put(errorNotification("Update Create Prospect team member failed"));
  }
}

export default function* prospectSaga() {
  yield takeLatest(prospectActions.getProspects.type, getProspects);
  yield takeLatest(prospectActions.getProspect.type, getProspect);
  yield takeLatest(
    prospectActions.getCompanyProspects.type,
    getCompanyProspect
  );
  yield takeLatest(prospectActions.createProspect.type, createProspect);
  yield takeLatest(prospectActions.updateProspect.type, updateProspect);
  yield takeLatest(prospectActions.setProspectStatus.type, setProspectStatus);
  yield takeLatest(prospectActions.setProspectMfScore.type, setProspectMfScore);
  yield takeLatest(prospectActions.customTeamMember.type, customTeamMember);
}
