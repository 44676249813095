export const getErrorMessage = (error) => {
  let errorString = "";

  // Check if the error is a GraphQL error and has an array of errors
  if (error?.response?.errors && error.response.errors.length > 0) {
    // Extract the message from the first GraphQL error
    errorString = error.response.errors[0].message || "";
  } else {
    // Handle non-GraphQL errors or if no specific GraphQL error was found
    errorString = typeof error === "string" ? error : error?.message || "";
  }

  // Check for specific errors
  if (
    errorString.includes(
      "The email address is already in use by another account"
    )
  ) {
    return "This email is already used for another account. Please, try to “Sign in” using this email.";
  }

  // Check for 'auth:' specific errors
  if (errorString.startsWith("auth:")) {
    return errorString.replace("auth:", "").trim();
  }

  // Default error message if no specific error matches
  return "Something went wrong. Please try again!";
};
