import {
  Button as MuiButton,
  ButtonProps,
  styled,
  CircularProgress,
} from "@mui/material";
import { FlexBox } from "components/UI/Others/Utils";
interface Props extends ButtonProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  loading?: boolean;
}

export const ButtonOutline = ({ children, icon, loading, ...props }: Props) => {
  return (
    <Wrapper {...props}>
      {!loading && (
        <>
          {icon}
          <FlexBox>{children}</FlexBox>
        </>
      )}

      {loading && <LoadingAnimation size="20px" color="inherit" />}
    </Wrapper>
  );
};

const Wrapper = styled(MuiButton)`
  position: relative;
  display: flex;
  gap: 10px;
  border: 1px solid #ebecef;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.light};
  background-color: white;
  height: 48px;
  padding: 0px 24px;
  transition: all 0.5s;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 14px;
    padding: 0px 16px;
  }

  &:hover {
    background-color: #f4f5f9;
    cursor: pointer;
  }
`;

const LoadingAnimation = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  color: #6a6d84;
`;
