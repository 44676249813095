import { Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import HeaderBannerMobile from "assets/images/company/header-banner-mobile.png";
import HeaderBanner from "assets/images/company/header-banner.png";
import { useAppDispatch, useAppParams, useAppSelector } from "hooks/useHook";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { selectProspect } from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";
import { About } from "./components/About";
import { BusinessOverview } from "./components/BusinessOverview";
import { DataRoom } from "./components/DataRoom";
import { Header } from "./components/Header";
import { InvestmentDetails } from "./components/InvestmentDetails";
import { StatusBar } from "./components/StatusBar";
import { TeamMember } from "./components/TeamMembers";

interface Props {
  isAdmin?: boolean;
}

export const ProspectPage = ({ isAdmin }: Props) => {
  const [tab, setTab] = useState<"prospect" | "fundraising">("prospect");
  const theme = useTheme();
  const isMobileMode = useMediaQuery(theme.breakpoints.down("md"));
  const { id = "" } = useAppParams();
  const headerRef = useRef<HTMLDivElement>(null);
  const { logoUrl, displayName } = useAppSelector(selectProspect);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const currentTab = params.get("tab");

    if (currentTab) {
      setTab(currentTab as "prospect" | "fundraising");
    }
  }, [params]);

  useEffect(() => {
    dispatch(
      isAdmin
        ? prospectActions.getProspect({ id, isAdmin })
        : prospectActions.getCompanyProspects()
    );

    return () => {
      dispatch(prospectActions.unselectProspect());
    };
  }, [dispatch, id, isAdmin]);

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView(true);
    }
  }, []);

  return (
    <Wrapper ref={headerRef}>
      <Helmet>
        <title>{`${displayName ? `${displayName} | ` : ""} MyFoundry`}</title>
        <link rel="icon" href={logoUrl} />
        <meta property="description" content={`${displayName} Company Brief`} />
      </Helmet>

      {false && (
        <TabList>
          <Tabs
            value={tab}
            onChange={(_, value) => {
              setTab(value);
              navigate(`${pathname}?tab=${value}`);
            }}
            textColor="inherit"
            indicatorColor="primary"
          >
            <Tab value="prospect" label="Prospect" />
            <Tab value="fundraising" label="Fundraising" />
          </Tabs>
        </TabList>
      )}
      {tab === "prospect" && (
        <TabContent>
          <StatusBar />

          {false && (
            <BackgroundHeader
              src={isMobileMode ? HeaderBannerMobile : HeaderBanner}
            ></BackgroundHeader>
          )}

          <Container>
            <LeftBox>
              <Header />
              <BusinessOverview isAdmin={isAdmin} />
              <About />
              <TeamMember isAdmin={isAdmin} />
              {false && <DataRoom />}
            </LeftBox>
            <RightBox>
              <InvestmentDetails isAdmin={isAdmin} />
            </RightBox>
          </Container>
        </TabContent>
      )}

      {tab === "fundraising" && <TabContent></TabContent>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f4f5f7;
  padding-top: 30px;
  min-height: 100%;
  padding: clamp(20px, 4vh, 60px) clamp(20px, 4vw, 80px);
`;

const BackgroundHeader = styled.img`
  width: 100%;
  height: auto;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: clamp(20px, 3vw, 80px);

  ${(props) => props.theme.breakpoints.down("md")} {
    display: flex;
    flex-direction: column;
  }
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  grid-column-start: 1;
  grid-column-end: 3;
`;

const RightBox = styled.div`
  padding-top: 100px;
  grid-column-start: 3;
  grid-column-end: 4;
`;

const TabList = styled.div`
  margin-bottom: 32px;

  .MuiTab-root {
    font-size: 16px;
    text-transform: capitalize;

    &.Mui-selected {
      font-weight: 600;
    }
  }

  .MuiTabs-indicator {
    background: ${(props) => props.theme.palette.secondary.main};
  }
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
