import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const EyeIcon = ({ color = "#D8D9DF" }: Props) => {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="eye-icon"
    >
      <path
        d="M11 12C12.25 12 13.3127 11.5627 14.188 10.688C15.0627 9.81267 15.5 8.75 15.5 7.5C15.5 6.25 15.0627 5.18733 14.188 4.312C13.3127 3.43733 12.25 3 11 3C9.75 3 8.68734 3.43733 7.812 4.312C6.93734 5.18733 6.5 6.25 6.5 7.5C6.5 8.75 6.93734 9.81267 7.812 10.688C8.68734 11.5627 9.75 12 11 12ZM11 10.2C10.25 10.2 9.61267 9.93733 9.088 9.412C8.56267 8.88733 8.3 8.25 8.3 7.5C8.3 6.75 8.56267 6.11233 9.088 5.587C9.61267 5.06233 10.25 4.8 11 4.8C11.75 4.8 12.3877 5.06233 12.913 5.587C13.4377 6.11233 13.7 6.75 13.7 7.5C13.7 8.25 13.4377 8.88733 12.913 9.412C12.3877 9.93733 11.75 10.2 11 10.2ZM11 15C8.68334 15 6.56667 14.3877 4.65 13.163C2.73334 11.9377 1.28334 10.2833 0.300003 8.2C0.250003 8.11667 0.21667 8.01233 0.200003 7.887C0.183336 7.76233 0.175003 7.63333 0.175003 7.5C0.175003 7.36667 0.183336 7.23733 0.200003 7.112C0.21667 6.98733 0.250003 6.88333 0.300003 6.8C1.28334 4.71667 2.73334 3.06267 4.65 1.838C6.56667 0.612666 8.68334 0 11 0C13.3167 0 15.4333 0.612666 17.35 1.838C19.2667 3.06267 20.7167 4.71667 21.7 6.8C21.75 6.88333 21.7833 6.98733 21.8 7.112C21.8167 7.23733 21.825 7.36667 21.825 7.5C21.825 7.63333 21.8167 7.76233 21.8 7.887C21.7833 8.01233 21.75 8.11667 21.7 8.2C20.7167 10.2833 19.2667 11.9377 17.35 13.163C15.4333 14.3877 13.3167 15 11 15ZM11 13C12.8833 13 14.6127 12.504 16.188 11.512C17.7627 10.5207 18.9667 9.18333 19.8 7.5C18.9667 5.81667 17.7627 4.479 16.188 3.487C14.6127 2.49567 12.8833 2 11 2C9.11667 2 7.38734 2.49567 5.812 3.487C4.23734 4.479 3.03334 5.81667 2.2 7.5C3.03334 9.18333 4.23734 10.5207 5.812 11.512C7.38734 12.504 9.11667 13 11 13Z"
        fill={color}
      />
    </svg>
  );
};
