import { IconButton } from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useRef, useState } from "react";
import styled from "styled-components";

import { AttachmentIcon, EmojiIcon, SendIcon } from "assets/images/common";
import { Button } from "components";
import { TextArea } from "components/UI/Form/Input/TextArea";
import { NOTI_CODE } from "constants/firestore.const";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedChat, selectUserId } from "store/chat/chat.selector";
import { encrypt } from "utils/app.util";
import { db } from "utils/firebase.util";
import { selectNamespaceId } from "store/global/global.selector";

export const ChatInput = () => {
  const [message, setMessage] = useState("");

  const inputFileRef = useRef<HTMLInputElement>(null);
  const selectedChat = useAppSelector(selectSelectedChat);
  const senderId = useAppSelector(selectUserId);
  const namespaceId = useAppSelector(selectNamespaceId);

  const guestId = selectedChat?.memberIds.find((id) => id !== senderId);

  const handleSendMessage = async () => {
    try {
      if (!message || !selectedChat || !guestId) return;
      const limitedMessage = message.slice(0, 3000);

      const { id: roomId, unread, onChat } = selectedChat;

      const roomRef = doc(db, "rooms", roomId);
      const messageRef = collection(db, "messages");

      const addMessage = async (isRead: boolean) => {
        const newMessage = {
          namespaceId,
          roomId,
          senderId,
          message: encrypt(limitedMessage),
          createdAt: new Date().valueOf(),
          isRead,
        };

        await updateDoc(roomRef, {
          updatedAt: new Date().valueOf(),
          unread: {
            ...unread,
            [guestId]: isRead ? unread[guestId] : unread[guestId] + 1,
          },
          lastMessage: newMessage,
        });
        await addDoc(messageRef, newMessage);
      };

      if (onChat.includes(guestId)) {
        addMessage(true);
      } else {
        addMessage(false);

        const notificationRef = collection(db, "notifications");

        const querySnapshot = query(
          collection(db, "notifications"),
          where("namespaceId", "==", namespaceId),
          where("receiverId", "==", guestId),
          where("isRead", "==", false),
          orderBy("createdAt", "desc")
        );

        const snapShot = await getDocs(querySnapshot);

        if (snapShot.empty) {
          await addDoc(notificationRef, {
            code: `${NOTI_CODE.CHAT_DEFAULT}__${senderId}__${roomId}`,
            namespaceId,
            receiverId: guestId,
            createdAt: new Date().valueOf(),
            isRead: false,
            unreadCount: 1,
          });
        } else {
          const docSnap = snapShot.docs[0];

          const docRef = doc(db, "notifications", docSnap.id);

          await updateDoc(docRef, {
            ...docSnap.data(),
            createdAt: new Date().valueOf(),
            unreadCount: docSnap.data().unreadCount + 1,
          });
        }
      }
      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  const handleSeen = async () => {
    try {
      if (!selectedChat || !senderId || !guestId) return;

      const { id: roomId, unread } = selectedChat;

      if (!unread[senderId]) return;

      const querySnapshot = query(
        collection(db, "messages"),
        where("namespaceId", "==", namespaceId),
        where("roomId", "==", selectedChat.id),
        where("isRead", "==", false),
        where("senderId", "==", guestId)
      );
      const docSnap = await getDocs(querySnapshot);

      if (!docSnap.empty) {
        docSnap.forEach((docData) => {
          const docRef = doc(db, "messages", docData.id);

          updateDoc(docRef, {
            isRead: true,
          });
        });
      }

      const roomRef = doc(db, "rooms", roomId);
      await updateDoc(roomRef, {
        unread: { ...unread, [senderId]: 0 },
      });

      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  const handleSubmit = () => {
    handleSendMessage();
    setMessage("");
  };

  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {false && (
        <IconButton onClick={() => inputFileRef.current?.click()}>
          <AttachmentIcon />
        </IconButton>
      )}

      <TextArea
        placeholder="Type your message"
        maxRows={1}
        autoFocus
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key == "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
          }
        }}
        onFocus={handleSeen}
      />

      <Button className="send-button" type="submit">
        <SendIcon />
      </Button>

      {false && (
        <IconButton>
          <EmojiIcon />
        </IconButton>
      )}

      <input ref={inputFileRef} type="file" hidden />
    </Wrapper>
  );
};

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0px 16px;
  border-top: 1px solid #ebecf0;

  & > div:first-child {
    width: 100%;

    textarea {
      padding: 12px;
      border: none;
      resize: none;
      white-space: pre-wrap;
    }
  }

  .send-button {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 10px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;
