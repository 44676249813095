import styled from "styled-components";

import { Box } from "@mui/material";
import { Button, LinearProgressBar, ProgressList, Text } from "components";
import { Progress, StatusIndicator } from "models/common.model";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { selectAppConfig } from "store/global/global.selector";

interface Props {
  progressList: Progress[];
  buttonText?: string;
  buttonLoading?: boolean;
  buttonDisabled?: boolean;
  tooltipText?: string;
  completeText?: string;
  hideFooter?: boolean;
  handleClickButton?: () => void;
}

export const ProgressSection = ({
  progressList,
  buttonText,
  buttonLoading,
  buttonDisabled,
  tooltipText,
  completeText,
  hideFooter,
  handleClickButton,
}: Props) => {
  const completedList = progressList.filter((item) => item.isComplete);
  const value = Math.round((completedList.length / progressList.length) * 100);
  const { status } = useAppSelector(selectOrganisation);
  const { feedPromote } = useAppSelector(selectAppConfig);

  return (
    <Wrapper>
      <Title>Progress</Title>

      <ProgressWrapper>
        <ProgressWithLabel>
          <Box width="100%">
            <LinearProgressBar value={value || 0} />
          </Box>

          <Text size="lg" fontWeight={600}>
            {value || 0}%
          </Text>
        </ProgressWithLabel>

        {completeText && completedList.length === progressList.length ? (
          <TextWrapper>
            <Text size="md">{completeText}</Text>
          </TextWrapper>
        ) : (
          <TextWrapper>
            <Text size="md">{`Lets start your work!`}</Text>
            <Text size="md">{`There's still a bit to completed...`}</Text>
          </TextWrapper>
        )}
      </ProgressWrapper>

      <ProgressList progressList={progressList} tooltipText={tooltipText} />

      {!hideFooter && (
        <Footer>
          <Button
            loading={buttonLoading}
            disabled={buttonDisabled}
            onClick={handleClickButton}
          >
            {buttonText || "Preview"}
          </Button>
          {feedPromote?.active && status !== StatusIndicator.PUBLISHED && (
            <Button onClick={() => window.open(feedPromote?.value, "_blank")}>
              {"Promote your venture"}
            </Button>
          )}
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px 20px;
  border: 1px solid #d8d9df;
  border-radius: 12px;
`;

const Title = styled.div`
  margin-bottom: 12px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 24px;
  font-weight: 700;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ebecef;
`;

const TextWrapper = styled.div`
  & > div {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
`;

const ProgressWithLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  & > div:last-child {
    flex-shrink: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 16px;
  border-top: 1px solid #ebecef;

  .button-wrapper,
  button {
    width: 100%;
  }
`;
