import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ZoomInIcon = ({ color = "#fff" }: Props) => {
  return (
    <svg
      className="zoom-in-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M247.7 116.5H90.3c-14.5 0-26.2-11.7-26.2-26.2S75.9 64 90.3 64h157.4c14.5 0 26.2 11.7 26.2 26.2 0 14.6-11.7 26.3-26.2 26.3zM933.1 959.3H775.7c-14.5 0-26.2-11.7-26.2-26.2s11.7-26.2 26.2-26.2h157.4c14.5 0 26.2 11.7 26.2 26.2 0 14.4-11.7 26.2-26.2 26.2z"
        fill={color}
      />
      <path
        d="M888.8 924.5L703.3 739c-10.2-10.2-10.2-26.8 0-37.1 10.2-10.2 26.8-10.2 37.1 0l185.5 185.5c10.2 10.2 10.2 26.8 0 37.1-10.3 10.2-26.9 10.2-37.1 0zM284.1 319.8L98.7 134.4c-10.2-10.2-10.2-26.8 0-37.1 10.2-10.2 26.8-10.2 37.1 0l185.5 185.5c10.2 10.2 10.2 26.8 0 37.1-10.3 10.2-26.9 10.2-37.2-0.1zM925.9 134.4L740.4 319.8c-10.2 10.2-26.8 10.2-37.1 0-10.2-10.2-10.2-26.8 0-37.1L888.8 97.3c10.2-10.2 26.8-10.2 37.1 0 10.2 10.2 10.2 26.8 0 37.1zM321.2 739L135.8 924.5c-10.2 10.2-26.8 10.2-37.1 0-10.2-10.2-10.2-26.8 0-37.1L284.1 702c10.2-10.2 26.8-10.2 37.1 0s10.3 26.8 0 37z"
        fill={color}
      />
      <path
        d="M775.7 116.5h157.4c14.5 0 26.2-11.7 26.2-26.2s-11.7-26.2-26.2-26.2H775.7c-14.5 0-26.2 11.7-26.2 26.2s11.8 26.2 26.2 26.2zM90.3 959.3h157.4c14.5 0 26.2-11.7 26.2-26.2s-11.7-26.2-26.2-26.2H90.3c-14.5 0-26.2 11.7-26.2 26.2 0 14.4 11.8 26.2 26.2 26.2z"
        fill={color}
      />
      <path
        d="M116.6 775.7V933c0 14.5-11.7 26.2-26.2 26.2S64.2 947.5 64.2 933V775.7c0-14.5 11.7-26.2 26.2-26.2 14.4-0.1 26.2 11.7 26.2 26.2zM959.3 90.3v157.4c0 14.5-11.7 26.2-26.2 26.2s-26.2-11.7-26.2-26.2V90.3c0-14.5 11.7-26.2 26.2-26.2 14.5-0.1 26.2 11.7 26.2 26.2zM906.9 775.7V933c0 14.5 11.7 26.2 26.2 26.2s26.2-11.7 26.2-26.2V775.7c0-14.5-11.7-26.2-26.2-26.2-14.5-0.1-26.2 11.7-26.2 26.2zM64.1 90.3v157.4c0 14.5 11.7 26.2 26.2 26.2s26.2-11.7 26.2-26.2V90.3c0-14.5-11.7-26.2-26.2-26.2-14.4-0.1-26.2 11.7-26.2 26.2z"
        fill={color}
      />
    </svg>
  );
};
