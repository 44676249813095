import styled from "styled-components/macro";

export const TermTemplate = () => {
  return (
    <Wrapper>
      <main className="notion-page notion-page-no-cover notion-page-has-icon notion-page-has-text-icon notion-full-page">
        <h1 className="notion-title">Terms and Conditions</h1>
        <div className="notion-page-content">
          <article className="notion-page-content-inner">
            <div className="notion-text">
              <b>Last Updated: October 24, 2023.</b>
            </div>
            <div className="notion-text end-section">
              These Terms of Service constitute a legal agreement between you,
              as the user of the MyFoundry platform, and Monaco Foundry
              Technologies SARL, as the owner of the platform. Throughout these
              Terms of Service we will refer to you as{" "}
              <b>“you” or “the User”</b>, and we will refer to Monaco Foundry
              Technologies SARL as <b>“we”, “us” or “Monaco Foundry”</b>. Please
              read these Terms of Service (the “<b>Terms</b>”) carefully because
              they govern your use of our MyFoundry platform (
              <b>“MyFoundry platform”</b> or “<b>Platform</b>”).
            </div>
            <div className="notion-text end-section">
              <b>ARBITRATION NOTICE:</b> UNLESS YOU OPT OUT OF ARBITRATION
              WITHIN 30 DAYS OF THE DATE YOU FIRST AGREE TO THESE TERMS BY
              FOLLOWING THE OPT-OUT PROCEDURE SPECIFIED IN THE “DISPUTE
              RESOLUTION” SECTION BELOW, THESE TERMS WILL, WITH LIMITED
              EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO
              BINDING AND FINAL ARBITRATION SOLELY ON AN INDIVIDUAL BASIS, AND
              NOT AS PART OF A CLASS, REPRESENTATIVE OR CONSOLIDATED ACTION.
            </div>
            <div className="notion-text">
              <b>1. Agreement to these Terms</b>
            </div>
            <div className="notion-text end-section">
              By using the Platform, you agree to be bound by these Terms. If
              you don’t agree to these Terms, do not use the Platform. If you
              are accessing and using the Platform on behalf of a company (such
              as your employer) or other legal entity, you represent and warrant
              that you have the authority to bind that company or other legal
              entity to these Terms. In that case, “you” and “your” will refer
              to that company or other legal entity.
            </div>
            <div className="notion-text">
              <b>2. Changes to the Terms or Platform</b>
            </div>
            <div className="notion-text end-section">
              We may modify the Terms at any time, at our sole discretion. If we
              do so, we will let you know either by posting the modified Terms
              on the Site or through other communications. If you continue to
              use the Platform after such a change, you are indicating that you
              agree to the modified Terms. We may also change or discontinue all
              or any part of the Platform, at any time and without notice or
              liability, at our sole discretion, including changes to prices and
              fees we apply.
            </div>
            <div className="notion-text">
              <b>3. Service Terms</b>
            </div>
            <div className="notion-text end-section">
              3.1. <b>Who may use the Platform</b>. You may only use the
              Platform if you are old enough to consent (by yourself and not by
              a parent or guardian) to share your data under applicable law. For
              example, you must be 13 years or older under most United States’
              law, or 16 years or older under California or European Union law.
            </div>
            <div className="notion-text end-section">
              3.2. <b>Use Restrictions</b>. Except as otherwise expressly
              authorized in these Terms, you will not, and will ensure your
              employees, contractors, and other persons associated with your
              MyFoundry account (“<b>Authorized Users</b>”) do not, and will not
              encourage or assist third parties to: (i) reverse engineer,
              decompile, disassemble, or otherwise attempt to discover the
              source code, object code, or underlying structure, ideas,
              know-how, or algorithms relevant to the Platform (except to the
              extent that such a restriction is impermissible under applicable
              law); (ii) provide, sell, resell, transfer, sublicense, lend,
              distribute, rent, or otherwise allow others to access or use the
              Platform; (iii) copy, modify, create derivative works of, or
              remove proprietary notices from the Platform; or (iv) circumvent
              any technical limitations implemented within MyFoundry Platform.
            </div>
            <div className="notion-text end-section">
              3.3. <b>Acceptable Privacy and Security Policy.</b> You will
              comply with, and will ensure your Authorized Users comply with,
              Monaco Foundry’s Privacy and Security Policy available at
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="notion-link"
                href="https://www.monacofoundry.com/privacy-policy"
              >
                {" "}
                https://www.monacofoundry.com/privacy-policy
              </a>
              .
            </div>
            <div className="notion-text end-section">
              3.4. <b>Authorized Users; Accounts.</b> As part of the
              registration process, you will identify an administrative username
              and password for your MyFoundry account. You represent and warrant
              that all registration information, including with respect to the
              list of domains owned or controlled by you for purposes of domain
              capture, you provide is truthful, accurate, and complete, and that
              you will maintain the accuracy of such information. You are
              responsible and liable for maintaining control over your account,
              including the confidentiality of your username and password, and
              are solely responsible and liable for all activities that occur on
              or through your account and all Authorized Users’ accounts,
              whether authorized by you or not.
            </div>
            <div className="notion-text end-section">
              3.5. <b>Feedback; Use Rights</b>. We welcome feedback, comments,
              and suggestions (“<b>Feedback</b>”). As we need to be able to
              freely work with your Feedback to improve the Platform, you hereby
              irrevocably transfer and assign all right, title, and interest
              (including all intellectual property rights, such as copyrights or
              trade secrets) in and to the Feedback, including any and all
              “moral rights” that you might have in such Feedback, and you
              hereby forever waive and agree never to assert any and all “moral
              rights” you may have in the Feedback. Additionally, Monaco Foundry
              will have the right to collect and analyze data and other
              information relating to the provision, use, and performance of
              various aspects of the MyFoundry Platform, and related systems and
              technologies, and Monaco Foundry will be free (during and after
              the term) to use such data and information to maintain, improve,
              and enhance any products or platform.
            </div>
            <div className="notion-text end-section">
              3.6. <b>Reservation of Rights.</b> As between the parties, Monaco
              Foundry owns all right, title, and interest in the Platform, and
              you own all right, title, and interest in any material(s) that are
              uploaded to the Platform by you (“
              <b>User Content</b>”). Except as expressly set forth in these
              Terms, each party retains all right, title, and interest in and to
              its intellectual property rights. All rights not expressly granted
              are reserved, and no license, covenant, immunity, transfer,
              authorization, or other right will be implied, by reason of
              statute, estoppel, or otherwise, under these Terms.
            </div>
            <div className="notion-text">
              <b>4. License</b>
            </div>
            <div className="notion-text end-section">
              <b>4.1. License to Platform. </b>Subject to the terms and
              conditions of these Terms, we hereby grant you a limited,
              non-exclusive, non-transferable, non-sublicensable license in your
              country for you to access and use the Platform for your internal
              business or personal purposes. As a user, initial access to the
              Platform is provided without charge. However, MF reserves the
              discretion to modify its pricing policy at its sole discretion and
              may provide notice of such changes to current users through
              updates on the website and via email communication.
            </div>
            <div className="notion-text">
              <b>5. Charges and Payment</b>
            </div>
            <div className="notion-text end-section">
              5.1.<b> Pricing. </b>Certain Services may be offered under
              different pricing plans, the limits and features, and further
              governed by https://www.monacofoundry.com/ website. Your rights
              and obligations with respect to certain Services will be based in
              part on the pricing plan you choose.
            </div>
            <div className="notion-text end-section">
              5.1.1<b> Fees. </b>A fixed fundraising fee of 3% shall be
              applicable and levied on the startup&apos;s side in the event of a
              successful fundraising transaction with an investor facilitated
              through the MyFoundry Platform. It is important to note that no
              fees shall be imposed on the investor&apos;s side in the case of
              an investment in startups listed on the platform.
            </div>
            <div className="notion-text end-section">
              <b>5.2. Payment Terms</b>
            </div>
            <div className="notion-text end-section">
              <b>5.2.1. Subscriptions.</b> When you purchase our Services (each
              such purchase, a “Subscription”), you expressly authorize us or
              our third-party payment processor to charge you for such Services.
              For the avoidance of doubt, Subscriptions do not include any sales
              by third parties on MyFoundry Marketplace.
            </div>
            <div className="notion-text end-section">
              5.2.2. You represent and warrant that you have the legal right to
              use all payment methods that you provide to us.
            </div>
            <div className="notion-text end-section">
              5.2.3. All fees are stated and solely payable Euros (unless
              otherwise stated during the payment process), non-cancelable,
              non-refundable (except as otherwise expressly set forth in these
              Terms), and not subject to setoff.
            </div>
            <div className="notion-text end-section">
              5.2.4. In the event that you fail to pay the full amount owed to
              us, we may limit your access to the Services, in addition to any
              other rights or remedies we may have.
            </div>
            <div className="notion-text end-section">
              <b>5.3. Authorization for Recurring Payments</b>
            </div>
            <div className="notion-text end-section">
              5.3.1. All Subscription pricing plans involve recurring fees
              (each, along with any applicable taxes and other charges are a
              “Subscription Fee”). Depending on which options you choose, those
              fees may recur each month, quarter or year thereafter, at the
              then-current rate. Please note that our fees are subject to
              change, although we will notify you before we effect any change.
            </div>
            <div className="notion-text end-section">
              5.3.2. By agreeing to these Terms and purchasing a Subscription,
              you acknowledge that your Subscription has recurring payment
              features and you accept responsibility for all recurring payment
              obligations prior to cancellation of your Subscription by you or
              Monaco Foundry. We (or our third party payment processor) will
              automatically charge you in accordance with the term of your
              Subscription (e.g., each month, quarter, or year), on the calendar
              day corresponding to the commencement of your Subscription using
              the payment information you have provided.
            </div>
            <div className="notion-text end-section">
              5.3.3. In the event your Subscription begins on a day not
              contained in a later month, your payment method will be charged on
              such other day as we deem appropriate. For example, if you started
              a monthly Subscription on January 31st, your next payment date is
              likely to be February 28th, and your payment method would be
              billed on that date. We may also periodically authorize your
              payment method in anticipation of applicable fees or related
              charges.
            </div>
            <div className="notion-text end-section">
              5.3.4. Your Subscription continues until canceled by you or we
              terminate your access to or use of the Services or the
              Subscription in accordance with these Terms.
            </div>
            <div className="notion-text end-section">
              <b>5.4. Canceling Subscriptions</b>
            </div>
            <div className="notion-text end-section">
              5.4.1. You may cancel your Subscription at any time but please
              note that such cancellation will only be effective at the end of
              the then-current Subscription period. Unless required by law, YOU
              WILL NOT RECEIVE A REFUND OF ANY PORTION OF THE SUBSCRIPTION FEE
              PAID FOR THE THEN-CURRENT SUBSCRIPTION PERIOD AT THE TIME OF
              CANCELLATION.
            </div>
            <div className="notion-text end-section">
              5.4.2. To cancel, you can either (i) email us at{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="notion-link"
                href="mailto:support@monacofoundry.com"
              >
                support@monacofoundry.com
              </a>{" "}
              &nbsp;and follow any instructions, if any, we provide to you in
              response to your cancellation request, or (ii) for some kinds of
              Subscriptions, initiate a cancellation through your MyFoundry
              platform account settings within the Services. You will be
              responsible for all Subscription Fees incurred for the
              then-current Subscription period. Canceling your Subscription will
              not terminate your MyFoundry account. See Section 12 (Termination)
              below for information on terminating your MyFoundry account.
            </div>
            <div className="notion-text end-section">
              <b>5.5. Taxes.</b> All fees do not include taxes, and you agree
              to: (a) pay all sales/use, gross receipts, value-added, GST,
              personal property, or other tax (including any interest and
              penalties) with respect to the transactions and payments under
              these Terms, other than taxes based on our income, employees, or
              real property; and (b) be responsible for any filing of any
              information or tax returns with respect thereto. If we were
              required to collect a tax and did not do so at the time of sale,
              we reserve the right to later charge you for the applicable tax.
            </div>
            <div className="notion-text end-section">
              <b>5.6. Withholding. </b>All payments made by you to us under
              these Terms exclude any deduction or withholding. If any such
              deduction or withholding (including but not limited to
              cross-border withholding taxes) is required by law, you will pay
              such additional amounts as are necessary so that the net amount
              received by us after such deduction or withholding will be equal
              to the full amount that we would have received if no deduction or
              withholding had been required. Each party will use commercially
              reasonable efforts to work with the other party to help obtain,
              reduce, or eliminate any necessary withholding, deduction, or
              royalty tax exemptions where applicable.
            </div>
            <div className="notion-text">
              5.7.<b> Marketplace Purchases</b>
            </div>
            <div className="notion-text end-section">
              5.7.1. <b>Nature of the Marketplace.</b> MyFoundry platform
              provides its users with access to a Marketplace featuring a
              selection of offers negotiated by verified third-party providers.
              These offers are curated with the intention of providing utility
              and value to our users.
            </div>
            <div className="notion-text end-section">
              5.7.2 . <b>Intermediary Role.</b> MyFoundry Platform acts solely
              as an intermediary in presenting these offers to users. The
              platform does not take responsibility for the quality,
              effectiveness, or overall level of service that users may receive
              from these third-party providers.
            </div>
            <div className="notion-text end-section">
              5.7.3 . <b>User Discretion.</b> Transactions and engagements
              between users and third-party providers within the Marketplace are
              entirely at the discretion of the user. Users are advised to
              exercise their own judgment when considering and utilizing the
              offers presented in the Marketplace.
            </div>
            <div className="notion-text end-section">
              5.7.4 . <b>Disclaimer of Guarantee.</b> MyFoundry Platform
              disclaims all warranties, whether expressed or implied, in
              relation to the services or offers provided by third-party
              providers. The platform makes no guarantees about the performance,
              satisfaction, or outcomes of services rendered by these providers.
            </div>
            <div className="notion-text end-section">
              5.7.5 . <b>User Accountability.</b> Users engage with third-party
              providers in the Marketplace at their own risk and accountability.
              Any issues, disputes, or concerns that may arise from these
              interactions or transactions are not the responsibility of
              MyFoundry Platform.
            </div>
            <div className="notion-text end-section">
              5.7.6 . <b>Review of Terms and Policies.</b> Users are strongly
              encouraged to review and comprehend the terms, conditions, and
              policies of the third-party providers before engaging with their
              services. Understanding the terms set by the providers is
              essential for making informed decisions and mitigating potential
              risks.
            </div>
            <div className="notion-text">
              5.8{" "}
              <b>MonacoFoundry deal room and Special Purpose Vehicles (SPVs)</b>
            </div>
            <div className="notion-text end-section">
              Monaco Foundry has established a partnership with a financially
              regulated entity based in the UK, enabling the creation of Special
              Purpose Vehicles (SPVs) for investment purposes at a preferential
              rate. Should the Client opt to utilize MonacoFoundry deal room to
              facilitate the establishment of SPVs through MF&apos;s financially
              regulated partner, the Client would need to pay the SPV creation
              fees that are fixed by our regulated partner and may change at any
              time if the partner updates them. As per the most recent
              information, the following fee structure shall apply:
            </div>
            <div className="notion-text end-section">
              {`
                        i. "Co-Investment SPV": Intended for Institutional Deals, can
                        include up to 149 investors. Set-up cost $19,900 + 0.99% of
                        funds raised.  
                    `}
            </div>
            <div className="notion-text end-section">
              {`
                        ii. "Nano container": Used for Angels, can include up to 25
                        investors. Set-up cost $4,900 + 1.99% of funds raised.   
                    `}
            </div>
            <div className="notion-text end-section">
              {`
                        iii. "Club Deal SPV": Intended for HNWI & Family Offices,
                        can include up to 25 investors. Set-up cost $12,900 + 0.99%
                        of funds raised.  
                    `}
            </div>
            <div className="notion-text end-section">
              {`
                        iv. "UK Single Fund Feeder": Aimed to pool capital to invest
                        in a VC Fund, can include up to 25 investors. Set-up cost
                        $14,900 + 0.99% of funds raised.  
                    `}
            </div>
            <div className="notion-text end-section">
              {`
                        v. "Atom SPV": Used for deals <$200K, can include up to 25
                        investors. Set-up cost $2,000 + 2.99% of funds raised.  
                    `}
            </div>
            <div className="notion-text">
              <b>6. Role and Liability of Monaco Foundry</b>
            </div>
            <div className="notion-text end-section">
              6.1 Monaco Foundry Technologies SARL, is a non-financially
              regulated entity, and as a user of its platform named MyFoundry in
              its current form or in a white-label solution you explicitly
              acknowledge and agree that its role is strictly limited to
              providing a technology platform that matches investors with
              startups for the purpose of raising funds. Monaco Foundry shall
              not engage in fundraising activities directly or indirectly, and
              shall not be involved in any negotiations or transactions related
              to fundraising, including the setup and operation of Special
              Purpose Vehicles (SPVs). Any fees or compensation received by
              Monaco Foundry for its technology services shall be separate and
              distinct from fundraising activities.
            </div>
            <div className="notion-text end-section">
              6.2 Monaco Foundry further affirms that the setup and operation of
              SPVs shall be conducted exclusively by an external, financially
              regulated partner, and Monaco Foundry shall not be held liable or
              responsible for any aspect of SPV creation or management.
            </div>
            <div className="notion-text end-section">
              6.3 The parties expressly understand and agree that Monaco
              Foundry&apos;s role is limited to providing a technology platform,
              even if it receives compensation if funds are raised using its
              technology or services are purchased on its platform, it shall,
              therefore, not be deemed to be engaging in fundraising activities
              or providing financial services directly or indirectly. Monaco
              Foundry shall not be liable for any actions or omissions of third
              parties using its platform.
            </div>
            <div className="notion-text end-section">
              6.4 The parties expressly understand and agree that Monaco Foundry
              shall not be liable for any losses or financial consequences
              incurred during or as a result of fundraising activities conducted
              through its platform or through the usage of its third-party
              services such as setting up and managing Special Purpose Vehicles.
            </div>
            <div className="notion-text">
              <b>7. White Labeling</b>
            </div>
            <div className="notion-text end-section">
              7.1 <b>White Labeled Platform Customization. </b>Each client of
              the MyFoundry platform may choose to request a white labeled
              version of the platform. This white labeled version will be fully
              customized to reflect the client&apos;s brand identity, including
              the incorporation of the client&apos;s logo, color scheme, and a
              website link of the client&apos;s choice. All the functionalities
              of the MyFoundry platform will be included in the white labeled
              platform provided to the client.
            </div>
            <div className="notion-text end-section">
              7.1.2 By ordering a white labeled version of the platform, the
              client gains access to the opportunity to create their own private
              equity ecosystem in one place. It is the client&apos;s
              responsibility to promote the platform, bring in their portfolio
              of start-ups, invite investors, and facilitate their interaction
              within this customized ecosystem.
            </div>
            <div className="notion-text end-section">
              7.2 <b>Intellectual Property Rights. </b>The Client acknowledges
              that all intellectual property rights, including but not limited
              to copyrights, trademarks, and patents, related to the Digital
              Platform and its content are the sole property of Monaco Foundry
              (MF) or its licensors.
            </div>
            <div className="notion-text end-section">
              7.2.1 MF grants the Client a non-exclusive, non-transferable
              license to use the Digital Platform. Monaco Foundry licenses the
              platform to the client for free as part of a freemium usage model.
              MF reserves the right to change the licensing pricing at any time,
              with a 30-day notice delivered via email to alert the client about
              any pricing changes.
            </div>
            <div className="notion-text end-section">
              7.3 <b>Fees for White Labeled Platform</b>In the case of the white
              labeled platform, Monaco Foundry reserves the right to charge a
              success fundraising fee of 1% from the startup side for all
              successful deals that occur through the client&apos;s white label
              platform. This fee applies to funds raised by startups from an
              investor, whether the transaction is conducted using
              MyFoundry&apos;s Special Purpose Vehicle (SPV) functionalities or
              completed outside of the platform. It also applies when startups
              use our SPV features to raise funds with external investors who
              are not registered on the platform. In all these scenarios, Monaco
              Foundry retains the right to charge the 1% success fundraising
              fee.
            </div>
            <div className="notion-text end-section">
              7.3.1 The client may choose to charge additional fundraising fees
              on top of MF&apos;s platform. In such cases, the client is
              required to contact MF to specify the additional percentage to be
              added to the Terms and Conditions of the white-labeled platform.
            </div>
            <div className="notion-text end-section">
              7.4. <b>Inclusion in the Global MyFoundry Ecosystem. </b>
              Automatically, all companies published by the client on the white
              labeled platform become part of the global MyFoundry ecosystem of
              startups, unless otherwise specified.
            </div>
            <div className="notion-text end-section">
              7.4.1 If the client wishes to keep the companies they have listed
              on their white labeled platform private and not included in the
              global ecosystem, they must inform Monaco Foundry accordingly.
            </div>
            <div className="notion-text end-section">
              7.5 <b>Regulatory Status and Liability. </b>Monaco Foundry (MF)
              wishes to clarify that it is not a financial institution or a
              fundraising entity, and as such, it is not regulated by any
              financial authority. MF&apos;s technology solely serves the
              purpose of matching startups and investors and providing the
              necessary technological infrastructure to facilitate investment
              activities.
            </div>
            <div className="notion-text end-section">
              7.5.1 MF is not involved in fundraising activities and does not
              engage in the solicitation of funds. It is essential for clients
              to understand that MF is a technology provider and does not offer
              financial advice, investment services, or guarantee investment
              outcomes.
            </div>
            <div className="notion-text end-section">
              7.5.2 Clients utilizing the MyFoundry platform are responsible for
              compliance with all applicable laws and regulations related to
              fundraising, investment, and financial activities within their
              respective jurisdictions. MF assumes no liability for the actions
              or activities of its clients on the platform.
            </div>

            <div className="notion-text">
              8<b>. Confidentiality</b>
            </div>
            <div className="notion-text end-section">
              8.1.<b> Confidential Information.</b> We (the “<b>Discloser</b>”)
              have disclosed or may disclose proprietary or non-public business,
              technical, financial, or other information (“
              <b>Confidential Information</b>”) to you (the “<b>Recipient</b>”).
              Our Confidential Information expressly includes non-public
              information regarding features, functionality, and performance of
              the Platform, including security related information.
            </div>
            <div className="notion-text end-section">
              8.2.<b> Obligations. </b>The Recipient will use the Discloser’s
              Confidential Information only for the purpose of evaluating
              whether or not to use (or continue to use) the Platform. The
              Recipient will not disclose the Discloser’s Confidential
              Information to parties other than the Recipient’s employees,
              contractors, affiliates, agents, or professional advisors (“
              <b>Representatives</b>”) who need to know it and who have a legal
              obligation to keep it confidential. The Recipient will ensure that
              its Representatives are subject to no less restrictive
              confidentiality obligations than those herein. Notwithstanding the
              foregoing, the Recipient may disclose the Discloser’s Confidential
              Information: (a) if directed by Discloser; or (b) to the extent
              required by applicable legal process, provided that the Recipient
              uses commercially reasonable efforts to (i) promptly notify the
              Discloser in advance, to the extent permitted by law, and (ii)
              comply with the Discloser’s reasonable requests regarding its
              efforts to oppose the disclosure. The obligations set forth herein
              will survive for so long as these Terms are in effect between the
              parties and for five years thereafter.
            </div>
            <div className="notion-text">
              <b>9. Warranties and Disclaimers</b>
            </div>
            <div className="notion-text end-section">
              9.1. In the event of any loss or corruption of any data associated
              with a MyFoundry Platform, Monaco Foundry will use commercially
              reasonable efforts to restore the lost or corrupted data from the
              latest relevant backup maintained by Monaco Foundry. EXCEPT FOR
              THE FOREGOING, MONACO FOUNDRY WILL NOT BE RESPONSIBLE FOR ANY
              LOSS, DESTRUCTION, ALTERATION, UNAUTHORIZED DISCLOSURE OR
              CORRUPTION OF ANY DATA.
            </div>
            <div className="notion-text end-section">
              9.2. We make no warranty that the Platform will meet your
              requirements or be available on an uninterrupted, secure, or
              error-free basis. We make no warranty regarding the quality,
              accuracy, timeliness, truthfulness, completeness or reliability of
              any Platform, and we make no guarantees around data retention or
              preservation. EXCEPT AS SET FORTH IN SECTION 7.1, THE MyFoundry
              Platform IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND.
              WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              QUIET ENJOYMENT, AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING
              OUT OF COURSE OF DEALING OR USAGE OF TRADE.
            </div>
            <div className="notion-text end-section">
              <b>10. Indemnity</b>. You will indemnify and hold harmless Monaco
              Foundry and its officers, directors, employees, and agents, from
              and against any claims, disputes, demands, liabilities, damages,
              losses, and costs and expenses, including, without limitation,
              reasonable legal and accounting fees, arising out of or in any way
              connected with (i) your access to or use of the MyFoundry
              Platform; (ii) your User Content; or (iii) your violation of these
              Terms.
            </div>
            <div className="notion-text">
              <b>11. Limitations of Liability</b>
            </div>
            <div className="notion-text end-section">
              11.1. NEITHER MONACO FOUNDRY NOR ANY OTHER PARTY INVOLVED IN
              CREATING, PRODUCING, OR DELIVERING THE MyFoundry Platform (“
              <b>SUPPLIERS</b>”) WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL,
              EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS
              OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM
              FAILURE, OR THE COST OF SUBSTITUTE MyFoundry Platform ARISING OUT
              OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR
              INABILITY TO USE THE Platform, WHETHER BASED ON WARRANTY,
              CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY
              OTHER LEGAL THEORY, AND WHETHER OR NOT MONACO FOUNDRY HAS BEEN
              INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.
            </div>
            <div className="notion-text">
              <b>12. Termination.</b>
            </div>
            <div className="notion-text end-section">
              12.1. You may cease use of the MyFoundry Platform at any time.
            </div>
            <div className="notion-text end-section">
              12.2 . You may also cancel your MyFoundry account at any time by
              sending an email to support@monacofoundry.com .
            </div>
            <div className="notion-text end-section">
              12.3. Upon any termination, discontinuation, or cancellation of
              MyFoundryPlatform or your MyFoundry account, the following
              provisions of these Terms will survive: Service Terms, Charges and
              Payment (to the extent you owe any fees at the time of
              termination); Confidentiality; provisions related to permissions
              to access User Content (to the extent applicable); Warranties and
              Disclaimers; Indemnity; Limitations of Liability; Termination; and
              the Miscellaneous provisions under Section 11. Furthermore, we may
              remove or delete your User Content within a reasonable period of
              time after the termination or cancellation of MyFoundry Platform
              or your MyFoundry account.
            </div>
            <div className="notion-text">
              <b>13. Miscellaneous.</b>
            </div>
            <div className="notion-text end-section">
              13.1. <b>Privacy Policy. </b>Our Privacy Policy (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="notion-link"
                href="https://www.monacofoundry.com/privacy-policy"
              >
                https://www.monacofoundry.com/privacy-policy
              </a>
              ) governs how we collect, use and disclose information from the
              MyFoundry Platform.
            </div>
            <div className="notion-text end-section">
              13.2. <b>Force Majeure.</b> Monaco Foundry will not be liable for,
              or be considered to be in breach of or default under these Terms
              on account of, any delay or failure to perform as required by
              these Terms as a result of any cause or condition beyond its
              reasonable control, so long as it uses commercially reasonable
              efforts to avoid or remove those causes of non-performance.&nbsp;
              If Monaco Foundry believes in good faith that it is legally
              prohibited from providing you or your Authorized Users with the
              MyFoundry Platform, we may freeze your account at our sole
              discretion.
            </div>
            <div className="notion-text end-section">
              13.3. <b> Notices.</b> Any notices or other communications
              provided by Monaco Foundry under these Terms, including those
              regarding modifications to these Terms, will be given by Monaco
              Foundry: (i) via e-mail; or (ii) by posting to the Site. For
              notices made by e-mail, the date of receipt will be deemed the
              date on which such notice is transmitted.
            </div>

            <div className="notion-text end-section">
              13.4. <b>Copyright and IP Policy.</b> Monaco Foundry respects
              copyright law and expects its users to do the same. Monaco
              Foundry&apos;s Copyright and IP Policy applies to you as a
              MyFoundry platform user.
            </div>

            <div className="notion-text end-section">
              13.5. <b>Trademark Guidelines.</b> Monaco Foundry&apos;s Trademark
              Guidelines apply to any use by you of Monaco Foundry&apos;s Marks.
              If you or your Authorized Users use Monaco Foundry&apos;s Marks,
              you will comply with, and will ensure your Authorized Users comply
              with, Monaco Foundry&apos;s Trademark Guidelines.
            </div>
            <div className="notion-text end-section">
              13.6. <b> Severability.</b> The invalidity or unenforceability of
              any provision of these Terms will not affect the validity or
              enforceability of any other provision hereof and it is the intent
              and agreement of the parties that these Terms will be deemed
              amended by modifying such provision to the extent necessary to
              render it valid, legal, and enforceable while preserving its
              intent or, if such modification is not possible, by substituting
              another provision that is legal and enforceable and that achieves
              the same objective.
            </div>
            <div className="notion-text end-section">
              13.7. <b>Assignment.</b> These Terms (and your access to the
              MyFoundry Platform) are not assignable or transferable by you
              without our prior written consent. Any purported assignment in
              violation of this section is null and void.
            </div>
            <div className="notion-text end-section">
              13.8. <b> Service Providers. </b>For the avoidance of doubt,
              Monaco Foundry may engage third parties as service providers to
              the MyFoundry Platform (for example, as of the date of these
              Terms, Monaco Foundry hosts the MyFoundry Platform on a
              third-party cloud service).
            </div>
            <div className="notion-text end-section">
              13.9. <b> No Partnership.</b> No agency, partnership, joint
              venture, or employment is created as a result of these Terms, and
              neither party has any authority of any kind to bind the other
              party in any respect whatsoever.
            </div>
            <div className="notion-text end-section">
              13.10. <b> Governing Law.</b> These Terms will be governed by the
              laws of Luxembourg.
            </div>
            <div className="notion-text end-section">
              13.11. <b> Beta Features and Free Trials.</b> Product features
              clearly identified as Alpha or Beta features as well as any
              features, products, or platform provided on a free trial basis
              (collectively “<b>Early Access Features</b>
              ”) made available by Monaco Foundry are provided to you for
              testing and evaluation purposes only. Monaco Foundry does not make
              any commitment to provide Alpha or Beta features in any future
              versions of the Platform. Monaco Foundry may immediately and
              without notice remove Alpha or Beta features for any reason
              without liability to you. Any features, products, or platform
              provided on a free trial basis will be free of charge until the
              earlier of (a) the end of the evaluation period set forth by
              Monaco Foundry, or (b) the start date of any purchased
              subscriptions you order for the feature, product, or service being
              evaluated under the trial, or (c) termination by Monaco Foundry in
              its sole discretion. You are not obligated to use Early Access
              Features.
            </div>
            <div className="notion-text end-section">
              {`
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE TERMS, ALL
                        EARLY ACCESS FEATURES ARE PROVIDED "AS IS" WITHOUT WARRANTY
                        OF ANY KIND AND WITHOUT ANY PERFORMANCE OBLIGATIONS. ANY
                        DATA CUSTOMER ENTERS INTO THE MONACO FOUNDRY PLATFORM DURING
                        A FREE TRIAL MAY BE PERMANENTLY LOST UNLESS CUSTOMER
                        PURCHASES A SUBSCRIPTION TO THE MONACO FOUNDRY
                        PLATFORM TRIALED, PURCHASES A SUBSCRIPTION TO THE MONACO
                        FOUNDRY PLATFORM THAT IS AN UPGRADE TO THE SUBSCRIPTION
                        TRIALED, OR EXPORTS SUCH DATA, BEFORE THE END OF THE TRIAL
                        PERIOD.
                        `}
            </div>
          </article>
        </div>
      </main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  .notion-title {
    text-align: center;
    color: #050a30;
  }

  .notion-text {
    line-height: 24px;
    margin-bottom: 5px;

    b {
      margin-bottom: 10px;
      color: #050a30;
    }
  }

  .end-section {
    margin-bottom: 20px;
  }
`;
