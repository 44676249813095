import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { ImageEditor, SectionWrapper, Text } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { selectAppConfig } from "store/global/global.selector";
import { globalActions } from "store/global/global.slice";
import { uploadFromBlobAsync } from "utils/firebase.util";

export const AdminConfigPage = () => {
  const [logoImage, setLogoImage] = useState<Blob | undefined>(undefined);
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const { headerLogo, brandName } = appConfig;

  const schema = yup.object().shape({ brandName: yup.string().nullable() });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleUploadLogo = useCallback(
    async (logoImage: Blob) => {
      dispatch(globalActions.setPageLoading(true));

      try {
        const url = await uploadFromBlobAsync({
          blob: logoImage,
          name: "application-logo",
          folder: "avatars",
        });

        dispatch(
          globalActions.updateAppConfig({
            ...appConfig,
            headerLogo: url as string,
          })
        );
        setLogoImage(undefined);
        dispatch(globalActions.setPageLoading(false));
      } catch (_) {
        dispatch(
          globalActions.pushNotification({
            type: "error",
            message: "Update logo failed",
          })
        );

        dispatch(globalActions.setPageLoading(false));
      }
    },
    [appConfig, dispatch]
  );

  const handleUpdateField = (data: FieldValues) => {
    dispatch(
      globalActions.updateAppConfig({
        ...appConfig,
        brandName: data["brandName"],
      })
    );
  };

  useEffect(() => {
    if (logoImage) {
      handleUploadLogo(logoImage);
    }
  }, [handleUploadLogo, logoImage]);

  useEffect(() => {
    if (brandName) {
      setValue("brandName", brandName);
    }
  }, [brandName, setValue]);

  return (
    <Wrapper>
      <SectionWrapper title="My application">
        <Container>
          <Stack spacing="5px">
            <Text size="md" fontWeight={600}>
              Logo
            </Text>
            <LogoWrapper>
              <ImageEditor
                className="avatar-upload"
                onSave={setLogoImage}
                url={headerLogo}
              />
            </LogoWrapper>
          </Stack>

          <GroupTextField
            fieldName="Application name"
            name="brandName"
            formRef={register}
            placeholder="Type your application name"
            error={errors?.applicationName?.message as string}
            onBlur={handleSubmit(handleUpdateField)}
          />
        </Container>
      </SectionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  max-width: 800px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const LogoWrapper = styled.div`
  .avatar-upload {
    width: 96px;
    height: 96px;
    border-radius: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
