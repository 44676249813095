import { IconButton, Popover } from "@mui/material";
import { CheckIcon, EditIcon } from "assets/images/common";
import { Button, ButtonOutline } from "components";
import { SelectItem, SelectItemTask } from "models/common.model";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

interface Props {
  name: string;
  select: SelectItem[];
  data: SelectItem[];
  onSave: (data: { [key: string]: SelectItemTask[] }) => void;
  handleSelected?: (data: SelectItem[]) => void;
  children?: React.ReactNode;
  popoverElement?: React.ReactNode;
}

export const MultiSelect = ({
  name,
  onSave,
  handleSelected,
  children,
  popoverElement,
  data = [],
  select = [],
}: Props) => {
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [viewData, setViewData] = useState<SelectItem[]>([]);

  const handleSelectItem = (id: string) => {
    const fillData = viewData.map((i) =>
      i.id === id ? { ...i, check: !i?.check } : i
    );
    setViewData(fillData);
  };

  useEffect(() => {
    if (data.length) {
      const fillData = data.map((i) =>
        select.find((s) => s.id === i.id) ? { ...i, check: true } : i
      );
      setViewData(fillData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, select.length]);

  const handleSave = () => {
    const selectedItems = viewData.filter((i) => i.check);
    const removeList: SelectItemTask[] = select
      .filter((i) => !selectedItems.find((s) => s.id === i.id))
      .map((m) => ({ id: m.id, task: "REMOVE" }));
    const addList: SelectItemTask[] = selectedItems
      .filter((i) => !select.find((s) => s.id === i.id))
      .map((m) => ({ id: m.id, task: "ADD" }));
    const data = { [name]: [...addList, ...removeList] };
    onSave(data);
    handleSelected && handleSelected(selectedItems);
    setIsOpen(false);
  };

  return (
    <Wrapper className={isOpen ? "edit-field" : "text-field"}>
      <div ref={popoverRef} onClick={() => setIsOpen(!isOpen)}>
        {popoverElement ? (
          popoverElement
        ) : (
          <IconWrapper>
            <EditIcon />
          </IconWrapper>
        )}
      </div>

      {children}

      <PopoverWrapper
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: "180px",
            borderRadius: "16px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
        anchorEl={popoverRef.current}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <SelectWrapper>
          {viewData.map((item) => (
            <Item
              key={item.id}
              className={item?.check ? "checked" : ""}
              onClick={() => handleSelectItem(item.id)}
            >
              {item.check && (
                <CheckIconWrapper>
                  <CheckIcon color="#373b59" />
                </CheckIconWrapper>
              )}
              {item.name}
            </Item>
          ))}
        </SelectWrapper>
        <Footer>
          <Button onClick={handleSave}>Save</Button>
          <ButtonOutline onClick={() => setIsOpen(false)}>Cancel</ButtonOutline>
        </Footer>
      </PopoverWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  padding-right: 32px;
  position: relative;

  &.edit-field {
    .display-text {
      color: transparent;
    }
  }
`;

const PopoverWrapper = styled(Popover)`
  .MuiPaper-root {
    min-width: clamp(380px, calc(100vw - 40px), 420px);
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  z-index: 1;
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 0px 50px rgba(5, 10, 48, 0.1);
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 80px;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border: 1px solid #d8d9df;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 14px;
  cursor: pointer;

  &.checked {
    border-color: #ebecf0;
    background: #ebecf0;
  }

  &:hover {
    border-color: #ebecf0;
    background: #ebecf0;
  }
`;

const IconWrapper = styled(IconButton)`
  position: absolute;
  top: -25px;
  left: -10px;

  svg {
    width: 22px;
    height: auto;
  }
`;

const CheckIconWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ebecf0;
  background: #ffffff;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 12px;
  border-top: 1px solid #ebecef;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: rgb(5 10 48 / 15%) 3px 2px 20px 0px;
`;
