import styled from "styled-components";

import { OpenInNewIcon } from "assets/images/common";
import { Button, FlagText } from "components";
import { MarketplaceItem } from "models/marketplace.model";

interface Props {
  data: MarketplaceItem;
}

export const ListItem = ({ data }: Props) => {
  const {
    linkUrl,
    logoUrl,
    displayName,
    tags,
    description,
    buttonText,
    group,
  } = data;

  return (
    <Wrapper className={`${group === "HEALTH_CHECK" && "gold-type"}`}>
      <ButtonWrapper onClick={() => window.open(linkUrl, "_blank")}>
        <OpenInNewIcon />
      </ButtonWrapper>

      <Header>
        <img
          width="120px"
          height="120px"
          className="logo-image"
          src={logoUrl}
          onClick={() => window.open(linkUrl, "_blank")}
        />

        <div className="name">{displayName}</div>

        <div className="tag-list">
          {tags.map((tag) => (
            <FlagText key={tag.id}>{tag.name}</FlagText>
          ))}
        </div>
      </Header>

      <div className="description">{description}</div>

      <Button onClick={() => window.open(linkUrl, "_blank")}>
        {buttonText}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  border: 1px solid #d8d9df;
  border-radius: 12px;

  &.gold-type {
    background: #c9af7f4d;
    border: 1px solid #c9af7f26;
  }

  .description {
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 14px;
    text-align: center;
  }

  .button-wrapper {
    margin: auto auto 0px;

    button {
      height: 34px;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  transition: all 0.7s;

  .logo-image {
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.3s;
    width: 120px;
    height: auto;

    &:hover {
      transform: scale(1.02);
    }
  }

  .name {
    color: ${(props) => props.theme.palette.secondary.dark};
    font-weight: 700;
    font-size: 20px;
  }

  .tag-list {
    display: flex;
    gap: 6px;
  }

  .flag-text {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 12px;
    background-color: #6a6d8433;
    padding: 6px 12px;
    border-radius: 4px;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.05);
  }
`;
