import styled from "styled-components/macro";
import { GroupText, Link, Text } from "components";

const SignInHeader = () => {
  return (
    <SignInHeaderWrapper>
      <Text size="xxl">Log In</Text>
      <GroupText>
        <Text size="md">Don’t have an account yet?</Text>
        <Link color="#C9AF7F" href="/sign-up">
          Register
        </Link>
      </GroupText>
    </SignInHeaderWrapper>
  );
};

export { SignInHeader };

const SignInHeaderWrapper = styled.div`
  margin-bottom: 30px;
`;
