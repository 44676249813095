import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ReadIcon = ({ color = "#A5A7B4" }: Props) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2407 2.71269C10.4967 2.44167 10.4844 2.01445 10.2134 1.75849C9.9424 1.50252 9.51519 1.51472 9.25922 1.78575L4.08329 7.26615L1.24069 4.25634C0.984719 3.98531 0.557507 3.97311 0.286482 4.22907C0.0154572 4.48504 0.00325112 4.91225 0.259219 5.18328L4.08329 9.23229L10.2407 2.71269ZM13.2407 2.71269C13.4967 2.44167 13.4844 2.01445 13.2134 1.75849C12.9424 1.50252 12.5152 1.51472 12.2592 1.78575L6.59255 7.78575C6.33658 8.05678 6.34879 8.48399 6.61981 8.73996C6.89084 8.99592 7.31805 8.98372 7.57402 8.71269L13.2407 2.71269Z"
        fill={color}
      />
    </svg>
  );
};
