import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import { TextArea } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { Fundraising } from "models/fundraising.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectFundraising,
  selectPossibleEditFundraising,
} from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";

interface Props {
  fieldType?: "text" | "text-area" | "number";
  fieldName: string;
  name: string;
  yupSchema: yup.AnySchema;
  placeholder?: string;
  maxLength?: number;
  tooltipText?: string;
}

export const FundraisingFieldInput = ({
  fieldType = "text",
  fieldName,
  name,
  yupSchema,
  placeholder,
  maxLength = 140,
  tooltipText,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedFundraising = useAppSelector(selectFundraising);
  const isPossibleEditFundraising = useAppSelector(
    selectPossibleEditFundraising
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = [AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(
    authMode
  );

  const fieldData = selectedFundraising[name as keyof Fundraising];
  const schema = yup.object().shape({ [name]: yupSchema });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleUpdateField = async (data: FieldValues) => {
    const value = data?.[name];
    const input = {
      [name]: value,
    };

    if (value === fieldData) return;

    if (value === null && fieldType === "number") {
      input[name] = -1;
    }

    dispatch(
      fundraisingActions.updateFundraising({
        id: selectedFundraising.id,
        input: input,
      })
    );
  };

  useEffect(() => {
    if (fieldData === -1 && fieldType === "number") {
      setValue(name, null);
    } else {
      setValue(name, fieldData);
    }
  }, [fieldData, fieldType, name, setValue]);

  if (fieldType === "number")
    return (
      <GroupTextField
        fieldName={fieldName}
        name={name}
        placeholder={placeholder || "Please fill data"}
        type="number"
        tooltipText={tooltipText}
        formRef={register}
        error={errors?.[name]?.message as string}
        onBlur={handleSubmit(handleUpdateField)}
        hasClear
        watch={watch}
        reset={reset}
        trigger={trigger}
        afterClear={() => handleSubmit(handleUpdateField)()}
        disabled={isAdmin || !isPossibleEditFundraising}
      />
    );

  if (fieldType === "text")
    return (
      <GroupTextField
        fieldName={fieldName}
        name={name}
        placeholder={placeholder || "Please fill data"}
        type="input"
        tooltipText={tooltipText}
        formRef={register}
        error={errors?.[name]?.message as string}
        onBlur={handleSubmit(handleUpdateField)}
        hasClear
        watch={watch}
        reset={reset}
        trigger={trigger}
        afterClear={() => handleSubmit(handleUpdateField)()}
        disabled={isAdmin || !isPossibleEditFundraising}
      />
    );

  if (fieldType === "text-area")
    return (
      <TextArea
        fieldName={fieldName}
        name={name}
        placeholder={placeholder || "Type some words here..."}
        minRows={3}
        maxLength={maxLength}
        hasCount
        formRef={register}
        watch={watch}
        error={errors?.[name]?.message as string}
        onBlur={handleSubmit(handleUpdateField)}
        hasClear
        reset={reset}
        trigger={trigger}
        afterClear={() => handleSubmit(handleUpdateField)()}
        disabled={isAdmin || !isPossibleEditFundraising}
      />
    );

  return null;
};
