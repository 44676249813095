import { call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import organisationApi from "api/organisation.api";
import {
  AddTeamMemberParams,
  FounderScoreResult,
  SubmitFounderScoreParams,
  SubmitTrustScoreQuestionaryParams,
  UpdateOrganisationParams,
} from "models/organisation.model";
import { globalActions } from "store/global/global.slice";
import { errorNotification } from "utils/app.util";
import { deleteFolder, uploadFromBlobAsync } from "utils/firebase.util";
import { organisationActions } from "./organisation.slice";

function* getOrganisations() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { organisationsGet } = yield call(organisationApi.organisationsGet);

    if (organisationsGet) {
      const { organisations } = organisationsGet;

      if (organisations) {
        yield put(organisationActions.getOrganisationsSuccess(organisations));
      }
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getCompanyOrganisation() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { organisationsGet } = yield call(organisationApi.organisationsGet);

    if (organisationsGet) {
      const { organisations } = organisationsGet;

      if (organisations && organisations.length) {
        yield put(
          organisationActions.getCompanyOrganisationSuccess(organisations[0])
        );
      }
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getOrganisation(
  action: PayloadAction<{ id: string; isAdmin?: boolean }>
) {
  try {
    yield put(globalActions.setPageLoading(true));

    const { id, isAdmin } = action.payload;

    const apiCall = isAdmin
      ? organisationApi.organisationGetAdmin
      : organisationApi.organisationGet;

    const { organisationGetADMIN, organisationGet } = yield call(apiCall, id);

    const response = isAdmin ? organisationGetADMIN : organisationGet;

    if (response) {
      const { organisation } = response;

      if (organisation) {
        yield put(organisationActions.getOrganisationSuccess(organisation));
      }
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* createOrganisation() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { organisationsGet } = yield call(organisationApi.organisationsGet);

    if (organisationsGet) {
      const { organisations } = organisationsGet;

      if (!organisations || !organisations.length) {
        const { organisationCreate } = yield call(
          organisationApi.organisationCreate,
          { displayName: "" }
        );

        if (organisationCreate) {
          const { organisation } = organisationCreate;

          if (organisation) {
            yield put(
              organisationActions.createOrganisationSuccess(organisation)
            );
          }
        }
      }
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* updateOrganisation(
  action: PayloadAction<{
    organisationId: string;
    input: UpdateOrganisationParams;
    fieldUpdate?: string;
    isAdmin?: boolean;
    callback?: () => void;
  }>
) {
  try {
    const { organisationId, input, fieldUpdate, isAdmin, callback } =
      action.payload;

    const apiCall = isAdmin
      ? organisationApi.organisationAdmin
      : organisationApi.organisationUpdate;

    const { teamMembers } = input;
    const deleteMember = teamMembers?.find((i) => i.task === "DELETE");

    if (deleteMember) {
      yield call(
        deleteFolder,
        `workspaces/${organisationId}/teams/${deleteMember.id}`
      );
    }
    const { organisationUpdate, organisationADMIN } = yield call(
      apiCall,
      organisationId,
      input
    );

    const response = isAdmin ? organisationADMIN : organisationUpdate;

    if (response) {
      const { organisation } = response;

      if (organisation) {
        yield put(
          organisationActions.updateOrganisationSuccess({
            organisation,
            fieldUpdate,
          })
        );
      }
    }

    callback && callback();
  } catch (_) {
    yield put(organisationActions.updateOrganisationFailed());
  }
}

function* AddTeamMember(action: PayloadAction<AddTeamMemberParams>) {
  try {
    const { organisationId, member, avatarImage, callback } = action.payload;

    const { organisationUpdate } = yield call(
      organisationApi.organisationUpdate,
      organisationId,
      { teamMembers: [{ ...member, task: "CREATE" }] }
    );

    if (organisationUpdate) {
      const { organisation } = organisationUpdate;

      if (organisation) {
        const newMember = organisation.teamMembers[0];

        if (avatarImage) {
          const photoUrl: string = yield call(uploadFromBlobAsync, {
            blob: avatarImage,
            name: "avatar",
            folder: `workspaces/${organisationId}/teams/${newMember.id}`,
          });

          yield call(organisationApi.organisationUpdate, organisationId, {
            teamMembers: [
              {
                id: newMember.id,
                data: { photoUrl },
                task: "UPDATE",
              },
            ],
          });

          newMember.photoUrl = photoUrl;
        }

        yield put(organisationActions.addTeamMemberSuccess(newMember));
      }
    }

    callback && callback();
  } catch (_) {
    yield put(organisationActions.addTeamMemberFailed());
  }
}

function* reorderTeamMember(
  action: PayloadAction<{
    organisationId: string;
    teamMembers: UpdateOrganisationParams["teamMembers"];
  }>
) {
  try {
    yield put(globalActions.setPageLoading(true));

    const { organisationId, teamMembers } = action.payload;

    yield call(organisationApi.organisationUpdate, organisationId, {
      teamMembers,
    });

    yield put(organisationActions.getCompanyOrganisation());
    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
    yield put(errorNotification("Reorder team member failed"));
  }
}

function* submitOrganisation(
  action: PayloadAction<{ organisationId: string; callback?: () => void }>
) {
  try {
    const { organisationId, callback } = action.payload;

    const { organisationSubmit } = yield call(
      organisationApi.organisationSubmit,
      organisationId
    );

    if (organisationSubmit) {
      const { organisation } = organisationSubmit;

      if (organisation) {
        yield put(organisationActions.submitOrganisationSuccess(organisation));
      }
    }

    callback && callback();
  } catch (_) {
    yield put(organisationActions.submitOrganisationFailed());
    yield put(errorNotification("Submit organisation failed"));
  }
}

function* getOrganisationsAdmin() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { organisationListADMIN } = yield call(
      organisationApi.organisationListAdmin
    );

    if (organisationListADMIN) {
      const { organisations } = organisationListADMIN;

      if (organisations) {
        yield put(
          organisationActions.getOrganisationsAdminSuccess(organisations)
        );
      }
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* unPublishOrganisation(
  action: PayloadAction<{ organisationId: string; callback?: () => void }>
) {
  try {
    const { organisationId, callback } = action.payload;

    const { organisationUnPublish } = yield call(
      organisationApi.organisationUnPublish,
      organisationId
    );

    if (organisationUnPublish) {
      const { organisation } = organisationUnPublish;

      if (organisation) {
        yield put(
          organisationActions.unPublishOrganisationSuccess(organisation)
        );
      }
    }

    callback && callback();
  } catch (_) {
    yield put(organisationActions.unPublishOrganisationFailed());
    yield put(errorNotification("UnPublish organisation failed"));
  }
}

function* getTrustScoreQuestions() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { trustScoreQuestionaryGet } = yield call(
      organisationApi.trustScoreQuestionaryGet
    );

    if (trustScoreQuestionaryGet) {
      const { items } = trustScoreQuestionaryGet;

      yield put(organisationActions.getTrustScoreQuestionsSuccess(items));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}
function* submitTrustScore(
  action: PayloadAction<{
    organisationId: string;
    input: SubmitTrustScoreQuestionaryParams;
    callback?: (score: number) => void;
  }>
) {
  try {
    const { organisationId, input, callback } = action.payload;

    const { trustScoreQuestionarySubmit } = yield call(
      organisationApi.trustScoreQuestionarySubmit,
      organisationId,
      input
    );

    if (trustScoreQuestionarySubmit) {
      const { scale } = trustScoreQuestionarySubmit;

      yield put(organisationActions.submitTrustScoreSuccess());
      callback && callback(scale?.score);
    }
  } catch (_) {
    yield put(organisationActions.submitTrustScoreFailed());
    yield put(errorNotification("Submit MyFoundry score failed"));
  }
}

function* getFounderScoreQuestions() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { foundersAssesmentGet } = yield call(
      organisationApi.foundersAssesmentGet
    );

    if (foundersAssesmentGet) {
      const { assesment } = foundersAssesmentGet;
      yield put(organisationActions.getFounderScoreQuestionsSuccess(assesment));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* submitFounderScore(
  action: PayloadAction<{
    assesment: SubmitFounderScoreParams;
    callback?: (result: FounderScoreResult[]) => void;
  }>
) {
  try {
    const { assesment, callback } = action.payload;

    const { assesmentResolve } = yield call(
      organisationApi.foundersAssesmentSubmit,
      assesment
    );

    if (assesmentResolve) {
      const { result } = assesmentResolve;

      yield put(organisationActions.submitFounderScoreSuccess());
      callback && callback(result);
    }
  } catch (_) {
    yield put(organisationActions.submitFounderScoreFailed());
    yield put(errorNotification("Submit founder score failed"));
  }
}

export default function* organisationSaga() {
  yield takeLatest(organisationActions.getOrganisations.type, getOrganisations);
  yield takeLatest(
    organisationActions.getCompanyOrganisation.type,
    getCompanyOrganisation
  );
  yield takeLatest(organisationActions.getOrganisation.type, getOrganisation);
  yield takeLatest(
    organisationActions.updateOrganisation.type,
    updateOrganisation
  );
  yield takeLatest(
    organisationActions.createOrganisation.type,
    createOrganisation
  );
  yield takeLatest(organisationActions.addTeamMember.type, AddTeamMember);
  yield takeLatest(
    organisationActions.reorderTeamMember.type,
    reorderTeamMember
  );
  yield takeLatest(
    organisationActions.submitOrganisation.type,
    submitOrganisation
  );
  yield takeLatest(
    organisationActions.getOrganisationsAdmin.type,
    getOrganisationsAdmin
  );
  yield takeLatest(
    organisationActions.unPublishOrganisation.type,
    unPublishOrganisation
  );
  yield takeLatest(
    organisationActions.getTrustScoreQuestions.type,
    getTrustScoreQuestions
  );
  yield takeLatest(
    organisationActions.getFounderScoreQuestions.type,
    getFounderScoreQuestions
  );
  yield takeLatest(organisationActions.submitTrustScore.type, submitTrustScore);
  yield takeLatest(
    organisationActions.submitFounderScore.type,
    submitFounderScore
  );
}
