import { TablePagination } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { EditIcon } from "assets/images/common";
import { BasicTable, PageTitle, Text } from "components";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks/useHook";
import { TBD } from "models/common.model";
import { selectFundraisingList } from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import { formatCurrency } from "utils/system.util";
import { StatusFlag } from "../Company/components";

const CONFIG = [
  {
    name: "Organisation",
    width: "auto",
  },
  {
    name: "Round type",
    width: "auto",
  },
  {
    name: "Amount",
    width: "auto",
  },
  {
    name: "End date",
    width: 160,
  },
  {
    name: "Status",
    width: 160,
  },
  {
    name: "Action",
    width: 120,
  },
];

export const FundraisingPage = () => {
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useAppNavigate();
  const fundraisingList = useAppSelector(selectFundraisingList);

  useEffect(() => {
    dispatch(fundraisingActions.getFundraisingListAdmin());
  }, [dispatch]);

  useEffect(() => {
    const data = fundraisingList
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item) => {
        return {
          organisation: <GroupCell>{item.organisation?.displayName}</GroupCell>,
          roundType: <GroupCell>{item.currentRound?.name}</GroupCell>,
          amount: (
            <GroupCell>
              {formatCurrency(item.roundSize, item.organisation?.currency)}
            </GroupCell>
          ),
          endDate: (
            <GroupCell>
              {item.roundCloseDate &&
                dayjs(item.roundCloseDate).format("DD/MM/YYYY")}
            </GroupCell>
          ),
          status: (
            <GroupCell>
              <StatusFlag status={item.status} />
            </GroupCell>
          ),
          action: (
            <GroupCell>
              <ActionWrapper
                onClick={() => navigate(`${PATH.ADMIN_FUNDRAISING}/${item.id}`)}
              >
                <EditIcon />
                <Text size="md">View more</Text>
              </ActionWrapper>
            </GroupCell>
          ),
        };
      });

    setTableData(data);
  }, [fundraisingList, navigate, page, pathname, rowsPerPage]);

  return (
    <Wrapper>
      <PageTitle>Fundraising</PageTitle>
      <BasicTable config={CONFIG} data={tableData} />

      {fundraisingList.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={fundraisingList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            setPage(0);
          }}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 100%;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  & > .MuiPaper-root {
    flex: 1;
  }
`;

const GroupCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: auto;
  }

  &:hover {
    text-decoration: underline;
  }
`;
