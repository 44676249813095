import { width } from "@mui/system";
import styled from "styled-components/macro";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: number;
  thin?: number;
}

export const HorizontalLine = ({ ...props }: Props) => {
  return <Wrapper {...props}></Wrapper>;
};

const Wrapper = styled.div<Props>`
  height: 0;
  width: ${(p) => (p?.width ? `${width}px` : `100%`)};
  border: 0 solid #ebecef;
  border-bottom-width: ${(p) => p?.thin || 1}px;
`;
