import { Dialog, IconButton } from "@mui/material";
import { ZoomInIcon, ZoomOutIcon } from "assets/images/common";
import { useAppDispatch } from "hooks/useHook";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { globalActions } from "store/global/global.slice";
import styled, { css } from "styled-components/macro";
import { getFileBlob } from "utils/firebase.util";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
interface Props {
  url: string;
  forceZoom?: boolean;
}

interface ViewFrameProps {
  file: Blob;
  pageWidth: number;
  isZoom: boolean;
  setIsZoom: Dispatch<SetStateAction<boolean>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
}

const ViewFrame = ({
  file,
  pageWidth,
  isZoom,
  setIsZoom,
  pageNumber,
  setPageNumber,
}: ViewFrameProps) => {
  const dispatch = useAppDispatch();
  const [numPages, setNumPages] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
  };

  const onNext = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const onPrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    dispatch(globalActions.setPageLoading(true));
  }, [dispatch]);

  return (
    <Document
      file={file || ""}
      onLoadSuccess={onDocumentLoadSuccess}
      className={`${pageLoading && "loading"}`}
    >
      <ZoomButton
        onClick={() => {
          setIsZoom(!isZoom);
        }}
      >
        <IconButton>{isZoom ? <ZoomOutIcon /> : <ZoomInIcon />}</IconButton>
      </ZoomButton>
      {pageNumber > 1 && <LeftArrow onClick={onPrevious}>&larr;</LeftArrow>}
      {pageNumber < numPages && (
        <RightArrow onClick={onNext}>&rarr;</RightArrow>
      )}
      {Array(numPages)
        .fill(true)
        .map((_, index) => (
          <Page
            className={index + 1 === pageNumber ? "show-page" : "hide-page"}
            key={index}
            pageNumber={index + 1}
            width={pageWidth}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            onRenderSuccess={() => {
              if (index + 1 === pageNumber) {
                dispatch(globalActions.setPageLoading(false));
                setPageLoading(false);
              }
            }}
          />
        ))}
      <PagePagination>
        <PagePaginationWrapper>
          {Array(numPages)
            .fill(true)
            .map((_, index) => (
              <PageIndexItem
                key={index}
                className={`${index + 1 === pageNumber && "selected"}`}
                onClick={() => setPageNumber(index + 1)}
              />
            ))}
        </PagePaginationWrapper>
      </PagePagination>
    </Document>
  );
};

export const PdfView = ({ url, forceZoom }: Props) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<Blob>();
  const [pageWidth, setPageWidth] = useState(380);
  const [pageModalWidth, setPageModalWidth] = useState(0);
  const [isZoom, setIsZoom] = useState(!!forceZoom);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const parentRef = useRef(null);
  const parentModalRef = useRef(null);

  useEffect(() => {
    if (parentRef) {
      const parentWidth = (parentRef as React.RefObject<HTMLDivElement>).current
        ?.offsetWidth;
      if (parentWidth) setPageWidth(parentWidth);
    }
  }, []);

  useEffect(() => {
    if (isZoom) {
      setTimeout(() => {
        if (parentModalRef) {
          const parentModalWidth = (
            parentModalRef as React.RefObject<HTMLDivElement>
          ).current?.offsetWidth;
          if (parentModalWidth) setPageModalWidth(parentModalWidth);
        }
      }, 500);
    }
  }, [isZoom]);

  useEffect(() => {
    if (url) {
      dispatch(globalActions.setPageLoading(true));
      getFileBlob(url)
        .then((file) => {
          setPageNumber(1);
          setFile(file as Blob);
        })
        .finally(() => {
          dispatch(globalActions.setPageLoading(false));
        });

      setTimeout(() => {
        dispatch(globalActions.setPageLoading(false));
      }, 3000);
    }
  }, [dispatch, url]);

  return (
    <>
      {isZoom && (
        <ModalWrapper open={isZoom} onClose={() => setIsZoom(false)}>
          <Wrapper
            ref={parentModalRef}
            className={`${isZoom && "zoom-out"}`}
            key={"pdf-modal-wrapper"}
          >
            {file && (
              <ViewFrame
                file={file}
                pageWidth={pageModalWidth}
                isZoom={isZoom}
                setIsZoom={setIsZoom}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </Wrapper>
        </ModalWrapper>
      )}
      <Wrapper
        ref={parentRef}
        className={`${isZoom && "zoom-out"}`}
        key={"pdf-wrapper"}
      >
        {file && (
          <ViewFrame
            file={file}
            pageWidth={pageWidth}
            isZoom={isZoom}
            setIsZoom={setIsZoom}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </Wrapper>
    </>
  );
};

const ModalWrapper = styled(Dialog)`
  .MuiPaper-root.MuiPaper-elevation {
    min-height: 60vh;
    max-width: unset;
    max-height: calc(100% - 40px);

    ${(props) => props.theme.breakpoints.down("sm")} {
      margin: 0;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  border-radius: 12px;
  //overflow: hidden;

  .hide-page {
    display: none;
  }

  .react-pdf__Document {
    position: relative;
    width: fit-content;
    padding-bottom: 40px;

    &.loading::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
    }

    .react-pdf__Page {
      border-radius: 12px;
      overflow: hidden;
    }
  }

  &.zoom-out {
    width: clamp(0px, calc(100vw - 80px), 1140px);
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    &.zoom-out {
      width: clamp(0px, calc(100vw - 10px), 1140px);
    }
  }
`;

const ZoomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: #80808035;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 4px;
  z-index: 2;

  svg {
    width: 20px;
    height: auto;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const PagePagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  position: absolute;
  left: 0;
  bottom: 10px;
  height: 12px;
`;

const PagePaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  border-radius: 8px;
`;

const PageIndexItem = styled.div`
  width: 8px;
  height: 8px;
  background-color: #ebecef;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #d8d9df;
    transform: scale(1.2);
  }

  &.selected {
    width: 12px;
    height: 12px;
    background-color: #d8d9df;
  }
`;

const baseArrowCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #80808035;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: calc(50% - 30px);
  z-index: 2;
  cursor: pointer;
  user-select: none;

  &:hover {
    transform: scaleY(1.05);
  }
`;

const LeftArrow = styled.div`
  ${baseArrowCss}
  left: 8px;
`;

const RightArrow = styled.div`
  ${baseArrowCss}
  right: 8px;
`;
