import React from "react";

export const ConfirmSendMailIcon = () => {
  return (
    <svg
      width="94"
      height="86"
      viewBox="0 0 94 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_302_4657)">
        <path
          d="M92.9951 32.8881C92.9615 32.8882 92.9287 32.8777 92.9012 32.858L47.3814 0.486089C47.2429 0.388033 47.0783 0.335631 46.9097 0.335924C46.7412 0.336218 46.5768 0.389193 46.4386 0.48773L1.24671 32.8577C1.21096 32.8833 1.16672 32.8934 1.12372 32.8856C1.08072 32.8779 1.04247 32.8531 1.0174 32.8166C0.992326 32.7801 0.98248 32.7349 0.990028 32.691C0.997575 32.6471 1.0219 32.608 1.05764 32.5824L46.2495 0.212473C46.443 0.0745422 46.6732 0.000397603 46.9092 1.59463e-06C47.1452 -0.000394414 47.3756 0.0729766 47.5696 0.210258L93.0893 32.5821C93.1181 32.6026 93.1397 32.6319 93.151 32.6657C93.1623 32.6996 93.1627 32.7363 93.1522 32.7704C93.1417 32.8046 93.1208 32.8344 93.0926 32.8556C93.0643 32.8767 93.0302 32.8882 92.9951 32.8881L92.9951 32.8881Z"
          fill="#373B59"
        />
        <path
          d="M3.8291 34.0358L46.9545 1.39844L90.4072 36.3581L49.1765 61.3174L26.7918 56.107L3.8291 34.0358Z"
          fill="#EBECEF"
        />
        <path
          d="M28.6813 76.7335H7.37628C7.24546 76.7337 7.11588 76.7075 6.99497 76.6565C6.87406 76.6055 6.76419 76.5307 6.67163 76.4363C6.57906 76.3419 6.50563 76.2298 6.45553 76.1064C6.40543 75.983 6.37964 75.8507 6.37964 75.7171C6.37964 75.5835 6.40543 75.4512 6.45553 75.3278C6.50563 75.2044 6.57906 75.0923 6.67163 74.9979C6.76419 74.9035 6.87406 74.8287 6.99497 74.7777C7.11588 74.7267 7.24546 74.7005 7.37628 74.7007H28.6813C28.8121 74.7005 28.9417 74.7267 29.0626 74.7777C29.1835 74.8287 29.2934 74.9035 29.3859 74.9979C29.4785 75.0923 29.5519 75.2044 29.602 75.3278C29.6521 75.4512 29.6779 75.5835 29.6779 75.7171C29.6779 75.8507 29.6521 75.983 29.602 76.1064C29.5519 76.2298 29.4785 76.3419 29.3859 76.4363C29.2934 76.5307 29.1835 76.6055 29.0626 76.6565C28.9417 76.7075 28.8121 76.7337 28.6813 76.7335Z"
          fill="url(#paint0_linear_302_4657)"
        />
        <path
          d="M15.02 72.3634H7.37628C7.24546 72.3636 7.11588 72.3374 6.99497 72.2864C6.87407 72.2354 6.76419 72.1606 6.67163 72.0662C6.57906 71.9718 6.50563 71.8597 6.45553 71.7363C6.40543 71.6128 6.37964 71.4806 6.37964 71.347C6.37964 71.2134 6.40543 71.0811 6.45553 70.9577C6.50563 70.8343 6.57906 70.7222 6.67163 70.6278C6.76419 70.5334 6.87407 70.4586 6.99497 70.4076C7.11588 70.3566 7.24546 70.3304 7.37628 70.3306H15.02C15.1509 70.3304 15.2804 70.3566 15.4013 70.4076C15.5223 70.4586 15.6321 70.5334 15.7247 70.6278C15.8173 70.7222 15.8907 70.8343 15.9408 70.9577C15.9909 71.0811 16.0167 71.2134 16.0167 71.347C16.0167 71.4806 15.9909 71.6128 15.9408 71.7363C15.8907 71.8597 15.8173 71.9718 15.7247 72.0662C15.6321 72.1606 15.5223 72.2354 15.4013 72.2864C15.2804 72.3374 15.1509 72.3636 15.02 72.3634Z"
          fill="url(#paint1_linear_302_4657)"
        />
        <path
          d="M47.5609 52.2143C47.399 52.2145 47.2386 52.1824 47.0887 52.1199L20.4919 40.8506V5.57594C20.4923 5.26402 20.6138 4.96498 20.8298 4.74441C21.0458 4.52385 21.3386 4.39978 21.6441 4.39941H72.668C72.9735 4.39978 73.2663 4.52385 73.4823 4.74441C73.6983 4.96498 73.8198 5.26402 73.8202 5.57594V40.8752L73.7702 40.8971L48.0475 52.1144C47.8935 52.1805 47.728 52.2145 47.5609 52.2143Z"
          fill="white"
        />
        <path
          d="M47.561 52.2984C47.3883 52.2986 47.2172 52.2644 47.0574 52.1977L20.4097 40.9068V5.576C20.41 5.24179 20.5402 4.92138 20.7716 4.68506C21.0031 4.44874 21.3168 4.31581 21.6441 4.31543H72.668C72.9953 4.31581 73.3091 4.44874 73.5405 4.68506C73.7719 4.92138 73.9021 5.24179 73.9025 5.576V40.9308L48.0798 52.1917C47.9156 52.2622 47.7392 52.2985 47.561 52.2984ZM20.7388 40.6823L47.1829 51.887C47.4296 51.9891 47.7058 51.9875 47.9512 51.8823L73.5733 40.7089V5.576C73.573 5.33091 73.4776 5.09596 73.3079 4.92266C73.1381 4.74936 72.9081 4.65187 72.668 4.65158H21.6441C21.4041 4.65187 21.174 4.74936 21.0043 4.92266C20.8346 5.09596 20.7391 5.33091 20.7389 5.576L20.7388 40.6823Z"
          fill="#373B59"
        />
        <path
          d="M92.666 32.5518H92.6331L73.7378 40.7908L47.8852 52.0636C47.7841 52.107 47.6757 52.1297 47.5662 52.1302C47.4566 52.1308 47.3479 52.1093 47.2465 52.067L20.5742 40.7673L1.21468 32.5652L1.1851 32.5518H1.15215C0.846687 32.5521 0.553828 32.6762 0.337831 32.8967C0.121834 33.1173 0.000338939 33.4164 0 33.7283V84.8232C0.000339374 85.1352 0.121834 85.4342 0.337831 85.6548C0.553828 85.8753 0.846687 85.9994 1.15215 85.9998H92.666C92.9715 85.9994 93.2644 85.8753 93.4803 85.6548C93.6964 85.4342 93.8178 85.1352 93.8182 84.8232V33.7283C93.8178 33.4164 93.6964 33.1173 93.4803 32.8967C93.2644 32.6762 92.9715 32.5521 92.666 32.5518ZM93.489 84.8232C93.4889 85.0461 93.4022 85.2598 93.2479 85.4174C93.0936 85.575 92.8843 85.6635 92.666 85.6636H1.15215C0.933916 85.6635 0.724643 85.575 0.570325 85.4174C0.416008 85.2598 0.329274 85.0461 0.329187 84.8232V33.7283C0.329485 33.511 0.412036 33.3022 0.559581 33.1456C0.707126 32.9889 0.908242 32.8966 1.12089 32.8879L20.5742 41.1303L47.1198 52.3779C47.4072 52.4973 47.7292 52.4955 48.0152 52.3729L73.7378 41.1555L92.699 32.8879C92.9113 32.8973 93.1118 32.9898 93.259 33.1464C93.4061 33.3029 93.4885 33.5113 93.489 33.7283V84.8232Z"
          fill="#373B59"
        />
        <path
          d="M43.7434 41C42.965 41.0014 42.2073 40.7527 41.5845 40.2913L41.5459 40.2627L33.4153 34.1116C33.0387 33.8265 32.7227 33.4709 32.4852 33.0651C32.2477 32.6594 32.0935 32.2113 32.0312 31.7467C31.969 31.282 32 30.8097 32.1224 30.3569C32.2449 29.9041 32.4564 29.4795 32.7449 29.1074C33.0334 28.7353 33.3933 28.423 33.8039 28.1884C34.2145 27.9537 34.6679 27.8013 35.1382 27.7398C35.6085 27.6783 36.0864 27.7089 36.5447 27.8299C37.003 27.9509 37.4327 28.1599 37.8092 28.445L43.0756 32.4352L55.5209 16.3975C55.8095 16.0255 56.1695 15.7133 56.5802 15.4788C56.9909 15.2443 57.4444 15.092 57.9147 15.0307C58.3849 14.9694 58.8628 15.0001 59.3211 15.1213C59.7793 15.2425 60.2089 15.4517 60.5854 15.7369L60.508 15.8407L60.5874 15.7384C61.3467 16.3151 61.8436 17.1658 61.9689 18.104C62.0942 19.0422 61.8378 19.9912 61.2559 20.7428L46.6173 39.607C46.2787 40.0417 45.8433 40.3935 45.345 40.6351C44.8466 40.8766 44.2986 41.0015 43.7434 41Z"
          fill="url(#paint2_linear_302_4657)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_302_4657"
          x1="18.0288"
          y1="74.7007"
          x2="18.0288"
          y2="76.7335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFCFB3" />
          <stop offset="1" stopColor="#C9AF7F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_302_4657"
          x1="11.1982"
          y1="70.3306"
          x2="11.1982"
          y2="72.3634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFCFB3" />
          <stop offset="1" stopColor="#C9AF7F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_302_4657"
          x1="47"
          y1="15"
          x2="47"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFCFB3" />
          <stop offset="1" stopColor="#C9AF7F" />
        </linearGradient>
        <clipPath id="clip0_302_4657">
          <rect width="93.8182" height="86" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
