import { Dialog } from "@mui/material";
import styled from "styled-components/macro";

import { NewFeed } from "models/post.model";
import { NewFeedItem } from "./NewFeedItem";
import { useEffect, useRef } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  list: NewFeed[];
  selectedId: string;
}

export const NewFeedsModal = ({ isOpen, onClose, list, selectedId }: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedId]);

  return (
    <BaseDialog open={isOpen} onClose={onClose} maxWidth="xl">
      <HeaderBox>{"New Feeds"}</HeaderBox>

      <Wrapper>
        {list
          .filter((i) => i.id === selectedId)
          .map((item) => (
            <div ref={selectedId === item.id ? headerRef : null} key={item.id}>
              <NewFeedItem data={item} key={item.id} />
            </div>
          ))}
      </Wrapper>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 32px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 20px 20px 0px;
  }
`;

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 820px;
  height: 100%;
  overflow-y: auto;
`;
