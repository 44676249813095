import { Dialog } from "@mui/material";

import styled from "styled-components/macro";
import { MediaFrame } from "views/main/Company/components";

interface Props {
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ViewFileModal = ({ url, isOpen, onClose }: Props) => {
  return (
    <BaseDialog open={isOpen} onClose={onClose} maxWidth="lg">
      <MediaFrame url={url} />
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  padding: 30px;

  .MuiPaper-root {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0px;
    box-shadow: none;
    border-radius: 16px;

    & > div {
      height: 100%;
      overflow: scroll;
    }

    .react-pdf__Document {
      position: unset;
    }
  }
`;
