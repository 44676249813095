import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { ReportIcon } from "assets/images/common";
import { Button, ButtonOutline, PageTitle, Text, TextField } from "components";
import { Combobox } from "components/Feature/Combobox";
import { DateRangePicker } from "components/Feature/DateRangePicker";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Option } from "models/common.model";
import { Asset, Report } from "models/report.model";
import {
  selectAssetList,
  selectReportLoading,
  selectSelectedReport,
} from "store/report/report.selector";
import { reportActions } from "store/report/report.slice";
import { ReportTable } from "./components";

const options = [
  { label: "Last quarter - Q4 2022", value: "Last quarter - Q4 2022" },
];

export const GenerateReport = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const { id: reportParamId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectReportLoading);
  const selectedReport = useAppSelector(selectSelectedReport);

  const schema = yup.object().shape({
    displayName: yup.string().required("Report name is Required"),
    startDate: yup.number().required("Start date is Required"),
    endDate: yup.number().required("End date is Required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const assetList = useAppSelector(selectAssetList);

  const assets = useMemo(() => {
    return assetList.map((item) => ({
      id: item.id,
      label: item.displayName,
      value: item.displayName,
      assetType: item.__typename,
    }));
  }, [assetList]);

  const [remainAsset, setRemainAsset] = useState(assets);

  const updateRemainAsset = useCallback(() => {
    const formData = watch && watch();
    const bestPerformerCompanies = formData?.bestPerformerCompanies || [];
    const worstPerformerCompanies = formData?.worstPerformerCompanies || [];
    const tableData = [...bestPerformerCompanies, ...worstPerformerCompanies];
    const remainAssets = assets.filter((asset) => {
      return !tableData.find((i) => i?.id === asset.id);
    });
    setRemainAsset(remainAssets);
  }, [assets, watch]);

  useEffect(() => {
    setRemainAsset(assets);
  }, [assets]);

  const onSubmit = (data: FieldValues) => {
    const payload = {
      id: data.id,
      displayName: data.displayName,
      data: {
        startDate: data.startDate,
        endDate: data.endDate,
        bestPerformerCompanies: data.bestPerformerCompanies.filter(
          (i: Asset) => i.id
        ),
        worstPerformerCompanies: data.worstPerformerCompanies.filter(
          (i: Asset) => i.id
        ),
      },
    };

    if (reportParamId) {
      dispatch(
        reportActions.updateReport({
          data: payload,
          callback: (report) => navigate(`/insights/reports/${report.id}`),
        })
      );
    } else {
      dispatch(
        reportActions.createReport({
          data: payload,
          callback: (report) => navigate(`/insights/reports/${report.id}`),
        })
      );
    }
  };

  useEffect(() => {
    if (reportParamId) {
      dispatch(reportActions.getReport(reportParamId as string));

      return () => {
        dispatch(reportActions.getReportSuccess({} as Report));
      };
    }
  }, [dispatch, reportParamId]);

  useEffect(() => {
    if (reportParamId) {
      for (const key in selectedReport) {
        if (key === "data") {
          const {
            startDate,
            endDate,
            bestPerformerCompanies,
            worstPerformerCompanies,
          } = selectedReport.data;

          setStartDate(dayjs(startDate));
          setEndDate(dayjs(endDate));
          setValue("startDate", startDate);
          setValue("endDate", endDate);
          bestPerformerCompanies.forEach((company, index) => {
            setValue(`bestPerformerCompanies[${index}]`, company);
            setValue(
              `bestPerformerCompanies[${index}].country`,
              company.country
            );
            setValue(`bestPerformerCompanies[${index}].sector`, company.sector);
            setValue(
              `bestPerformerCompanies[${index}].currentRound`,
              company.currentRound
            );
          });
          worstPerformerCompanies.forEach((company, index) => {
            setValue(`worstPerformerCompanies[${index}]`, company);
            setValue(
              `worstPerformerCompanies[${index}].country`,
              company.country
            );
            setValue(
              `worstPerformerCompanies[${index}].sector`,
              company.sector
            );
            setValue(
              `worstPerformerCompanies[${index}].currentRound`,
              company.currentRound
            );
          });
        } else {
          setValue(key, selectedReport[key as keyof Report]);
        }
      }

      setTimeout(() => {
        updateRemainAsset();
      }, 500);
    }
  }, [dispatch, reportParamId, selectedReport, setValue, updateRemainAsset]);

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="30px"
      >
        <PageTitle>Report</PageTitle>

        <ButtonOutline onClick={() => navigate(-1)}>Back</ButtonOutline>
      </Stack>

      {false && (
        <TopBar>
          <Combobox
            id="combobox-sort"
            freeSolo={false}
            options={options}
            onchange={() => null}
            value={{
              label: "Last quarter - Q4 2022",
              value: "Last quarter - Q4 2022",
            }}
            isOptionEqualToValue={(option, value) => {
              return (option as Option).value === (value as Option).value;
            }}
          />
        </TopBar>
      )}

      <Header>
        <Stack spacing="16px">
          <FieldGroup>
            <Text size="md" fontWeight={600}>
              Report Name
            </Text>

            <TextField
              placeholder="Fill Report Name"
              name="displayName"
              formRef={register}
              error={errors.displayName?.message as string}
            />
          </FieldGroup>

          <FieldGroup>
            <Text size="md" fontWeight={600}>
              Date
            </Text>

            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              maxStartDate={dayjs()}
              maxEndDate={dayjs()}
              startDateError={errors.startDate?.message as string}
              endDateError={errors.endDate?.message as string}
              onChangeStartDate={(value) => {
                setStartDate(value);
                setValue("startDate", value?.valueOf());
                trigger("startDate");
              }}
              onChangeEndDate={(value) => {
                setEndDate(value);
                setValue("endDate", value?.valueOf());
                trigger("endDate");
              }}
            />
          </FieldGroup>
        </Stack>
      </Header>

      <ReportTable
        id="bestPerformerCompanies"
        title="Choose your best performer"
        register={register}
        setValue={setValue}
        control={control}
        remainAsset={remainAsset}
        updateRemainAsset={updateRemainAsset}
        assetList={assetList}
      />

      <ReportTable
        id="worstPerformerCompanies"
        title="Choose your worst performer"
        register={register}
        setValue={setValue}
        control={control}
        remainAsset={remainAsset}
        updateRemainAsset={updateRemainAsset}
        assetList={assetList}
      />

      <Button
        type="submit"
        icon={<ReportIcon color="#FFFFFF" />}
        onClick={handleSubmit(onSubmit)}
        loading={loading}
      >
        {reportParamId ? "Update report" : "Generate report"}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  .combobox-wrapper {
    width: 300px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const FieldGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  & > div:first-child {
    min-width: 100px;
  }

  & > div:last-child {
    flex: 1;
  }
`;
