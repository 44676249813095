import { AvatarGroup, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import styled from "styled-components";

import {
  AttachmentIcon,
  CommentIcon,
  LikeIcon,
  ReplyIcon,
} from "assets/images/common";
import { Avatar, FlexBox, TextField } from "components";

export const InternalPost = () => {
  const [isOpen, setIsOpen] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  return (
    <Wrapper>
      <InfoWrapper>
        <Avatar
          src="https://bit.ly/ryan-florence"
          name="Spectre Biotech"
          size={44}
        />

        <TextWrapper>
          <div className="name">Dario Ferrante</div>
          <div className="sub">October 26, 2022</div>
        </TextWrapper>
      </InfoWrapper>

      <Content>
        <p>Proposed Сompany valuation for Q4 2022 - 14M €</p>
        <p>
          {
            "Based on the Q2-Q3 2022 trends from S&P 500 within the Healthcare sector which increased by 12%, I believe that Spectre's valuation has slightly increased."
          }
        </p>
      </Content>

      <ActionsWrapper>
        <FlexBox>
          {isOpen && (
            <AvatarGroup>
              {[...Array(3)].map((_, index) => (
                <Avatar
                  key={index}
                  src="https://bit.ly/ryan-florence"
                  name="Spectre Biotech"
                  size={32}
                />
              ))}
            </AvatarGroup>
          )}

          <ButtonIcon onClick={() => setIsOpen(!isOpen)}>
            <CommentIcon /> 2 Comments
          </ButtonIcon>
        </FlexBox>

        <ButtonIcon>
          <LikeIcon /> 8 Likes
        </ButtonIcon>
      </ActionsWrapper>

      {isOpen && (
        <ExpandContainer>
          <InputBar>
            <Avatar
              src="https://bit.ly/ryan-florence"
              name="Spectre Biotech"
              size={44}
            />

            <InputWrapper>
              <TextField placeholder="Write your comment here" />

              <IconButton onClick={() => inputFileRef.current?.click()}>
                <AttachmentIcon />
              </IconButton>

              <input ref={inputFileRef} type="file" hidden />
            </InputWrapper>
          </InputBar>

          <CommentListWrapper>
            {[...Array(2)].map((_, index) => (
              <CommentWrapper key={index}>
                <InfoWrapper gap={12}>
                  <Avatar
                    src="https://bit.ly/ryan-florence"
                    name="Spectre Biotech"
                    size={44}
                  />

                  <TextWrapper>
                    <div className="name">
                      Antony Branco Lopes <span>4h ago</span>
                    </div>
                    <div className="sub">
                      Art-directoCEO & Founder of Spectre Biotechr
                    </div>
                  </TextWrapper>
                </InfoWrapper>

                <div className="content">
                  <div className="text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </div>

                  <div className="actions">
                    <ButtonIcon>
                      <LikeIcon /> 2
                    </ButtonIcon>

                    <ButtonIcon>
                      <ReplyIcon /> Reply
                    </ButtonIcon>
                  </div>
                </div>
              </CommentWrapper>
            ))}
          </CommentListWrapper>
        </ExpandContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid #d8d9df;
`;

const ExpandContainer = styled.div`
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 1;

  input {
    padding-right: 40px;
  }

  button {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
`;

const CommentListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
  padding-left: 24px;

  & > div:last-child::after {
    display: none;
  }
`;

const CommentWrapper = styled.div`
  position: relative;

  &::after {
    position: absolute;
    bottom: -16px;
    left: 22px;
    width: 1px;
    height: calc(100% - 40px);
    background: #ebecf0;
    content: "";
  }

  .content {
    margin-top: 12px;
    padding-left: 58px;

    .text {
      margin-bottom: 12px;
      color: ${(props) => props.theme.palette.secondary.main};
      font-size: 14px;
    }

    .actions {
      display: flex;
      gap: 12px;
      margin-left: -12px;
    }
  }
`;

const InputBar = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const Content = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 16px;
  margin-bottom: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap = 16 }: { gap?: number }) => gap}px;
  margin-bottom: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .name {
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;

    span {
      color: #a5a7b4;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .sub {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 14px;
    line-height: 1.2;
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 14px;
  cursor: pointer;
`;
