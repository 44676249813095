import { yupResolver } from "@hookform/resolvers/yup";
import { FieldEditor, Text } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import {
  selectProspect,
  selectProspectLoading,
} from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";
import * as yup from "yup";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const descriptionData = [
  "Spectre developed the first cloud-based platform and software for rapid, affordable, and training-free brain imaging data processing. ",
  "Thanks toits automated algorithms and digitalized reports, Spectre reduces the price of a brain imaging report from $200 to $15 and its delivery from 3 months to 1 hour.",
  "Our mission is to democratize brain imaging, as blood testing is.",
];

export const About = () => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectProspectLoading);
  const { description, id } = useAppSelector(selectProspect);

  const schema = yup.object().shape({
    description: yup.string().nullable(true),
  });

  const { register, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("description", description);
  }, [description, setValue]);

  const handleUpdateField = async (data: FieldValues) => {
    try {
      if (!id) {
        dispatch(prospectActions.createProspect(data));
      } else {
        dispatch(
          prospectActions.updateProspect({ prospectUpdateId: id, input: data })
        );
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  return (
    <Wrapper>
      <Text size="xl" color="#050A30">
        About
      </Text>
      <DescriptionWrapper>
        {/* {descriptionData.map((item, index) => (
          <DescriptionLine key={index}>{item}</DescriptionLine>
        ))} */}
        <FieldEditor
          loading={submitLoading}
          onHandle={handleSubmit(handleUpdateField)}
          className="text-area-input"
          textElement={
            <DescriptionLine className="display-text">
              {getValues("description") || description || "Set description"}
            </DescriptionLine>
          }
          inputElement={
            <TextArea
              placeholder="Please input company description"
              rows={8}
              {...register("description")}
            />
          }
        ></FieldEditor>
      </DescriptionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 40px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DescriptionLine = styled.p`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 16px;
  margin: 0;
  white-space: pre-wrap;
`;

const TextArea = styled.textarea`
  width: 100%;
  font-family: inherit;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
`;
