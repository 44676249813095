import styled from "styled-components/macro";

import {
  Autocomplete,
  AutocompleteRenderOptionState,
  CircularProgress,
  FilterOptionsState,
  IconButton,
  PopperProps,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";

import { CloseIcon, DownArrowIcon, NoticeIcon } from "assets/images/common";
import { Text } from "components/UI/Typography/Text";
import { capitalizeFirstLetter } from "utils/system.util";

interface Props {
  id: string;
  options: unknown[];
  name?: string;
  fieldName?: string;
  value?: unknown;
  error?: string;
  freeSolo?: boolean;
  multiple?: boolean;
  placeholder?: string;
  popperPlacement?: PopperProps["placement"];
  disabled?: boolean;
  loading?: boolean;
  noOptionsText?: string;
  onchange?: (value: any) => void;
  onInputChange?: (value: string) => void;
  onBlur?: () => void;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  getOptionLabel?: (option: any) => string;
  filterOptions?:
    | ((options: any[], state: FilterOptionsState<any>) => any[])
    | undefined;
  onClear?: () => void;
}

export const Combobox = ({
  id,
  options,
  name,
  fieldName,
  value,
  error,
  freeSolo,
  placeholder,
  popperPlacement,
  multiple,
  disabled,
  loading,
  noOptionsText,
  onchange,
  onInputChange,
  onBlur,
  isOptionEqualToValue,
  renderOption,
  getOptionLabel,
  filterOptions,
  onClear,
}: Props) => {
  return (
    <Wrapper className="combobox-wrapper">
      {fieldName && (
        <Text size="md" fontWeight={600}>
          {fieldName}
        </Text>
      )}

      <InputWrapper>
        <Autocomplete
          className={error && "error"}
          disablePortal
          componentsProps={{
            popper: {
              placement: popperPlacement,
            },
          }}
          id={id}
          options={options}
          value={value}
          onChange={(_, value) => {
            if (value && onchange) {
              onchange(value);
            }
          }}
          renderInput={(props) => (
            <TextField
              label={name}
              placeholder={placeholder}
              {...props}
              InputProps={{
                ...props.InputProps,
                endAdornment: loading ? (
                  <CircularProgress
                    sx={{ position: "absolute", right: "10px" }}
                    color="secondary"
                    size={20}
                  />
                ) : (
                  props.InputProps.endAdornment
                ),
              }}
            />
          )}
          onInputChange={(_, value) => onInputChange && onInputChange(value)}
          freeSolo={freeSolo}
          renderOption={renderOption}
          popupIcon={
            !error && (
              <DownArrow>
                <DownArrowIcon />
              </DownArrow>
            )
          }
          clearIcon={
            onClear ? (
              <CloseButtonWrapper onClick={onClear}>
                <CloseIcon color="#a5a7b4" />
              </CloseButtonWrapper>
            ) : null
          }
          onBlur={onBlur}
          multiple={multiple}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          disabled={disabled}
          loading={loading}
          noOptionsText={noOptionsText}
          filterOptions={filterOptions}
        />

        {error && (
          <Error className="field-error">
            <ErrorTooltip
              title={capitalizeFirstLetter(error)}
              placement="top-end"
              sx={{ textTransform: "capitalize" }}
            >
              <IconButton>
                <NoticeIcon />
              </IconButton>
            </ErrorTooltip>
          </Error>
        )}
      </InputWrapper>
    </Wrapper>
  );
};

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: #f4f5f7;
  border-radius: 50%;

  svg {
    width: 8px;
    height: 8px;
  }
`;

const Error = styled.div`
  position: absolute;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FAFBFC",
    color: props.theme.palette.error.main,
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const DownArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  transition: all 0.5s;

  &:hover {
    background: #ebecf0;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  & > ${Error} {
    bottom: 4px;
    right: 4px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .MuiAutocomplete-root {
    .MuiAutocomplete-inputRoot {
      padding-left: 16px;
      padding-right: 16px;
      height: 48px;

      input {
        padding: 0px;
        color: ${(props) => props.theme.palette.primaryText.main};
      }
    }

    .MuiAutocomplete-clearIndicator {
      margin-right: 0px;
      padding: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ebecef;
      border-radius: 4px;
      transition: all 0.7s;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ebecef;
    }

    &.error .MuiOutlinedInput-notchedOutline {
      border: 1px solid;
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root {
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1px -1px,
        rgba(0, 0, 0, 0.14) 0px 0px 1px 0px, rgba(0, 0, 0, 0.12) 0px 0px 3px 0px;

      li {
        padding: 8px 16px;
        color: ${(props) => props.theme.palette.primaryText.main};

        &[aria-selected="true"] {
          background: #ebecf0;
        }

        &.Mui-focused {
          background: #ebecf0b3;
        }
      }
    }
  }
`;
