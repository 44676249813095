import * as yup from "yup";

import { FundraisingMediaEditor, SectionWrapper } from "components";

export const PitchDeckSection = () => {
  const stringYup = yup.string().nullable();

  return (
    <SectionWrapper title="Pitch Deck">
      <FundraisingMediaEditor
        inputFieldName="Link to video presentation"
        inputName="videoUrl"
        inputPlaceholder="https://"
        inputSchema={stringYup}
        attachmentName="pitchDeckUrl"
      />
    </SectionWrapper>
  );
};
