export enum NOTI_CODE {
  REVIEW_ORG = "NOTI-REVIEW-ORG",
  REVIEW_FUND = "NOTI-REVIEW-FUND",
  CHAT_DEFAULT = "NOTI-CHAT-DEFAULT",
  CHAT_INVEST = "NOTI-CHAT-INVEST",
  CHAT_REACH_OUT_FUNDS = "CHAT_REACH_OUT_FUNDS",
}

export enum MESSAGE_CODE {
  INVEST = "MESSAGE-DFCODE-INVEST",
  REACH_OUT_FUNDS = "MESSAGE-DFCODE-REACH_OUT",
}

export const NOTI_STRUCTS = {
  [NOTI_CODE.REVIEW_ORG]: ["code", "senderId", "orgId", "status"],
  [NOTI_CODE.REVIEW_FUND]: ["code", "senderId", "fundId", "status"],
  [NOTI_CODE.CHAT_DEFAULT]: ["code", "senderId", "roomId"],
  [NOTI_CODE.CHAT_INVEST]: ["code", "senderId", "roomId"],
  [NOTI_CODE.CHAT_REACH_OUT_FUNDS]: ["code", "senderId", "roomId"],
};

export const MESSAGE_STRUCTS = {
  [MESSAGE_CODE.INVEST]: [
    "code",
    "instrument",
    "ticket",
    "timeline",
    "message",
  ],
  [MESSAGE_CODE.REACH_OUT_FUNDS]: ["code", "fundsId", "message"],
};
