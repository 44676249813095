import { Text } from "components";
import styled from "styled-components/macro";
import LeftArrowIcon from "assets/images/company/left-arrow-icon.svg";
import DownArrowIcon from "assets/images/company/down-arrow-icon.svg";
import FileIcon from "assets/images/company/file-icon.svg";
import FolderIcon from "assets/images/company/folder-icon.svg";
import FolderYellowIcon from "assets/images/company/folder-yellow-icon.svg";
import { IconText } from "components/UI/Typography/IconText";
import { DownloadIcon } from "assets/images/common";

export const DataRoom = () => {
  return (
    <Wrapper>
      <Text size="xl" color="#050A30">
        Data Room
      </Text>
      <TableWrapper>
        <Table>
          <Row className="title-row">
            <NameCell>Name</NameCell>
            <ModifyCell>Last Modified</ModifyCell>
            <ModifyCell>File</ModifyCell>
          </Row>
          <Row className="single-row">
            <NameCell>Pitch Deck</NameCell>
            <ModifyCell>Sep 29, 2022</ModifyCell>
            <ModifyCell>
              <IconText icon={<DownloadIcon />}>Download</IconText>
            </ModifyCell>
          </Row>
          <Row className="collapse-row">
            <NameCell>Articles of Incorporation</NameCell>
            <ModifyCell>Oct 4, 2022</ModifyCell>
            <ModifyCell></ModifyCell>
          </Row>
          <Row className="expand-row">
            <NameCell>Articles of Association</NameCell>
            <ModifyCell>Oct 7, 2022</ModifyCell>
            <ModifyCell></ModifyCell>
          </Row>
          <Row className="single-row child">
            <NameCell>Pitch Deck</NameCell>
            <ModifyCell>Oct 7, 2022</ModifyCell>
            <ModifyCell>
              <IconText icon={<DownloadIcon />}>Download</IconText>
            </ModifyCell>
          </Row>
          <Row className="single-row child">
            <NameCell>Pitch Deck</NameCell>
            <ModifyCell>Oct 7, 2022</ModifyCell>
            <ModifyCell>
              <IconText icon={<DownloadIcon />}>Download</IconText>
            </ModifyCell>
          </Row>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow-x: hidden;

  & > div:first-child {
    font-weight: 600;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  overflow-x: auto;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  width: clamp(600px, 100vw, 100%);
`;

const NameCell = styled.div`
  flex-grow: 1;
`;

const ModifyCell = styled.div`
  width: 140px;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #ebecef;

  & > div {
    padding: 10px;
  }

  &.title-row {
    font-weight: 600;
  }

  &.single-row {
    ${NameCell} {
      position: relative;
      padding-left: 24px;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url(${FileIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }

    &.child {
      ${NameCell} {
        padding-left: 56px;
        &::after {
          left: 30px;
        }
      }
    }
  }

  &.collapse-row,
  &.expand-row {
    ${NameCell} {
      position: relative;
      padding-left: 56px;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url(${LeftArrowIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        left: 28px;
        width: 18px;
        height: 18px;
        background-image: url(${FolderIcon});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  &.expand-row {
    font-weight: 600;
    color: ${(props) => props.theme.palette.primary.main};
    ${NameCell} {
      &::after {
        background-image: url(${DownArrowIcon});
      }
      &::before {
        background-image: url(${FolderYellowIcon});
      }
    }
  }
`;
