import { Avatar as MuiAvatar } from "@mui/material";
import styled from "styled-components";
import { stringAvatar } from "utils/system.util";

interface CustomAvatarProps {
  size?: number;
  rounded?: string;
}

interface Props extends CustomAvatarProps {
  src?: string;
  name?: string;
}

export const Avatar = ({ src, name, ...props }: Props) => {
  if (src) {
    return <CustomAvatar src={src} {...props} />;
  }

  if (name) {
    return <CustomAvatar {...props}>{stringAvatar(name)}</CustomAvatar>;
  }

  return <CustomAvatar {...props} />;
};

const CustomAvatar = styled(MuiAvatar)`
  width: ${({ size = 40 }: CustomAvatarProps) => size}px;
  height: ${({ size = 40 }: CustomAvatarProps) => size}px;
  border-radius: ${({ rounded = "50%" }: CustomAvatarProps) => rounded};
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${({ size = 40 }: CustomAvatarProps) =>
    size / 2.4 < 10 ? 10 : size / 2.4}px;

  &.MuiAvatar-colorDefault {
    background: #ebecef;
  }
`;
