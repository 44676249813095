import { TablePagination } from "@mui/material";
import { BasicTable } from "components";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks/useHook";
import _ from "lodash";
import { SortConfig, TBD } from "models/common.model";
import { Prospect } from "models/prospect.model";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { selectSearchProspect } from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled, { css } from "styled-components";
import { fDate } from "utils/time.util";

const CONFIG = [
  {
    name: "Company name",
    width: "auto",
  },
  {
    name: "Round",
    width: 120,
  },
  {
    name: "Status",
    width: 120,
    fieldName: "status",
    isSort: true,
  },
  {
    name: "Updated",
    width: 120,
    fieldName: "updatedAt",
    isSort: true,
  },
];

export const AdminViewPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const prospects = useAppSelector(selectSearchProspect);
  const [rawData, setRawData] = useState<Prospect[]>([]);
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleSort = (sort: SortConfig) => {
    setRawData(
      _.orderBy(
        (rawData?.length && rawData) || [],
        [sort.name],
        [sort.type || "asc"]
      )
    );
  };

  useEffect(() => {
    dispatch(prospectActions.getProspects({ isAdmin: true }));
  }, [dispatch]);

  useEffect(() => {
    setRawData(prospects);
  }, [prospects]);

  useEffect(() => {
    const tempData = rawData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item) => {
        return {
          rowClick: () => navigate(`/admin/admin-view/${item.id}`),
          companyName: (
            <GroupCell>
              {item.logoUrl ? (
                <Logo src={item.logoUrl} />
              ) : (
                <PlaceholderSpace />
              )}
              {"  "}
              {item.displayName}
            </GroupCell>
          ),
          round: <GrayFlag>{item.currentRound?.name || "Empty"}</GrayFlag>,
          status:
            item.status === "draft" ? (
              <GrayFlag>{item.status}</GrayFlag>
            ) : item.status === "review" ? (
              <YellowFlag>{item.status}</YellowFlag>
            ) : (
              <GreenFlag>{item.status}</GreenFlag>
            ),
          updated: fDate(Number(item.updatedAt)),
        };
      });
    setTableData(tempData);
  }, [navigate, page, rawData, rowsPerPage]);

  return (
    <Wrapper>
      <Helmet>
        <title>MyFoundry</title>
        <link rel="icon" href="/logo.png" />
      </Helmet>
      <BasicTable config={CONFIG} data={tableData} onSort={handleSort} />

      {rawData.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={rawData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            setPage(0);
          }}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  padding-bottom: 10px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  & > .MuiPaper-root {
    flex: 1;
  }
`;

const GroupCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
`;

const baseFlagStyles = css`
  padding: 5px 10px;
  width: fit-content;
  border-radius: 8px;
`;

const GrayFlag = styled.div`
  ${baseFlagStyles}
  background-color: #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
`;

const YellowFlag = styled.div`
  ${baseFlagStyles}
  background-color: rgba(253, 209, 80, 0.1);
  color: #ca9600;
`;

const GreenFlag = styled.div`
  ${baseFlagStyles}
  background-color: rgba(52, 168, 83, 0.1);
  color: #2a8944;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
`;

const PlaceholderSpace = styled.div`
  height: 40px;
  width: 40px;
  background-color: #ebecef;
`;
