import { Dialog } from "@mui/material";
import { Button, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { useDispatch } from "react-redux";
import { selectNotificationModal } from "store/global/global.selector";
import { globalActions } from "store/global/global.slice";
import styled from "styled-components/macro";

export const NotificationModal = () => {
  const dispatch = useDispatch();
  const { isShow, onSubmit, title } = useAppSelector(selectNotificationModal);

  const handleClose = () => {
    dispatch(
      globalActions.showNotificationModal({
        isShow: false,
        title: "",
      })
    );
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(handleClose);
  };
  return (
    <BaseDialog open={isShow} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <HeaderBox>
          <Text size="md" color="#050a30">
            {title}
          </Text>
        </HeaderBox>
        <GroupButton>
          <Button onClick={handleSubmit}>Confirm</Button>
        </GroupButton>
      </Wrapper>
    </BaseDialog>
  );
};
const BaseDialog = styled(Dialog)`
  padding: 30px;
  .MuiPaper-root {
    border-radius: 32px;
    margin: 0;
    width: 420px;
    min-height: 215px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  padding-top: 30px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;
`;

const HeaderBox = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;

  button {
    border-radius: 16px;
    padding: 0 20px;
  }
`;
