import { CircularProgress } from "@mui/material";
import styled from "styled-components";

interface Props {
  value: number;
}

export const CircleProgress = ({ value }: Props) => {
  return (
    <Wrapper>
      <CircularProgress
        className="background"
        variant="determinate"
        value={100}
        size={120}
        thickness={2.4}
      />
      <CircularProgress
        className="foreground"
        variant="determinate"
        value={value}
        size={120}
        thickness={2.4}
      />

      <span className="circle-progress__text">{Math.round(value)}%</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 120px;
  height: 120px;

  .background,
  .foreground {
    position: absolute;
  }
  .foreground .MuiCircularProgress-circle {
    color: ${(props) => props.theme.palette.primary.main};
  }
  .background .MuiCircularProgress-circle {
    color: #ebecef;
  }

  .circle-progress__text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 700;
    font-size: 24px;
    transform: translate(-50%, -50%);
  }
`;
