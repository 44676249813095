import { Text } from "components";
import styled from "styled-components/macro";

const ForgotPasswordHeader = () => {
  return (
    <Wrapper>
      <Text size="xxl" className="header-text">
        Forgot password
      </Text>
    </Wrapper>
  );
};

export { ForgotPasswordHeader };

const Wrapper = styled.div`
  margin-bottom: 30px;
  .header-text {
    text-align: center;
  }
`;
