import React from "react";
import styled from "styled-components/macro";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  icon?: React.ReactNode;
}

export const IconText = ({ children, icon, ...props }: Props) => {
  return (
    <Wrapper {...props} className="icon-text">
      {icon}
      <span className="text">{children}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  font-size: 16px;
`;
