import { IconButton, Popover } from "@mui/material";
import { MenuIcon } from "assets/images/common";
import { useRef, useState } from "react";
import styled from "styled-components/macro";
import LeftMenu from "./LeftMenu";

const MobileMenu = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const settingsRef = useRef(null);
  return (
    <Wrapper className="mobile-menu">
      <IconButton
        onClick={() => setOpenMobileMenu(!openMobileMenu)}
        ref={settingsRef}
      >
        <MenuIcon />
      </IconButton>

      <Popover
        anchorEl={settingsRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={() => {
          setOpenMobileMenu(!openMobileMenu);
        }}
        open={openMobileMenu}
        PaperProps={{
          sx: { width: "256px", top: "65px !important", left: "0 !important" },
        }}
      >
        <LeftMenu />
      </Popover>
    </Wrapper>
  );
};

export default MobileMenu;

const Wrapper = styled.div`
  display: flex;
`;
