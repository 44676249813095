import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  color?: string;
}

export const PlusIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0294 7.8316C13.4643 7.8316 13.8169 7.47903 13.8169 7.0441C13.8169 6.60918 13.4643 6.2566 13.0294 6.2566L7.83177 6.2566L7.83177 1.05898C7.83177 0.62406 7.4792 0.271484 7.04427 0.271484C6.60935 0.271484 6.25677 0.62406 6.25677 1.05898L6.25677 6.2566L1.05919 6.2566C0.624267 6.2566 0.271692 6.60918 0.271692 7.0441C0.271692 7.47903 0.624267 7.8316 1.05919 7.8316L6.25677 7.8316L6.25677 13.0292C6.25677 13.4641 6.60935 13.8167 7.04427 13.8167C7.4792 13.8167 7.83177 13.4641 7.83177 13.0292L7.83177 7.8316L13.0294 7.8316Z"
        fill={color}
      />
    </svg>
  );
};
