import { gql } from "graphql-request";
import { CustomPostParam, GetListPostParam } from "models/post.model";

import { graphqlRequest } from "utils/graphQL.util";

const postApi = {
  getPosts(params: GetListPostParam) {
    const variables = {
      filter: {
        namespace: params.filter.namespace || "Own",
        postedById: params.filter?.postedById || null,
        status: params.filter?.status || null,
      },
      sort: params.sort,
    };

    const query = gql`
      query PostListGet($filter: postFilter!, $sort: sort) {
        postListGet(filter: $filter, sort: $sort) {
          ... on PostListSuccess {
            posts {
              id
              title
              body
              postedBy {
                displayName
                logoUrl
              }
              createdAt
              updatedAt
              attachment
            }
          }
          ... on NoPostsFound {
            message
          }
          ... on PostError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
          ... on SystemError {
            message
            errorCode
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  createPost(organisationId: string, input: CustomPostParam) {
    const variables = {
      organisationId,
      input: { ...input, status: "published" },
    };

    const query = gql`
      mutation PostCreate($organisationId: ID!, $input: postCreateInput) {
        postCreate(organisationId: $organisationId, input: $input) {
          ... on PostSuccess {
            post {
              id
              title
              body
              attachment
              createdAt
              updatedAt
            }
          }
          ... on PostError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  updatePost(postId: string, input: CustomPostParam) {
    const variables = {
      postId,
      input: { ...input, status: "published" },
    };

    const query = gql`
      mutation PostUpdate($postId: ID!, $input: postUpdateInput) {
        postUpdate(postId: $postId, input: $input) {
          ... on PostSuccess {
            post {
              id
              title
              body
              attachment
              createdAt
              updatedAt
            }
          }
          ... on PostError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  deletePost(postId: string) {
    const variables = {
      postId,
    };

    const query = gql`
      mutation PostDelete($postId: ID!) {
        postDelete(postId: $postId) {
          ... on PostDeleteSuccess {
            postId
          }
          ... on PostError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },
};

export default postApi;
