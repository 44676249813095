import { Stack } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { DownloadIcon, EditIcon, PrintIcon } from "assets/images/common";
import {
  BarChart,
  ButtonOutline,
  DoughnutChart,
  PageTitle,
  PieChart,
  Text,
} from "components";
import { Combobox } from "components/Feature/Combobox";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Option } from "models/common.model";
import { Report } from "models/report.model";
import { selectAppConfig } from "store/global/global.selector";
import { globalActions } from "store/global/global.slice";
import { selectSelectedReport } from "store/report/report.selector";
import { reportActions } from "store/report/report.slice";
import { downloadPageAsPDF } from "utils/app.util";
import { fDate } from "utils/time.util";
import { ChartSection, ReportTable } from "./components";

const options = [
  { label: "Last quarter - Q4 2022", value: "Last quarter - Q4 2022" },
];

export const ReportDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: reportParamId } = useParams();
  const pageRef = useRef<HTMLDivElement>(null);

  const { headerLogo, brandName } = useAppSelector(selectAppConfig);
  const { id, displayName, data } = useAppSelector(selectSelectedReport);
  const {
    startDate,
    endDate,
    bestPerformerCompanies = [],
    worstPerformerCompanies = [],
  } = data || {};

  const reportList = [...bestPerformerCompanies, ...worstPerformerCompanies];

  const getChartData = (key: "country" | "sector" | "currentRound") => {
    const listFilter = _.flatten(reportList.map((item) => item[key]));
    const countedObject = _.countBy(
      listFilter,
      (item) => item?.name || "Unknown"
    );
    const sortedArray = _.orderBy(
      Object.entries(countedObject),
      ([, value]) => value,
      "desc"
    );
    const firstFive = sortedArray.slice(0, 5);
    const remain = sortedArray.slice(5);
    const remainingSum = _.sumBy(sortedArray.slice(5), ([, value]) => value);

    const data =
      sortedArray.length > 5
        ? _.fromPairs([...firstFive, ["Others", remainingSum]])
        : _.fromPairs(sortedArray);

    return {
      labels: Object.keys(data),
      data: Object.values(data),
      others: remain.length ? _.fromPairs(remain) : undefined,
    };
  };

  const getCapitalInvestedChart = () => {
    const yearGroup = _.groupBy(
      reportList,
      (item) => item.vintageYear || "Unknown"
    );
    const dataGroup = _.groupBy(
      reportList,
      (item) => item["currentRound"]?.name || "Unknown"
    );

    const datasets = Object.entries(dataGroup).map((item) => ({
      label: item[0],
      data: Array(Object.keys(yearGroup).length).fill(null),
    }));

    Object.entries(yearGroup).forEach((item, index) => {
      const dataCount = _.mapValues(
        _.groupBy(item[1], (company) => company.currentRound?.name),
        (item) => _.sumBy(item, (company) => +company.capitalInvested || 0)
      );

      for (const name in dataCount) {
        const matched = datasets.find((item) => item.label === name);

        if (matched) {
          matched.data[index] = dataCount[name];
        }
      }
    });

    return { labels: Object.keys(yearGroup), datasets };
  };

  useEffect(() => {
    if (reportParamId) {
      dispatch(reportActions.getReport(reportParamId as string));

      return () => {
        dispatch(reportActions.getReportSuccess({} as Report));
      };
    }
  }, [dispatch, reportParamId]);

  return (
    <Wrapper ref={pageRef}>
      <Stack
        className="app-logo"
        direction="row"
        alignItems="center"
        gap="16px"
        marginBottom="32px"
      >
        {headerLogo ? <LogoWrapper src={headerLogo} /> : <LogoPlaceholder />}
        <LogoText>{brandName}</LogoText>
      </Stack>

      <ReportHead>
        <Stack direction="row" alignItems="center" gap="16px">
          <PageTitle>{displayName}</PageTitle>

          {startDate && endDate && (
            <Text size="lg" fontSize="20px">
              ({fDate(startDate, "DD/MM/YYYY")} - {fDate(endDate, "DD/MM/YYYY")}
              )
            </Text>
          )}
        </Stack>

        <ButtonOutline
          className="pdf-to-hidden"
          icon={<EditIcon />}
          onClick={() => navigate(`/insights/assets/generate-report/${id}`)}
        >
          Edit
        </ButtonOutline>
      </ReportHead>

      <TopBar className="pdf-to-hidden">
        <ActionWrapper>
          {false && <ButtonOutline icon={<PrintIcon />}>Print</ButtonOutline>}

          <ButtonOutline
            className="download-pdf-btn"
            icon={<DownloadIcon />}
            onClick={() => {
              if (!pageRef.current) return;

              downloadPageAsPDF(pageRef.current, displayName, (loading) =>
                dispatch(globalActions.setPageLoading(loading))
              );
            }}
          >
            Export in PDF
          </ButtonOutline>
        </ActionWrapper>

        {false && (
          <Combobox
            id="combobox-sort"
            freeSolo={false}
            options={options}
            onchange={() => null}
            value={{
              label: "Last quarter - Q4 2022",
              value: "Last quarter - Q4 2022",
            }}
            isOptionEqualToValue={(option, value) =>
              (option as Option).value === (value as Option).value
            }
          />
        )}
      </TopBar>

      <ChartWrapper>
        <ChartSection title="Portfolio Breakdown" label="Sector">
          <DoughnutChart
            labels={getChartData("sector").labels}
            data={getChartData("sector").data}
            othersTooltipData={getChartData("sector").others}
          />
        </ChartSection>

        <ChartSection title="Portfolio Breakdown" label="Country">
          <DoughnutChart
            labels={getChartData("country").labels}
            data={getChartData("country").data}
            othersTooltipData={getChartData("country").others}
          />
        </ChartSection>
      </ChartWrapper>

      <ChartWrapper column={2}>
        <ChartSection title="Capital Invested" label="Vintage year">
          <BarChart
            labels={getCapitalInvestedChart().labels}
            datasets={getCapitalInvestedChart().datasets}
          />
        </ChartSection>

        <ChartSection title="Portfolio Breakdown" label="Stage">
          <PieChart
            labels={getChartData("currentRound").labels}
            data={getChartData("currentRound").data}
            othersTooltipData={getChartData("currentRound").others}
          />
        </ChartSection>
      </ChartWrapper>

      <Stack spacing="32px" className="pdf-to-next-page">
        <ReportTable
          title="Best performer"
          data={bestPerformerCompanies}
          isPreview
        />

        <ReportTable
          title="Worst performer"
          data={worstPerformerCompanies}
          isPreview
        />
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  .combobox-wrapper {
    width: 300px;
  }

  .app-logo {
    display: none;
  }

  &.page-as-pdf--processing {
    .app-logo {
      display: flex;
    }

    .pdf-to-hidden {
      display: none;
    }

    .pdf-to-next-page {
      margin-top: 240px;
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  .combobox-wrapper {
    max-width: 300px;
  }

  .download-pdf-btn {
    width: 180px;
  }
`;

const LogoWrapper = styled.img`
  width: auto;
  height: 64px;
  border-radius: 4px;
`;

const LogoPlaceholder = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 4px;
  background-color: #ebecef;
`;

const LogoText = styled.div`
  color: ${(props) => props.theme.palette.primaryText.dark};
  font-family: "Times New Roman", Times, serif;
  font-size: 38px;
  font-weight: 700;
  user-select: none;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ column = 2 }: { column?: number }) =>
    `repeat(${column}, 1fr)`};
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;

const ReportHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;
