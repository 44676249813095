import { useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import styled from "styled-components";

import { CheckIcon } from "assets/images/common";
import { SectionWrapper } from "components";
import { useDebounce } from "hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { CachedResName, FixedData } from "models/cached.model";
import { SelectItem, SelectItemTask } from "models/common.model";
import { Fundraising } from "models/fundraising.model";
import { selectAuthMode } from "store/auth/auth.selector";
import { CachedState, cachedActions } from "store/cached/cached.slice";
import {
  selectFundraising,
  selectPossibleEditFundraising,
} from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";

interface Props {
  title?: string;
  subTitle: string;
  name: string;
  resName: CachedResName;
  inline?: boolean;
  callApi: () => void;
  selector: (state: { cached: CachedState }) => FixedData;
}

interface ListWrapperProps {
  isShowAll?: boolean;
  inline?: boolean;
  disabled?: boolean;
}

export const SelectSection = ({
  title,
  subTitle,
  name,
  resName,
  inline,
  callApi,
  selector,
}: Props) => {
  const [isShowAll, setIsShowAll] = useState(false);
  const [selectList, setSelectList] = useState<SelectItem[]>([]);

  const dispatch = useAppDispatch();

  const debouncedValue = useDebounce<SelectItem[]>(selectList, 1500);
  const selectedFundraising = useAppSelector(selectFundraising);
  const isPossibleEditFundraising = useAppSelector(
    selectPossibleEditFundraising
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = [AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(
    authMode
  );
  const fixedData = useAppSelector(selector);

  const selectedList = selectedFundraising[
    name as keyof Fundraising
  ] as SelectItem[];

  const handleSelect = (id: string) => {
    const newList = selectList.map((item) =>
      item.id === id ? { ...item, check: !item.check } : item
    );

    setSelectList(newList);
  };

  const handleUpdate = useCallback(
    (data: FieldValues | { [key: string]: SelectItemTask[] }) => {
      if (selectedFundraising?.id) {
        dispatch(
          fundraisingActions.updateFundraising({
            id: selectedFundraising.id,
            input: data,
          })
        );
      }
    },
    [dispatch, selectedFundraising.id]
  );

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName,
        callApi,
        updateAt: fixedData.updateAt,
      })
    );
  }, [callApi, dispatch, fixedData.updateAt, resName]);

  useEffect(() => {
    if (selectedList) {
      setSelectList(
        fixedData.data.map((i) =>
          selectedList.find((s) => s.id === i.id) ? { ...i, check: true } : i
        )
      );
    } else {
      setSelectList(fixedData.data);
    }
  }, [fixedData.data, selectedList]);

  useEffect(() => {
    if (!selectedList || !debouncedValue.length) return;

    const checkedList = debouncedValue.filter((i) => i.check);

    const addList: SelectItemTask[] = checkedList
      .filter((i) => !selectedList.find((s) => s.id === i.id))
      .map((m) => ({ id: m.id, task: "ADD" }));

    const removeList: SelectItemTask[] = selectedList
      .filter((i) => !checkedList.find((s) => s.id === i.id))
      .map((m) => ({ id: m.id, task: "REMOVE" }));

    addList.length && handleUpdate({ [name]: addList });
    removeList.length && handleUpdate({ [name]: removeList });
  }, [debouncedValue, handleUpdate, name, selectedList]);

  const children = (
    <Container>
      <div className="sub-title">{subTitle}</div>

      <ListWrapper
        isShowAll={isShowAll}
        inline={inline}
        disabled={isAdmin || !isPossibleEditFundraising}
      >
        {selectList.map((item) => {
          return (
            <ListItem
              key={item.id}
              className={item.check ? "checked" : undefined}
              onClick={() => handleSelect(item.id)}
            >
              {item.check && (
                <IconWrapper>
                  <CheckIcon color="#6a6d84" />
                </IconWrapper>
              )}
              {item.name}
            </ListItem>
          );
        })}
      </ListWrapper>

      {!isShowAll && !inline && (
        <div className="show-btn" onClick={() => setIsShowAll(true)}>
          Show all
        </div>
      )}
    </Container>
  );

  return inline ? (
    <Container>{children}</Container>
  ) : (
    <SectionWrapper title={title}>{children}</SectionWrapper>
  );
};

const Container = styled.div`
  .sub-title {
    margin-bottom: 12px;
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 14px;
    font-weight: 600;
  }

  .show-btn {
    width: fit-content;
    margin-top: 24px;
    color: ${(props) => props.theme.palette.secondary.dark};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-height: ${({ isShowAll, inline }: ListWrapperProps) =>
    inline ? "unset" : isShowAll ? "unset" : "127px"};
  overflow: hidden;
  padding-top: 10px;
  pointer-events: ${({ disabled }: ListWrapperProps) =>
    disabled ? "none" : "unset"};
`;

const ListItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border: 1px solid #d8d9df;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 14px;
  cursor: pointer;

  &.checked {
    border-color: #ebecf0;
    background: #ebecf0;
  }

  &:hover {
    border-color: #ebecf0;
    background: #ebecf0;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ebecf0;
  background: #ffffff;

  svg {
    width: 10px;
    height: 10px;
  }
`;
