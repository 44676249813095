import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const BoltIcon = ({ color = "#D8D9DF" }: Props) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.15001 12L2.83334 7.3H0.400008C0.277786 7.3 0.188897 7.24444 0.133341 7.13333C0.0777859 7.02222 0.0777859 6.91667 0.133341 6.81667L4.21667 0H4.90001L4.21667 4.68333H6.61667C6.7389 4.68333 6.83056 4.73889 6.89167 4.85C6.95279 4.96111 6.95556 5.06667 6.90001 5.16667L2.83334 12H2.15001Z"
        fill={color}
      />
    </svg>
  );
};
