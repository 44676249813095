import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { LocationIcon } from "assets/images/company";
import DefaultProspectLogo from "assets/images/workspace/dashboard-card-logo.png";
import { PageWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectFunds } from "store/feed/feed.selector";
import { scrollLoadMore } from "utils/app.util";
import { formatCurrency } from "utils/system.util";
import { ListTag } from "../DealFlow/components/ListTag";

export const FundsPage = () => {
  const funds = useAppSelector(selectFunds);
  const navigate = useNavigate();

  const handleLoadMoreItem = (event: Event | undefined) => {
    scrollLoadMore(event, () => {
      console.log("get more list");
    });
  };

  useEffect(() => {
    const pageContainerElem =
      document.getElementsByClassName("page-container")?.[0];
    pageContainerElem &&
      pageContainerElem?.addEventListener("scroll", handleLoadMoreItem);
    return () =>
      pageContainerElem?.removeEventListener("scroll", handleLoadMoreItem);
  }, []);

  return (
    <PageWrapper>
      <Cards>
        {funds.map((item) => (
          <CardItem key={item.id} onClick={() => navigate(`/funds/${item.id}`)}>
            <CardSection className="header-section">
              <TitleHeader>
                <CardLogo src={item.logoUrl || DefaultProspectLogo} />
                <CardName>{item.displayName}</CardName>
              </TitleHeader>
              <CountryBox>
                <LocationIcon />
                {item?.country?.name && (
                  <Text size="md">{item?.country.name}</Text>
                )}
              </CountryBox>
              <Text size="md" className="limit-two-line">
                {item.slogan}
              </Text>
              <ListTag data={item.sector} limit={3} />
            </CardSection>
            <CardSection className="price-section">
              <Text size="md" className="light-text">
                Raising now:
              </Text>
              {!!item?.roundSize && (
                <Text size="lg">{formatCurrency(item.roundSize)}</Text>
              )}
            </CardSection>
          </CardItem>
        ))}
      </Cards>
    </PageWrapper>
  );
};

const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
`;

const Cards = styled.div`
  display: grid;
  padding-top: 10px;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${(props) => props.theme.breakpoints.down("xl")} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 12px;
  gap: 20px;
  height: 360px;
  width: 100%;
  border-radius: 32px;
  background-color: white;
  box-shadow: 0px 3px 5px rgba(5, 10, 48, 0.05);
  cursor: pointer;
  transition: all 0.8s;

  .header-section {
    height: 230px;
  }

  .price-section {
    height: 60px;
  }

  &:hover {
    opacity: 0.9;
    transform: scale(1.02);
  }
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
  padding-top: 10px;
  position: relative;
  border-top: 1px solid #ebecef;

  &:first-child {
    border: none;
  }

  .limit-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .light-text {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const CardLogo = styled.img`
  width: auto;
  height: 60px;
  border-radius: 16px;
`;

const CardName = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.dark};
`;

const CountryBox = styled.div`
  display: flex;
  justify-items: center;
  gap: 10px;
`;
