import { CreateWorkspaceParams } from "./../../models/workspace.model";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NEW_WORKSPACES } from "constants/app.const";
import { Workspace } from "models/workspace.model";
import { LocalStorage } from "utils/localStorage.util";

export interface WorkspaceState {
  activeWorkspace: Workspace;
  workspaces: Workspace[];
  loading: boolean;
}

export const initialState: WorkspaceState = {
  activeWorkspace: {} as Workspace,
  workspaces: [] as Workspace[],
  loading: false,
};

const authSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    createWorkspace(state, _action: PayloadAction<CreateWorkspaceParams>) {
      state.loading = true;
    },
    createWorkspaceFailed(state) {
      state.loading = false;
    },
    createWorkspaceSuccess(state, _action: PayloadAction<Workspace>) {
      const workspaces = state.workspaces;
      workspaces.push(_action.payload);
      state.workspaces = workspaces;
      state.loading = false;

      const newWorkspaces = LocalStorage.get(NEW_WORKSPACES) || [];
      newWorkspaces.push(_action.payload.id);
      LocalStorage.set(newWorkspaces, NEW_WORKSPACES);
    },
    getWorkspaces() {},
    getWorkspacesFailed() {},
    getWorkspacesSuccess(state, _action: PayloadAction<Workspace[]>) {
      state.workspaces = _action.payload;
    },
    getWorkspace() {},
    getWorkspaceFailed() {},
    getWorkspaceSuccess(state, _action: PayloadAction<Workspace>) {
      state.activeWorkspace = _action.payload;
    },
    deleteWorkspace(_, _action: PayloadAction<{ organisationId?: string }>) {},
    updateWorkspace(
      state,
      _action: PayloadAction<{
        input: CreateWorkspaceParams;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    updateWorkspaceSuccess(state, _action: PayloadAction<Workspace>) {
      const modifiedIndex = state.workspaces.findIndex(
        (w) => w.id === _action.payload.id
      );

      if (modifiedIndex !== -1) {
        state.workspaces[modifiedIndex] = _action.payload;
      }

      state.loading = false;
    },
    updateWorkspaceFailed(state) {
      state.loading = false;
    },
  },
});
export const {
  actions: workspaceActions,
  reducer: workspaceReducer,
  name: sliceKey,
} = authSlice;
