import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { Avatar, Skeleton } from "components";
import { useAppSelector } from "hooks/useHook";
import { Notification, User } from "models/chat.model";
import { StatusIndicator } from "models/common.model";
import { selectNamespaceId } from "store/global/global.selector";
import { selectAppConfig } from "store/global/global.selector";
import { db } from "utils/firebase.util";

interface Props {
  data: Notification;
  onClick?: () => void;
}

dayjs.extend(relativeTime);

export const NotificationItem = ({ data, onClick }: Props) => {
  const [guest, setGuest] = useState<User>();

  const { headerLogo, brandName } = useAppSelector(selectAppConfig);
  const namespaceId = useAppSelector(selectNamespaceId);

  const {
    senderId,
    receiverId,
    isRead,
    unreadCount,
    createdAt,
    status,
    isChatInvest,
    isReachOutFunds,
    isReviewOrg,
    isReviewFund,
  } = data;

  useEffect(() => {
    const docRef = doc(db, "users", senderId);

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const { type, displayName, avatar } = doc.data();

        setGuest({
          ...doc.data(),
          id: doc.id,
          displayName: type === "admin" ? `${brandName} Admin` : displayName,
          avatar: type === "admin" ? headerLogo : avatar,
        } as User);
      }
    });

    return unsubscribe;
  }, [brandName, headerLogo, senderId]);

  const getNotificationText = () => {
    if (isChatInvest) {
      return "sent you an investment proposal";
    }

    if (isReachOutFunds) {
      return "reached out to you";
    }

    if (isReviewOrg) {
      if (receiverId === namespaceId) {
        if (status === StatusIndicator.DRAFT) {
          return `has just unpublished their company`;
        }
        if (status === StatusIndicator.REVIEW) {
          return `has just submitted their company`;
        }

        return "just updated the status of their company";
      }

      if (status === StatusIndicator.REVIEW) {
        return `has just updated the status of your company to "Review"`;
      }
      if (status === StatusIndicator.PUBLISHED) {
        return `has just updated the status of your company to "Published"`;
      }
      if (status === StatusIndicator.FLAGGED) {
        return "has just rejected your company";
      }

      return "has just updated the status of your company";
    }

    if (isReviewFund) {
      if (receiverId === namespaceId) {
        if (status === StatusIndicator.REVIEW) {
          return `has just submitted their funding round`;
        }

        return "just updated the status of their company";
      }

      if (status === StatusIndicator.REVIEW) {
        return `has just updated the status of funding round to "Review"`;
      }
      if (status === StatusIndicator.PUBLISHED) {
        return `has just updated the status of funding round to "Published"`;
      }
      if (status === StatusIndicator.FLAGGED) {
        return "has just rejected your funding round";
      }

      return "has just updated the status of your funding round";
    }

    return `has left you ${unreadCount} new message${
      unreadCount > 1 ? "s" : ""
    }`;
  };

  return (
    <Wrapper
      className={`notification-item ${isRead ? undefined : "unread"}`}
      onClick={onClick}
    >
      <InfoWrapper>
        {guest ? (
          <Avatar src={guest.avatar} name={guest.displayName} size={46} />
        ) : (
          <Skeleton variant="circular" width="46px" height="46px" />
        )}

        <TextInfo>
          <div className="text">
            {guest ? (
              <span className="name">{guest.displayName}</span>
            ) : (
              <Skeleton
                variant="text"
                width="150px"
                sx={{ display: "inline-block" }}
              />
            )}

            <span> {getNotificationText()}</span>
          </div>

          <div className="time">{dayjs(createdAt).fromNow()}</div>
        </TextInfo>
      </InfoWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 24px 0px;
  border-bottom: 1px solid #ebecef;
  transition: all 0.5s;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 50%;
    right: 0px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #1877f2;
    transform: translateY(-50%);
    opacity: 0;
    z-index: 2;
    content: "";
  }

  &::after {
    position: absolute;
    top: 6px;
    left: -12px;
    width: calc(100% + 24px);
    height: calc(100% - 12px);
    border-radius: 12px;
    background: #f4f5f7;
    opacity: ${({ active }: { active?: boolean }) => (active ? 1 : 0)};
    z-index: 1;
    transition: all 0.5s;
    content: "";
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &.unread {
    &::after,
    &::before {
      opacity: 1;
    }
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  cursor: pointer;
  z-index: 10;

  & > :first-child {
    flex-shrink: 0;
  }
`;

const TextInfo = styled.div`
  font-size: 16px;

  .text {
    color: ${(props) => props.theme.palette.secondary.main};

    .name {
      font-weight: 600;
    }
  }

  .time {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 14px;
  }
`;
