import styled from "styled-components";

import image from "assets/images/files/image.png";
import pdf from "assets/images/files/pdf.png";
import video from "assets/images/files/video.png";
import { SectionWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { FileTypes } from "models/common.model";
import { useState } from "react";
import { selectSelectedFund } from "store/feed/feed.selector";
import { getFileNameFromURL, getUrlFileType } from "utils/system.util";
import { ViewMediaModal } from "./ViewMediaModal";

export const DataRoomSection = () => {
  const { dataRoomLinks } = useAppSelector(selectSelectedFund);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");

  const getFileIcon = (url: string) => {
    const type = getUrlFileType(url);

    switch (type) {
      case FileTypes.IMAGE:
        return <FileIcon src={image} />;
      case FileTypes.PDF:
        return <FileIcon src={pdf} />;
      case FileTypes.VIDEO:
        return <FileIcon src={video} />;
      default:
        return null;
    }
  };

  return (
    <SectionWrapper title="Documentation">
      <ListWrapper>
        {dataRoomLinks.map((item, index) => (
          <Item
            key={index}
            onClick={() => {
              setSelectedFileUrl(item.fileUrl);
              setIsOpen(true);
            }}
          >
            {getFileIcon(item.fileUrl)}
            <Text size="md">
              {item.fileName || getFileNameFromURL(item.fileUrl)}
            </Text>
          </Item>
        ))}
      </ListWrapper>
      <ViewMediaModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedFileUrl("");
        }}
        url={selectedFileUrl}
      />
    </SectionWrapper>
  );
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  overflow-y: auto;
`;

const Item = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 6px;
  padding-right: 12px;
  border-radius: 4px;
  background: #ebecef;
  cursor: pointer;
`;

const FileIcon = styled.img`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  object-fit: cover;
  object-position: center;
`;
