import prospectApi from "api/prospect.api";
import { UploadIcon } from "assets/images/common";
import {
  Button,
  GroupText,
  HorizontalBox,
  LinearProgressBar,
  NoticeTooltip,
  ProspectDatePicker,
  ProspectFieldEditor,
  ProspectSelectStatus,
  Text,
} from "components";
import { ProspectCombobox } from "components/Business/Prospect/ProspectCombobox";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { ProspectMultiSelectType } from "models/prospect.model";
import { useEffect, useState } from "react";
import {
  selectProspect,
  selectProspectLoading,
} from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled, { css } from "styled-components/macro";
import * as yup from "yup";

interface Props {
  isAdmin?: boolean;
}

export const InvestmentDetails = ({ isAdmin }: Props) => {
  const dispatch = useAppDispatch();
  const [rounds, setRounds] = useState<ProspectMultiSelectType[]>([]);
  const [instruments, setInstruments] = useState<ProspectMultiSelectType[]>([]);
  const submitLoading = useAppSelector(selectProspectLoading);
  const { id, mfScore, status } = useAppSelector(selectProspect);

  const getListRound = async () => {
    const { fundingRounds } = await prospectApi.fundingRounds();
    const roundsData = fundingRounds?.map((i: ProspectMultiSelectType) => ({
      ...i,
      label: i.name,
    }));
    setRounds(roundsData);
  };

  const getListInstrument = async () => {
    const { fundingInstruments } = await prospectApi.fundingInstruments();
    const instrumentsData = fundingInstruments?.map(
      (i: ProspectMultiSelectType) => ({
        ...i,
        label: i.name,
      })
    );
    setInstruments(instrumentsData);
  };

  useEffect(() => {
    getListInstrument();
    getListRound();
  }, []);

  const handlePublishProspect = () => {
    if (id) {
      dispatch(
        prospectActions.setProspectStatus({
          prospectSetStatusId: id,
          input: {
            status: isAdmin ? "published" : "review",
          },
        })
      );
    }
  };

  return (
    <Wrapper>
      <Text size="xl" color="#050A30">
        Investment Details
      </Text>
      <CategoryWrapper>
        <CategoryItem>
          <Text size={"lg"}>Current round:</Text>
          <ProspectCombobox
            name="currentRound"
            dataList={rounds}
            placeholder="Select round"
            textClass="value-text"
          />
        </CategoryItem>
        <CategoryItem>
          <Text size={"lg"}>Round closes on:</Text>
          <ProspectDatePicker
            name="roundCloseDate"
            placeholder="Select day"
            textClass="value-text"
          />
        </CategoryItem>
        <CategoryItem>
          <Text size={"lg"}>Instrument:</Text>
          <ProspectCombobox
            name="instrument"
            dataList={instruments}
            placeholder="Select instrument"
            textClass="value-text"
          />
        </CategoryItem>
        <CategoryItem>
          <Text size={"lg"}>Min ticket size:</Text>
          <ProspectFieldEditor name="minTicketSize" yupSchema={yup.number()} />
        </CategoryItem>
      </CategoryWrapper>
      <TrustSection>
        <HorizontalBox>
          <GroupText>
            <Text size="lg" fontWeight={400}>
              MF Trust Score:
            </Text>
            <ProspectFieldEditor
              name="mfScore"
              yupSchema={yup.number().max(100).min(1).nullable()}
              unit="/100"
            />
          </GroupText>
          <NoticeTooltip
            placement="top-start"
            data={{
              title: "MF Trust Score",
              content: `The Monaco Foundry Trust Score represents an indicative percentage number
              of the company's attractiveness to investors based on answers
              provided by the founders to our questionnaires, combined with analysis by
              the MF team of key factors such as, the team, the idea, the product, the
              marketing/distribution strategies, the attractiveness of the target market
              and the eventual financial results. These factors are weighted according
              to a proprietary algorithm.`,
            }}
          />
        </HorizontalBox>
        <LinearProgressBar value={mfScore || 50} />
      </TrustSection>
      {isAdmin ? (
        <CategoryItem>
          <Text size={"lg"}>Status:</Text>
          <ProspectSelectStatus />
        </CategoryItem>
      ) : (
        <>
          {status === "draft" && (
            <PublishSection>
              <Button
                icon={<UploadIcon />}
                onClick={handlePublishProspect}
                loading={submitLoading}
              >
                Publish in Deal Flow
              </Button>
            </PublishSection>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 30px;

  .value-text {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary.main};
  }

  .edit-field {
    .text-field-wrapper {
      input {
        min-width: 220px;
      }
    }
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  border-top-width: 1px;
  padding: 12px 0;

  & > div {
    text-align: left;
    font-size: 16px;

    &:first-child {
      font-weight: 400;
      flex-basis: 45%;
    }

    &:nth-child(2) {
      flex-basis: 55%;
    }
  }
`;

const BoxSectionStyle = css`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 10px;
  border-top: 1px solid #ebecef;
`;

const TrustSection = styled.div`
  ${BoxSectionStyle}
`;

const PublishSection = styled.div`
  ${BoxSectionStyle}
  align-items: center;

  & > * {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;
