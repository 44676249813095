import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const LocationIcon = ({ color = "#6A6D84" }: Props) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 9.99967C8.50449 9.99967 8.93624 9.82001 9.29558 9.46067C9.6543 9.10195 9.83366 8.67051 9.83366 8.16634C9.83366 7.66217 9.6543 7.23042 9.29558 6.87109C8.93624 6.51237 8.50449 6.33301 8.00033 6.33301C7.49616 6.33301 7.06471 6.51237 6.70599 6.87109C6.34666 7.23042 6.16699 7.66217 6.16699 8.16634C6.16699 8.67051 6.34666 9.10195 6.70599 9.46067C7.06471 9.82001 7.49616 9.99967 8.00033 9.99967ZM8.00033 16.7372C9.86421 15.0261 11.2469 13.4714 12.1482 12.0732C13.0496 10.6756 13.5003 9.4344 13.5003 8.34967C13.5003 6.6844 12.9693 5.3207 11.9072 4.25859C10.8457 3.19709 9.54338 2.66634 8.00033 2.66634C6.45727 2.66634 5.15469 3.19709 4.09258 4.25859C3.03108 5.3207 2.50033 6.6844 2.50033 8.34967C2.50033 9.4344 2.95102 10.6756 3.85241 12.0732C4.7538 13.4714 6.13644 15.0261 8.00033 16.7372ZM8.00033 18.8226C7.8781 18.8226 7.75588 18.7997 7.63366 18.7538C7.51144 18.708 7.40449 18.6469 7.31283 18.5705C5.08227 16.5997 3.41699 14.7703 2.31699 13.0824C1.21699 11.3939 0.666992 9.81634 0.666992 8.34967C0.666992 6.05801 1.4043 4.23231 2.87891 2.87259C4.35291 1.51287 6.06005 0.833008 8.00033 0.833008C9.9406 0.833008 11.6477 1.51287 13.1217 2.87259C14.5964 4.23231 15.3337 6.05801 15.3337 8.34967C15.3337 9.81634 14.7837 11.3939 13.6837 13.0824C12.5837 14.7703 10.9184 16.5997 8.68783 18.5705C8.59616 18.6469 8.48921 18.708 8.36699 18.7538C8.24477 18.7997 8.12255 18.8226 8.00033 18.8226Z"
        fill={color}
      />
    </svg>
  );
};
