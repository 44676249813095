import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const SearchIcon = ({ color = "#606C82" }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2393_9960)">
        <path
          d="M11.5851 10.5325L11.5127 10.6185L11.5907 10.6996L15.3987 14.6601C15.673 14.9454 15.6639 15.4005 15.3788 15.6748L15.3788 15.6749C15.2446 15.804 15.0681 15.875 14.8816 15.875C14.6844 15.875 14.5012 15.797 14.3644 15.6549L10.5275 11.6644L10.4532 11.5871L10.3655 11.6487C9.29709 12.3996 8.04789 12.795 6.73636 12.795C3.24341 12.795 0.401367 9.95294 0.401367 6.45999C0.401367 2.96704 3.24341 0.125 6.73636 0.125C10.2293 0.125 13.0714 2.96704 13.0714 6.45999C13.0714 7.95501 12.5453 9.39099 11.5851 10.5325ZM11.6361 6.45999C11.6361 3.75809 9.43826 1.56022 6.73636 1.56022C4.03446 1.56022 1.83658 3.75809 1.83658 6.45999C1.83658 9.1619 4.03446 11.3598 6.73636 11.3598C9.43826 11.3598 11.6361 9.1619 11.6361 6.45999Z"
          fill={color}
          stroke="white"
          strokeWidth="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_2393_9960">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
