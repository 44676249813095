import { ContactFounderForm, FavoriteParams } from "./../models/prospect.model";
import { gql } from "graphql-request";
import {
  GraphqlQuery,
  GraphqlResponse,
  QueryParams,
} from "models/common.model";
import { Prospect } from "models/prospect.model";
import { graphqlRequest } from "utils/graphQL.util";

const feedApi = {
  feedGet(
    queryOptions?: QueryParams,
    query?: GraphqlQuery
  ): Promise<GraphqlResponse<Prospect[]>> {
    const variables = queryOptions;

    const defaultQuery = gql`
      query ($feedFilter: feedFilter, $sort: sort) {
        feedGet(feedFilter: $feedFilter, sort: $sort) {
          __typename
          fav_person
          ... on Prospect {
            id
            logoUrl
            displayName
            description
            slogan
            pitchDeckUrl
            websiteUrl
            annualRevenue
            MRR
            minTicketSize
            mfScore
            mfScoreUrl
            roundSize
            status
            roundCloseDate
            preFundedAmount
            dataroomReady
            videoUrl
            posterUrl
            prevPartOf
            contactEmail
            currentRound {
              id
              name
            }
            instrument {
              id
              name
            }
            country {
              id
              name
              alpha2Code
            }
            sector {
              id
              name
            }
            impactValues {
              id
              name
              active
            }
            businessType {
              id
              name
              active
            }
            productType {
              id
              name
              active
            }
            teamMembers {
              bio
              displayName
              id
              photoUrl
              role
              email
              order
            }
          }
          ... on Organisation {
            id
            logoUrl
            displayName
            description
            websiteUrl
            videoUrl
            slogan
            address
            city
            MRR
            preFunded
            preFundedAmount
            prevPartOf
            bankAccMonitoring
            annualRevenue
            updatedAt
            mfScore
            mfScoreUrl
            posterUrl
            productType {
              name
              id
            }
            currency {
              id
              symbol
              name
            }
            country {
              id
              name
            }
            businessType {
              name
              id
            }
            impactValues {
              name
              id
            }
            sector {
              id
              name
            }
            teamMembers {
              id
              displayName
              bio
              photoUrl
              role
              email
              order
            }
            fundraisingProjects {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              acceptMA
              status
              message
            }
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },
  ticketSizeRangesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query {
        ticketSizeRanges {
          id
          currency {
            id
            name
          }
          label
          from
          to
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  sendProspectContact(
    prospectContactId: string,
    input: ContactFounderForm,
    query?: GraphqlQuery
  ) {
    const variables = {
      prospectContactId,
      input,
    };
    const defaultQuery = gql`
      mutation ($prospectContactId: ID!, $input: prospectContactInput!) {
        prospectContact(id: $prospectContactId, input: $input)
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  favoriteCreate(input: FavoriteParams) {
    const variables = { input };

    const query = gql`
      mutation FavouriteCreate($input: favInput!) {
        favouriteCreate(input: $input) {
          ... on FauvoritesSuccess {
            id
          }
          ... on FauvoritesError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  favoriteRemove(input: FavoriteParams) {
    const variables = { input };

    const query = gql`
      mutation FavouriteRemove($input: favInput!) {
        favouriteRemove(input: $input) {
          ... on FauvoritesSuccess {
            id
          }
          ... on FauvoritesError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },
};

export default feedApi;
