import {
  Box,
  CircularProgress,
  Popover as MUIPopover,
  popoverClasses,
  PopoverProps,
} from "@mui/material";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import { ReadIcon } from "assets/images/common";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
// import { AUTH_MODE_TYPE } from "models/auth.model";
import { Notification as INotification } from "models/chat.model";
// import { selectAuthMode } from "store/auth/auth.selector";
import { selectNamespaceId } from "store/global/global.selector";
// import { authActions } from "store/auth/auth.slice";
import { selectUserId } from "store/chat/chat.selector";
import { chatActions } from "store/chat/chat.slice";
import styled from "styled-components/macro";
import {
  getFirstNotificationBatch,
  notificationNextBatch,
} from "utils/chat.util";
import { db } from "utils/firebase.util";
import { parseCodeObject } from "utils/firestore.util";
import { NotificationItem } from "./NotificationItem";

export const Notification = (props: PopoverProps) => {
  const [notificationList, setNotificationList] = useState<INotification[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastDocument, setLastDocument] = useState<any>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const namespaceId = useAppSelector(selectNamespaceId);
  // const authMode = useAppSelector(selectAuthMode);
  const notificationsRef = useRef(notificationList);

  // const handleNotificationNavigate = (
  //   pathUrl: string,
  //   workingPlace: string
  // ) => {
  //   if (authMode === AUTH_MODE_TYPE.ADMIN_WORK_AS_USER) {
  //     if (workingPlace === "admin") {
  //       dispatch(authActions.userUpdateRole(AUTH_MODE_TYPE.ADMIN_OWNER));
  //       setTimeout(() => {
  //         navigate(pathUrl);
  //       }, 500);
  //     }
  //     if (workingPlace === "workspace") {
  //       dispatch(
  //         authActions.userUpdateRole(AUTH_MODE_TYPE.ADMIN_WORK_AS_WORKSPACE)
  //       );
  //       setTimeout(() => {
  //         navigate(pathUrl);
  //       }, 500);
  //     }
  //   } else {
  //     navigate(pathUrl);
  //   }
  // };

  const updateNotifications = useCallback(
    (newNotifications: INotification[]) => {
      notificationsRef.current = newNotifications;
      setNotificationList(newNotifications);
      dispatch(
        chatActions.setNotificationList(
          newNotifications.map((n) => ({ ...n, ...parseCodeObject(n.code) }))
        )
      );
    },
    [dispatch]
  );

  const handleRead = async (notificationId: string) => {
    const docRef = doc(db, "notifications", notificationId);

    await updateDoc(docRef, {
      isRead: true,
    });
  };

  const handleReadAll = async () => {
    const querySnapshot = query(
      collection(db, "notifications"),
      where("namespaceId", "==", namespaceId),
      where("receiverId", "==", userId),
      where("isRead", "==", false)
    );

    const docSnap = await getDocs(querySnapshot);

    docSnap.docs.map((docData) => {
      const docRef = doc(db, "notifications", docData.id);

      updateDoc(docRef, {
        isRead: true,
      });
    });
  };

  const nextDocuments = async () => {
    if (!lastDocument) return;

    const { notifications, lastVisible } = await notificationNextBatch(
      namespaceId,
      userId,
      lastDocument
    );

    if (!notifications.length) {
      setHasMore(false);
      return;
    }

    setLastDocument(lastVisible);
    updateNotifications([...notificationList, ...notifications]);
  };

  useEffect(() => {
    if (!userId) return;

    const unsub = getFirstNotificationBatch(
      namespaceId,
      userId,
      (querySnapshot) => {
        const notifications: INotification[] = [];
        querySnapshot.docChanges().forEach(({ type, doc }) => {
          const notification = { ...doc.data(), id: doc.id } as INotification;

          if (type === "added") {
            notifications.push(notification);
          }

          if (type === "modified") {
            const modifiedIndex = notificationsRef.current.findIndex(
              (item) => item.id === doc.id
            );

            if (modifiedIndex !== -1) {
              notificationsRef.current[modifiedIndex] = notification;
              updateNotifications([
                ...notifications,
                ...notificationsRef.current,
              ]);
            }
          }
        });

        if (!notifications.length) {
          setHasMore(false);
          return;
        }

        if (notificationsRef.current.length === 0) {
          setLastDocument(
            querySnapshot.docChanges()[querySnapshot.docChanges().length - 1]
              .doc
          );
        }

        updateNotifications([...notifications, ...notificationsRef.current]);
      }
    );

    return () => {
      unsub();
      updateNotifications([]);
    };
  }, [updateNotifications, namespaceId, userId]);

  return (
    <Popover
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      {...props}
    >
      <PopoverWrapper>
        <Header>
          <Title>Notifications</Title>

          <ReadAllButton onClick={handleReadAll}>
            <ReadIcon color="#606C82" />
            Mark all as read
          </ReadAllButton>
        </Header>

        <ListWrapper id="notifications-wrapper">
          <InfiniteScroll
            dataLength={notificationList.length}
            next={nextDocuments}
            hasMore={hasMore}
            loader={
              <Box display="flex" justifyContent="center" padding="16px 0px">
                <CircularProgress size="24px" color="secondary" />
              </Box>
            }
            scrollableTarget="notifications-wrapper"
          >
            {notificationList.map((n) => {
              const item = { ...n, ...parseCodeObject(n.code) };

              return (
                <NotificationItem
                  key={item.id}
                  data={item}
                  onClick={() => {
                    handleRead(item.id);
                    props.onClose && props.onClose({}, "backdropClick");

                    if (item.isReviewOrg) {
                      if (item.receiverId === namespaceId) {
                        navigate(
                          `${PATH.ADMIN_COMPANY}/${item.orgId}/settings`
                        );
                        return;
                      }

                      navigate(PATH.COMPANY_SETTINGS);
                      return;
                    }

                    if (item.isReviewFund) {
                      if (item.receiverId === namespaceId) {
                        navigate(`${PATH.ADMIN_FUNDRAISING}/${item.fundId}`);
                        return;
                      }
                      navigate(`${PATH.COMPANY_FUNDRAISING}/${item.fundId}`);
                      return;
                    }

                    navigate(`/chats/${item.roomId}`);
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </ListWrapper>
      </PopoverWrapper>
    </Popover>
  );
};

const Popover = styled(({ ...props }: PopoverProps) => (
  <MUIPopover {...props} />
))(() => ({
  [`& .${popoverClasses.paper}`]: {
    width: "450px",
    borderRadius: "16px",
    boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
  },
}));

const PopoverWrapper = styled.div`
  padding: 24px;
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebecf0;
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 24px;
  font-weight: 700;
`;

const ReadAllButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const ListWrapper = styled.div`
  min-height: 80px;
  max-height: 500px;
  margin: 0px -24px;
  overflow-y: auto;

  .infinite-scroll-component {
    padding: 0px 24px;
  }

  .notification-item:last-child {
    border-bottom: none;
  }
`;
