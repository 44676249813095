import { IconButton } from "@mui/material";
import { EditIcon, PlusIcon } from "assets/images/common";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { CropImageModal } from "./CropImageModal";

interface Props {
  className?: string;
  onSave: (image: Blob | undefined) => void;
  url: string;
  height?: number;
  width?: number;
  ignoreCrop?: boolean;
  uploadText?: string;
  clearImage?: boolean;
  error?: string;
}

export const ImageEditor = ({
  className = "",
  onSave,
  url,
  height,
  width,
  ignoreCrop,
  uploadText,
  clearImage,
  error,
}: Props) => {
  const [file, setFile] = useState(undefined);
  const [cropImage, setCropImage] = useState<Blob | undefined>(undefined);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isHasImage = cropImage || url;

  const onDrop = useCallback((files: any) => {
    setFile(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  useEffect(() => {
    if (file) {
      setIsOpenModal(true);
    }
  }, [file]);

  useEffect(() => {
    if (cropImage) {
      onSave(cropImage);

      clearImage && setCropImage(undefined);
    }
  }, [clearImage, cropImage, onSave]);

  return (
    <Wrapper
      className={`${className} ${isHasImage && "has-image"} ${
        !!error && "error"
      }`}
    >
      <DropZone
        {...getRootProps()}
        className={`${isDragActive && "dragging-img"}`}
      >
        <input {...getInputProps()} id="upload-image-avatar" />
        {isHasImage && (
          <ImageViewWrapper className="image-view">
            <IconWrapper>
              <EditIcon color="white" />
            </IconWrapper>
            <ImageView src={cropImage ? URL.createObjectURL(cropImage) : url} />
          </ImageViewWrapper>
        )}
        {!isHasImage && (
          <UploadWrapper>
            <PlusIcon color="#606C82" />

            {uploadText && <div className="upload-text">{uploadText}</div>}
          </UploadWrapper>
        )}
      </DropZone>
      <CropImageModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        image={file}
        onSave={setCropImage}
        height={height}
        width={width}
        ignoreCrop={ignoreCrop}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #dfe1e6;
  border-radius: 4px;
  background: white;
  overflow: hidden;

  &.has-image {
    border: 0;
  }

  &.error {
    border-color: #eb5a46;
  }
`;

const IconWrapper = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 50%;
  background-color: #373b593c;
`;

const ImageViewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageView = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const DropZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &.dragging-img {
    border: 2px dashed;
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  svg {
    width: 14px;
    height: auto;
    transition: all 0.7s;
  }

  ${IconWrapper} {
    svg {
      width: 14px;
      height: auto;
    }
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-text {
    max-width: 300px;
    margin-top: 16px;
    padding: 0px 16px;
    color: #a5a7b4;
    font-size: 12px;
    text-align: center;
  }
`;
