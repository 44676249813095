import styled from "styled-components";

import { Text } from "components";
import { SuccessIcon } from "assets/images/common";

interface Props {
  score: number;
}

export const ThankYouScreen = ({ score }: Props) => {
  return (
    <Wrapper className="form-item">
      <SuccessIcon />

      <TextWrapper>
        <Text size="lg">
          The MF Trust score of your company (out of 100%) is:{" "}
          <span className="score">{Math.round(score)}%</span>
        </Text>

        <Text size="lg">
          This result will be automatically displayed on your Company brief.
        </Text>

        <Text size="lg">
          The breakdown report will be added to your Company brief within 48
          hours.
        </Text>
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: 100%;
  padding: 20px;

  & > svg {
    width: 140px;
    height: 140px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;

  .score {
    color: #58a369;
    font-size: 24px;
    font-weight: 700;
  }
`;
