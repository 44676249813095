import { Chart } from "chart.js/auto";
import { CHART_BACKGROUND_COLOR } from "constants/app.const";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { convertToK } from "utils/system.util";

interface Props {
  labels: string[];
  datasets: { label: string; data: number[] }[];
  stepSize?: number;
}

export const BarChart = ({ labels, datasets, stepSize }: Props) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"bar"> | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "bar",
        data: {
          labels,
          datasets: datasets.map((item, index) => ({
            label: item.label,
            data: item.data,
            backgroundColor: CHART_BACKGROUND_COLOR[index],
            barThickness: 44,
            borderRadius: 8,
          })),
        },
        options: {
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
              border: {
                dash: [5, 5],
              },
              ticks: {
                stepSize: stepSize,
                callback: (value) => convertToK(value as number),
              },
            },
          },
        },
      });
    }
  }, [datasets, labels, stepSize]);

  return (
    <Wrapper>
      <canvas ref={chartRef} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
