import styled from "styled-components";

import { Button } from "components";

interface Props {
  imageUrl?: string;
  title: string;
  subTitle?: string;
  maxWidth?: number;
  hasButton?: boolean;
  buttonText?: string;
  buttonLoading?: boolean;
  handleClick?: () => void;
}

export const Empty = ({
  imageUrl,
  title,
  subTitle,
  maxWidth,
  hasButton,
  buttonText,
  buttonLoading,
  handleClick,
}: Props) => {
  return (
    <Wrapper>
      <Content maxWidth={maxWidth}>
        {imageUrl && <img src={imageUrl} />}

        <TextWrapper>
          <Title>{title}</Title>

          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </TextWrapper>

        {hasButton && (
          <Button loading={buttonLoading} onClick={handleClick}>
            {buttonText}
          </Button>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: ${({ maxWidth = 200 }: { maxWidth?: number }) => maxWidth}px;

  img {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`;

const SubTitle = styled.div`
  color: #7c8698;
  font-size: 14px;
  text-align: center;
`;
