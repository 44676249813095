import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const CompanyIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.553 6.95865L10.5 6.90562L10.447 6.95865L8.77193 8.63369C8.34561 9.06001 7.6544 9.06001 7.22808 8.63369L4.13045 5.53606C3.8343 5.23991 3.8343 4.75976 4.13045 4.46361C4.4266 4.16747 4.90675 4.16747 5.20289 4.46361L7.94697 7.20769L8.00001 7.26073L8.05304 7.20769L9.72808 5.53265C10.1544 5.10633 10.8456 5.10633 11.2719 5.53265L13.7803 8.04103L13.8333 8.09406L13.8864 8.04103L14.9638 6.96361C15.2599 6.66747 15.7401 6.66747 16.0362 6.96361C16.3324 7.25976 16.3324 7.73991 16.0362 8.03606L14.6053 9.46702C14.1789 9.89335 13.4877 9.89335 13.0614 9.46703L10.553 6.95865ZM18.4083 12.4998V12.4248H18.3333H1.66667H1.59167V12.4998V14.3332V14.4082H1.66667L18.3333 14.4082H18.4083V14.3332V12.4998ZM18.3333 10.9082H18.4083V10.8332V3.33317V3.25817H18.3333H1.66667H1.59167V3.33317V10.8332V10.9082H1.66667H18.3333ZM7.1291 16.0748H8.39616L7.97676 18.3116L7.96011 18.4004H8.05048H11.9506H12.041L12.0243 18.3116L11.6049 16.0748H12.872L13.2965 18.3393L13.308 18.4004H13.3702H14.6517C14.9956 18.4004 15.2744 18.6792 15.2744 19.0231C15.2744 19.367 14.9956 19.6458 14.6517 19.6458L12.8036 19.6458L12.8022 19.6458C12.7943 19.6459 12.7865 19.6459 12.7787 19.6458V19.6458H12.7773L7.22379 19.6458L7.22235 19.6458C7.21909 19.6459 7.21581 19.6459 7.21253 19.6459C7.20798 19.6459 7.20343 19.6459 7.19887 19.6458L7.19887 19.6458H7.19744H5.34937C5.00547 19.6458 4.72669 19.367 4.72669 19.0231C4.72669 18.6792 5.00547 18.4004 5.34937 18.4004H6.63081H6.69306L6.70453 18.3393L7.1291 16.0748ZM18.3333 1.7415C19.2124 1.7415 19.925 2.45412 19.925 3.33317V14.3332C19.925 15.2122 19.2124 15.9248 18.3333 15.9248H1.66667C0.787613 15.9248 0.075 15.2122 0.075 14.3332V3.33317C0.075 2.45412 0.787614 1.7415 1.66667 1.7415H18.3333Z"
        fill={color}
        stroke="white"
        strokeWidth="0.15"
        strokeLinecap="round"
      />
    </svg>
  );
};
