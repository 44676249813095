import React from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  children: React.ReactNode;
}

export const SectionWrapper = ({ title, children }: Props) => {
  return (
    <Wrapper className="section-wrapper">
      {title && <Title className="section-wrapper__title">{title}</Title>}

      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  border: 1px solid #d8d9df;
  border-radius: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const Title = styled.div`
  margin-bottom: 22px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 24px;
  font-weight: 700;
`;
