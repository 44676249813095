import { NoticeTooltip, Text, TextField } from "components";
import { InputHTMLAttributes } from "react";
import {
  FieldValues,
  UseFormRegister,
  UseFormReset,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import styled from "styled-components/macro";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  fieldName?: string;
  error?: string;
  icon?: React.ReactElement;
  tooltipText?: string;
  hasClear?: boolean;
  reset?: UseFormReset<FieldValues>;
  formRef?: UseFormRegister<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  trigger?: UseFormTrigger<FieldValues>;
  afterClear?: () => void;
}

const GroupTextField: React.FC<Props> = ({
  fieldName,
  tooltipText,
  ...props
}) => {
  return (
    <FieldGroup>
      {fieldName && (
        <div className="label">
          <Text size="md" fontWeight={600}>
            {fieldName}
          </Text>

          {tooltipText && (
            <NoticeTooltip
              placement="right"
              data={{
                content: tooltipText,
              }}
            />
          )}
        </div>
      )}
      <TextField {...props} />
    </FieldGroup>
  );
};

export default GroupTextField;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  .label {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
