import { createSelector } from "@reduxjs/toolkit";
import { parseToken } from "utils/system.util";
import { AuthState, initialState } from "./auth.slice";

const selectDomain = (state: { auth: AuthState }) => state.auth || initialState;

export const selectAuthLoading = createSelector(
  [selectDomain],
  (auth) => auth.loading
);

export const selectAuthMode = createSelector(
  [selectDomain],
  (auth) => auth.mode
);

export const selectWorkspaceType = createSelector(
  [selectDomain],
  (auth) => auth.workspaceType
);

export const selectWorkspaceUpdate = createSelector(
  [selectDomain],
  (auth) => auth.workspaceUpdate
);

export const selectUserToken = createSelector(
  [selectDomain],
  (auth) => auth.userToken
);

export const selectUserTokenObject = createSelector([selectDomain], (auth) =>
  auth.userToken ? parseToken(auth.userToken) : {}
);

export const selectAuthUserId = createSelector([selectDomain], (auth) =>
  auth.userToken ? parseToken(auth.userToken)?.id : null
);
