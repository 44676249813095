import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
} from "@mui/material";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { FlagText } from "components";
import { PATH } from "constants/app.const";
import { MenuNode } from "constants/menu.const";
import { useAppSelector } from "hooks/useHook";
import { selectNotificationList } from "store/chat/chat.selector";

interface Props {
  item: MenuNode;
  isActive?: boolean;
  expandSubMenu?: MenuNode | undefined;
  handleExpandSubMenu?: (item: MenuNode) => void;
}

export const MenuItem = ({
  item,
  isActive,
  expandSubMenu,
  handleExpandSubMenu,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const companyId = _.includes(location.pathname, PATH.ADMIN_COMPANY)
    ? location.pathname.split("/")[3]
    : "";

  const isExpand = !!expandSubMenu && expandSubMenu.route() === item.route();

  const notifications = useAppSelector(selectNotificationList);
  const unreadCount = notifications
    .filter((i) => !i.isRead && (i.isChatInvest || i.isChatDefault))
    .reduce((acc, cur) => acc + (cur.unreadCount || 1), 0);

  return (
    <Wrapper>
      <Accordion className="menu-wrapper" expanded={isExpand}>
        <AccordionSummary
          className={`menu-item ${isActive ? "active" : undefined}`}
          onClick={() => {
            navigate(
              item.route({
                companyId,
              })
            );
            !_.isEmpty(item.subs) &&
              handleExpandSubMenu &&
              handleExpandSubMenu(item);
          }}
        >
          {item.name === "Chats" ? (
            <Badge badgeContent={unreadCount} color="error">
              <FlagText
                icon={<item.icon color={isActive ? "#373b59" : "#7c8698"} />}
                type={"WHITE"}
              >
                {item.name}
              </FlagText>
            </Badge>
          ) : (
            <FlagText
              icon={<item.icon color={isActive ? "#373b59" : "#7c8698"} />}
              type={"WHITE"}
            >
              {item.name}
            </FlagText>
          )}
        </AccordionSummary>

        {item.subs &&
          item.subs.map((sub, index) => {
            const isActive = _.includes(
              location.pathname,
              sub.route({ companyId })
            );

            return (
              <AccordionDetails
                key={index}
                className={`menu-sub ${isActive ? "active" : undefined}`}
                onClick={() => navigate(sub.route({ companyId }))}
              >
                <FlagText type={"WHITE"}>{sub.name}</FlagText>
              </AccordionDetails>
            );
          })}
      </Accordion>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .menu-wrapper {
    box-shadow: none;

    .MuiCollapse-root {
      position: relative;
      margin-top: 12px;
      transition: all 0.4s;

      &::after {
        position: absolute;
        content: "";
        top: 0px;
        left: 12px;
        width: 1px;
        height: 100%;
        background: #dfe1e6;
      }
    }

    .flag-text {
      padding: 0px;
    }

    .menu-item {
      width: 100%;
      height: 44px;
      min-height: unset;
      padding: 12px;
      border-radius: 8px;
      transition: all 0.7s;

      &:hover {
        background: #f4f5f7;
      }

      & > div {
        margin: 0px;
        width: 100%;
      }

      .flag-text {
        background: transparent;
        color: #7c8698;
      }
    }
  }
  .menu-item.active {
    background: #f4f5f7;

    .flag-text {
      color: ${(props) => props.theme.palette.text.primary};
      font-weight: 600;
    }
  }

  .menu-sub {
    height: 44px;
    padding: 12px;
    padding-left: 24px;
    cursor: pointer;

    .flag-text {
      background: transparent;
      color: #7c8698;
    }
  }

  .menu-sub.active {
    .flag-text {
      color: ${(props) => props.theme.palette.text.primary};
      font-weight: 500;
    }
  }
`;
