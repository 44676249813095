import { createSelector } from "@reduxjs/toolkit";

import { ReportState, initialState } from "./report.slice";

const selectDomain = (state: { report: ReportState }) =>
  state.report || initialState;

export const selectReportLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectAssetsFuzzySearch = createSelector(
  [selectDomain],
  (state) => state.assetsFuzzySearch
);

export const selectAssetList = createSelector(
  [selectDomain],
  (state) => state.assetList
);

export const selectSelectedReport = createSelector(
  [selectDomain],
  (state) => state.selectedReport
);

export const selectReportList = createSelector(
  [selectDomain],
  (state) => state.reportList
);
