import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ChatIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.375 7.1125L5 7.1125C4.52365 7.1125 4.1375 6.72635 4.1375 6.25C4.1375 5.77366 4.52365 5.3875 5 5.3875L9.375 5.3875C9.85135 5.3875 10.2375 5.77365 10.2375 6.25C10.2375 6.72635 9.85135 7.1125 9.375 7.1125ZM4.1375 9.375C4.1375 8.89865 4.52365 8.5125 5 8.5125H13.75C14.2263 8.5125 14.6125 8.89865 14.6125 9.375C14.6125 9.85135 14.2263 10.2375 13.75 10.2375H5C4.52365 10.2375 4.1375 9.85135 4.1375 9.375ZM4.1375 12.5C4.1375 12.0237 4.52365 11.6375 5 11.6375H12.2917C12.768 11.6375 13.1542 12.0237 13.1542 12.5C13.1542 12.9763 12.768 13.3625 12.2917 13.3625H5C4.52365 13.3625 4.1375 12.9763 4.1375 12.5Z"
        fill={color}
        stroke="white"
        strokeWidth="0.15"
        strokeLinecap="round"
      />
      <path
        d="M1.875 3.05H1.8V3.125V15.625V15.7H1.875H15.1626C15.8907 15.7 16.5947 15.9604 17.1475 16.4343L18.0762 17.2303L18.2 17.3364V17.1733V3.125V3.05H18.125H1.875ZM0.075 2.5C0.075 1.85107 0.601065 1.325 1.25 1.325H18.75C19.3989 1.325 19.925 1.85107 19.925 2.5V18.5322C19.925 19.5361 18.7475 20.0777 17.9853 19.4244L16.0249 17.744C15.7847 17.5381 15.4789 17.425 15.1626 17.425H1.25C0.601065 17.425 0.075 16.8989 0.075 16.25V2.5Z"
        fill={color}
        stroke="white"
        strokeWidth="0.15"
      />
    </svg>
  );
};
