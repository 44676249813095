import { Popover } from "@mui/material";
import { DownArrowIcon, SortIcon } from "assets/images/common";
import { FlagText, Text } from "components";
import { SortItem } from "models/common.model";
import { HTMLAttributes, useRef, useState } from "react";
import styled from "styled-components/macro";

interface Props {
  data: SortItem[];
  selectedItem: SortItem | undefined;
  onSelect: (item: SortItem) => void;
}

interface SItemProps extends HTMLAttributes<HTMLDivElement> {
  item: SortItem;
}

const SItem = ({ item, ...props }: SItemProps) => (
  <SortItemWrapper {...props}>
    <Text size="md">{item.name}</Text>
    {item.order === "asc" && <Text size="md">&uarr;</Text>}
    {item.order === "desc" && <Text size="md">&darr;</Text>}
  </SortItemWrapper>
);

export const SortFeature = ({ data, selectedItem, onSelect }: Props) => {
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper className={isOpen ? "edit-field" : "text-field"}>
      <SortWrapper ref={popoverRef} onClick={() => setIsOpen(!isOpen)}>
        <FlagText type="WHITE" icon={<SortIcon />}>
          Sort by:
          <span className="selected-name">{selectedItem?.name}</span>
          <DownArrowIcon />
        </FlagText>
      </SortWrapper>

      <PopoverWrapper
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: "210px",
            borderRadius: "16px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
        anchorEl={popoverRef.current}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <SelectWrapper>
          {data.map((item) => (
            <SItem
              key={item.id}
              className={`${
                selectedItem && selectedItem.id === item.id && "selected"
              }`}
              onClick={() => {
                onSelect(item);
                setIsOpen(false);
              }}
              item={item}
            />
          ))}
        </SelectWrapper>
      </PopoverWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  position: relative;

  &.edit-field {
    .flag-text {
      background: #d8d9df;
    }
  }

  .flag-text {
    .text {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;

      .selected-name {
        color: ${(props) => props.theme.palette.text.secondary};
      }
    }
  }
`;

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
`;

const SortItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: #ffffff;
  padding: 6px 12px;
  border-radius: 4px;
  transition: all 0.5s;
  width: 100%;

  &:hover {
    background-color: #ebecf0;
  }

  &.selected {
    background-color: #ebecf0;
  }
`;

const PopoverWrapper = styled(Popover)`
  .MuiPaper-root {
    min-width: 180px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  z-index: 1;
  background-color: white;
  color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 0px 50px rgba(5, 10, 48, 0.1);
  overflow-y: auto;
`;
