import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { StatusIndicator } from "models/common.model";
import { FundState, initialState } from "./fund.slice";

const selectDomain = (state: { fund: FundState }) => state.fund || initialState;

export const selectFundLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectFundList = createSelector(
  [selectDomain],
  (state) => state.fundList
);

export const selectSelectedFund = createSelector(
  [selectDomain],
  (state) => state.selectedFund
);

export const selectPossibleEditFund = createSelector(
  [selectSelectedFund],
  ({ status }) =>
    !_.includes(
      [
        StatusIndicator.REVIEW,
        StatusIndicator.PUBLISHED,
        StatusIndicator.ARCHIVED,
      ],
      status as StatusIndicator
    )
);
