import { Palette } from "@mui/material";
import { gql } from "graphql-request";
import { RefObject } from "react";
export interface BaseItem<T> {
  text: string;
  value: T;
}

export interface GraphqlResponse<T> {
  data: {
    [key: string]: T;
  };
}

export type GraphqlQuery = ReturnType<typeof gql>;

export type OrderType = "asc" | "desc";

export interface SortConfig {
  name: string;
  type: OrderType | null;
}

export interface FilterOptions {
  [key: string]: string[];
}
export interface PaginationParams {
  pageSize: number;
  total: number;
}

export interface ErrorResponse {
  data: {
    tradCode: string;
    message?: string;
  };
}
export type NamespaceState = "Global" | "Own";
type FeedType = "Organisation" | "FundraisingProject" | "Prospect";
export interface QueryParams {
  status?: number;
  limit?: number;
  text?: string;
  page?: number;
  type?: string;
  sort?: {
    order: "asc" | "desc";
    byKey: string;
  };
  feedFilter?: {
    namespace: NamespaceState;
    types: FeedType[];
  };
  order?: OrderType;
  id?: string;

  [key: string]: any;
}

export interface SelectItem {
  id: string;
  name: string;
  active?: boolean;
  check?: boolean;

  [key: string]: any;
}

export interface SelectItemTask {
  id: string;
  task: "ADD" | "REMOVE";
}

export interface TBD<T> {
  [key: string]: T;
}

export type QueryAction = "CREATE" | "UPDATE" | "DELETE";

export interface TableHeaderConfig {
  name: string;
  width: number | string;
  isSort?: boolean;
  fieldName?: string;
  justifyHead?: string;
}

export enum FileTypes {
  VIDEO = "VIDEO",
  PDF = "PDF",
  IMAGE = "IMAGE",
  UPLOAD_VIDEO = "UPLOAD_VIDEO",
  UNKNOW = "UNKNOW",
  IFRAME = "IFRAME",
}

export interface RadioButtonData {
  label: string;
  value: string;
}
export interface ListItem {
  label: string;
  id: string;
}

export type FunctionType<T extends any[] = []> = (...arg: T) => void;

export type ValidFileType = { isValid: boolean; errorMessage: string };

export interface Pagination {
  cursor: string;
  first: number;
}

export interface SortItem {
  id: string;
  name: string;
  byKey: string;
  feedFilter?: {
    namespace: NamespaceState;
    types: FeedType[];
  };
  order: "asc" | "desc";
}

export interface Progress {
  label: string;
  isComplete: boolean;
  onClick?: () => void;
}

export interface Option {
  label: string;
  [key: string]: any;
}

export interface NavItem {
  title: string;
  ref: RefObject<HTMLDivElement>;
  hidden?: boolean;
}

export enum StatusIndicator {
  DRAFT = "draft",
  REVIEW = "review",
  PUBLISHED = "published",
  FLAGGED = "flagged",
  ARCHIVED = "archived",
}

type AppConfigItem = {
  active: boolean;
  value?: any;
};
export interface AppConfig {
  projectId: string;
  id: string;
  bannerLogo: string;
  headerLogo: string;
  parnerLogo?: string;
  faviLogo?: string;
  avatarLogo?: string;
  brandName: string;
  brandUrl: string;
  brandLabel: string;
  title: string;
  description: string;
  themeConfig: Palette;
  updateFeed: AppConfigItem;
  lockMFT: AppConfigItem;
  namespaceFilter: AppConfigItem;
  feedPromote: AppConfigItem;
  menuTab: AppConfigItem[];
}
