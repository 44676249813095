import {
  Badge,
  IconButton,
  Toolbar as ToolbarMui,
  Tooltip,
} from "@mui/material";
import { BellIcon, GalleryIcon } from "assets/images/common";
import { ProfileIcon } from "assets/images/common/ProfileIcon";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { useRef, useState } from "react";
import { selectAuthMode } from "store/auth/auth.selector";
import { authActions } from "store/auth/auth.slice";
import { selectNotificationList } from "store/chat/chat.selector";
import styled from "styled-components/macro";
import { Notification } from "./Notification";
import ProfilePanel from "./ProfilePanel";

const TopBarTool = () => {
  const dispatch = useAppDispatch();
  const authMode = useAppSelector(selectAuthMode);
  const [openProfilePanel, setOpenProfilePanel] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const settingsRef = useRef(null);
  const notificationRef = useRef(null);
  const notifications = useAppSelector(selectNotificationList);
  const unreadCount = notifications
    .filter((i) => !i.isRead)
    .reduce((acc, cur) => acc + cur.unreadCount, 0);

  return (
    <Wrapper>
      <ToolBar>
        {[
          AUTH_MODE_TYPE.WORKSPACE,
          AUTH_MODE_TYPE.ADMIN_WORK_AS_WORKSPACE,
          AUTH_MODE_TYPE.ADMIN_OWNER,
        ].includes(authMode) && (
          <Tooltip title="My spaces">
            <IconButton onClick={() => dispatch(authActions.workspaceLogout())}>
              <GalleryIcon />
            </IconButton>
          </Tooltip>
        )}

        {![
          AUTH_MODE_TYPE.GUEST,
          AUTH_MODE_TYPE.ADMIN_WORK_AS_USER,
          AUTH_MODE_TYPE.USER,
        ].includes(authMode) && (
          <>
            <IconButton
              ref={notificationRef}
              onClick={() => setOpenNotification(true)}
            >
              <Badge badgeContent={unreadCount} color="error">
                <BellIcon />
              </Badge>
            </IconButton>
            <Notification
              anchorEl={notificationRef.current}
              open={openNotification}
              onClose={() => setOpenNotification(false)}
            />
          </>
        )}

        <IconButton
          onClick={() => setOpenProfilePanel(!openProfilePanel)}
          ref={settingsRef}
        >
          <ProfileIcon />
        </IconButton>

        <ProfilePanel
          anchorEl={settingsRef.current}
          onClose={() => {
            setOpenProfilePanel(!openProfilePanel);
          }}
          open={openProfilePanel}
        />
      </ToolBar>
    </Wrapper>
  );
};

export default TopBarTool;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToolBar = styled(ToolbarMui)`
  min-height: 64px;
  gap: 10px;

  button {
    width: 35px;
    height: 35px;
  }
`;
