import styled from "styled-components";

import { Combobox } from "components/Feature/Combobox";
import { MarketResearchItem } from ".";

const options = [
  { label: "Last quarter - Q4 2022", value: "Last quarter - Q4 2022" },
];

export const MarketResearchSection = () => {
  return (
    <Wrapper>
      <Header>
        <div className="title">Market research</div>

        <Combobox
          id="combobox-sort"
          freeSolo={false}
          options={options}
          onchange={() => null}
          value="Last quarter - Q4 2022"
        />
      </Header>

      <ListWrapper>
        {[...Array(12)].map((_, index) => (
          <MarketResearchItem
            key={index}
            title="Social Performance"
            value={74.86}
            raisingValue={7.33}
            fromTime="vs last week"
          />
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .MuiAutocomplete-root {
    min-width: 210px;

    .MuiOutlinedInput-root {
      height: 40px;
      font-size: 14px;
    }
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root li {
      font-size: 14px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 20px;

  .title {
    color: ${(props) => props.theme.palette.secondary.dark};
    font-size: 24px;
    font-weight: 700;
  }
`;

const ListWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);

  ${(props) => props.theme.breakpoints.down("xl")} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr;
  }
`;
