import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "@mui/material";
import authApi from "api/auth.api";
import { EyeClosedIcon, EyeIcon } from "assets/images/common";
import { Button, ButtonOutline, CheckBox } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import {
  getAuth,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";
import { useAppDispatch } from "hooks/useHook";
import { UserLoginParams, UserRegisterParams } from "models/auth.model";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { authActions } from "store/auth/auth.slice";
import { globalActions } from "store/global/global.slice";
import styled from "styled-components/macro";
import { findUIElement } from "utils/system.util";
import * as yup from "yup";
import { TermTemplate } from "./TermTemplate";
import { getErrorMessage } from "errors";

const SignUpForm = () => {
  const dispatch = useAppDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isAgreedTerm, setIsAgreedTerm] = useState(false);
  const [isOpenedTermModal, setIsOpenedTermModal] = useState(false);
  const [disabledAccept, setDisabledAccept] = useState(true);
  const PasswordIcon = showPassword ? EyeClosedIcon : EyeIcon;

  const schema = yup.object().shape({
    displayName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().min(6).max(20).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required(),
    companyName: yup.string().required(),
    websiteUrl: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = async (data: UserLoginParams) => {
    const { email, password } = data;
    const userCredentail: UserCredential = await signInWithEmailAndPassword(
      getAuth(),
      email,
      password
    );
    if (userCredentail.user) {
      dispatch(
        globalActions.pushNotification({
          message: "Registered successfully",
          type: "success",
        })
      );
      const userToken = await userCredentail.user.getIdToken();

      dispatch(authActions.userLogin(userToken));
    }
    setSubmitLoading(false);
  };

  const handleRegister = async (data: FieldValues) => {
    setSubmitLoading(true);
    const { email, password, displayName, companyName, websiteUrl } = data;
    const registerData: UserRegisterParams = {
      email,
      password,
      displayName,
      companyName,
      websiteUrl,
    };
    authApi
      .userRegister(registerData)
      .then(() => {
        handleLogin({ email, password });
      })
      .catch((err) => {
        const errText = getErrorMessage(err);
        dispatch(
          globalActions.pushNotification({
            message: errText,
            type: "error",
          })
        );
        setSubmitLoading(false);
      });
  };

  const handleScroll = useCallback(
    (element: Element) => {
      if (!element) return;
      const contentHeight = element.scrollHeight;
      const scrollTop = element.scrollTop;
      const wrapperHeight = element.clientHeight;

      if (scrollTop + wrapperHeight >= contentHeight - 20) {
        if (!disabledAccept) return;
        setDisabledAccept(false);
      }
    },
    [disabledAccept]
  );

  useEffect(() => {
    if (isOpenedTermModal) {
      findUIElement(".term-body-wrapper", 5000, 50).then((element) => {
        (element as Element)?.addEventListener("scroll", () =>
          handleScroll(element as Element)
        );
      });
    }
  }, [handleScroll, isOpenedTermModal]);

  return (
    <Form onSubmit={handleSubmit(handleRegister)}>
      <GroupTextField
        fieldName="Name"
        placeholder="Anthoy Holland"
        name="displayName"
        formRef={register}
        error={errors.displayName?.message as string}
      />

      <GroupTextField
        fieldName="Work email"
        placeholder="email@company.com"
        name="email"
        formRef={register}
        error={errors.email?.message as string}
      />

      <GroupTextField
        fieldName="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        formRef={register}
        icon={
          <PasswordIconWrapper onClick={() => setShowPassword(!showPassword)}>
            <PasswordIcon />
          </PasswordIconWrapper>
        }
        error={errors.password?.message as string}
      />

      <GroupTextField
        fieldName="Confirm password"
        name="confirmPassword"
        type={showPassword ? "text" : "password"}
        formRef={register}
        error={errors.confirmPassword?.message as string}
      />
      <GroupTextField
        fieldName="Company name"
        placeholder="Your company name..."
        name="companyName"
        formRef={register}
        error={errors.companyName?.message as string}
      />
      <GroupTextField
        fieldName="Website link (optional)"
        placeholder="https://..."
        name="websiteUrl"
        formRef={register}
        error={errors.websiteUrl?.message as string}
      />
      <CheckBox
        disabled={false}
        mainsize={22}
        label="I agree with the Terms and Conditions of the Platform"
        checked={isAgreedTerm}
        onClick={() => {
          setIsOpenedTermModal(true);
        }}
      />
      <Button type="submit" loading={submitLoading} disabled={!isAgreedTerm}>
        Get started
      </Button>
      <ModalWrapper
        open={isOpenedTermModal}
        onClose={() => setIsOpenedTermModal(false)}
      >
        <TermWrapper>
          <TermBody className="term-body-wrapper">
            <TermTemplate />
          </TermBody>
          <TermFooter>
            <ButtonOutline
              type="button"
              onClick={() => {
                setIsAgreedTerm(false);
                setIsOpenedTermModal(false);
              }}
            >
              Decline
            </ButtonOutline>
            <Button
              type="button"
              disabled={disabledAccept}
              onClick={() => {
                setIsAgreedTerm(true);
                setIsOpenedTermModal(false);
              }}
            >
              Agree
            </Button>
          </TermFooter>
        </TermWrapper>
      </ModalWrapper>
    </Form>
  );
};

export default SignUpForm;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;

  .button-wrapper {
    width: 100%;
    margin-top: -10px;

    & button[type="submit"] {
      width: 100%;
    }
  }

  .form-group-checkbox {
    margin-top: 30px;
  }
`;
const PasswordIconWrapper = styled.span``;

const ModalWrapper = styled(Dialog)`
  .MuiPaper-root.MuiPaper-elevation {
    min-height: 80vh;
    width: 80vw;
    max-width: 980px;
    max-height: calc(100vh - 52px);
    height: 100%;

    ${(props) => props.theme.breakpoints.down("sm")} {
      margin: 0;
    }
  }
`;

const TermWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px clamp(20px, 4vw, 40px);
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const TermBody = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const TermFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 70px;
  gap: clamp(20px, 10vw, 80px);
`;
