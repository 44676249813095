import { StatusIndicator } from "models/common.model";
import { Flag } from "./Flag";

interface Props {
  status: StatusIndicator;
}

export const StatusFlag = ({ status }: Props) => {
  switch (status) {
    case StatusIndicator.DRAFT:
      return <Flag colorSchema="gray" text={status} />;
    case StatusIndicator.REVIEW:
      return <Flag colorSchema="yellow" text={status} />;
    case StatusIndicator.PUBLISHED:
      return <Flag colorSchema="green" text={status} />;
    case StatusIndicator.FLAGGED:
      return <Flag colorSchema="red" text={status} />;
    case StatusIndicator.ARCHIVED:
      return <Flag colorSchema="outline" text={status} />;
    default:
      return null;
  }
};
