import { Popover } from "@mui/material";
import { CheckIcon, DownArrowIcon } from "assets/images/common";
import { Button, ButtonOutline, Text } from "components";
import { FilterOptions, SelectItem } from "models/common.model";
import { useRef, useState } from "react";
import styled from "styled-components/macro";

interface Props {
  title: string;
  name: string;
  data: SelectItem[];
  filterValues: string[];
  filterCount: number;
  onFilter: (data: FilterOptions, isApply: boolean) => void;
}

export const MultiFilter = ({
  title,
  name,
  data,
  filterCount,
  filterValues = [],
  onFilter,
}: Props) => {
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectedNumber = filterValues?.length;

  const handleShowResult = (isApply: boolean) => {
    onFilter({ [name]: filterValues }, isApply);
  };

  const onSelectItem = (itemId: string) => {
    const isHas = filterValues.find((id) => id === itemId);
    let tempArr = [...filterValues];
    if (isHas) tempArr = tempArr.filter((id) => id !== itemId);
    else tempArr.push(itemId);
    onFilter({ [name]: tempArr }, false);
  };

  const clearAll = () => {
    onFilter({ [name]: [] }, true);
  };

  const selectAll = () => {
    const tempArr = data.map((i) => i.id);
    onFilter({ [name]: tempArr }, true);
  };

  return (
    <Wrapper className={isOpen ? "edit-field" : "text-field"}>
      <div ref={popoverRef} onClick={() => setIsOpen(!isOpen)}>
        <Filter className={`${isOpen && "active"}`}>
          <Text size="md" className="title">
            {title}
          </Text>
          {!!selectedNumber && <NumberBox>{selectedNumber}</NumberBox>}
          <DownArrowIcon />
        </Filter>
      </div>

      <PopoverWrapper
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: "180px",
            borderRadius: "16px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
        anchorEl={popoverRef.current}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <SelectWrapper>
          {data.map((item) => (
            <Item
              key={item.id}
              className={`${
                filterValues.find((id) => item.id === id) && "checked"
              }`}
              onClick={() => onSelectItem(item.id)}
            >
              {filterValues.find((id) => item.id === id) && (
                <IconWrapper>
                  <CheckIcon color="#373b59" />
                </IconWrapper>
              )}
              {item.name}
            </Item>
          ))}
        </SelectWrapper>
        <Footer>
          <ButtonOutline onClick={() => clearAll()}>Clear all</ButtonOutline>
          <ButtonOutline onClick={() => selectAll()} className="btn-select-all">
            Select all
          </ButtonOutline>
          <Button
            className={`${!filterCount && "hide-button"}`}
            onClick={() => {
              handleShowResult(true);
              setIsOpen(false);
            }}
          >{`Show ${filterCount} results`}</Button>
        </Footer>
      </PopoverWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  position: relative;

  &.edit-field {
    .display-text {
      color: transparent;
    }
  }
`;

const NumberBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #b4bac4;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 0 8px;
  background-color: #ffffff;
  border: 1px solid #d8d9df;
  gap: 6px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: #d8d9df2f;
  }

  ${NumberBox} {
    background: #ebecf0;
  }

  &.active {
    background-color: #d8d9df;

    ${NumberBox} {
      background-color: #b4bac4;
    }
  }

  .title {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const PopoverWrapper = styled(Popover)`
  .MuiPaper-root {
    min-width: clamp(380px, calc(100vw - 40px), 480px);
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  z-index: 1;
  background-color: white;
  color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 0px 50px rgba(5, 10, 48, 0.1);
  max-height: clamp(380px, calc(100vh - 100px), 480px);
  overflow-y: auto;
  padding-bottom: 100px;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border: 1px solid #d8d9df;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 14px;
  cursor: pointer;

  &.checked {
    border-color: #ebecf0;
    background: #ebecf0;
  }

  &:hover {
    border-color: #ebecf0;
    background: #ebecf0;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 12px;
  border-top: 1px solid #ebecef;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: rgb(5 10 48 / 15%) 3px 2px 20px 0px;

  button {
    border-radius: 4px;
    padding: 0 20px;
    height: 40px;

    &.btn-select-all {
      background-color: #d8d9df;
      color: ${(props) => props.theme.palette.secondary.main};
    }

    &.hide-button {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ebecf0;
  background: #ffffff;

  svg {
    width: 10px;
    height: 10px;
  }
`;
