import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import fundApi from "api/fund.api";
import { globalActions } from "store/global/global.slice";
import { fundActions } from "./fund.slice";
import { CustomFundParams } from "models/fund.model";
import { errorNotification } from "utils/app.util";
import { deleteFolder } from "utils/firebase.util";

function* getFundList(action: PayloadAction<{ organisationId: string }>) {
  try {
    yield put(globalActions.setPageLoading(true));
    const { organisationId } = action.payload;

    const { fundListGet } = yield call(fundApi.fundListGet, organisationId);

    if (fundListGet) {
      const { fundList } = fundListGet;

      yield put(fundActions.getFundListSuccess(fundList || []));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getFund(action: PayloadAction<{ fundId: string }>) {
  try {
    yield put(globalActions.setPageLoading(true));
    const { fundId } = action.payload;

    const { fundGet } = yield call(fundApi.fundGet, fundId);

    if (fundGet) {
      const { fund } = fundGet;

      yield put(fundActions.getFundSuccess(fund));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* createFund(
  action: PayloadAction<{
    organisationId: string;
    callback?: (fundId: string) => void;
  }>
) {
  try {
    yield put(fundActions.setLoading(true));
    const { organisationId, callback } = action.payload;

    const { fundCreate } = yield call(fundApi.fundCreate, organisationId);

    if (fundCreate) {
      const { fund } = fundCreate;

      yield put(fundActions.createFundSuccess(fund));
      callback && callback(fund.id);
    }

    yield put(fundActions.setLoading(false));
  } catch (err) {
    yield put(fundActions.setLoading(false));
    yield put(
      globalActions.pushNotification({
        type: "error",
        message: "Create fund failed",
      })
    );
  }
}

function* updateFund(
  action: PayloadAction<{
    fundId: string;
    input: CustomFundParams;
    callback?: () => void;
  }>
) {
  try {
    yield put(fundActions.setLoading(true));

    const { fundId, input, callback } = action.payload;

    const { fundUpdate } = yield call(fundApi.fundUpdate, fundId, input);

    if (fundUpdate) {
      const { fund } = fundUpdate;

      yield put(fundActions.updateFundSuccess(fund));
      callback && callback();
    }

    yield put(fundActions.setLoading(false));
  } catch (err) {
    yield put(globalActions.setPageLoading(false));
    yield put(errorNotification("Update fund failed"));
  }
}

function* deleteFund(
  action: PayloadAction<{
    fundId: string;
    organisationId: string;
    callback?: () => void;
  }>
) {
  try {
    yield put(fundActions.setLoading(true));
    const { fundId, organisationId, callback } = action.payload;

    yield call(deleteFolder, `workspaces/${organisationId}/funds/${fundId}`);

    const { fundDelete } = yield call(fundApi.fundDelete, fundId);

    if (fundDelete) {
      const { fundId } = fundDelete;

      yield put(fundActions.deleteFundSuccess({ fundId }));
      callback && callback();
    }

    yield put(fundActions.setLoading(false));
  } catch (err) {
    yield put(fundActions.setLoading(false));
    yield put(
      globalActions.pushNotification({
        type: "error",
        message: "Delete fund failed",
      })
    );
  }
}

export default function* fundSaga() {
  yield takeLatest(fundActions.getFundList.type, getFundList);
  yield takeLatest(fundActions.getFund.type, getFund);
  yield takeLatest(fundActions.createFund.type, createFund);
  yield takeLatest(fundActions.updateFund.type, updateFund);
  yield takeLatest(fundActions.deleteFund.type, deleteFund);
}
