import {
  IconButton,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { CloseIcon, NoticeIcon } from "assets/images/common";
import { InputHTMLAttributes } from "react";
import {
  FieldValues,
  UseFormRegister,
  UseFormReset,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import styled from "styled-components/macro";
import { capitalizeFirstLetter } from "utils/system.util";
interface InputProps {
  hasIcon: boolean;
}
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactElement;
  error?: string;
  hasClear?: boolean;
  reset?: UseFormReset<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  formRef?: UseFormRegister<FieldValues>;
  trigger?: UseFormTrigger<FieldValues>;
  afterClear?: () => void;
}

export const TextField: React.FC<Props> = ({
  error,
  icon,
  hasClear,
  formRef,
  reset,
  watch,
  trigger,
  afterClear,
  ...props
}) => {
  const option = formRef ? { ...formRef(props.name || "") } : {};
  const hasValue =
    watch && (watch(props.name || "") === 0 || !!watch(props.name || ""));

  return (
    <Wrapper className="text-field-wrapper">
      <Input
        hasIcon={!!icon || !!hasClear}
        {...option}
        {...props}
        className={error && "error"}
        onWheel={(e) => e.currentTarget.blur()}
      />
      {!error && icon}
      {error && (
        <Error className="field-error">
          <ErrorTooltip
            title={capitalizeFirstLetter(error)}
            placement="top-end"
            sx={{ textTransform: "capitalize" }}
          >
            <IconButton>
              <NoticeIcon />
            </IconButton>
          </ErrorTooltip>
        </Error>
      )}
      {hasValue && hasClear && !error && (
        <CloseButtonWrapper>
          <IconButton
            disabled={props.disabled}
            onClick={() => {
              if (props.name && reset) {
                reset({ [props.name]: props.type === "number" ? null : "" });
                trigger && trigger();
                afterClear && afterClear();
              }
            }}
          >
            <CloseIcon color="#a5a7b4" />
          </IconButton>
        </CloseButtonWrapper>
      )}
    </Wrapper>
  );
};

const Error = styled.div`
  position: absolute;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FAFBFC",
    color: "#eb5a46",
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const Wrapper = styled.div`
  position: relative;
  .eye-icon {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
  & > ${Error} {
    top: 4px;
    right: 4px;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  button {
    padding: 4px;
    background-color: #f4f5f7;
  }

  svg {
    width: 8px;
    height: 8px;
  }
`;

const Input = styled.input`
  border: 1px solid #ebecef;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  height: 48px;
  padding: 0 16px;
  padding-right: ${(props: InputProps) => (props.hasIcon ? "40px" : "20px")};
  width: 100%;
  color: ${(props) => props.theme.palette.text.main};

  transition: all 0.7s;

  &::placeholder {
    color: #a5a7b4;
  }
  &:hover {
    border-color: #d8d9df;
  }
  &.error {
    border-color: ${(props) => props.theme.palette.error.main};
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.38);
    background: unset;
  }

  &[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;
