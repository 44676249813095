import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import createSagaMiddleWare from "redux-saga";
import rootSaga from "./rootSaga";

import { globalReducer } from "./global/global.slice";
import { authReducer } from "./auth/auth.slice";
import { workspaceReducer } from "./workspace/workspace.slice";
import { prospectReducer } from "./prospect/prospect.slice";
import { feedReducer } from "./feed/feed.slice";
import { organisationReducer } from "./organisation/organisation.slice";
import { fundraisingReducer } from "./fundraising/fundraising.slice";
import { chatReducer } from "./chat/chat.slice";
import { reportReducer } from "./report/report.slice";
import { cachedReducer } from "./cached/cached.slice";
import { postReducer } from "./post/post.slice";
import { fundReducer } from "./fund/fund.slice";

const sagaMiddleware = createSagaMiddleWare();

export const store = configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    workspace: workspaceReducer,
    organisation: organisationReducer,
    feed: feedReducer,
    prospect: prospectReducer,
    fundraising: fundraisingReducer,
    chat: chatReducer,
    report: reportReducer,
    cached: cachedReducer,
    post: postReducer,
    fund: fundReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
