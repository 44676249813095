import styled from "styled-components";

import { InstagramIcon, LinkedinIcon, TwitterIcon } from "assets/images/common";
import DetailsImage from "assets/images/insights/details.png";

export const DetailsBar = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={DetailsImage} />
      </ImageWrapper>

      <InfoWrapper>
        <div className="title">Details</div>

        <InfoItem>
          Info: <span>Company Brief</span>
        </InfoItem>

        <InfoItem>
          Website: <span>https://spectre-biotech.com</span>
        </InfoItem>

        <InfoItem>
          Location: <span>France</span>
        </InfoItem>
      </InfoWrapper>

      <Footer>
        <LinkedinIcon />
        <InstagramIcon />
        <TwitterIcon />
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid #d8d9df;
  border-radius: 12px;
  padding: 24px;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  margin: -24px -24px 0px;
  background: #2f52f5;

  img {
    width: 288px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    color: ${(props) => props.theme.palette.secondary.dark};
    font-weight: 700;
    font-size: 24px;
  }
`;

const InfoItem = styled.div`
  display: flex;
  gap: 8px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;

  span {
    color: ${(props) => props.theme.palette.secondary.dark};
    font-weight: 700;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 40px;
  padding-top: 32px;
  border-top: 1px solid #ebecf0;
`;
