import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { StatusIndicator } from "models/common.model";
import { TeamMember } from "models/organisation.model";
import { hasValue, reorderTeamMembers } from "utils/system.util";
import { ProspectState, initialState } from "./prospect.slice";

const selectDomain = (state: { prospect: ProspectState }) =>
  state.prospect || initialState;

export const selectProspects = createSelector(
  [selectDomain],
  (state) => state.prospects
);

export const selectProspect = createSelector(
  [selectDomain],
  (state) => state.selectedProspect
);

export const selectSearchProspect = createSelector(
  [selectDomain],
  (state) => state.searchProspects
);

export const selectProspectId = createSelector(
  [selectDomain],
  (state) => state.selectedProspect?.id
);

export const selectProspectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectProspectTeamMembers = createSelector(
  [selectDomain],
  (state) =>
    reorderTeamMembers(state.selectedProspect.teamMembers as TeamMember[])
);

export const selectPossibleEditProspect = createSelector(
  [selectProspect],
  ({ status }) =>
    _.includes(
      [StatusIndicator.DRAFT, StatusIndicator.FLAGGED],
      status as StatusIndicator
    )
);

export const selectProspectProgress = createSelector(
  [selectProspect],
  (state) => {
    if (!state.id) return [];

    const {
      posterUrl,
      logoUrl,
      displayName,
      description,
      websiteUrl,
      sector,
      impactValues,
      businessType,
      productType,
      annualRevenue,
      MRR,
      country,
      city,
      zip,
      address,
      slogan,
    } = state;

    return [
      { label: "Logo", isComplete: hasValue(logoUrl) },
      { label: "Cover", isComplete: hasValue(posterUrl) },
      {
        label: "My Company",
        isComplete: hasValue(displayName) && hasValue(slogan),
      },
      {
        label: "About",
        isComplete: !!description && !!websiteUrl,
      },
      { label: "Sectors", isComplete: hasValue(sector) },
      { label: "Impacts", isComplete: hasValue(impactValues) },
      {
        label: "Business",
        isComplete:
          hasValue(businessType) &&
          hasValue(productType) &&
          hasValue(annualRevenue) &&
          hasValue(MRR),
      },
      {
        label: "Location",
        isComplete:
          hasValue(country) &&
          hasValue(city) &&
          hasValue(zip) &&
          hasValue(address),
      },
    ];
  }
);
