import { Organisation, SelectType } from "./organisation.model";

export enum AssetType {
  AssetOrganisation = "AssetOrganisation",
  PseudoOrganisation = "PseudoOrganisation",
  AssetTypesNetwork = "Organisation",
}
export interface Asset extends Organisation {
  __typename: AssetType;
  vintageYear: string;
  capitalInvested: number;
  IRR: number;
  RRR: number;
  CBR: number;
  entrepreneurialScore: number;
  currentRound: SelectType;
  link?: {
    type: AssetType.AssetTypesNetwork;
    id: string;
  };
}

export interface CreateAssetLinkParams {
  toId: string;
  assetType: AssetType.AssetTypesNetwork;
}
export interface CreateAssetPseudoParams {
  input: {
    displayName: string;
    websiteUrl: string;
  };
  assetType: AssetType.PseudoOrganisation;
}

export interface DeleteAssetParams {
  assetId: string;
  assetType: AssetType;
}

export interface assetFuzzySearch {
  websiteUrl: string;
  type: AssetType.AssetTypesNetwork;
  id: string;
  displayName: string;
}

export interface Report {
  id: string;
  displayName: string;
  data: {
    startDate: number;
    endDate: number;
    bestPerformerCompanies: Asset[];
    worstPerformerCompanies: Asset[];
  };
}
