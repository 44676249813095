import { useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import commonApi from "api/common.api";
import {
  CheckBox,
  OrganisationDropdown,
  OrganisationFieldInput,
  SectionWrapper,
} from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectOrganisation,
  selectPossibleEditOrganisation,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { SelectSection } from "./SelectSection";
import {
  selectBusinessTypes,
  selectCurrencies,
  selectProductTypes,
} from "store/cached/cached.selector";
import { cachedActions } from "store/cached/cached.slice";

export const BusinessSection = () => {
  const [firstChecked, setFirstChecked] = useState(false);
  const [secondChecked, setSecondChecked] = useState(false);

  const dispatch = useAppDispatch();
  const possibleEditOrganisation = useAppSelector(
    selectPossibleEditOrganisation
  );
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = [AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(
    authMode
  );
  const { id, preFunded, prevPartOf } = useAppSelector(selectOrganisation);
  const fixedData = useAppSelector(selectCurrencies);

  const currencyOptions = fixedData.data.map((item) => ({
    ...item,
    label: `${item.name} (${item.symbol})`,
  }));

  const numberYup = yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .min(0, "The field value must be greater than or equal to 0.");

  const stringYup = yup.string().nullable();

  const handleUpdatePreFunded = (checked: boolean) => {
    const data = {
      preFunded: checked,
    };

    dispatch(
      organisationActions.updateOrganisation({
        organisationId: id,
        input: data,
      })
    );
  };

  useEffect(() => {
    setFirstChecked(!!preFunded);
  }, [preFunded]);

  useEffect(() => {
    setSecondChecked(!!prevPartOf);
  }, [prevPartOf]);

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName: "currencies",
        callApi: commonApi.currenciesGet,
        updateAt: fixedData.updateAt,
      })
    );
  }, [dispatch, fixedData.updateAt]);

  return (
    <SectionWrapper title="Business">
      <Container>
        <SelectSection
          subTitle="Business Type"
          name="businessType"
          resName="businessTypes"
          callApi={commonApi.businessTypesGet}
          selector={selectBusinessTypes}
          inline
        />

        <SelectSection
          subTitle="Product Type"
          name="productType"
          resName="productTypes"
          callApi={commonApi.productTypesGet}
          selector={selectProductTypes}
          inline
        />

        <OrganisationDropdown
          fieldName="Currency"
          name="currency"
          placeholder="Fill currency"
          freeSolo={false}
          options={currencyOptions}
        />

        <OrganisationFieldInput
          fieldType="number"
          fieldName="Annual Revenue"
          name="annualRevenue"
          placeholder="Fill Annual Revenue"
          yupSchema={numberYup}
        />

        <OrganisationFieldInput
          fieldType="number"
          fieldName="MRR"
          name="MRR"
          placeholder="Fill MRR"
          yupSchema={numberYup}
        />

        <div className="label">Track Record</div>

        <CheckBox
          disabled={isAdmin || !possibleEditOrganisation}
          mainsize={20}
          label="I have raised funds in the past"
          checked={firstChecked}
          onChange={(e) => {
            setFirstChecked(e.target.checked);
            handleUpdatePreFunded(e.target.checked);
          }}
        />

        {firstChecked && (
          <>
            <OrganisationFieldInput
              fieldName="Total Amount"
              fieldType="number"
              name="preFundedAmount"
              placeholder="Fill Total Amount"
              yupSchema={numberYup.min(
                1,
                "This amount should be greater than 0"
              )}
            />

            <OrganisationFieldInput
              fieldName="Lead investor/ VC/ Family office name"
              name="leadInvestor"
              placeholder="Fill Lead investor/ VC/ Family office name"
              yupSchema={stringYup}
            />
          </>
        )}

        <CheckBox
          disabled={isAdmin || !possibleEditOrganisation}
          mainsize={20}
          label="I have been part of an Incubator/Accelerator"
          checked={secondChecked}
          onChange={(e) => {
            setSecondChecked(e.target.checked);
          }}
        />

        {secondChecked && (
          <>
            <OrganisationFieldInput
              fieldName="Incubator/Accelerator name"
              name="prevPartOf"
              placeholder="Fill Incubator/Accelerator name"
              yupSchema={stringYup}
            />
          </>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .label {
    color: ${(props) => props.theme.palette.primaryText.main};
    font-size: 14px;
    font-weight: 600;
  }

  .MuiFormGroup-root {
    width: fit-content;
  }
`;
