import { Chart } from "chart.js/auto";
import { useEffect, useRef } from "react";
import styled from "styled-components/macro";

interface Props {
  labels: string[];
  data: number[];
  chartStyleConfig?: { [key: string]: string };
  othersTooltipData?: { label: string; value: string }[];
}

export const RadarChart = ({
  labels,
  data,
  chartStyleConfig,
  othersTooltipData,
}: Props) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"radar"> | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "radar",
        data: {
          labels,
          datasets: [
            {
              label: "Founder score",
              data,
              ...chartStyleConfig,
              // backgroundColor: "rgba(255, 99, 132, 0.2)",
              // borderColor: "rgb(255, 99, 132)",
              // pointBackgroundColor: "rgb(255, 99, 132)",
              // pointBorderColor: "#fff",
              // pointHoverBackgroundColor: "#fff",
              // pointHoverBorderColor: "rgb(255, 99, 132)",
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: "right",
              labels: {
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: false,
                padding: 16,
              },
            },
            tooltip: {
              callbacks: {
                footer: (item) => {
                  if (item[0].label === "Others" && othersTooltipData) {
                    return "";
                  }
                },
              },
            },
          },
        },
      });
    }
  }, [data, labels, chartStyleConfig, othersTooltipData]);

  return (
    <Wrapper>
      <canvas ref={chartRef} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
