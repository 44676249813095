import styled from "styled-components/macro";
import ForgotPasswordForm from "./components/ForgotPasswordFrom";
import { ForgotPasswordHeader } from "./components/ForgotPasswordStuff";

export const ForgotPasswordPage = () => {
  return (
    <ForgotPasswordWrapper>
      <ForgotPasswordHeader />
      <ForgotPasswordForm />
    </ForgotPasswordWrapper>
  );
};

const ForgotPasswordWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 30px;
    border-radius: 16px;
  }
`;
