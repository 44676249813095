import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const DevicesIcon = ({ color = "#606C82" }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 3.0625H0.875L0.875 10.9375H6.5625V12.25H0.875C0.391751 12.25 0 11.8582 0 11.375L0 2.625C0 2.14175 0.391751 1.75 0.875 1.75H12.25C12.7332 1.75 13.125 2.14175 13.125 2.625V3.0625H12.25ZM13.125 5.6875H8.75V11.8125H13.125V5.6875ZM8.75 4.375C8.26675 4.375 7.875 4.76675 7.875 5.25V12.25C7.875 12.7332 8.26675 13.125 8.75 13.125H13.125C13.6082 13.125 14 12.7332 14 12.25V5.25C14 4.76675 13.6082 4.375 13.125 4.375H8.75ZM11.1806 10.5737C11.1086 10.5257 11.024 10.5 10.9375 10.5C10.8215 10.5 10.7102 10.5461 10.6281 10.6281C10.5461 10.7102 10.5 10.8215 10.5 10.9375C10.5 11.024 10.5257 11.1086 10.5737 11.1806C10.6218 11.2525 10.6901 11.3086 10.7701 11.3417C10.85 11.3748 10.938 11.3835 11.0229 11.3666C11.1077 11.3497 11.1857 11.308 11.2469 11.2469C11.308 11.1857 11.3497 11.1077 11.3666 11.0229C11.3835 10.938 11.3748 10.85 11.3417 10.7701C11.3086 10.6901 11.2525 10.6218 11.1806 10.5737Z"
        fill={color}
      />
    </svg>
  );
};
