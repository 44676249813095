import { gql } from "graphql-request";
import { UserDeleteParams, UserRegisterParams } from "models/auth.model";
import { GraphqlQuery, GraphqlResponse } from "models/common.model";
import { graphqlRequest } from "utils/graphQL.util";

const authApi = {
  userRegister(input: UserRegisterParams, query?: GraphqlQuery) {
    const variables = {
      input,
    };
    const defaultQuery = gql`
      mutation ($input: userSignupWIthEmailPwInput) {
        userSignupWithEmailPassword(input: $input) {
          uid
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },
  userDelete(
    input: UserDeleteParams,
    query?: GraphqlQuery
  ): Promise<GraphqlResponse<string>> {
    const variables = {
      input,
    };
    const defaultQuery = gql`
      mutation ($input: userDeleteInput!) {
        userDelete(input: $input)
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },
};

export default authApi;
