import styled from "styled-components";

import { LineChart, SectionWrapper } from "components";

import { InternalPost } from ".";

export const InternalCommentsSection = () => {
  return (
    <SectionWrapper title="Internal comments">
      <Container>
        <ChartWrapper>
          <div className="title">Company Valuation Change</div>

          <LineChart
            labels={["", "Q1", "Q2", "Q3", "Q4"]}
            data={[10, 13, 15, 17, 18]}
            maxTicksLimit={4}
          />
        </ChartWrapper>

        {[...Array(2)].map((_, index) => (
          <InternalPost key={index} />
        ))}

        <ShowAllButton>Show all</ShowAllButton>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ChartWrapper = styled.div`
  padding-top: 24px;
  border-top: 1px solid #ebecef;

  .title {
    margin-bottom: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 20px;
    font-weight: 700;
  }

  img {
    width: 100%;
  }
`;

const ShowAllButton = styled.div`
  width: fit-content;
  margin-top: -12px;
  padding: 12px 24px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.05);
  }
`;
