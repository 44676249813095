import { yupResolver } from "@hookform/resolvers/yup";
import { Popover } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { EditIcon, LeftArrowIcon } from "assets/images/common";
import {
  BasicTable,
  ButtonOutline,
  FlexBox,
  SectionWrapper,
  TextField,
} from "components";
import { Combobox } from "components/Feature/Combobox";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { SortConfig, TBD } from "models/common.model";

const CONFIG = [
  {
    name: "Rank",
    width: 90,
  },
  {
    name: "Company",
    width: "auto",
  },
  {
    name: "Traffic Rank",
    fieldName: "trafficRank",
    justifyHead: "flex-end",
    width: 200,
    isSort: true,
  },
  {
    name: "Traffic Est.",
    fieldName: "trafficEst",
    justifyHead: "flex-end",
    width: 200,
    isSort: true,
  },
];

export const CompetitorsSection = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const popoverRef = useRef<HTMLDivElement | null>(null);

  const schema = yup.object().shape({
    companyName: yup.string(),
    website: yup.string(),
    trafficRank: yup.number(),
    trafficEst: yup.number(),
  });

  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSort = (sort: SortConfig) => {
    setTableData(_.orderBy(tableData, [sort.name], [sort.type || "asc"]));
  };

  useEffect(() => {
    const data = [...Array(8)]?.map(() => {
      return {
        rank: <GroupCell>1</GroupCell>,
        company: isEdit ? (
          <PopoverTrigger
            ref={popoverRef}
            onClick={(e) => {
              popoverRef.current = e.currentTarget;
              setIsOpen(true);
            }}
          >
            <div>Luksusbaby & Tweens 👑: luksusbaby.dk</div>
          </PopoverTrigger>
        ) : (
          <GroupCell className="cursor-pointer" onClick={() => navigate("")}>
            <CellValue>
              <FlexBox>
                <div className="line-clamp">Luksusbaby & Tweens 👑</div>

                <RightArrow>
                  <LeftArrowIcon />
                </RightArrow>
              </FlexBox>
            </CellValue>
            <CellDetail color="#A5A7B4">luksusbaby.dk</CellDetail>
          </GroupCell>
        ),
        trafficRank: isEdit ? (
          <TextField
            type="number"
            name="trafficRank"
            min={0}
            defaultValue={198124}
            formRef={register}
            error={errors.trafficRank?.message as string}
          />
        ) : (
          <GroupCell className="text-right">
            <CellValue>198 124</CellValue>
            <CellDetail color="#58A369">+36 495</CellDetail>
          </GroupCell>
        ),
        trafficEst: isEdit ? (
          <TextField
            type="number"
            name="trafficEst"
            min={0}
            defaultValue={107}
            formRef={register}
            error={errors.trafficEst?.message as string}
          />
        ) : (
          <GroupCell className="text-right">
            <CellValue>107</CellValue>
            <CellDetail>–</CellDetail>
          </GroupCell>
        ),
      };
    });

    setTableData(data);
  }, [
    errors.trafficEst?.message,
    errors.trafficRank?.message,
    isEdit,
    navigate,
    register,
  ]);

  return (
    <SectionWrapper title="Competitors">
      <Container>
        <ButtonOutline
          icon={!isEdit && <EditIcon />}
          onClick={() => setIsEdit(!isEdit)}
        >
          {isEdit ? "Save" : "Edit"}
        </ButtonOutline>

        <BasicTable config={CONFIG} data={tableData} onSort={handleSort} />
      </Container>

      <Popover
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: "230px",
            borderRadius: "16px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
        anchorEl={popoverRef.current}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <PopoverContent>
          <Combobox
            // fieldName="companyName"
            id="combobox-company-name"
            freeSolo={false}
            options={[]}
            onchange={(value) => {
              setValue("companyName", value);
              trigger("companyName");
            }}
            error={errors.companyName?.message as string}
          />

          <GroupTextField
            fieldName="Website Link"
            name="website"
            formRef={register}
            error={errors.website?.message as string}
          />
        </PopoverContent>
      </Popover>
    </SectionWrapper>
  );
};

const Container = styled.div`
  button {
    position: absolute;
    top: 24px;
    right: 16px;
    height: 35px;
    padding: 6px 12px;
    font-size: 14px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .MuiPaper-root {
    margin: -24px;
    width: calc(100% + 48px);
    border: none;

    .text-field-wrapper {
      max-width: 120px;
      margin-left: auto;

      input[type="number"] {
        height: 40px;
        padding: 0px 16px;
        font-size: 14px;
        text-align: right;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }
  }
`;

const GroupCell = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;

  &.text-right {
    text-align: right;
  }

  &.cursor-pointer {
    cursor: pointer;
  }
`;

const CellValue = styled.div`
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 150px;
    overflow: hidden;
  }
`;

const CellDetail = styled.div`
  font-size: 12px;
  color: ${({ color }: { color?: string }) => color};
`;

const RightArrow = styled.div`
  svg {
    transform: rotate(180deg);
  }
`;

const PopoverTrigger = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  border: 1px solid #d8d9df;
  border-radius: 4px;
  max-width: 230px;
  cursor: pointer;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    height: 40px;
    font-size: 14px;
  }

  .text-field-wrapper input {
    height: 40px;
    font-size: 14px;
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root li {
      font-size: 14px;
    }
  }
`;
