import { Popover } from "@mui/material";
import { DownArrowIcon } from "assets/images/common";
import { Button, ButtonOutline, GroupText, SliderBar, Text } from "components";
import { FilterOptions, SelectItem } from "models/common.model";
import { useRef, useState } from "react";
import styled from "styled-components/macro";

interface SliderData {
  defaultPoint: number;
  totalItem: number;
}
interface Props {
  title: string;
  name: string;
  data: SelectItem[];
  filterCount: number;
  filterValues: string[];
  onFilter: (data: FilterOptions, isApply: boolean) => void;
  type: string;
  sliderData?: SliderData;
}

export const OneSelectFilter = ({
  title,
  name,
  filterCount,
  onFilter,
  type,
  filterValues,
  sliderData,
}: Props) => {
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { defaultPoint, totalItem } = sliderData as SliderData;
  const isHasFilter = !!filterValues?.length;
  const point = isHasFilter ? Number(filterValues?.[0]) : defaultPoint;

  const onMoveSlider = (value: number | number[]) => {
    if (typeof value === "number") {
      onFilter({ [name]: [`${value}`] }, false);
    }
  };

  const filterPoint = () => {
    if (isHasFilter) {
      onFilter({ [name]: filterValues }, true);
    } else {
      onFilter({ [name]: [defaultPoint.toString()] }, true);
    }
  };

  const clearAll = () => {
    onFilter({ [name]: [] }, true);
  };

  return (
    <Wrapper className={isOpen ? "edit-field" : "text-field"}>
      <div ref={popoverRef} onClick={() => setIsOpen(!isOpen)}>
        <Filter className={`${isOpen && "active"}`}>
          <Text size="md" className="title">
            {title}
          </Text>
          {isHasFilter && <NumberBox>{1}</NumberBox>}
          <DownArrowIcon />
        </Filter>
      </div>

      <PopoverWrapper
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: "180px",
            borderRadius: "16px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
        anchorEl={popoverRef.current}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <SelectWrapper>
          {type === "ARRANGE" && (
            <SliderWrapper>
              <GroupText>
                <Text size="md">Show more than:</Text>
                <Text size="lg">{` ${point || defaultPoint}/100`}</Text>
              </GroupText>
              <SliderBar value={point} onMoveController={onMoveSlider} />
            </SliderWrapper>
          )}
        </SelectWrapper>
        <Footer>
          <ButtonOutline onClick={clearAll}>Clear all</ButtonOutline>
          <Button
            className={`${
              isHasFilter
                ? !filterCount && "hide-button"
                : !totalItem && "hide-button"
            }`}
            onClick={() => {
              filterPoint();
              setIsOpen(false);
            }}
          >{`Show ${isHasFilter ? filterCount : totalItem} results`}</Button>
        </Footer>
      </PopoverWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  position: relative;

  &.edit-field {
    .display-text {
      color: transparent;
    }
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
`;

const NumberBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #b4bac4;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 0 8px;
  background-color: #ffffff;
  border: 1px solid #d8d9df;
  gap: 6px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: #d8d9df2f;
  }

  ${NumberBox} {
    background: #ebecf0;
  }

  &.active {
    background-color: #d8d9df;

    ${NumberBox} {
      background-color: #b4bac4;
    }
  }

  .title {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const PopoverWrapper = styled(Popover)`
  .MuiPaper-root {
    min-width: clamp(380px, calc(100vw - 40px), 380px);
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  z-index: 1;
  background-color: white;
  color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 16px;
  padding: 20px 30px;
  box-shadow: 0px 0px 50px rgba(5, 10, 48, 0.1);
  height: 320px;
  max-height: 160px;
  overflow-y: auto;
  padding-bottom: 100px;

  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 12px;
  border-top: 1px solid #ebecef;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: rgb(5 10 48 / 15%) 3px 2px 20px 0px;

  button {
    border-radius: 4px;
    padding: 0 20px;
    height: 40px;

    &.btn-select-all {
      background-color: #d8d9df;
      color: ${(props) => props.theme.palette.secondary.main};
    }

    &.hide-button {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }
  }
`;
