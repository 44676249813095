import styled from "styled-components";

import { FlagText, SectionWrapper, Text } from "components";

interface Props {
  title: string;
  label: string;
  children: React.ReactNode;
}

export const ChartSection = ({ title, label, children }: Props) => {
  return (
    <SectionWrapper>
      <Header>
        <Text size="lg" fontSize="20px" fontWeight={700}>
          {title}
        </Text>

        <FlagText type="WHITE">{label}</FlagText>
      </Header>

      {children}
    </SectionWrapper>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;

  .flag-text {
    border: 1px solid #ebecef;
  }
`;
