import styled from "styled-components/macro";

interface Props {
  children: React.ReactNode;
}

export const GroupText = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & * {
    word-wrap: no-wrap;
  }
`;
