import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const MarkIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333984 13.0003V1.91699C0.333984 1.65033 0.433984 1.41699 0.633984 1.21699C0.833984 1.01699 1.06732 0.916992 1.33398 0.916992H8.66732C8.93398 0.916992 9.16732 1.01699 9.36732 1.21699C9.56732 1.41699 9.66732 1.65033 9.66732 1.91699V13.0003L5.00065 11.0003L0.333984 13.0003ZM1.33398 11.4837L5.00065 9.93366L8.66732 11.4837V1.91699H1.33398V11.4837ZM1.33398 1.91699H8.66732H5.00065H1.33398Z"
        fill={color}
      />
    </svg>
  );
};
