import { gql } from "graphql-request";
import { CustomFundParams } from "models/fund.model";

import { graphqlRequest } from "utils/graphQL.util";

const fundApi = {
  fundListGet(organisationId: string) {
    const variables = {
      organisationId,
    };

    const defaultQuery = gql`
      query FundListGet($organisationId: ID!) {
        fundListGet(organisationId: $organisationId) {
          ... on FundListSuccess {
            fundList {
              id
              createdAt
              displayName
              description
              logoUrl
              status
              organisation {
                id
                displayName
                logoUrl
                status
              }
            }
          }
          ... on NoFundsFoundError {
            message
          }
          ... on FundError {
            message
          }
          ... on InsuffecientPermissionError {
            message
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(defaultQuery, variables);
  },

  fundGet(fundId: string) {
    const variables = {
      fundId,
    };

    const defaultQuery = gql`
      query FundGet($fundId: ID!) {
        fundGet(fundId: $fundId) {
          ... on FundSuccess {
            fund {
              id
              createdAt
              displayName
              description
              logoUrl
              status
              organisation {
                id
                displayName
                logoUrl
                status
              }
            }
          }
          ... on FundError {
            message
          }
          ... on InsuffecientPermissionError {
            message
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(defaultQuery, variables);
  },

  fundCreate(organisationId: string) {
    const variables = {
      organisationId,
      input: { displayName: "" },
    };
    const defaultQuery = gql`
      mutation FundCreate($organisationId: ID!, $input: fundCreateInput) {
        fundCreate(organisationId: $organisationId, input: $input) {
          ... on FundSuccess {
            fund {
              id
              createdAt
              displayName
              description
              logoUrl
              status
              organisation {
                id
                displayName
                logoUrl
                status
              }
            }
          }
          ... on FundError {
            message
          }
          ... on InsuffecientPermissionError {
            message
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundUpdate(fundId: string, input: CustomFundParams) {
    const variables = {
      fundId,
      input,
    };
    const defaultQuery = gql`
      mutation FundUpdate($fundId: ID!, $input: fundUpdateInput) {
        fundUpdate(fundId: $fundId, input: $input) {
          ... on FundSuccess {
            fund {
              id
              createdAt
              displayName
              description
              logoUrl
              status
              organisation {
                id
                displayName
                logoUrl
                status
              }
            }
          }
          ... on FundError {
            message
          }
          ... on InsuffecientPermissionError {
            message
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundSubmit(fundId: string) {
    const variables = {
      fundId,
    };

    const defaultQuery = gql`
      mutation FundSubmit($fundId: ID!) {
        fundSubmit(fundId: $fundId) {
          ... on FundSuccess {
            fund {
              id
              createdAt
              displayName
              description
              logoUrl
              status
              organisation {
                id
                displayName
                logoUrl
                status
              }
            }
          }
          ... on FundError {
            message
          }
          ... on InsuffecientPermissionError {
            message
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundUnPublish(fundId: string) {
    const variables = {
      fundId,
    };

    const defaultQuery = gql`
      mutation FundUnPublish($fundId: ID!) {
        fundUnPublish(fundId: $fundId) {
          ... on FundSuccess {
            fund {
              id
              createdAt
              displayName
              description
              logoUrl
              status
              organisation {
                id
                displayName
                logoUrl
                status
              }
            }
          }
          ... on FundError {
            message
          }
          ... on InsuffecientPermissionError {
            message
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundDelete(fundId: string) {
    const variables = {
      fundId: fundId,
    };
    const defaultQuery = gql`
      mutation FundDelete($fundId: ID!) {
        fundDelete(fundId: $fundId) {
          ... on FundDeleteSuccess {
            fundId
          }
          ... on FundError {
            message
          }
          ... on InsuffecientPermissionError {
            message
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },
};

export default fundApi;
