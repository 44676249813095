import { FieldEditor, Text } from "components";
import { Combobox } from "components/Feature/Combobox";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { Prospect, ProspectMultiSelectType } from "models/prospect.model";
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import {
  selectProspect,
  selectProspectLoading,
} from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";

interface Props {
  name: string;
  dataList: ProspectMultiSelectType[];
  placeholder: string;
  textClass?: string;
}

export const ProspectCombobox = ({
  name,
  dataList,
  textClass,
  placeholder,
}: Props) => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectProspectLoading);
  const prospect = useAppSelector(selectProspect);
  const currentItem = prospect[name as keyof Prospect];
  const [selectItem, setSelectedItem] = useState<ProspectMultiSelectType>();

  const handleUpdateField = async (data: FieldValues) => {
    try {
      if (!prospect?.id) {
        dispatch(prospectActions.createProspect(data));
      } else {
        dispatch(
          prospectActions.updateProspect({
            prospectUpdateId: prospect?.id,
            input: data,
          })
        );
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  const handleSaveData = () => {
    if (selectItem) {
      const data = {
        [name]: {
          id: selectItem.id,
        },
      };
      handleUpdateField(data);
    }
  };

  useEffect(() => {
    if (currentItem) {
      setSelectedItem(currentItem as ProspectMultiSelectType);
    }
  }, [currentItem]);

  return (
    <FieldEditor
      loading={submitLoading}
      onHandle={handleSaveData}
      textElement={
        <Text size="md" color="#6A6D84" className={textClass}>
          {(currentItem as ProspectMultiSelectType)?.name || placeholder}
        </Text>
      }
      inputElement={
        <Combobox
          id={`prospect-combobox-${name}`}
          name={placeholder}
          options={dataList}
          onchange={(value) =>
            setSelectedItem(value as ProspectMultiSelectType)
          }
        />
      }
    ></FieldEditor>
  );
};
