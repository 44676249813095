import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const UploadIcon = ({ color = "white" }: Props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 16C8.21667 16 7.97933 15.904 7.788 15.712C7.596 15.5207 7.5 15.2833 7.5 15V7.85L5.625 9.725C5.44167 9.90833 5.204 9.996 4.912 9.988C4.62067 9.97933 4.38333 9.88333 4.2 9.7C4.01667 9.51667 3.925 9.28333 3.925 9C3.925 8.71667 4.01667 8.48333 4.2 8.3L7.8 4.7C7.9 4.6 8.00833 4.529 8.125 4.487C8.24167 4.44567 8.36667 4.425 8.5 4.425C8.63333 4.425 8.75833 4.44567 8.875 4.487C8.99167 4.529 9.1 4.6 9.2 4.7L12.8 8.3C12.9833 8.48333 13.075 8.71667 13.075 9C13.075 9.28333 12.9833 9.51667 12.8 9.7C12.6167 9.88333 12.3793 9.97933 12.088 9.988C11.796 9.996 11.5583 9.90833 11.375 9.725L9.5 7.85V15C9.5 15.2833 9.40433 15.5207 9.213 15.712C9.021 15.904 8.78333 16 8.5 16ZM1.5 5C1.21667 5 0.979 4.904 0.787 4.712C0.595667 4.52067 0.5 4.28333 0.5 4V2C0.5 1.45 0.696 0.979333 1.088 0.588C1.47933 0.196 1.95 0 2.5 0H14.5C15.05 0 15.521 0.196 15.913 0.588C16.3043 0.979333 16.5 1.45 16.5 2V4C16.5 4.28333 16.404 4.52067 16.212 4.712C16.0207 4.904 15.7833 5 15.5 5C15.2167 5 14.9793 4.904 14.788 4.712C14.596 4.52067 14.5 4.28333 14.5 4V2H2.5V4C2.5 4.28333 2.40433 4.52067 2.213 4.712C2.021 4.904 1.78333 5 1.5 5Z"
        fill={color}
      />
    </svg>
  );
};
