import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { TriangleDownIcon, TriangleUpIcon } from "assets/images/common";
import {
  OrderType,
  SortConfig,
  TableHeaderConfig,
  TBD,
} from "models/common.model";
import { ReactEventHandler, ReactNode, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { SpanBox } from "../Others/Utils";

interface Props {
  config: TableHeaderConfig[];
  data: TBD<unknown>[];
  title?: string;
  onSort?: (sort: SortConfig) => void;
}

export const BasicTable = ({ config, data, title, onSort }: Props) => {
  const [sort, setSort] = useState<SortConfig>({
    name: "",
    type: null,
  });

  const getSortColor = (name: string, type: OrderType) =>
    sort.name === name && sort.type === type ? "#373B59" : "#C2C7CF";

  useEffect(() => {
    if (sort.name && sort.type) {
      onSort && onSort(sort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "10px 20px",
        border: "1px solid #D8D9DF",
        borderRadius: "12px !important",
        boxShadow: "none !important",
      }}
    >
      {title && <TableTitle>{title}</TableTitle>}

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {config.map((cell) => (
              <HeaderTableCell key={cell.name} width={cell.width}>
                <TitleWrapper justify={cell.justifyHead}>
                  {cell.name}
                  {cell?.isSort && (
                    <SortGroupIcon>
                      <SpanBox
                        onClick={() =>
                          setSort({
                            name: cell?.fieldName as string,
                            type: "desc",
                          })
                        }
                      >
                        <TriangleUpIcon
                          color={getSortColor(
                            cell?.fieldName as string,
                            "desc"
                          )}
                        />
                      </SpanBox>
                      <SpanBox
                        onClick={() =>
                          setSort({
                            name: cell?.fieldName as string,
                            type: "asc",
                          })
                        }
                      >
                        <TriangleDownIcon
                          color={getSortColor(cell?.fieldName as string, "asc")}
                        />
                      </SpanBox>
                    </SortGroupIcon>
                  )}
                </TitleWrapper>
              </HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <BodyTableRow
              key={index}
              onClick={row?.rowClick as ReactEventHandler}
            >
              {Object.keys(row).map(
                (key) =>
                  key !== "rowClick" && (
                    <BodyTableCell key={key}>
                      {row[key] as ReactNode}
                    </BodyTableCell>
                  )
              )}
            </BodyTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const HeaderTableCell = styled(TableCell)`
  color: ${(props) => props.theme.palette.primaryText.dark};
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0;
  border-bottom: 1px solid #d8d9df;
`;

const BodyTableCell = styled(TableCell)`
  color: ${(props) => props.theme.palette.primaryText.main};
  font-size: 14px;
  padding: 15px 0;
  border-bottom: 1px solid #d8d9df;
`;

const BodyTableRow = styled(TableRow)``;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }: { justify?: string }) => justify};
  align-items: center;
  gap: 10px;
`;

const SortGroupIcon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  svg {
    cursor: pointer;
    transition: all 0.7s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const TableTitle = styled.div`
  margin-bottom: 25px;
  color: ${(props) => props.theme.palette.primaryText.main};
  font-size: 24px;
  font-weight: 700;
`;
