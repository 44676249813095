import styled from "styled-components";

import { ReadIcon } from "assets/images/common";

interface Props {
  message: string;
  isAuthor?: boolean;
  isSeen?: boolean;
  time: string;
  hideStatus?: boolean;
}

export const Message = ({
  message,
  isAuthor,
  isSeen,
  time,
  hideStatus,
}: Props) => {
  return (
    <Wrapper isAuthor={isAuthor}>
      <Text
        className={isAuthor && isSeen ? "seen" : undefined}
        isAuthor={isAuthor}
      >
        {message}
      </Text>

      {!hideStatus && (
        <Status isAuthor={isAuthor}>
          {time} {isAuthor && isSeen && <ReadIcon color="#a5a7b4" />}
        </Status>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isAuthor }: { isAuthor?: boolean }) =>
    isAuthor ? "flex-end" : "flex-start"};
  gap: 4px;
  max-width: 350px;
  margin: ${({ isAuthor }: { isAuthor?: boolean }) =>
    isAuthor ? "0px 0px 0px auto" : "0px 0px 0px 0px"};
`;

const Text = styled.div`
  width: fit-content;
  padding: 8px 16px;
  background: ${({ isAuthor }: { isAuthor?: boolean }) =>
    isAuthor ? "#A5A7B4" : "#F4F5F7"};
  border-radius: 8px;
  color: ${({ isAuthor }: { isAuthor?: boolean }) =>
    isAuthor ? "#FFFFFF" : "#606c82"};
  font-size: 14px;

  &.seen {
    background: #373b59;
    color: #ffffff;
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #a5a7b4;
  font-size: 9px;
  margin: ${({ isAuthor }: { isAuthor?: boolean }) =>
    isAuthor ? "0px 0px 0px auto" : "0px 0px 0px 0px"};
`;
