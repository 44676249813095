import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { User } from "models/chat.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectNotificationList,
  selectSelectedChat,
  selectUserId,
} from "store/chat/chat.selector";
import { chatActions } from "store/chat/chat.slice";
import { selectAppConfig } from "store/global/global.selector";
import { db } from "utils/firebase.util";
import { ChatHeader, ChatInput, Messages, RateModal } from ".";

export const ChatBox = () => {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const authMode = useAppSelector(selectAuthMode);
  const { brandName, avatarLogo } = useAppSelector(selectAppConfig);
  const userId = useAppSelector(selectUserId);
  const notifications = useAppSelector(selectNotificationList);

  const guestId = selectedChat?.memberIds.find((id) => id !== userId);
  const roomNotifications = notifications.filter(
    (n) => n.receiverId === userId && n.roomId === selectedChat?.id && !n.isRead
  );

  const handleReadNotification = useCallback(async () => {
    roomNotifications.forEach((n) => {
      const docRef = doc(db, "notifications", n.id);

      updateDoc(docRef, {
        isRead: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomNotifications.length]);

  const handleOnChat = useCallback(
    async (type: "add" | "remove") => {
      try {
        if (!selectedChat?.id) return;

        const docRef = doc(db, "rooms", selectedChat.id);

        const docData = await getDoc(docRef);

        if (docData.exists() && userId) {
          const onChat = docData.data().onChat;

          if (type === "add" && !_.includes(onChat, userId)) {
            await updateDoc(docRef, {
              onChat: [...onChat, userId],
            });
          }

          if (type === "remove" && _.includes(onChat, userId)) {
            await updateDoc(docRef, {
              onChat: onChat.filter((id: string) => id !== userId),
            });
          }
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
    [selectedChat?.id, userId]
  );

  useEffect(() => {
    if (!guestId) return;

    const docRef = doc(db, "users", guestId);

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const { type, displayName, avatar } = doc.data();

        dispatch(
          chatActions.setSelectedGuest({
            ...doc.data(),
            id: doc.id,
            displayName: type === "admin" ? `${brandName} Admin` : displayName,
            avatar: type === "admin" ? avatarLogo : avatar,
          } as User)
        );
      }
    });

    return () => {
      unsubscribe();
      dispatch(chatActions.setSelectedGuest(undefined));
    };
  }, [authMode, avatarLogo, brandName, dispatch, guestId]);

  useEffect(() => {
    handleOnChat("add");
    handleReadNotification();

    window.addEventListener("beforeunload", () => {
      handleOnChat("remove");
    });

    return () => {
      handleOnChat("remove");
    };
  }, [handleOnChat, handleReadNotification]);

  return (
    <Wrapper>
      <ChatHeader openRateModal={() => setOpenModal(true)} />
      <Messages />
      <ChatInput />

      <RateModal isOpen={openModal} onClose={() => setOpenModal(false)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;
