/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Fundraising, CustomFundraisingParams } from "models/fundraising.model";

export interface FundraisingState {
  fundraisingList: Fundraising[];
  selectedFundraising: Fundraising;
  latestFundraising: Fundraising;
  loading: boolean;
}

export const initialState: FundraisingState = {
  fundraisingList: [],
  selectedFundraising: {} as Fundraising,
  latestFundraising: {} as Fundraising,
  loading: false,
};

const fundraisingSlice = createSlice({
  name: "fundraising",
  initialState,
  reducers: {
    getFundraisingList(_, _action: PayloadAction<{ id: string }>) {},
    getFundraisingListSuccess(state, _action: PayloadAction<Fundraising[]>) {
      state.fundraisingList = _action.payload;
    },
    getFundraising(
      _,
      _action: PayloadAction<{ id: string; isAdmin?: boolean }>
    ) {},
    getFundraisingSuccess(state, _action: PayloadAction<Fundraising>) {
      state.selectedFundraising = _action.payload;
    },
    getFundraisingFailed() {},
    updateFundraising(
      state,
      _action: PayloadAction<{
        id: string;
        input: CustomFundraisingParams;
        isAdmin?: boolean;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    updateFundraisingSuccess(state, _action: PayloadAction<Fundraising>) {
      state.selectedFundraising = _action.payload;
      state.loading = false;
    },
    updateFundraisingFailed(state) {
      state.loading = false;
    },
    createFundraising(
      _,
      _action: PayloadAction<{
        id: string;
        callback?: (fundraisingId: string) => void;
      }>
    ) {},
    createFundraisingSuccess(state, _action: PayloadAction<Fundraising>) {
      const fundraisingList = state.fundraisingList;
      fundraisingList.push(_action.payload);
      state.fundraisingList = fundraisingList;
    },
    createFundraisingFailed() {},
    deleteFundraising(
      _,
      _action: PayloadAction<{
        organisationId: string;
        fundraisingProjectId: string;
        callback?: () => void;
      }>
    ) {},
    getFundraisingListAdmin() {},
    getFundraisingListAdminSuccess(
      state,
      _action: PayloadAction<Fundraising[]>
    ) {
      state.fundraisingList = _action.payload;
    },
    getLatestFundraising(_, _action: PayloadAction<{ id: string }>) {},
    getLatestFundraisingSuccess(state, _action: PayloadAction<Fundraising>) {
      state.latestFundraising = _action.payload;
    },
    setLoading(state, _action: PayloadAction<boolean>) {
      state.loading = _action.payload;
    },
    submitFundraising(
      _,
      _action: PayloadAction<{ id: string; callback?: () => void }>
    ) {},
    submitFundraisingSuccess(state, _action: PayloadAction<Fundraising>) {
      state.selectedFundraising = _action.payload;
    },
    submitFundraisingFailed() {},
  },
});

export const {
  actions: fundraisingActions,
  reducer: fundraisingReducer,
  name: sliceKey,
} = fundraisingSlice;
