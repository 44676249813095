import { GroupText, LinearProgressBar, Text } from "components";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks/useHook";
import { useEffect, useRef } from "react";
import { workspaceActions } from "store/workspace/workspace.slice";
import styled from "styled-components/macro";
import { ListTag } from "./ListTag";

import { LocationIcon } from "assets/images/company";
import DefaultProspectLogo from "assets/images/workspace/dashboard-card-logo.png";
import {
  selectFeedDetail,
  selectFilterFeeds,
  selectFilterOptions,
} from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";
import { scrollLoadMore } from "utils/app.util";
import { formatCurrency } from "utils/system.util";
import _ from "lodash";
import { selectAppConfig } from "store/global/global.selector";

export const ListCard = () => {
  const dispatch = useAppDispatch();
  const filterFeeds = useAppSelector(selectFilterFeeds);
  const { lockMFT } = useAppSelector(selectAppConfig);
  const navigate = useAppNavigate();
  const { isGlobal } = useAppSelector(selectFilterOptions);
  const isFeedGlobal = !_.isEmpty(isGlobal);
  const headerRef = useRef<HTMLDivElement>(null);
  const focusFeed = useAppSelector(selectFeedDetail);

  const handleLoadMoreItem = (event: Event | undefined) =>
    scrollLoadMore(event, () => {
      dispatch(feedActions.increaseLimit(6));
    });

  const navigateToFeed = (id: string) => {
    dispatch(feedActions.selectFeed(id));
    navigate(`/deal-flow/${id}`);
  };

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }, [focusFeed]);

  useEffect(() => {
    dispatch(workspaceActions.getWorkspace());
    dispatch(
      feedActions.getFeeds({
        id: "0",
        name: "Default",
        feedFilter: {
          types: ["Organisation"],
          namespace: isFeedGlobal ? "Global" : "Own",
        },
        byKey: "",
        order: "desc",
      })
    );
    const pageContainerElem =
      document.getElementsByClassName("page-container")?.[0];
    pageContainerElem &&
      pageContainerElem?.addEventListener("scroll", handleLoadMoreItem);
    return () =>
      pageContainerElem?.removeEventListener("scroll", handleLoadMoreItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Wrapper>
      <Cards>
        {filterFeeds.map((item) => (
          <CardItem
            key={item.id}
            onClick={() => navigateToFeed(item.id)}
            className={`${
              !item.mfScore && lockMFT?.active && "clear-score-section"
            } ${lockMFT?.active && "lock-score-section"}`}
            //ref={focusFeed?.id === item.id ? headerRef : null}
          >
            <CardSection className="header-section">
              <TitleHeader>
                <CardLogo src={item.logoUrl || DefaultProspectLogo} />
                <CardName>{item.displayName}</CardName>
              </TitleHeader>
              <CountryBox>
                <LocationIcon />
                {item?.country?.name && (
                  <Text size="md">{item?.country.name}</Text>
                )}
              </CountryBox>
              <Text size="md" className="limit-two-line">
                {item.slogan}
              </Text>
              <ListTag data={item.sector} limit={3} />
            </CardSection>
            <CardSection className="price-section">
              <Text size="md" className="light-text">
                Raising now:
              </Text>
              {!!item?.roundSize && (
                <Text size="lg">
                  {formatCurrency(item.roundSize, item?.currency)}
                </Text>
              )}
            </CardSection>
            {!!item.mfScore && (
              <CardSection className="score-section">
                <GroupText>
                  <Text size="md" className="light-text">
                    MF Trust Score:
                  </Text>
                  <Text size="lg">{`${
                    Math.round(item.mfScore) || 0
                  }/100`}</Text>
                </GroupText>
                <LinearProgressBar value={item.mfScore || 0} />
              </CardSection>
            )}
          </CardItem>
        ))}
      </Cards>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;

const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
`;

const Cards = styled.div`
  display: grid;
  padding-top: 10px;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${(props) => props.theme.breakpoints.down("xl")} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 12px;
  gap: 20px;
  height: 360px;
  width: 100%;
  border-radius: 32px;
  background-color: white;
  box-shadow: 0px 3px 5px rgba(5, 10, 48, 0.05);
  cursor: pointer;
  transition: all 0.8s;

  &.lock-score-section {
    height: 410px;

    .score-section {
      height: 100px;
      opacity: 1;
    }
  }

  .header-section {
    height: 230px;
    min-height: 230px;
  }

  .price-section {
    height: 60px;
  }

  .score-section {
    height: 0px;
    opacity: 0;
    transition: all 0.7s;
  }

  &:hover {
    height: 410px;
    .score-section {
      height: 100px;
      opacity: 1;
    }
    opacity: 0.9;
    transform: scale(1.02);
  }

  &.clear-score-section {
    &:hover {
      height: 360px;
      .score-section {
        height: 0px;
        opacity: 0;
      }
    }
  }
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
  padding-top: 10px;
  position: relative;
  border-top: 1px solid #ebecef;

  &:first-child {
    border: none;
  }

  .limit-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .light-text {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const CardLogo = styled.img`
  width: auto;
  height: 60px;
  border-radius: 16px;
`;

const CardName = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.dark};
`;

const CountryBox = styled.div`
  display: flex;
  justify-items: center;
  gap: 10px;
`;
