import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";

import image from "assets/images/files/image.png";
import PDFImage from "assets/images/files/pdf.png";
import videoImage from "assets/images/files/video.png";
import { Text } from "components/UI/Typography/Text";
import { FileTypes } from "models/common.model";
import { getUrlFileType, humanFileSize } from "utils/system.util";
import { ButtonOutline } from "../Button/ButtonOutline";

interface Props {
  fieldName?: string;
  label?: string;
  types?: string[];
  url?: string;
  disabled?: boolean;
  handleChange: (file: File | undefined) => void;
  handleRemoveUrl?: () => void;
}

export const DragDropField = ({
  fieldName,
  label = "Upload or drop a file right here",
  types,
  url,
  disabled,
  handleChange,
  handleRemoveUrl,
}: Props) => {
  const [file, setFile] = useState<File | undefined>();

  const getFileTypePreview = (url: string) => {
    const type = getUrlFileType(url);

    switch (type) {
      case FileTypes.IMAGE:
        return <ImageView src={image} />;
      case FileTypes.PDF:
        return <ImageView src={PDFImage} />;
      case FileTypes.VIDEO:
        return <ImageView src={videoImage} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (url) {
      setFile(undefined);
    }
  }, [url]);

  return (
    <Wrapper>
      {fieldName && (
        <Text size="md" fontWeight={600}>
          {fieldName}
        </Text>
      )}

      {url || file ? (
        url ? (
          <ViewWrapper>
            {getFileTypePreview(url)}

            <FileInfo>
              <div className="name">{url}</div>
            </FileInfo>

            <ButtonOutline
              onClick={() => {
                handleChange(undefined);
                setFile(undefined);
                handleRemoveUrl && handleRemoveUrl();
              }}
              disabled={disabled}
            >
              Remove
            </ButtonOutline>
          </ViewWrapper>
        ) : file ? (
          <ViewWrapper>
            {_.includes(file.type, "image/") && (
              <ImageView src={URL.createObjectURL(file)} />
            )}
            {_.includes(file.type, "application/pdf") && (
              <ImageView src={PDFImage} />
            )}

            <FileInfo>
              <div className="name">{file.name}</div>

              <div className="description">
                <span>{dayjs(file.lastModified).format("D MMM, YYYY")}</span>
                <span className="dot">•</span>
                <span>{humanFileSize(file.size)}</span>
              </div>
            </FileInfo>

            <ButtonOutline
              onClick={() => {
                handleChange(undefined);
                setFile(undefined);
              }}
              disabled={disabled}
            >
              Remove
            </ButtonOutline>
          </ViewWrapper>
        ) : null
      ) : (
        <FileUploader
          classes="uploader"
          types={types}
          handleChange={(file: File | undefined) => {
            handleChange(file);
            setFile(file);
          }}
          disabled={disabled}
        >
          <div className="label">{label}</div>

          <ButtonOutline disabled={disabled}>Browse</ButtonOutline>
        </FileUploader>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .MuiButtonBase-root {
    flex-shrink: 0;
    height: 40px;
    padding: 0px 16px;
    font-size: 16px;

    &:hover {
      background: white;
      transform: scale(1.05);
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      padding: 0px 12px;
      font-size: 12px;
    }
  }

  .uploader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: unset;
    height: unset;
    padding: 16px;
    border: 1px dashed #dfe1e6;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    .label {
      max-width: 400px;
      color: #a5a7b4;
      font-size: 12px;
      white-space: pre-wrap;
    }
  }
`;

const ViewWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 24px;
  border: 1px solid #ebecef;
  border-radius: 4px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 16px;
  }
`;

const ImageView = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 2px;
  object-fit: cover;
  object-position: center;
`;

const FileInfo = styled.div`
  flex-shrink: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    white-space: pre-wrap;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .description {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #a5a7b4;
    font-size: 12px;
    line-height: 1.2;
  }
`;
