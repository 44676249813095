import {
  IconButton,
  TextareaAutosize,
  TextareaAutosizeProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import {
  FieldValues,
  UseFormRegister,
  UseFormReset,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import styled from "styled-components";

import { CloseIcon, NoticeIcon } from "assets/images/common";
import { Text } from "components/UI/Typography/Text";
import { capitalizeFirstLetter } from "utils/system.util";

interface Props extends TextareaAutosizeProps {
  fieldName?: string;
  error?: string;
  hasCount?: boolean;
  hasClear?: boolean;
  formRef?: UseFormRegister<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  reset?: UseFormReset<FieldValues>;
  trigger?: UseFormTrigger<FieldValues>;
  afterClear?: () => void;
}

export const TextArea = ({
  fieldName,
  error,
  hasCount,
  hasClear,
  formRef,
  watch,
  reset,
  trigger,
  afterClear,
  ...props
}: Props) => {
  const option = formRef ? { ...formRef(props.name || "") } : {};
  const text = watch ? watch(props.name || "") || "" : "";

  return (
    <Wrapper className={fieldName && "has-label"}>
      {(fieldName || hasCount) && (
        <div className="label">
          {fieldName && (
            <Text size="md" fontWeight={600}>
              {fieldName}
            </Text>
          )}

          {hasCount && (
            <div className="count">
              {text.length}/{props.maxLength}
            </div>
          )}
        </div>
      )}

      <TextareaAutosize className={error && "error"} {...option} {...props} />

      {error && (
        <Error className="field-error">
          <ErrorTooltip
            title={capitalizeFirstLetter(error)}
            placement="top-end"
            sx={{ textTransform: "capitalize" }}
          >
            <IconButton>
              <NoticeIcon />
            </IconButton>
          </ErrorTooltip>
        </Error>
      )}

      {text && hasClear && !error && (
        <CloseButtonWrapper className="close-btn">
          <IconButton
            disabled={props.disabled}
            onClick={() => {
              if (props.name && reset) {
                reset({ [props.name]: "" });
                trigger && trigger();
                afterClear && afterClear();
              }
            }}
          >
            <CloseIcon color="#a5a7b4" />
          </IconButton>
        </CloseButtonWrapper>
      )}
    </Wrapper>
  );
};

const Error = styled.div`
  position: absolute;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FAFBFC",
    color: "#eb5a46",
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > ${Error} {
    top: 4px;
    right: 4px;
  }

  &.has-label > {
    ${Error} {
      top: 28px;
    }

    .close-btn {
      top: 34px;
    }
  }

  .label {
    display: flex;
    gap: 16px;

    .count {
      margin-left: auto;
      color: #98a0ae;
      font-size: 12px;
    }
  }

  & > textarea {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #ebecef;
    border-radius: 4px;
    outline: none;
    color: ${(props) => props.theme.palette.primaryText.main};
    font-size: 16px;
    font-family: inherit;
    resize: vertical;
    transition: border 0.7s;

    &::placeholder {
      color: #a5a7b4;
    }

    &.error {
      border-color: #eb5a46;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.38);
      background: unset;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  button {
    padding: 4px;
    background-color: #f4f5f7;
  }

  svg {
    width: 8px;
    height: 8px;
  }
`;
