import styled from "styled-components";

import FireworkImage from "assets/images/common/firework.png";
import { Avatar, LineText, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedFund } from "store/feed/feed.selector";

export const ReachOutFundBox = ({ isAuthor }: { isAuthor?: boolean }) => {
  const { logoUrl, displayName, slogan } = useAppSelector(selectSelectedFund);

  return (
    <Wrapper>
      <LeftBox>
        <InfoWrapper>
          <Avatar src={logoUrl} name={displayName} size={40} rounded="0px" />

          <TextInfo>
            <div className="name">{displayName}</div>
          </TextInfo>
        </InfoWrapper>

        <Text size="md" fontWeight={600}>
          {slogan}
        </Text>

        <LineText />

        <MessageBox>
          {isAuthor && (
            <>
              <Text size="lg">{`Rearch out to ${displayName} funds!`}</Text>
              <Text size="md">
                {
                  "Send a message to the fund manager, get to know them, ask for additional information, get involved!"
                }
              </Text>
            </>
          )}

          {!isAuthor && (
            <Text size="lg">{`Hey, I'm interested to know more about your fund!`}</Text>
          )}
        </MessageBox>
      </LeftBox>

      <img className="firework" src={FireworkImage} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-top: 8px;
  padding: 16px;
  border-radius: 12px;
  background: #f4f5f7;

  .firework {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  z-index: 1;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextInfo = styled.div`
  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 600;
  }

  .description {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 12px;
  }
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  & > div {
    text-algin: center;
  }
`;
