import prospectApi from "api/prospect.api";
import { MarkIcon } from "assets/images/common";
import { ProspectItemFilter, ProspectOtherFilter, SearchBox } from "components";
import { useAppDispatch } from "hooks/useHook";
import { feedActions } from "store/feed/feed.slice";
import styled from "styled-components/macro";

const FILTER_DATA = [
  {
    title: "Sector",
    name: "sector",
    callApi: prospectApi.sectorsGet,
    resName: "marketSectors",
    type: "MULTI_SELECT",
  },
  {
    title: "Impact",
    name: "impactValues",
    callApi: prospectApi.impactsGet,
    resName: "impactValues",
    type: "MULTI_SELECT",
  },
  {
    title: "Country",
    name: "country",
    callApi: prospectApi.countriesGet,
    resName: "countries",
    type: "MULTI_SELECT",
  },
];

export const FilterBar = () => {
  const dispatch = useAppDispatch();

  const clearFilter = () => {
    dispatch(feedActions.clearFilter());
  };

  return (
    <Wrapper>
      <Tags>
        <MarkButton>
          <MarkIcon />
        </MarkButton>

        {FILTER_DATA.map((item) => (
          <ProspectItemFilter key={item.name} {...item} />
        ))}

        <ProspectOtherFilter />

        <ClearButton onClick={clearFilter}>Clear all</ClearButton>
      </Tags>

      <SearchBox autoSearch={true} onSearch={() => null} placeholder="Search" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  .search-box {
    height: 35px;
    padding: 10px 16px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: center;
  }
`;

const ClearButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 10px 16px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 14px;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const MarkButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  padding: 10px;
  border: 1px solid #ebecef;
  border-radius: 4px;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;
