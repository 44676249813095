import dayjs from "dayjs";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { Avatar, SectionWrapper } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { selectPosts } from "store/post/post.selector";
import { postActions } from "store/post/post.slice";
import { MediaFrame } from "../../components";

const LIMIT = 3;

export const UpdatesSection = () => {
  const [page, setPage] = useState(1);

  const dispatch = useAppDispatch();

  const posts = useAppSelector(selectPosts);
  const { id, displayName, logoUrl } = useAppSelector(selectOrganisation);

  useEffect(() => {
    dispatch(
      postActions.getPosts({
        filter: {
          namespace: "Own",
          postedById: id,
          status: null,
        },
      })
    );
    return () => {
      dispatch(postActions.clearPosts());
    };
  }, [dispatch, id]);

  if (!posts?.length) return null;

  return (
    <SectionWrapper title="Updates">
      <Container>
        {posts.slice(0, page * LIMIT).map((item) => (
          <Item key={item.id}>
            <Header>
              <Avatar src={logoUrl} name={displayName} size={64} />

              <InfoWrapper>
                <div className="name">{displayName}</div>
                <div className="sub">{dayjs(item.createdAt).fromNow()}</div>
              </InfoWrapper>
            </Header>

            <Title>{item.title}</Title>

            <Text>{item.body}</Text>

            {item.attachment && <MediaFrame url={item.attachment} />}
          </Item>
        ))}
      </Container>

      {page * LIMIT < posts.length && (
        <ShowMore onClick={() => setPage((prePage) => prePage + 1)}>
          Show more
        </ShowMore>
      )}
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > div:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid #ebecef;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 700;
    font-size: 20px;
  }

  .sub {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 16px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 16px;
  font-weight: 600;
  word-break: break-all;
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
  word-break: break-all;
`;

const ShowMore = styled.div`
  width: fit-content;
  margin-top: 30px;
  margin-left: auto;
  color: ${(props) => props.theme.palette.text.dark};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
