export const FundData = {
  __typename: "Fund",
  id: "1689759019260",
  //workspaceId: "d691ea92-e775-4a25-95d9-664582540bce",
  workspaceId: "71035e0c-40e6-4df9-beff-f358a89375bb",
  displayName: "T1ST",
  slogan:
    "Bridging Deep Tech Founders and Corporates to Accelerate Sustainable Industrial Transitions",
  logoUrl:
    "https://storage.googleapis.com/myfoundry-media-files/platform_assets/t1st-logo.png",
  videoUrl: "https://www.youtube.com/watch?v=LG9F5G82jRA",
  websiteUrl: "https://t1st.vc",
  country: {
    id: "Q291bnRyeU5vZGU6MTEy",
    alpha2Code: "IL",
    name: "Global",
  },
  currentRound: {
    id: "54dabd19-aa33-4920-807a-df5e46d509e8",
    name: "Early stage",
  },
  sector: [
    {
      id: "Deep tech",
      name: "Deep tech",
    },
    {
      id: "Industrial transitions",
      name: "Industrial transitions",
    },
    {
      id: "Supply chain",
      name: "Supply chain",
    },
    {
      id: "Reconversion",
      name: "Reconversion",
    },
    {
      id: "Sustainability",
      name: "Sustainability",
    },
    {
      id: "2b8d85c1-6340-4ca0-9864-3d993d86b350",
      name: "Climate tech",
    },
  ],
  newFeeds: [
    {
      id: "new-feed-1",
      title: "Portfolio Update",
      description:
        "In a challenging fundraising environment, our companies raised over $120 million in the last 9 months, showcasing strong commercial traction and valuable partnerships. Notably, Series A and later-stage valuations have become more investor-friendly, with a focus on favorable terms, especially regarding liquidation preferences. As long-term investors, our primary focus is on sustained commercial growth and scalability for substantial gains.",
      author: {
        displayName: "T1ST",
        logoUrl:
          "https://storage.googleapis.com/myfoundry-media-files/platform_assets/t1st-logo.png",
      },
      createdAt: 1695488400000,
      updatedAt: 1689916399028,
    },
    {
      id: "new-feed-2",
      title: "Agriloops: No Rest for the Wicked",
      description:
        "Our very first French start-up, Agriloops, is set to transform the aquaculture industry by leveraging aquaponics to sustainably and simultaneously farm shrimps and vegetables. Agriloops had a very busy summer! They finalized their Series A equity round and secured debt finance from a high-profile pool of French banks. Agriloops is fast-tracking their demonstration plant in Bréal, Brittany, and recently broke ground in October. Congratulations to Jérémie and Romain for starting this new and exciting industrialization phase. Shrimps, anyone?",
      author: {
        displayName: "Agriloops",
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F35c3da4c-19c5-4f9a-9fc2-b13be841e23c%2FAgriloops.png?alt=media&token=8e3f1675-7574-4a16-84b7-2852dbdd75a1",
      },
      createdAt: 1695488400000,
      updatedAt: 1689916399028,
    },
    {
      id: "new-feed-3",
      title: "Resynergi: Waste to More",
      description: `Resynergi, our U.S.-based waste plastic to energy start-up, is on the rise! Not only did they secure a $6.3 million financing round to execute the first phase of their demonstration plant, but they will soon announce two major strategic partnerships that will involve early revenue generation. Brian is firing on all cylinders. If anyone is interested in truly addressing the issue of the '6th continent,' there is still $700,000 in allocation available for select co-investors. Feel free to reach out and get involved!`,
      author: {
        displayName: "Resynergi",
        logoUrl:
          "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F35c3da4c-19c5-4f9a-9fc2-b13be841e23c%2FResynergi.png?alt=media&token=611070c6-ad45-47fc-bafc-be2f87b0d3fc",
      },
      createdAt: 1695488400000,
      updatedAt: 1689916399028,
    },
  ],
  teamMembers: [
    {
      id: "1",
      photoUrl:
        "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/MockBBV%2FMarianne.jpeg?alt=media&token=ad032560-c235-41c8-b362-7c99b87dbef2",
      displayName: "Marianne Abib-Pech",
      role: "Managing Partner",
      bio: "With 20+ years in finance and investment management, Marianne has reshaped multi billion dollars natural resources giants, pioneered M&A in emerging markets and authored several books on leadership.",
    },
    {
      id: "2",
      photoUrl:
        "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/MockBBV%2FAlic.jpeg?alt=media&token=4e1c48b7-b47c-45e8-8ca8-0ac87df7bd48",
      displayName: "Alic Chen, PhD",
      role: "Managing Partner",
      bio: "A multidisciplinary engineer with a background in advanced materials, manufacturing, and health, Alic has incubated and launched startups that have raised over $500M in capital.",
    },
    {
      id: "3",
      photoUrl:
        "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/MockBBV%2FPatrick.jpeg?alt=media&token=844df7ae-08d9-47d9-bbf3-dc886e4f91fe",
      displayName: "Patrick Scaglia",
      role: "Founding Partner",
      bio: "CTO of a $1B+ R&D division at a $100B+ global tech company, with 30+ years as a Silicon Valley founder, exec, and investor.",
    },
    {
      id: "4",
      photoUrl:
        "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F7bc1bf4f-2506-43d6-b8bb-9db26a637b52%2Fteams%2F1b4ce227-4755-4030-b718-ef742aa2957e%2Favatar?alt=media&token=bc2c6e0a-4e9e-4e38-8559-da7bd53719ae",
      displayName: "Daniel Fidler",
      role: "Israel Partner",
      bio: "Angel investor specializing in tech scouting and matching global opportunities, supporting industrial companies through partnerships and innovative strategies.",
    },
    {
      id: "5",
      photoUrl:
        "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F7bc1bf4f-2506-43d6-b8bb-9db26a637b52%2Fteams%2F488e4b5a-1fda-4f24-8d95-0f635a51c94c%2Favatar?alt=media&token=8143b057-d367-40ef-9df1-e3103f51b015",
      displayName: "Kasra Pezeskhi",
      role: "Investment Guru",
      bio: "Almost 2 decades of experience in capital markets and corporate development. From securing government grants and partnerships, to raising capital at scale, Kasra has helped portfolio companies through listing and sale.",
    },
    // {
    //   id: "6",
    //   photoUrl:
    //     "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F35c3da4c-19c5-4f9a-9fc2-b13be841e23c%2Fteams%2F9e82675d-56b8-4b77-98fc-94a95b6144df%2Favatar?alt=media&token=44ad8560-d468-4145-a92a-51735d617ede",
    //   displayName: "Irfan Vissandjee",
    //   role: "Our Discoverer",
    //   bio: "With his blend of financial expertise and deep tech investment insight, Irfan allows us to find and assess untapped opportunities.",
    // },
    // {
    //   id: "7",
    //   photoUrl:
    //     "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F35c3da4c-19c5-4f9a-9fc2-b13be841e23c%2Fteams%2F2e74ffd4-b784-4cc5-9929-b4eb9574cf31%2Favatar?alt=media&token=95b8b98f-8295-4c52-b990-098f90a8ffc3",
    //   displayName: "Lorenz Wilk",
    //   role: "Our Sales Expert",
    //   bio: "Lorenz offers a firsthand view of company-building. Proficient in prototype design, market strategies, and global business, he’s our touchpoint for portfolio companies.",
    // },
    // {
    //   id: "8",
    //   photoUrl:
    //     "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F35c3da4c-19c5-4f9a-9fc2-b13be841e23c%2Fteams%2F90d58823-b2ea-4430-9a66-cd8a9c64d533%2Favatar?alt=media&token=abcd8552-3a2e-4cbf-8850-06a9c3f7a6f8",
    //   displayName: "Linda Maduwura",
    //   role: "Our Fund Admin",
    //   bio: "Fife Avenue Partners founder and seasoned PE pro supporting 10+ VC firms, specializing in operational efficiencies and scale.",
    // },
  ],
  dataRoomLinks: [
    {
      fileName: "Investment pipeline",
      fileUrl:
        "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F35c3da4c-19c5-4f9a-9fc2-b13be841e23c%2Ffundraising%2F1151372e-970c-4183-b88f-8d090c3a2f7b%2Fpdf%2FTransitions%201st%20-%2009-2023%20-%20Investments%20Pipeline.pdf?alt=media&token=562bb78e-9d77-445e-a4b6-91d048c6b9c9",
    },
    {
      fileName: "Teaser",
      fileUrl:
        "https://firebasestorage.googleapis.com/v0/b/myfoundry-app-dev.appspot.com/o/workspaces%2F35c3da4c-19c5-4f9a-9fc2-b13be841e23c%2Ffundraising%2F1151372e-970c-4183-b88f-8d090c3a2f7b%2Fpdf%2FTransitions%201st%20-%2009-2023%20-%20Teaser%20-%20Private%20%26%20Institutional%20(Linked).pdf?alt=media&token=7d1b6cf8-f124-4ca2-8d85-dbe1548f0ade",
    },
  ],
  duration: "4-year investment period, 10 years total fund term",
  managementFee:
    "1.5% avg.on committed capital - 2% p.a. during investment period, gradually decreasing to 1%",
  returnParticipation: "20% carry on realized profits; 30% carry on >4x return",
  roundCloseDate: "Q4 2023",
  sellingPoints: [
    "Curated set of long-term relationships, particularly as a UC Berkley-Affiliated Fund",
    "We find the gems before the leading global firms ID them",
    "A Diverse Investment Team with decades of experience in industryFinance and Technology management.",
  ],
  roundSize: 50000000,
  minTicketSize: 500000,
};
