import { autocompleteClasses, IconButton } from "@mui/material";
import { EditIcon } from "assets/images/common";
import { Button, ButtonOutline } from "components";
import { useState } from "react";
import styled from "styled-components/macro";

interface Props {
  loading?: boolean;
  textElement: React.ReactElement;
  inputElement: React.ReactElement;
  onHandle: () => void;
  className?: string;
}

export const FieldEditor = ({
  inputElement,
  textElement,
  onHandle,
  className,
  loading,
}: Props) => {
  const [isActive, setIsActive] = useState(false);

  const handleSave = () => {
    onHandle();
    setIsActive(false);
  };

  return (
    <Wrapper className={isActive ? "edit-field" : "text-field"}>
      {isActive && (
        <InputWrapper className={className}>
          {inputElement}
          <GroupButton>
            <Button
              className="bottom-button"
              onClick={handleSave}
              loading={loading}
            >
              Save
            </Button>
            <ButtonOutline
              className="bottom-button"
              onClick={() => setIsActive(false)}
            >
              Cancel
            </ButtonOutline>
          </GroupButton>
        </InputWrapper>
      )}

      {textElement}
      {!isActive && (
        <IconWrapper
          onClick={(event) => {
            event.stopPropagation();
            setIsActive(!isActive);
          }}
        >
          <EditIcon />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding-right: 32px;

  &.edit-field {
    .display-text {
      color: transparent;
    }
  }
`;

const GroupButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  position: absolute;
  top: 0px;
  left: -20px;
  z-index: 1;
  width: fit-content;
  box-shadow: 0px 3px 5px rgb(5 10 48 / 5%);
  gap: 0;
  background: white;

  &.text-area-input {
    width: 100%;
    left: 0;
  }

  .${autocompleteClasses.root} {
    background: white;
    z-index: 1;
    min-width: 220px;
  }

  input {
    min-width: clamp(180px, 50vw, 280px);
  }

  input.MuiOutlinedInput-input {
    min-width: unset;
  }

  textarea {
    border: 1px solid #ebecef;
    border-radius: 16px;
    resize: none;
  }

  .field-error {
    top: 12px;
    right: 10px;

    button {
      padding: unset;
    }
  }

  .bottom-button {
    padding: 0 20px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    position: fixed;
    top: calc(40vh - 30px);
    left: 10px;
    width: calc(100vw - 20px);
    background-color: #f4f5f7;
    border-radius: 16px;
  }
`;

const IconWrapper = styled(IconButton)`
  position: absolute;
  top: -20px;
  left: -10px;

  svg {
    width: 18px;
    height: auto;
  }
`;
