import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const WarningIcon = ({ color = "#B4453D" }: Props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.688316 21.6706L0.688362 21.6706L0.689724 21.6681L11.2861 2.18836C12.9622 -0.631307 17.0401 -0.627604 18.7139 2.18837L29.3108 21.6681L29.3107 21.6682L29.3122 21.6706C31.0231 24.5526 28.9481 28.1954 25.5976 28.1954H4.40208C1.04876 28.1954 -1.02258 24.5497 0.688316 21.6706ZM13.5964 3.51333L13.5963 3.51325L13.5941 3.51733L3.00373 22.9861L2.97718 23.0308C2.32042 24.136 3.11432 25.5329 4.40208 25.5329H25.5976C26.8848 25.5329 27.6796 24.1363 27.0227 23.0297L26.9965 22.9855L16.4059 3.51734L16.406 3.51727L16.4036 3.51334C15.7549 2.47876 14.245 2.47902 13.5964 3.51333ZM16.6828 17.7236C16.6828 18.6512 15.9276 19.4064 15 19.4064C14.0724 19.4064 13.3172 18.6512 13.3172 17.7236V8.9345C13.3172 8.0069 14.0724 7.25169 15 7.25169C15.9276 7.25169 16.6828 8.0069 16.6828 8.9345V17.7236ZM15 24.6798C14.0724 24.6798 13.3172 23.9246 13.3172 22.997C13.3172 22.0694 14.0724 21.3142 15 21.3142C15.9276 21.3142 16.6828 22.0694 16.6828 22.997C16.6828 23.9246 15.9276 24.6798 15 24.6798Z"
        fill={color}
        stroke="white"
        strokeWidth="0.15"
      />
    </svg>
  );
};
