import { useState } from "react";
import styled from "styled-components";

import { StarIcon } from "assets/images/common";
import { Text } from "components/UI/Typography/Text";

interface Props {
  fieldName?: string;
  handleChange: (rating: number) => void;
}

export const RatingField = ({ fieldName, handleChange }: Props) => {
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState<number>(0);

  return (
    <FieldGroup>
      {fieldName && (
        <Text size="md" fontWeight={600}>
          {fieldName}
        </Text>
      )}

      <Wrapper>
        {[...Array(5)].map((_, index) => {
          index += 1;

          return (
            <StarWrapper
              key={index}
              onClick={() => {
                handleChange(index);
                setRating(index);
              }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <StarIcon
                color={index <= (hover || rating) ? "#C9AF7F" : undefined}
              />
            </StarWrapper>
          );
        })}
      </Wrapper>
    </FieldGroup>
  );
};

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
`;

const StarWrapper = styled.div`
  cursor: pointer;
`;
