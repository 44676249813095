import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const LoadingIcon = ({ color = "#6A6D84" }: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 13.9629 4.0378 18 9 18C13.6787 18 17.5346 14.412 17.9609 9.84294C18.0042 9.37896 17.6222 9 17.1562 9C16.6903 9 16.3176 9.37939 16.2642 9.84231C15.845 13.4783 12.7461 16.3125 9 16.3125C4.9697 16.3125 1.6875 13.0308 1.6875 9C1.6875 5.25458 4.52228 2.15513 8.15769 1.73578C8.62061 1.68238 9 1.30974 9 0.84375C9 0.37776 8.62104 -0.00415388 8.15706 0.0391512C3.58852 0.465557 0 4.32201 0 9Z"
        fill={color}
      />
    </svg>
  );
};
