import { createSelector } from "@reduxjs/toolkit";

import { TeamMember } from "models/organisation.model";
import { reorderTeamMembers } from "utils/system.util";
import { FeedState, initialState } from "./feed.slice";
import { FundData } from "./fund.data";

const selectDomain = (state: { feed: FeedState }) => state.feed || initialState;

export const selectFeeds = createSelector(
  [selectDomain],
  (state) => state.feeds
);

export const selectFeedDetail = createSelector([selectDomain], (state) => ({
  ...state.selectedFeed,
  teamMembers: reorderTeamMembers(
    state.selectedFeed.teamMembers as TeamMember[]
  ),
}));

export const selectCountFilter = createSelector(
  [selectDomain],
  (state) => state.countFilter
);

export const selectFilterFeeds = createSelector([selectDomain], (state) =>
  state.filterFeeds.slice(0, state.limit)
);

export const selectFilterOptions = createSelector(
  [selectDomain],
  (state) => state.filterOptions
);

export const selectSortSelected = createSelector(
  [selectDomain],
  (state) => state.sortSelected
);

export const selectFeedLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

// remove later and use fund.selector.ts
export const selectSelectedFund = createSelector(
  [selectDomain],
  () => FundData
);
export const selectFunds = createSelector([selectDomain], () => [FundData]);
