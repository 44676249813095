import { createSelector } from "@reduxjs/toolkit";

import { CachedState, initialState } from "./cached.slice";

const selectDomain = (state: { cached: CachedState }) =>
  state.cached || initialState;

export const selectSectors = createSelector(
  [selectDomain],
  (state) => state.marketSectors
);

export const selectImpacts = createSelector(
  [selectDomain],
  (state) => state.impactValues
);

export const selectBusinessTypes = createSelector(
  [selectDomain],
  (state) => state.businessTypes
);

export const selectProductTypes = createSelector(
  [selectDomain],
  (state) => state.productTypes
);

export const selectCountries = createSelector(
  [selectDomain],
  (state) => state.countries
);

export const selectFundingRounds = createSelector(
  [selectDomain],
  (state) => state.fundingRounds
);

export const selectFundingInstruments = createSelector(
  [selectDomain],
  (state) => state.fundingInstruments
);

export const selectTicketSizeRanges = createSelector(
  [selectDomain],
  (state) => state.ticketSizeRanges
);

export const selectCurrencies = createSelector(
  [selectDomain],
  (state) => state.currencies
);
