import { Stack, useMediaQuery } from "@mui/material";
import { useState } from "react";
import styled from "styled-components/macro";

import { PlayIcon } from "assets/images/common";
import { LocationIcon } from "assets/images/company";
import { Avatar, Button, Link, SectionWrapper, Text } from "components";
import { ViewFileModal } from "components/UI/Others/ViewFileModal";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedFund } from "store/feed/feed.selector";
import { DataRoomSection } from "./DataRoomSection";
import { NewFeedsSection } from "./NewFeedsSection";

interface Props {
  handleReachOut: () => void;
}

export const InfoSection = ({ handleReachOut }: Props) => {
  const [open, setOpen] = useState(false);

  const lessThan1400 = useMediaQuery("(max-width: 1400px)");

  const {
    logoUrl,
    displayName,
    slogan,
    country,
    sector,
    currentRound,
    videoUrl,
    websiteUrl,
  } = useAppSelector(selectSelectedFund);

  return (
    <SectionWrapper>
      <Wrapper spacing="15px">
        <Text
          className="section-wrapper__title"
          size="xl"
          fontSize="20px"
          fontWeight={600}
        >
          What they do?
        </Text>
        <HeaderBox>
          <Stack direction="row" alignItems="center" gap="12px">
            <Avatar src={logoUrl} name={displayName} size={48} rounded="0px" />

            <Text size="lg" limit={1}>
              {displayName}
            </Text>
          </Stack>

          <Button onClick={handleReachOut}>Get involved</Button>
        </HeaderBox>
        <Text size="md">{slogan}</Text>

        <Tags>
          {country && (
            <Flag>
              <LocationIcon /> {country.name}
            </Flag>
          )}

          {currentRound && <Flag>{currentRound.name}</Flag>}

          {sector.length > 0 &&
            sector.map((item) => <Flag key={item.id}>{item.name}</Flag>)}
        </Tags>
        <Stack direction="row" gap="20px" alignItems="center">
          {websiteUrl && (
            <Stack direction="row">
              <Text size="md" fontWeight={600}>
                {"Website: "}
              </Text>
              <Link href={websiteUrl} target="_blank">
                {websiteUrl}
              </Link>
            </Stack>
          )}
          {false && (
            <Frame onClick={() => setOpen(true)}>
              <PlayButton>
                <PlayIcon />
              </PlayButton>

              <Text size="md" fontWeight={600}>
                Video pitch
              </Text>
            </Frame>
          )}
        </Stack>

        <MediaFileBox>
          <SectionWrapper title="News Feed">
            <NewFeedsSection />
          </SectionWrapper>
          {/* {videoUrl && lessThan1400 ? (
            <MediaFrame url={videoUrl} />
          ) : (
            <Frame>
              <PlayButton onClick={() => setOpen(true)}>
                <PlayIcon />
              </PlayButton>

              <Text size="md" fontWeight={600}>
                Video pitch
              </Text>
            </Frame>
          )} */}
          <DataRoomSection />
        </MediaFileBox>
      </Wrapper>
      {videoUrl && !lessThan1400 && (
        <ViewFileModal
          isOpen={open}
          onClose={() => setOpen(false)}
          url={videoUrl}
        />
      )}
    </SectionWrapper>
  );
};

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const MediaFileBox = styled.div`
  display: flex;
  gap: 20px;
  //height: fit-content;
  max-height: 100%;
  margin-top: 20px !important;
  flex-basis: 100%;
  flex-grow: 1;

  & > div:first-child {
    flex-basis: 60%;
  }

  & > div:nth-child(2) {
    flex-basis: 40%;
  }

  .section-wrapper {
    //max-height: 100%;
    height: fit-content;

    &__title {
      font-size: 16px !important;
      margin-bottom: 10px;
      margin-top: -8px;
    }
  }

  @media (max-width: 1640px) and (min-width: 1400px) {
    flex-direction: column;
    & > div {
      flex-basis: unset !important;
    }
  }
`;

const Wrapper = styled(Stack)`
  height: 100%;
  padding-right: 16px;
  margin-right: -16px;
  overflow-y: auto;

  ${(props) => props.theme.breakpoints.down("sm")} {
    .${HeaderBox} {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const Frame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 160px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid #d8d9df;
  background: white;
  cursor: pointer;
`;

const PlayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: rgb(242, 70, 70);
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  svg {
    width: 18px;
    height: auto;
  }
`;

const Flag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  width: fit-content;
  padding: 4px 12px;
  border-radius: 4px;
  background: #ebecef;

  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
`;
