import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  color?: string;
}

export const DealFlowIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 18H2C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H17C17.55 0 18.021 0.195667 18.413 0.587C18.8043 0.979 19 1.45 19 2V16C19 16.55 18.8043 17.021 18.413 17.413C18.021 17.8043 17.55 18 17 18ZM2 5H17V2H2V5ZM5 7H2V16H5V7ZM14 7V16H17V7H14ZM12 7H7V16H12V7Z"
        fill={color}
      />
    </svg>
  );
};
