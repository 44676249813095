import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, dialogClasses } from "@mui/material";
import { Button, ButtonOutline, Text } from "components";
import { ImageEditor } from "components/Feature/ImageEditor";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { TextArea } from "components/UI/Form/Input/TextArea";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { ProspectTeamMember } from "models/prospect.model";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { globalActions } from "store/global/global.slice";
import { selectProspectLoading } from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";
import { uploadFromBlobAsync } from "utils/firebase.util";
import * as yup from "yup";

interface Props {
  data: ProspectTeamMember | null;
  prospectCustomTeamMemberId: string;
  isOpen: boolean;
  onClose: () => void;
  isAdmin?: boolean;
}

export const ProspectAddTeamMemberModal = ({
  prospectCustomTeamMemberId,
  data,
  isOpen,
  onClose,
  isAdmin,
}: Props) => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectProspectLoading);
  const [avatarImage, setAvatarImage] = useState<Blob | undefined>(undefined);

  const schema = yup.object().shape({
    photoUrl: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.string().required(),
    bio: yup.string().required(),
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddMember = async (formData: FieldValues) => {
    dispatch(prospectActions.setLoading(true));

    const { firstName, lastName, bio, role, email } = formData;
    const displayName = `${firstName} ${lastName}`;

    dispatch(
      prospectActions.customTeamMember({
        prospectCustomTeamMemberId,
        data: {
          displayName,
          bio,
          role,
          email,
        },
        type: "CREATE",
        avatarImage,
        isAdmin,
        callback: () => {
          dispatch(prospectActions.setLoading(false));
          handleClose();
        },
      })
    );
  };

  const handleUpdateMember = async (formData: FieldValues) => {
    if (!data) return;

    dispatch(prospectActions.setLoading(true));

    if (avatarImage) {
      try {
        dispatch(globalActions.setPageLoading(true));

        const url = await uploadFromBlobAsync({
          blob: avatarImage,
          name: data.id,
          folder: "avatars",
        });

        setValue("photoUrl", url);
        dispatch(globalActions.setPageLoading(false));
      } catch (_) {
        dispatch(globalActions.setPageLoading(false));
      }
    }

    const { firstName, lastName, bio, photoUrl, role, email } = formData;
    const displayName = `${firstName} ${lastName}`;

    dispatch(
      prospectActions.customTeamMember({
        prospectCustomTeamMemberId,
        data: {
          displayName,
          bio,
          photoUrl,
          role,
          email,
        },
        type: "UPDATE",
        teamMemberId: data.id,
        isAdmin,
        callback: () => {
          dispatch(prospectActions.setLoading(false));
          handleClose();
        },
      })
    );
  };

  const handleClose = () => {
    onClose();
    reset();
    setAvatarImage(undefined);
  };

  useEffect(() => {
    if (data) {
      Object.keys(data).map((key) => {
        if (key === "displayName") {
          const splitName = data[key].split(" ");

          setValue("firstName", splitName[0]);
          setValue("lastName", splitName[1]);
        } else {
          setValue(key, data[key as keyof ProspectTeamMember]);
        }
      });
    } else {
      reset();
    }
  }, [data, reset, setValue]);

  useEffect(() => {
    if (avatarImage) {
      setValue("photoUrl", "pending");
    }
  }, [avatarImage, setValue]);

  return (
    <BaseDialog
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      maxWidth="lg"
    >
      <HeaderBox>Add team member</HeaderBox>
      <Wrapper
        id="team-member-form"
        onSubmit={handleSubmit(data?.id ? handleUpdateMember : handleAddMember)}
      >
        <GroupBox>
          <FieldWrapper>
            <Text size="md" fontWeight={600}>
              Attachment
            </Text>

            <ImageEditor
              className="avatar-editor"
              onSave={setAvatarImage}
              url={data?.photoUrl || ""}
              uploadText="Upload a SVG, PNG, JPG or GIF picture."
              error={errors.photoUrl?.message as string}
            />
          </FieldWrapper>

          <HorizontalBox>
            <GroupTextField
              fieldName="First name"
              placeholder="Fill first name"
              name="firstName"
              formRef={register}
              error={errors.firstName?.message as string}
            />

            <GroupTextField
              fieldName="Last name"
              placeholder="Fill last name"
              name="lastName"
              formRef={register}
              error={errors.lastName?.message as string}
            />
          </HorizontalBox>
        </GroupBox>

        <FieldWrapper>
          <GroupTextField
            fieldName="Role"
            placeholder="Fill position in the company"
            name="role"
            formRef={register}
            error={errors.role?.message as string}
          />
        </FieldWrapper>

        <FieldWrapper>
          <TextArea
            fieldName="Bio"
            name="bio"
            placeholder="Type some words here..."
            minRows={2}
            maxLength={1400}
            hasCount
            formRef={register}
            watch={watch}
            error={errors.bio?.message as string}
          />
        </FieldWrapper>

        <FieldWrapper>
          <GroupTextField
            fieldName="Email"
            placeholder="example@gmail.com"
            name="email"
            formRef={register}
            error={errors.email?.message as string}
          />
        </FieldWrapper>
      </Wrapper>

      <FooterBox>
        <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>

        <Button form="team-member-form" type="submit" loading={submitLoading}>
          {data ? "Update member" : "Add member"}
        </Button>
      </FooterBox>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 32px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: #373b59;
  font-size: 24px;
  font-weight: 700;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  .avatar-editor {
    width: 142px;
    height: 142px;
  }

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: calc(100vw - 64px);
  }
`;

const GroupBox = styled.div`
  display: flex;
  gap: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const HorizontalBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
`;

const FooterBox = styled.div`
  display: flex;
  gap: 16px;
  margin: 32px 32px 0px;
  padding: 32px 0px;
  border-top: 1px solid #ebecef;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }

  & button,
  .button-wrapper {
    width: 100%;
  }
`;
