import { gql } from "graphql-request";
import {
  GraphqlQuery,
  GraphqlResponse,
  QueryAction,
} from "models/common.model";
import {
  CustomProspectParams,
  Prospect,
  ProspectStatus,
  ProspectTeamMemberParams,
} from "models/prospect.model";
import { graphqlRequest } from "utils/graphQL.util";

const prospectApi = {
  prospectsGetAdmin(
    query?: GraphqlQuery
  ): Promise<GraphqlResponse<Prospect[]>> {
    const defaultQuery = gql`
      query {
        prospectsGetAdmin {
          id
          logoUrl
          displayName
          status
          currentRound {
            id
            name
          }
          updatedAt
        }
      }
    `;
    return graphqlRequest(query || defaultQuery);
  },

  prospectGetAdmin(prospectGetAdminId: string, query?: GraphqlQuery) {
    const variables = {
      prospectGetAdminId,
    };
    const defaultQuery = gql`
      query ($prospectGetAdminId: ID!) {
        prospectGetAdmin(id: $prospectGetAdminId) {
          id
          logoUrl
          displayName
          description
          slogan
          pitchDeckUrl
          websiteUrl
          annualRevenue
          MRR
          minTicketSize
          mfScore
          roundSize
          status
          roundCloseDate
          preFundedAmount
          dataroomReady
          videoUrl
          posterUrl
          prevPartOf
          mfScoreUrl
          leadInvestor
          preFunded
          bankAccMonitoring
          zip
          city
          address
          currentRound {
            id
            name
          }
          instrument {
            id
            name
          }
          country {
            id
            name
            alpha2Code
          }
          sector {
            id
            name
          }
          impactValues {
            id
            name
            active
          }
          businessType {
            id
            name
            active
          }
          productType {
            id
            name
            active
          }
          teamMembers {
            bio
            displayName
            id
            photoUrl
            role
            email
            order
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  prospectsGet(query?: GraphqlQuery): Promise<GraphqlResponse<Prospect[]>> {
    const defaultQuery = gql`
      query {
        prospectsGet {
          id
          logoUrl
          displayName
          status
          currentRound {
            id
            name
          }
          updatedAt
        }
      }
    `;
    return graphqlRequest(query || defaultQuery);
  },

  prospectGet(prospectGetId: string, query?: GraphqlQuery) {
    const variables = {
      prospectGetId,
    };
    const defaultQuery = gql`
      query ($prospectGetId: ID!) {
        prospectGet(id: $prospectGetId) {
          id
          logoUrl
          displayName
          description
          slogan
          pitchDeckUrl
          websiteUrl
          annualRevenue
          MRR
          minTicketSize
          mfScore
          roundSize
          status
          roundCloseDate
          preFundedAmount
          dataroomReady
          videoUrl
          posterUrl
          prevPartOf
          mfScoreUrl
          leadInvestor
          preFunded
          bankAccMonitoring
          zip
          city
          address
          currentRound {
            id
            name
          }
          instrument {
            id
            name
          }
          country {
            id
            name
            alpha2Code
          }
          sector {
            id
            name
          }
          impactValues {
            id
            name
            active
          }
          businessType {
            id
            name
            active
          }
          productType {
            id
            name
            active
          }
          teamMembers {
            bio
            displayName
            id
            photoUrl
            role
            email
            order
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  prospectCreate(input: CustomProspectParams, query?: GraphqlQuery) {
    const variables = {
      input,
    };
    const defaultQuery = gql`
      mutation ($input: prospectInputCreate) {
        prospectCreate(input: $input) {
          id
          logoUrl
          displayName
          description
          slogan
          pitchDeckUrl
          websiteUrl
          annualRevenue
          MRR
          minTicketSize
          mfScore
          roundSize
          status
          roundCloseDate
          preFundedAmount
          dataroomReady
          videoUrl
          posterUrl
          prevPartOf
          mfScoreUrl
          leadInvestor
          preFunded
          bankAccMonitoring
          zip
          city
          address
          currentRound {
            id
            name
          }
          instrument {
            id
            name
          }
          country {
            id
            name
            alpha2Code
          }
          sector {
            id
            name
          }
          impactValues {
            id
            name
            active
          }
          businessType {
            id
            name
            active
          }
          productType {
            id
            name
            active
          }
          teamMembers {
            bio
            displayName
            id
            photoUrl
            role
            email
            order
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  prospectUpdate(
    prospectUpdateId: string,
    input: CustomProspectParams,
    query?: GraphqlQuery
  ) {
    const variables = {
      prospectUpdateId,
      input,
    };
    const defaultQuery = gql`
      mutation ($prospectUpdateId: ID!, $input: prospectInputUpdate) {
        prospectUpdate(id: $prospectUpdateId, input: $input) {
          id
          logoUrl
          displayName
          description
          slogan
          pitchDeckUrl
          websiteUrl
          annualRevenue
          MRR
          minTicketSize
          mfScore
          roundSize
          status
          roundCloseDate
          preFundedAmount
          dataroomReady
          videoUrl
          posterUrl
          prevPartOf
          mfScoreUrl
          leadInvestor
          preFunded
          bankAccMonitoring
          zip
          city
          address
          currentRound {
            id
            name
          }
          instrument {
            id
            name
          }
          country {
            id
            name
            alpha2Code
          }
          sector {
            id
            name
          }
          impactValues {
            id
            name
            active
          }
          businessType {
            id
            name
            active
          }
          productType {
            id
            name
            active
          }
          teamMembers {
            bio
            displayName
            id
            photoUrl
            role
            email
            order
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  prospectSetStatus(
    prospectSetStatusId: string,
    input: {
      status: ProspectStatus;
    },
    query?: GraphqlQuery
  ) {
    const variables = {
      prospectSetStatusId,
      input,
    };
    const defaultQuery = gql`
      mutation ($prospectSetStatusId: ID!, $input: prospectStatusUpdate) {
        prospectSetStatus(id: $prospectSetStatusId, input: $input) {
          status
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  prospectSetMfScore(
    prospectSetMfScoreId: string,
    input: {
      mfScore: number;
    },
    query?: GraphqlQuery
  ) {
    const variables = {
      prospectSetMfScoreId,
      input,
    };
    const defaultQuery = gql`
      mutation ($prospectSetMfScoreId: ID!, $input: prospectScoreInput) {
        prospectSetMfScore(id: $prospectSetMfScoreId, input: $input) {
          mfScore
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  prospectCustomTeamMember(
    prospectCustomTeamMemberId: string,
    data: ProspectTeamMemberParams | null,
    type: QueryAction,
    teamMembersId?: string,
    query?: GraphqlQuery
  ) {
    const variables = {
      prospectAddTeamMemberId: prospectCustomTeamMemberId,
      prospectUpdateTeamMemberId: prospectCustomTeamMemberId,
      input: {
        teamMembers: [
          {
            data,
            task: type,
            id: teamMembersId,
          },
        ],
      },
    };
    const createQuery = gql`
      mutation (
        $prospectAddTeamMemberId: ID!
        $input: prospectTeamMemberCreateInput!
      ) {
        prospectAddTeamMember(id: $prospectAddTeamMemberId, input: $input) {
          teamMembers {
            bio
            displayName
            id
            photoUrl
            role
            email
            order
          }
        }
      }
    `;

    const updateQuery = gql`
      mutation (
        $prospectUpdateTeamMemberId: ID!
        $input: prospectTeamMemberUpdateInput
      ) {
        prospectUpdateTeamMember(
          id: $prospectUpdateTeamMemberId
          input: $input
        ) {
          teamMembers {
            id
            displayName
            bio
            role
            photoUrl
            email
            order
          }
        }
      }
    `;

    const defaultQuery = type === "CREATE" ? createQuery : updateQuery;

    return graphqlRequest(query || defaultQuery, variables);
  },

  productTypesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query ($active: Boolean) {
        productTypes(active: $active) {
          id
          name
          active
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  impactsGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query ($active: Boolean) {
        impactValues(active: $active) {
          id
          name
          active
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  businessTypesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query ($active: Boolean) {
        businessTypes(active: $active) {
          id
          name
          active
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  sectorsGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query ($active: Boolean) {
        marketSectors(active: $active) {
          id
          name
          active
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  countriesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query ($active: Boolean) {
        countries(active: $active) {
          id
          name
          alpha2Code
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },
  fundingRounds(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query ($active: Boolean) {
        fundingRounds(active: $active) {
          id
          name
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },
  fundingInstruments(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };
    const defaultQuery = gql`
      query ($active: Boolean) {
        fundingInstruments(active: $active) {
          id
          name
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },
};

export default prospectApi;
