import ReactPlayer from "react-player";
import Slider from "react-slick";
import styled from "styled-components";

import { PdfView } from "components/Feature/PdfView";
import { FileTypes } from "models/common.model";
import { getUrlFileType } from "utils/system.util";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

interface Props {
  url: string;
}

export const MediaFrame = ({ url }: Props) => {
  const dataType = getUrlFileType(url as string);

  if (!url) return null;

  if (dataType === FileTypes.VIDEO)
    return (
      <VideoWrapper>
        <ReactPlayer
          url={url as string}
          width={"100%"}
          height={"auto"}
          controls={true}
        />
      </VideoWrapper>
    );

  if (dataType === FileTypes.IMAGE) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Slider {...settings}>
        <ImageWrapper src={url as string} />
      </Slider>
    );
  }

  if (dataType === FileTypes.IFRAME) {
    const viewUrl = (url as string).replace("/view", "/preview");
    return (
      <iframe src={viewUrl} width="100%" height="480" allow="autoplay"></iframe>
    );
  }

  if (dataType === FileTypes.PDF) {
    return <PdfView url={url as string} />;
  }

  return null;
};

const VideoWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 400px;
  border-radius: 12px;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-height: 200px;
  }
`;

const ImageWrapper = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
