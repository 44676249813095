import { ReactComponent as MyFoundryLogoIcon } from "assets/images/common/my-foundry-logo.svg";
import { ReactComponent as MyFoundryTextIcon } from "assets/images/common/my-foundry-text.svg";
import { useAppSelector } from "hooks/useHook";
import { selectAppConfig } from "store/global/global.selector";
import styled from "styled-components/macro";
import { Text } from "components";

const GuestHeader = () => {
  const appConfig = useAppSelector(selectAppConfig);
  const { bannerLogo, parnerLogo, brandName } = appConfig;
  return (
    <Wrapper className="guest-header">
      {false && <MyFoundryLogoIcon />}
      {false && <MyFoundryTextIcon />}
      <LogoGroup>
        {bannerLogo ? <LogoWrapper src={bannerLogo} /> : <LogoPlaceholder />}
        {parnerLogo && (
          <>
            <Text size="md" fontSize="16px" color="white">
              in partnership with
            </Text>
            <LogoWrapper className="monaco-foundry-logo" src={parnerLogo} />
          </>
        )}
      </LogoGroup>
      {!parnerLogo && <AppName>{brandName}</AppName>}
    </Wrapper>
  );
};

export default GuestHeader;

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
`;

const AppName = styled.div`
  color: #fff;
  font-size: 22px;
  font-weight: 700;
`;

const LogoGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const LogoWrapper = styled.img`
  max-width: 320px;
  max-height: 140px;
  border-radius: 4px;

  &.monaco-foundry-logo {
    height: auto;
    width: 320px;
  }
`;

const LogoPlaceholder = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: #ebecef;
`;
