import { Badge } from "@mui/material";
import dayjs from "dayjs";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { ReadIcon } from "assets/images/common";
import { Avatar } from "components";
import { useAppSelector } from "hooks/useHook";
import { Chat, User } from "models/chat.model";
import { selectUserId } from "store/chat/chat.selector";
import { selectAppConfig } from "store/global/global.selector";
import { decrypt } from "utils/app.util";
import { db } from "utils/firebase.util";
import { parseCodeObject } from "utils/firestore.util";

interface Props {
  isActive?: boolean;
  onClick?: () => void;
  data: Chat;
}

const fChatDate = (date: number) => {
  const now = dayjs();
  const targetDate = dayjs(date);

  if (targetDate.isSame(now, "day")) {
    return targetDate.format("HH:mm");
  } else if (targetDate.isAfter(now.subtract(7, "day"))) {
    return targetDate.format("ddd");
  } else if (targetDate.isAfter(now.subtract(1, "year"))) {
    return targetDate.format("DD MMM");
  } else {
    return targetDate.format("DD MMM, YYYY");
  }
};

export const ChatListItem = ({ data, isActive, onClick }: Props) => {
  const [guest, setGuest] = useState<User>();

  const { avatarLogo, brandName } = useAppSelector(selectAppConfig);
  const userId = useAppSelector(selectUserId);

  const guestId = data.memberIds.find((id) => id !== userId);
  const unreadCount = data.unread[userId];
  const decryptedMessage =
    data.lastMessage && decrypt(data.lastMessage.message);

  useEffect(() => {
    if (!guestId) return;

    const docRef = doc(db, "users", guestId);

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const { type, displayName, avatar } = doc.data();

        setGuest({
          ...doc.data(),
          id: doc.id,
          displayName: type === "admin" ? `${brandName} Admin` : displayName,
          avatar: type === "admin" ? avatarLogo : avatar,
        } as User);
      }
    });

    return unsubscribe;
  }, [avatarLogo, brandName, guestId]);

  return (
    <Wrapper className="chat-list-item" active={isActive} onClick={onClick}>
      <InfoWrapper>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          hidden={!guest?.isOnline}
        >
          <Avatar src={guest?.avatar} name={guest?.displayName} size={40} />
        </StyledBadge>

        <TextInfo>
          <div className="name">{guest?.displayName}</div>

          <div className="text">
            {(decryptedMessage && parseCodeObject(decryptedMessage).message) ||
              decryptedMessage}
          </div>
        </TextInfo>
      </InfoWrapper>

      {(data.lastMessage || !!unreadCount) && (
        <RightBox>
          {data.lastMessage && (
            <div className="time">{fChatDate(data.lastMessage.createdAt)}</div>
          )}

          {!unreadCount ? <ReadIcon /> : <Count>+{unreadCount}</Count>}
        </RightBox>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 24px 18px;
  border-bottom: 1px solid #ebecef;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 6px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% - 12px);
    border-radius: 12px;
    background: #f4f5f7;
    opacity: ${({ active }: { active?: boolean }) => (active ? 1 : 0)};
    z-index: 1;
    transition: all 0.5s;
    content: "";
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 10;
`;

const TextInfo = styled.div`
  font-size: 12px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 600;
    margin-bottom: 4px;
  }

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${(props) => props.theme.palette.secondary.light};
  }
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  z-index: 10;

  .time {
    color: #a5a7b4;
    font-size: 10px;
  }
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => props.theme.palette.secondary.main};
  color: #ffffff;
  font-size: 9px;
  line-height: 1;
`;

const StyledBadge = styled(Badge)(({ theme, hidden }) => ({
  "& .MuiBadge-badge": {
    display: hidden ? "none" : undefined,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    backgroundColor: "#58A369",
    color: "#58A369",

    "&::after": {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: `""`,
    },
  },
}));
