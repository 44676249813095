import styled from "styled-components/macro";
import { Stack } from "@mui/material";

import {
  Button,
  GroupText,
  LinearProgressBar,
  SectionWrapper,
  Text,
} from "components";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedFund } from "store/feed/feed.selector";
import { convertToK } from "utils/system.util";

interface Props {
  handleReachOut: () => void;
}

export const UniqueSellingPoints = ({ handleReachOut }: Props) => {
  const { minTicketSize, roundSize } = useAppSelector(selectSelectedFund);

  return (
    <SectionWrapper>
      <Wrapper>
        <Text size="xl" fontSize="20px">
          Why invest?
        </Text>
        {/* <ListWrapper>
          {sellingPoints.map((p, i) => (
            <ListItem key={i}>
              <Text size="md">{p}</Text>
            </ListItem>
          ))}
        </ListWrapper> */}

        <ListWrapper>
          <Text size="md" fontSize="12px">
            Why would you choose us in the realm of VCs looking to raise in one
            of the hardest market conditions ever experienced?
          </Text>
          <Text size="md" fontSize="12px">
            Because we have built this fund on:
          </Text>
          <Point>
            <Text size="md" fontSize="12px">
              <span>
                <strong>1. Experiential Conviction: </strong>
                Our thesis is anchored in our individual convictions and early
                identification of macro trends. We believe today’s greatest
                challenges cannot be purely solved by digitalization. We must
                truly tackle the reconstruction of our global supply chain to
                establish the blueprint for a sustainable future.
              </span>
            </Text>
          </Point>
          <Point>
            <Text size="md" fontSize="12px">
              <span>
                <strong>2. Established Innovation: </strong>
                <span>
                  {
                    "Science is the foundation of the future. We have a long history and an established presence in the leading innovation ecosystems of Silicon Valley and Europe. We've historically been able to identify the gems before anyone else!"
                  }
                </span>
              </span>
            </Text>
          </Point>
          <Point>
            <Text size="md" fontSize="12px">
              <span>
                <strong>3. Demonstrable Execution: </strong>
                <span>
                  {
                    "Without action, there is no change. Our experience speaks volumes about our ability to execute. Alic has incubated more than 60+ deep tech start-ups, Kasra has been a trailblazer in the nascent EV industry, Daniel has grown businesses internationally, and Marianne has transformed a 20B+ company and pioneered M&A in Frontier markets."
                  }
                </span>
              </span>
            </Text>
          </Point>
          <Point>
            <Text size="md" fontSize="12px">
              We are builders and doers.
            </Text>
          </Point>
          <Point>
            <Text size="md" fontSize="12px">
              <span>
                {"We believe our recipe of "}
                <strong>
                  Conviction, Innovation, and Execution will lead to superior
                  financial performance.
                </strong>{" "}
                <span>
                  We complement this with strong ethics and the drive to go
                  beyond the call of duty.
                </span>
              </span>
            </Text>
          </Point>
          <Point>
            <Text size="md" fontSize="12px">
              <span>
                Cynics could argue that we are not experienced enough fund
                managers to manage a $50M+ fund? Yes, we are seasoned and
                successful operators, scientists, entrepreneurs, and investors
                with all the qualities of leading emerging fund managers.
                Venture industry data continues to show that top-performing
                funds are usually first or second funds with international
                exposure, strong thematic focus, and diverse teams.
              </span>
            </Text>
          </Point>
          <Point>
            <Text size="md" fontSize="12px">
              <span>
                {`Now is the time to build a sustainable future with all hands on deck. As many great leaders and scholars have said, 'If not us, who? If not now, when?'`}
              </span>
            </Text>
          </Point>
        </ListWrapper>
        <Footer>
          <Stack direction="column" gap="10px">
            <GroupText>
              <Text size="md" fontWeight={500}>
                LPs Committed to date:
              </Text>
              <Text size="lg" fontWeight={600}>
                16%
              </Text>
            </GroupText>
            <LinearProgressBar value={16} />
          </Stack>

          <Stack direction="row" gap="8px">
            <Flag>
              <Text size="sm" fontWeight={600}>
                Min Ticket
              </Text>

              <Text size="md" fontWeight={400}>
                {convertToK(minTicketSize)} $
              </Text>
            </Flag>

            <Flag>
              <Text size="sm" fontWeight={600}>
                Raising
              </Text>

              <Text size="md" fontWeight={400}>
                {convertToK(roundSize)} $
              </Text>
            </Flag>
          </Stack>

          <Button onClick={handleReachOut}>Get involved</Button>
        </Footer>
      </Wrapper>
    </SectionWrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .MuiLinearProgress-colorPrimary {
    height: 10px;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: #fff;

  .button-wrapper {
    width: 100%;
    margin-top: 12px;

    button {
      width: 100%;
    }
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 15px;
  height: 100%;
  overflow-y: auto;
`;

const Point = styled.div`
  display: flex;
  gap: 5px;
`;

// const ListItem = styled.div`
//   position: relative;
//   padding-left: 16px;

//   &::after {
//     position: absolute;
//     left: 0px;
//     top: 50%;
//     width: 8px;
//     height: 8px;
//     border-radius: 50%;
//     background: ${(props) => props.theme.palette.secondary.main};
//     transform: translateY(-50%);
//     content: "";
//   }
// `;

const Flag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 4px 12px;
  border-radius: 4px;
  background: #ebecef;
`;
