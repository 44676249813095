import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PlusIcon, ReportIcon } from "assets/images/common";
import EmptyImage from "assets/images/common/empty.png";
import {
  Button,
  ButtonOutline,
  Empty,
  PageTitle,
  SortFeature,
} from "components";
import { Combobox } from "components/Feature/Combobox";
import ConfirmModal from "components/Feature/ConfirmModal";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { SortItem } from "models/common.model";
import { Asset, DeleteAssetParams } from "models/report.model";
import {
  selectAssetList,
  selectReportLoading,
} from "store/report/report.selector";
import { reportActions } from "store/report/report.slice";
import { AddNewAssetModal, AssetItem } from "../components";

const SORT_DATA: SortItem[] = [
  {
    id: "0",
    name: "Relevant",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "",
    order: "desc",
  },
];

const options = [{ label: "Last 7 days", value: "Last 7 days" }];

export const InsightsAssets = () => {
  const [open, setOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();
  const [deleteAssetParams, setDeleteAssetParams] = useState<
    DeleteAssetParams | undefined
  >();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const assetList = useAppSelector(selectAssetList);
  const loading = useAppSelector(selectReportLoading);

  const deleteAsset = () => {
    if (deleteAssetParams) {
      dispatch(
        reportActions.deleteAsset({
          data: deleteAssetParams,
          callback: () => {
            setDeleteAssetParams(undefined);
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(reportActions.getAssets());
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle>Assets</PageTitle>

      <Header>
        <ButtonGroup>
          <Button
            icon={<PlusIcon color="#FFFFFF" />}
            onClick={() => setOpen(true)}
          >
            Add new asset
          </Button>

          <ButtonOutline
            icon={<ReportIcon />}
            onClick={() => navigate("/insights/assets/generate-report")}
          >
            Generate report
          </ButtonOutline>
        </ButtonGroup>

        {false && (
          <Combobox
            id="combobox-sort"
            freeSolo={false}
            options={options}
            onchange={() => null}
            value="Last 7 days"
          />
        )}
      </Header>

      {false && (
        <SortBar>
          <div className="result">No results</div>

          <SortFeature
            data={SORT_DATA}
            selectedItem={SORT_DATA[0]}
            onSelect={() => null}
          />
        </SortBar>
      )}

      <ListWrapper>
        {assetList.map((item) => (
          <AssetItem
            key={item.id}
            data={item}
            handleSelectAsset={(asset) => {
              setSelectedAsset(asset);
              setOpen(true);
            }}
            handleDeleteAsset={(params) => setDeleteAssetParams(params)}
          />
        ))}
      </ListWrapper>

      {!assetList.length && (
        <EmptyWrapper>
          <Empty
            imageUrl={EmptyImage}
            title="Start to add your first portfolio asset"
            subTitle="Add a new asset to build up your personal portfolio"
            maxWidth={340}
          />

          <Button
            icon={<PlusIcon color="#ffffff" />}
            onClick={() => setOpen(true)}
          >
            Add new asset
          </Button>
        </EmptyWrapper>
      )}

      <AddNewAssetModal
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setSelectedAsset(undefined);
        }}
        selectedAsset={selectedAsset}
      />

      <ConfirmModal
        title="Are you sure to delete this asset ?"
        isOpen={!!deleteAssetParams}
        onClose={() => setDeleteAssetParams(undefined)}
        callback={deleteAsset}
        loading={loading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  min-height: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .MuiAutocomplete-root {
    min-width: 130px;

    .MuiOutlinedInput-root {
      height: 40px;
      font-size: 14px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;

  button {
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
  }
`;

const SortBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .result {
    color: #7c8698;
    font-size: 16px;
  }
`;

const EmptyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;

  & > div {
    width: fit-content;
    height: fit-content;
  }

  button {
    height: 36px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: center;
  }
`;
