import { Dayjs } from "dayjs";
import styled from "styled-components";

import { DatePicker } from "./DatePicker";

type Props = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  minStartDate?: Dayjs;
  maxStartDate?: Dayjs;
  maxEndDate?: Dayjs;
  startDateError?: string;
  endDateError?: string;
  onChangeStartDate: (value: React.SetStateAction<Dayjs | null>) => void;
  onChangeEndDate: (value: React.SetStateAction<Dayjs | null>) => void;
};

export const DateRangePicker = ({
  startDate,
  endDate,
  minStartDate,
  maxStartDate,
  maxEndDate,
  startDateError,
  endDateError,
  onChangeStartDate,
  onChangeEndDate,
}: Props) => {
  return (
    <Wrapper>
      <DatePicker
        value={startDate}
        onchange={(value) => onChangeStartDate(value)}
        placeholder="Fill start date"
        minDate={minStartDate}
        maxDate={maxStartDate}
        error={startDateError}
      />
      <DatePicker
        value={endDate}
        onchange={(value) => onChangeEndDate(value)}
        placeholder="Fill end date"
        minDate={startDate || undefined}
        maxDate={maxEndDate}
        disabled={!startDate}
        error={endDateError}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding-bottom: 20px;

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    margin-top: 0px;
  }
`;
