import styled from "styled-components";

import FireworkImage from "assets/images/common/firework.png";
import { Avatar } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedGuest } from "store/chat/chat.selector";

export const InvestorBox = ({
  data,
  isAuthor,
}: {
  data: {
    ticket: string;
    instrument: string;
    timeline: string;
  };
  isAuthor?: boolean;
}) => {
  const selectedGuest = useAppSelector(selectSelectedGuest);

  const { ticket, instrument, timeline } = data || {};

  return (
    <Wrapper>
      <LeftBox>
        <InfoWrapper>
          <Avatar
            src={selectedGuest?.avatar}
            name={selectedGuest?.displayName}
            size={40}
          />

          <TextInfo>
            <div className="name">{selectedGuest?.displayName}</div>
          </TextInfo>
        </InfoWrapper>

        <Text>
          {isAuthor
            ? `Congratulations! You’ve just sent a New Investment Proposal`
            : ` Congratulations, your company has traction! You’ve just received a New
          Investment Proposal`}
        </Text>

        <OverViewWrapper>
          <OverviewItem>
            <div className="title">Ticket to invest:</div>
            <div className="value">{ticket}</div>
          </OverviewItem>

          <OverviewItem>
            <div className="title">Instrument:</div>
            <div className="value">{instrument}</div>
          </OverviewItem>

          <OverviewItem>
            <div className="title">Timeline:</div>
            <div className="value">{timeline}</div>
          </OverviewItem>
        </OverViewWrapper>
      </LeftBox>

      <img className="firework" src={FireworkImage} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-top: 8px;
  padding: 16px;
  border-radius: 12px;
  background: #f4f5f7;

  .firework {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 520px;
  gap: 12px;
  font-size: 14px;
  z-index: 1;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextInfo = styled.div`
  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 600;
  }

  .description {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 12px;
  }
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
`;

const OverViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  background: #ffffff;
`;

const OverviewItem = styled.div`
  .title {
    margin-bottom: 2px;
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 14px;
  }

  .value {
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 14px;
    font-weight: 600;
  }
`;
