import { TablePagination } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { DeleteIcon, EditIcon, EyeIcon } from "assets/images/common";
import { BasicTable, PageTitle, Text } from "components";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks/useHook";
import { TBD } from "models/common.model";
import { fDate } from "utils/time.util";
import {
  selectReportList,
  selectReportLoading,
} from "store/report/report.selector";
import { reportActions } from "store/report/report.slice";
import ConfirmModal from "components/Feature/ConfirmModal";

const CONFIG = [
  {
    name: "Report name",
    width: "auto",
  },
  {
    name: "Start date",
    width: "auto",
  },
  {
    name: "End date",
    width: "auto",
  },
  {
    name: "Action",
    width: 340,
  },
];

export const ReportsPage = () => {
  const [deleteReportId, setDeleteReportId] = useState<string | undefined>();
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const reportList = useAppSelector(selectReportList);
  const loading = useAppSelector(selectReportLoading);

  const handleDeleteReport = () => {
    if (deleteReportId) {
      dispatch(
        reportActions.deleteReport({
          reportId: deleteReportId,
          callback: () => setDeleteReportId(undefined),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(reportActions.getReports());
  }, [dispatch]);

  useEffect(() => {
    const data = reportList
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item) => {
        return {
          reportName: <GroupCell>{item.displayName}</GroupCell>,
          startDate: (
            <GroupCell>{fDate(item.data.startDate, "DD/MM/YYYY")}</GroupCell>
          ),
          endDate: (
            <GroupCell>{fDate(item.data.endDate, "DD/MM/YYYY")}</GroupCell>
          ),
          action: (
            <GroupCell>
              <ActionWrapper
                onClick={() => navigate(`/insights/reports/${item.id}`)}
              >
                <EyeIcon color="#6A6D84" /> ViewMore
              </ActionWrapper>

              <ActionWrapper
                onClick={() =>
                  navigate(`/insights/assets/generate-report/${item.id}`)
                }
              >
                <EditIcon />
                <Text size="md">Edit</Text>
              </ActionWrapper>

              <ActionWrapper onClick={() => setDeleteReportId(item.id)}>
                <DeleteIcon /> Delete
              </ActionWrapper>
            </GroupCell>
          ),
        };
      });

    setTableData(data);
  }, [navigate, page, reportList, rowsPerPage]);

  return (
    <Wrapper>
      <PageTitle>Reports</PageTitle>
      <BasicTable config={CONFIG} data={tableData} />

      {reportList.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={reportList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            setPage(0);
          }}
        />
      )}

      <ConfirmModal
        title="Are you sure to delete this report?"
        isOpen={!!deleteReportId}
        onClose={() => setDeleteReportId(undefined)}
        callback={handleDeleteReport}
        loading={loading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 100%;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  & > .MuiPaper-root {
    flex: 1;
  }
`;

const GroupCell = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 32px;
  cursor: pointer;

  svg {
    width: 20px;
    height: auto;
  }

  &:hover {
    text-decoration: underline;
  }
`;
