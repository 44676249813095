import { forwardRef, ReactNode, RefObject } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  activeRef?: RefObject<HTMLDivElement>;
}
type Ref = HTMLDivElement;

export const ScrollToWrapper = forwardRef<Ref, Props>(
  ({ children, activeRef }, ref) => {
    const active = activeRef === ref;

    return (
      <Wrapper ref={ref} className={active ? "active" : undefined}>
        {children}
      </Wrapper>
    );
  }
);

ScrollToWrapper.displayName = "ScrollToWrapper";

const Wrapper = styled.div`
  & > div {
    transition: all 0.5s;
  }

  &.active > div {
    animation: highlight 1s ease-in-out forwards;
    border: 1px solid;
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  @keyframes highlight {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }
`;
