import styled from "styled-components/macro";

interface Props {
  children?: React.ReactNode;
}

export const LineText = ({ children }: Props) => {
  return (
    <Wrapper>
      <Line></Line>
      {children && <Text>{children}</Text>}
      {children && <Line></Line>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Line = styled.div`
  border-bottom: 1px solid #ebecef;
  flex-basis: 100%;
`;
const Text = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 14px;
  padding: 0 30px;
`;
