import styled from "styled-components";

import { Stack, useMediaQuery } from "@mui/material";
import { Avatar, SectionWrapper, Text } from "components";
import { TeamMember } from "models/organisation.model";
import { useState } from "react";
import AddTeamMemberModal from "views/main/Company/components/AddTeamMemberModal";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedFund } from "store/feed/feed.selector";

export const TeamMembersSection = () => {
  const [member, setMember] = useState<TeamMember | null>(null);
  const lessThan1400 = useMediaQuery("(max-width: 1400px)");

  const { teamMembers } = useAppSelector(selectSelectedFund);

  return (
    <SectionWrapper>
      <Text className="section-wrapper__title" size="xl" fontSize="20px">
        Who they are?
      </Text>

      <ListWrapper>
        {teamMembers?.map((member) => (
          <Member
            key={member.id}
            onClick={() => !lessThan1400 && setMember(member as TeamMember)}
          >
            <Stack direction="row" gap="8px" mb="6px">
              <Avatar
                src={member.photoUrl}
                name={member.displayName}
                size={44}
              />

              <Stack className="text-wrapper">
                <Text size="lg" fontSize="15px" limit={1}>
                  {member.displayName}
                </Text>

                <Text size="md" fontSize="12px" limit={1}>
                  {member.role}
                </Text>
              </Stack>
            </Stack>

            <Text size="md" fontSize="12px" limit={2}>
              {member.bio}
            </Text>
          </Member>
        ))}
      </ListWrapper>

      {!lessThan1400 && (
        <AddTeamMemberModal
          data={member}
          isOpen={!!member}
          onClose={() => setMember(null)}
          hideFooter
          titleType="View"
          isOnlyView
        />
      )}
    </SectionWrapper>
  );
};

const ListWrapper = styled.div`
  height: calc(100% - 56px);
  margin-top: 20px;
  padding-right: 16px;
  margin-right: -16px;
  overflow: auto;

  @media (max-width: 1400px) {
    height: unset;
  }
`;

const Member = styled.div`
  height: 107px;
  padding: 12px 0px;
  border-bottom: 1px solid #ebecef;
  transition: all 0.5s;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 1400px) {
    height: unset;
    cursor: default;

    .truncate {
      display: unset;
    }
  }
`;
