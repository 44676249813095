import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const SuccessIcon = ({ color = "#58A369" }: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.27243 12.0388C7.4042 12.1726 7.58269 12.2472 7.76951 12.2472C7.77439 12.2472 7.77858 12.2472 7.78346 12.2479C7.97517 12.2437 8.15644 12.1615 8.28539 12.0193L13.0329 6.79078C13.2915 6.50496 13.2706 6.06438 12.9855 5.80574C12.7018 5.5478 12.2611 5.56801 12.0011 5.85313L7.74929 10.5358L5.69413 8.44717C5.42434 8.17249 4.98306 8.16972 4.70838 8.4388C4.43371 8.70929 4.43023 9.14987 4.70002 9.42455L7.27243 12.0388Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 13.9629 4.0378 18 9 18C13.9629 18 18 13.9629 18 9C18 4.0378 13.9629 0 9 0C4.0378 0 0 4.0378 0 9ZM9 16.3125C4.9697 16.3125 1.6875 13.0308 1.6875 9C1.6875 4.96978 4.96978 1.6875 9 1.6875C13.0308 1.6875 16.3125 4.9697 16.3125 9C16.3125 13.0309 13.0309 16.3125 9 16.3125Z"
        fill={color}
      />
    </svg>
  );
};
