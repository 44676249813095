/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  Asset,
  AssetType,
  CreateAssetLinkParams,
  CreateAssetPseudoParams,
  DeleteAssetParams,
  Report,
  assetFuzzySearch,
} from "models/report.model";

export interface ReportState {
  assetList: Asset[];
  assetsFuzzySearch: assetFuzzySearch[];
  reportList: Report[];
  selectedReport: Report;
  loading: boolean;
}
export const initialState: ReportState = {
  assetsFuzzySearch: [],
  assetList: [],
  reportList: [],
  selectedReport: {} as Report,
  loading: false,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    updateReport(
      state,
      _action: PayloadAction<{
        data: Report;
        callback?: (report: Report) => void;
      }>
    ) {
      state.loading = true;
    },
    updateReportSuccess(state) {
      state.loading = false;
    },
    updateReportFailed(state) {
      state.loading = false;
    },
    createReport(
      state,
      _action: PayloadAction<{
        data: Report;
        callback?: (report: Report) => void;
      }>
    ) {
      state.loading = true;
    },
    createReportSuccess(state) {
      state.loading = false;
    },
    createReportFailed(state) {
      state.loading = false;
    },
    deleteReport(
      state,
      _action: PayloadAction<{ reportId: string; callback?: () => void }>
    ) {
      state.loading = true;
    },
    deleteReportSuccess(state, _action: PayloadAction<string>) {
      state.loading = false;
      state.reportList = state.reportList.filter(
        (i) => i.id !== _action.payload
      );
    },
    deleteReportFailed(state) {
      state.loading = false;
    },
    getReport(_, _action: PayloadAction<string>) {},
    getReportSuccess(state, _action: PayloadAction<Report>) {
      state.selectedReport = _action.payload;
    },
    getReports() {},
    getReportsSuccess(state, _action: PayloadAction<Report[]>) {
      state.reportList = _action.payload;
    },
    getAssetsFuzzyByName(
      state,
      _action: PayloadAction<{
        lookup: string;
        onLoad?: (loading: boolean) => void;
      }>
    ) {},
    getAssetsFuzzyByNameSuccess(
      state,
      _action: PayloadAction<assetFuzzySearch[]>
    ) {
      state.assetsFuzzySearch = _action.payload;
    },
    getAssets() {},
    getAssetsSuccess(state, _action: PayloadAction<Asset[]>) {
      state.assetList = _action.payload;
    },
    createAsset(
      state,
      _action: PayloadAction<{
        data: CreateAssetLinkParams | CreateAssetPseudoParams;
        type: AssetType.AssetTypesNetwork | AssetType.PseudoOrganisation;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    createAssetSuccess(state) {
      state.loading = false;
    },
    createAssetFailed(state) {
      state.loading = false;
    },
    updateAssetPseudo(
      state,
      _action: PayloadAction<{
        data: CreateAssetPseudoParams & { assetId: string };
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    updateAssetPseudoSuccess(state, _action: PayloadAction<Asset>) {
      const modifiedIndex = state.assetList.findIndex(
        (a) => a.id === _action.payload.id
      );

      if (modifiedIndex !== -1) {
        state.assetList[modifiedIndex] = _action.payload;
      }
      state.loading = false;
    },
    updateAssetPseudoFailed(state) {
      state.loading = false;
    },
    deleteAsset(
      state,
      _action: PayloadAction<{
        data: DeleteAssetParams;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    deleteAssetSuccess(state, _action: PayloadAction<string>) {
      state.assetList = state.assetList.filter((a) => a.id !== _action.payload);
      state.loading = false;
    },
    deleteAssetFailed(state) {
      state.loading = false;
    },
  },
});
export const {
  actions: reportActions,
  reducer: reportReducer,
  name: sliceKey,
} = reportSlice;
