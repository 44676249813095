import dayjs from "dayjs";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { PATH } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { selectNotificationList } from "store/chat/chat.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { selectActiveWorkspace } from "store/workspace/workspace.selector";

export const CompanyRoutePage = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotificationList);
  const { id, currency } = useAppSelector(selectOrganisation);
  const { pathname } = useLocation();
  const { defaultCurrency } = useAppSelector(selectActiveWorkspace);

  useEffect(() => {
    dispatch(organisationActions.createOrganisation({ displayName: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (!currency && defaultCurrency) {
      const input = { currency: { id: defaultCurrency.id } } as any;

      dispatch(
        organisationActions.updateOrganisation({ organisationId: id, input })
      );
    }
  }, [currency, defaultCurrency, dispatch, id]);

  useEffect(() => {
    const newNotification = notifications.find(
      (n) => n.isReviewOrg && !n.isRead
    );

    if (newNotification) {
      const { createdAt } = newNotification;

      const diff = dayjs().diff(dayjs(createdAt), "seconds");

      if (diff <= 30) {
        dispatch(organisationActions.getCompanyOrganisation());
      }
    }
  }, [dispatch, notifications]);

  useEffect(() => {
    const newNotification = notifications.find(
      (n) => n.isReviewFund && !n.isRead
    );

    if (newNotification) {
      const { fundId, createdAt } = newNotification;

      const diff = dayjs().diff(dayjs(createdAt), "seconds");

      if (diff <= 30) {
        if (pathname === PATH.COMPANY_FUNDRAISING && id) {
          dispatch(fundraisingActions.getFundraisingList({ id }));
        }

        if (pathname === `${PATH.COMPANY_FUNDRAISING}/${fundId}`) {
          dispatch(fundraisingActions.getFundraising({ id: fundId }));
        }
      }
    }
  }, [dispatch, id, pathname, notifications]);

  return <Outlet />;
};
