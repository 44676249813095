import { yupResolver } from "@hookform/resolvers/yup";
import { FieldEditor, Text } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { SelectItemTask } from "models/common.model";
import { Prospect } from "models/prospect.model";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import {
  selectProspect,
  selectProspectLoading,
} from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";
import { numberWithSpaces } from "utils/system.util";
import * as yup from "yup";

interface Props {
  name: string;
  yupSchema: yup.AnySchema;
  placeholder?: string;
  textClass?: string;
  unit?: string;
  linkName?: string;
}

const EXCEPTION_FIELDS = ["mfScore"];

export const ProspectFieldEditor = ({
  name,
  yupSchema,
  placeholder,
  textClass,
  unit,
  linkName,
}: Props) => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectProspectLoading);
  const prospect = useAppSelector(selectProspect);
  const fieldData = prospect[name as keyof Prospect];

  const schema = yup.object().shape({ [name]: yupSchema });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue(name, fieldData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData, name, setValue, prospect[name as keyof Prospect]]);

  const handleUpdateField = async (
    data: FieldValues | { [key: string]: SelectItemTask[] }
  ) => {
    try {
      if (!prospect?.id) {
        dispatch(prospectActions.createProspect(data));
      } else {
        if (EXCEPTION_FIELDS.includes(name)) {
          dispatch(
            prospectActions.setProspectMfScore({
              prospectSetMfScoreId: prospect.id,
              input: data as { mfScore: number },
            })
          );
        } else {
          dispatch(
            prospectActions.updateProspect({
              prospectUpdateId: prospect.id,
              input: data,
            })
          );
        }
      }
    } catch (err) {
      //throw err as Error;
    }
  };

  return (
    <FieldEditor
      loading={submitLoading}
      onHandle={handleSubmit(handleUpdateField)}
      textElement={
        linkName ? (
          <Link href={getValues(name)} target="_blank">
            {linkName === name
              ? getValues(name) || fieldData || placeholder
              : linkName}
          </Link>
        ) : (
          <Text size={"lg"} className={`display-text ${textClass}`}>
            {numberWithSpaces(
              getValues(name)?.toString() ||
                fieldData?.toString() ||
                placeholder ||
                "Fill data"
            )}
            {` ${
              ((getValues(name)?.toString() || fieldData?.toString()) &&
                unit) ||
              ""
            }`}
          </Text>
        )
      }
      inputElement={
        <GroupTextField
          name={name}
          placeholder="Please fill data"
          type="input"
          formRef={register}
          error={errors?.[name]?.message as string}
        />
      }
    ></FieldEditor>
  );
};

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.secondary.main};

  &:hover {
    text-decoration: underline;
  }
`;
