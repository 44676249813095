import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  color?: string;
}

export const StarIcon = ({ color = "#DFE1E6" }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99982 12.9853L4.12202 15.3549C3.95071 15.4654 3.77161 15.5128 3.58473 15.497C3.39785 15.4812 3.23432 15.4181 3.09416 15.3075C2.954 15.1969 2.84499 15.0588 2.76712 14.8933C2.68925 14.7271 2.67368 14.5413 2.7204 14.3359L3.74825 9.85744L0.314289 6.84808C0.158554 6.70591 0.061375 6.54383 0.0227527 6.36184C-0.0164926 6.18049 -0.00496829 6.00293 0.0573258 5.82916C0.11962 5.65539 0.213061 5.51322 0.337649 5.40264C0.462237 5.29206 0.633546 5.22097 0.851575 5.18938L5.38347 4.78655L7.13549 0.568699C7.21336 0.379133 7.33421 0.236958 7.49804 0.142175C7.66125 0.0473916 7.82851 0 7.99982 0C8.17113 0 8.3387 0.0473916 8.50254 0.142175C8.66575 0.236958 8.78629 0.379133 8.86415 0.568699L10.6162 4.78655L15.1481 5.18938C15.3661 5.22097 15.5374 5.29206 15.662 5.40264C15.7866 5.51322 15.88 5.65539 15.9423 5.82916C16.0046 6.00293 16.0164 6.18049 15.9778 6.36184C15.9386 6.54383 15.8411 6.70591 15.6854 6.84808L12.2514 9.85744L13.2792 14.3359C13.326 14.5413 13.3104 14.7271 13.2325 14.8933C13.1547 15.0588 13.0456 15.1969 12.9055 15.3075C12.7653 15.4181 12.6018 15.4812 12.4149 15.497C12.228 15.5128 12.0489 15.4654 11.8776 15.3549L7.99982 12.9853Z"
        fill={color}
      />
    </svg>
  );
};
