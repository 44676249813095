import { call, put, takeLatest, select } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import feedApi from "api/feed.api";
import { QueryParams, SortItem } from "models/common.model";
import { FavoriteParams, Feed, Prospect } from "models/prospect.model";
import { globalActions } from "store/global/global.slice";
import { convertFeed } from "utils/system.util";
import { selectFeeds } from "./feed.selector";
import { feedActions } from "./feed.slice";

function* getFeeds(action: PayloadAction<SortItem | undefined>) {
  try {
    const currentFeeds: Prospect[] = yield select(selectFeeds) || [];
    if (!currentFeeds?.length) {
      yield put(globalActions.setPageLoading(true));
    }
    const queryOptions =
      action.payload &&
      ({
        sort: {
          order: action.payload.order.toUpperCase(),
          byKey: action.payload.byKey,
        },
        feedFilter: action.payload.feedFilter || {
          namespace: "Global",
          types: ["Organisation"],
        },
      } as QueryParams);
    const { feedGet } = yield call(feedApi.feedGet, queryOptions);
    const clearFeeds = feedGet.filter((feed: Prospect) => feed?.id);
    const convertFeeds = clearFeeds.map((feed: Feed) => convertFeed(feed));

    yield put(feedActions.getFeedsSuccess(convertFeeds));
    yield put(feedActions.filterFeed({ filter: {}, isApply: true }));
  } catch (err) {
    // throw err as Error;
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* sortFeeds(action: PayloadAction<SortItem>) {
  try {
    yield put(globalActions.setPageLoading(true));
    const queryOptions = {
      sort: {
        order: action.payload.order,
        byKey: action.payload.byKey,
      },
    } as QueryParams;
    const { feedGet } = yield call(feedApi.feedGet, queryOptions);
    yield put(
      feedActions.sortFeedsSuccess({
        feeds: feedGet,
        sortSelected: action.payload,
      })
    );
    yield put(
      feedActions.filterFeed({
        filter: {},
        isApply: true,
      })
    );
  } catch (err) {
    // throw err as Error;
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getFeed(action: PayloadAction<string>) {
  try {
    yield put(globalActions.setPageLoading(true));
    if (action.payload) {
      const queryOptions = {
        feedFilter: {
          namespace: "Global",
          types: ["Organisation"],
        },
      } as QueryParams;
      const { feedGet } = yield call(feedApi.feedGet, queryOptions);
      if (feedGet) {
        const clearFeeds = feedGet.filter((feed: Prospect) => feed?.id);
        const convertFeeds = clearFeeds.map((feed: Feed) => convertFeed(feed));

        yield put(feedActions.getFeedsSuccess(convertFeeds));
        yield put(feedActions.selectFeed(action.payload));
      }
    }
  } finally {
    yield put(globalActions.setPageLoading(false));
  }
}

function* customFavorite(
  action: PayloadAction<{
    type: "create" | "remove";
    input: FavoriteParams;
  }>
) {
  const { type, input } = action.payload;

  try {
    const apiCall =
      type === "create" ? feedApi.favoriteCreate : feedApi.favoriteRemove;

    const { favouriteCreate, favouriteRemove } = yield call(apiCall, input);
    const response = type === "create" ? favouriteCreate : favouriteRemove;

    if (response) {
      const fav_person = type === "create" ? true : false;

      yield put(feedActions.customFavoriteSuccess(fav_person));
      yield put(feedActions.getFeeds(undefined));
    }
  } catch (_) {
    yield put(feedActions.customFavoriteFailed());
  }
}

export default function* feedSaga() {
  yield takeLatest(feedActions.getFeeds.type, getFeeds);
  yield takeLatest(feedActions.sortFeeds.type, sortFeeds);
  yield takeLatest(feedActions.getFeed.type, getFeed);
  yield takeLatest(feedActions.customFavorite.type, customFavorite);
}
