import { useRef } from "react";
import styled from "styled-components";

import { NavBar } from "components";
import {
  CompetitorsSection,
  DetailsBar,
  InfoSection,
  InternalCommentsSection,
  MarketResearchSection,
  SocialFeedSection,
} from "./components";

export const InsightsPage = () => {
  const marketResearchRef = useRef(null);
  const internalCommentsRef = useRef(null);
  const competitorsRef = useRef(null);
  const socialFeedRef = useRef(null);

  const navList = [
    { title: "Market research", ref: marketResearchRef },
    { title: "Internal comments", ref: internalCommentsRef },
    { title: "Competitors", ref: competitorsRef },
    { title: "Social Feed", ref: socialFeedRef },
  ];

  return (
    <Wrapper>
      <LeftBox>
        <InfoSection />

        <NavBar navList={navList} />

        <ScrollToWrapper ref={marketResearchRef}>
          <MarketResearchSection />
        </ScrollToWrapper>

        <ScrollToWrapper ref={internalCommentsRef}>
          <InternalCommentsSection />
        </ScrollToWrapper>

        <ScrollToWrapper ref={competitorsRef}>
          <CompetitorsSection />
        </ScrollToWrapper>

        <ScrollToWrapper ref={socialFeedRef}>
          <SocialFeedSection />
        </ScrollToWrapper>
      </LeftBox>

      <RightBox>
        <DetailsBar />
      </RightBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const LeftBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
`;

const RightBox = styled.div`
  width: 352px;
`;

const ScrollToWrapper = styled.div``;
