import { Dialog } from "@mui/material";
import styled from "styled-components/macro";

import { PdfView } from "components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  url: string;
}

export const ViewMediaModal = ({ isOpen, onClose, url }: Props) => {
  return (
    <BaseDialog open={isOpen} onClose={onClose} maxWidth="xl">
      <PdfView url={url} forceZoom={true} />
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
    //width: clamp(360px, 90vw, 1200px);
  }
`;
