import { useState } from "react";
import styled from "styled-components";

import { VideoIcon } from "assets/images/common";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { MediaFrame } from "../../components";

export const MediaSection = () => {
  const [mediaTab, setMediaTab] = useState<"video" | "deck">("video");

  const { videoUrl } = useAppSelector(selectOrganisation);

  if (!videoUrl) return null;

  return (
    <Wrapper>
      {videoUrl && mediaTab === "video" && <MediaFrame url={videoUrl} />}

      <TabWrapper>
        <TabButton onClick={() => setMediaTab("video")}>
          <VideoIcon />
          Video Presentation
        </TabButton>
      </TabWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`;

const TabButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;
