import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ImageIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1186_2693)">
        <path
          d="M13.4279 7.08751C13.4279 8.29563 12.4485 9.27501 11.2404 9.27501C10.0323 9.27501 9.05292 8.29563 9.05292 7.08751C9.05292 5.87938 10.0323 4.90001 11.2404 4.90001C12.4485 4.90001 13.4279 5.87938 13.4279 7.08751Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.67792 3.5C4.67792 2.5335 5.46142 1.75 6.42792 1.75H26.5529C27.5194 1.75 28.3029 2.5335 28.3029 3.5V18.375C28.3029 19.3415 27.5194 20.125 26.5529 20.125H6.42792C5.46142 20.125 4.67792 19.3415 4.67792 18.375V3.5ZM26.2029 12.4002V3.85H6.77792V13.9151L9.69802 10.995C10.7915 9.9015 12.5644 9.90151 13.6578 10.995L14.4936 11.8308C14.7948 12.132 15.293 12.0966 15.5486 11.7558L18.2478 8.15679C19.3384 6.70267 21.5039 6.65694 22.6549 8.06373L26.2029 12.4002ZM6.77792 16.8849L11.1829 12.4799C11.4563 12.2065 11.8995 12.2065 12.1729 12.4799L13.0087 13.3157C14.2135 14.5206 16.2062 14.3789 17.2286 13.0158L19.9278 9.41679C20.2005 9.05326 20.7418 9.04183 21.0296 9.39353L26.2029 15.7165V18.025H6.77792V16.8849Z"
          fill={color}
        />
        <path
          d="M2.92792 10.2555V17.017L2.75011 17.6806L2.92792 17.578V19.5987L2.10956 20.0712L1.75043 21.4114L21.1897 26.6202L21.8386 24.1985L20.79 21.875H24.2729L22.8801 27.0731C22.6299 28.0067 21.6703 28.5607 20.7368 28.3105L1.2975 23.1018C0.363936 22.8517 -0.190084 21.8921 0.060064 20.9585L2.92792 10.2555Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1186_2693">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
