import { IconButton, Popover } from "@mui/material";
import { useRef, useState } from "react";
import styled from "styled-components";

import { ActionsIcon, ImageIcon } from "assets/images/common";
import { Text } from "components";
import { Asset, AssetType, DeleteAssetParams } from "models/report.model";

interface Props {
  data: Asset;
  handleSelectAsset: (data: Asset) => void;
  handleDeleteAsset: (params: DeleteAssetParams) => void;
}

export const AssetItem = ({
  data,
  handleSelectAsset,
  handleDeleteAsset,
}: Props) => {
  const [open, setOpen] = useState(false);

  const buttonRef = useRef(null);

  const { id, displayName, __typename } = data;

  return (
    <Wrapper>
      <ImageWrapper>
        <ImageIcon />
      </ImageWrapper>

      <Text className="line-clamp" size="lg">
        {displayName}
      </Text>

      <IconButton
        className="action-btn"
        ref={buttonRef}
        onClick={() => setOpen(true)}
      >
        <ActionsIcon />
      </IconButton>

      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        PaperProps={{
          sx: {
            width: "180px",
            borderRadius: "4px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        <PopoverWrapper>
          {__typename === AssetType.PseudoOrganisation && (
            <ActionItem
              onClick={() => {
                handleSelectAsset(data);
                setOpen(false);
              }}
            >
              Edit
            </ActionItem>
          )}

          <ActionItem
            onClick={() => {
              handleDeleteAsset({
                assetId: id,
                assetType: __typename,
              });
              setOpen(false);
            }}
          >
            Delete
          </ActionItem>
        </PopoverWrapper>
      </Popover>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  gap: 16px;
  height: 90px;
  width: 350px;
  border-radius: 16px;
  border-radius: 16px;
  background-color: white;
  border: 1px solid #d8d9df;
  box-shadow: 0px 3px 5px rgba(5, 10, 48, 0.05);
  transition: all 0.5s;
  position: relative;

  .action-btn {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 4px;
  background: #f4f5f7;
`;

const PopoverWrapper = styled.div`
  padding: 20px;
  background-color: white;
`;

const ActionItem = styled.div`
  width: 100%;
  padding: 6px 12px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.main};
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #ebecf0;
  }
`;
