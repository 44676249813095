import { Dialog } from "@mui/material";
import { Button, ButtonOutline } from "components";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components/macro";

interface Props {
  image: File | undefined;
  isOpen: boolean;
  height?: number;
  width?: number;
  onClose: () => void;
  onSave: Dispatch<SetStateAction<Blob | undefined>>;
  ignoreCrop?: boolean;
}

export const CropImageModal = ({
  isOpen,
  onClose,
  image,
  onSave,
  height = 300,
  width = 300,
  ignoreCrop,
}: Props) => {
  const [scale, setScale] = useState(1);

  const editorRef = useRef() as MutableRefObject<AvatarEditor>;

  const handleScale = useCallback((e: any) => {
    e.preventDefault();
    setScale(parseFloat(e.target.value));
  }, []);

  const handleSave = useCallback(async () => {
    if (!image) return;
    const canvas = editorRef.current.getImageScaledToCanvas();
    const blob: Blob = await new Promise((res) => {
      canvas.toBlob((b: any) => res(b));
    });
    onSave(blob);
    onClose();
  }, [image, onClose, onSave]);

  return (
    <BaseDialog open={isOpen} onClose={onClose} maxWidth="lg">
      {image && (
        <Wrapper>
          {ignoreCrop ? (
            <ImageView src={URL.createObjectURL(image)} />
          ) : (
            <EditorWrapper>
              <CropImageWrapper>
                <AvatarEditor
                  ref={editorRef}
                  width={width}
                  height={height}
                  image={image}
                  border={50}
                  scale={scale}
                />
              </CropImageWrapper>

              <Controls>
                <input
                  name="scale"
                  type="range"
                  onChange={handleScale}
                  min={"0.5"}
                  max="4"
                  step="0.01"
                  defaultValue="1"
                />
              </Controls>
            </EditorWrapper>
          )}
        </Wrapper>
      )}

      <GroupButton>
        <Button onClick={handleSave}>Save</Button>
        <ButtonOutline onClick={onClose}>Cancel</ButtonOutline>
      </GroupButton>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 24px;
    border-radius: 32px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 25px;
`;

const ImageView = styled.img`
  width: 100%;
  height: clamp(380px, calc(100vh - 40px), 420px);
  object-fit: scale-down;
`;
const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  input {
    width: clamp(200px, 50vw, 350px);
  }
`;

const CropImageWrapper = styled.div`
  width: 100%;
  max-height: 420px;
  padding-right: 12px;
  padding-bottom: 6px;
  overflow: auto;

  canvas {
    max-width: 100%;
  }
`;

const Controls = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }

  .button-wrapper,
  button {
    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 100%;
    }
  }

  button {
    padding: 0 20px;
    min-width: 120px;
  }
`;
