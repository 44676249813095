import styled from "styled-components";

import { SectionWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedFund } from "store/feed/feed.selector";
import { Stack } from "@mui/material";
import { fDate } from "utils/time.util";

export const TermsSection = () => {
  const { duration, managementFee, returnParticipation } =
    useAppSelector(selectSelectedFund);

  return (
    <SectionWrapper>
      <Text size="xl" fontSize="20px">
        Fund terms
      </Text>
      <Container>
        <Stack spacing="4px">
          <Text className="term-item" size="md">
            <span>Entity:</span> {"US Delaware fund"}
          </Text>
          <Text className="term-item" size="md">
            <span>Duration:</span> {duration}
          </Text>

          <Text className="term-item" size="md">
            <span>Management fee:</span> {managementFee}
          </Text>

          <Text className="term-item" size="md">
            <span>Return participation:</span> {returnParticipation}
          </Text>
        </Stack>

        <Text className="term-item" size="md">
          <span>Close date:</span> {fDate(1711818000000, "DD/MM/YYYY")}
        </Text>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 12px;
  max-height: 110px;
  overflow-y: auto;
  padding-top: 10px;

  .term-item {
    span {
      font-weight: 600;
    }
  }

  @media (max-width: 1400px) {
    max-height: unset;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    gap: 4px;
  }
`;
