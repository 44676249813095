/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryAction } from "models/common.model";
import {
  CustomProspectParams,
  Prospect,
  ProspectStatus,
  ProspectTeamMember,
  ProspectTeamMemberParams,
} from "models/prospect.model";

export interface ProspectState {
  selectedProspect: Prospect;
  prospects: Prospect[];
  searchProspects: Prospect[];
  loading: boolean;
}

export const initialState: ProspectState = {
  selectedProspect: {} as Prospect,
  prospects: [],
  searchProspects: [],
  loading: false,
};

const prospectSlice = createSlice({
  name: "prospect",
  initialState,
  reducers: {
    unselectProspect(state) {
      state.selectedProspect = {} as Prospect;
    },
    getProspects(_, _action: PayloadAction<{ isAdmin?: boolean }>) {},
    getProspectsSuccess(state, _action: PayloadAction<Prospect[]>) {
      state.prospects = _action.payload;
      state.searchProspects = _action.payload;
    },
    searchProspect(state, _action: PayloadAction<string>) {
      const searchArr = _action.payload.split(" ").filter((i) => !!i);
      if (searchArr.length) {
        const tempProspects = state.prospects.filter((item) =>
          searchArr.find((i) =>
            item?.displayName?.toLowerCase()?.includes(i?.toLowerCase())
          )
        );
        state.searchProspects = tempProspects;
      } else {
        state.searchProspects = state.prospects;
      }
    },
    getCompanyProspects() {},
    getProspect(
      _,
      _action: PayloadAction<{ id: string; isAdmin?: boolean }>
    ) {},
    getProspectSuccess(state, _action: PayloadAction<Prospect>) {
      state.selectedProspect = _action.payload;
      state.loading = false;
    },
    createProspect(state, _action: PayloadAction<CustomProspectParams>) {
      state.loading = true;
    },
    createProspectSuccess(state, _action: PayloadAction<Prospect>) {
      state.selectedProspect = _action.payload;
      state.loading = false;
    },
    createProspectFailed(state) {
      state.loading = false;
    },
    updateProspect(
      _,
      _action: PayloadAction<{
        prospectUpdateId?: string;
        input: CustomProspectParams;
        fieldUpdate?: string;
      }>
    ) {},
    updateProspectSuccess(
      state,
      _action: PayloadAction<{ data: Prospect; fieldUpdate?: string }>
    ) {
      const { data, fieldUpdate } = _action.payload;

      if (fieldUpdate) {
        state.selectedProspect = {
          ...state.selectedProspect,
          [fieldUpdate]: data[fieldUpdate as keyof Prospect],
        };
      } else {
        state.selectedProspect = data;
      }
    },
    updateProspectFailed(state) {},
    setProspectMfScore(
      state,
      _action: PayloadAction<{
        prospectSetMfScoreId?: string;
        input: {
          mfScore: number;
        };
      }>
    ) {},
    setProspectMfScoreSuccess(
      state,
      _action: PayloadAction<{ mfScore: number }>
    ) {
      state.selectedProspect = {
        ...state.selectedProspect,
        mfScore: _action.payload.mfScore,
      };
      state.loading = false;
    },
    setProspectStatus(
      state,
      _action: PayloadAction<{
        prospectSetStatusId?: string;
        input: {
          status: ProspectStatus;
        };
      }>
    ) {
      state.loading = true;
    },
    setProspectStatusSuccess(
      state,
      _action: PayloadAction<{ status: ProspectStatus }>
    ) {
      state.selectedProspect = {
        ...state.selectedProspect,
        status: _action.payload.status,
      };
      state.loading = false;
    },
    setProspectStatusFailed(state) {
      state.loading = false;
    },
    customTeamMember(
      state,
      _action: PayloadAction<{
        prospectCustomTeamMemberId: string;
        data: ProspectTeamMemberParams | null;
        type: QueryAction;
        avatarImage?: Blob;
        teamMemberId?: string;
        isAdmin?: boolean;
        callback?: () => void;
      }>
    ) {
      state.loading = true;
    },
    customTeamMemberSuccess(
      state,
      _action: PayloadAction<ProspectTeamMember[]>
    ) {
      state.selectedProspect = {
        ...state.selectedProspect,
        teamMembers: _action.payload,
      };
      state.loading = false;
    },
    customTeamMemberFailed(state) {
      state.loading = false;
    },
    setLoading(state, _action: PayloadAction<boolean>) {
      state.loading = _action.payload;
    },
  },
});
export const {
  actions: prospectActions,
  reducer: prospectReducer,
  name: sliceKey,
} = prospectSlice;
