import { call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { reportActions } from "store/report/report.slice";

import reportApi from "api/report.api";
import {
  AssetType,
  CreateAssetLinkParams,
  CreateAssetPseudoParams,
  DeleteAssetParams,
  Report,
} from "models/report.model";
import { globalActions } from "store/global/global.slice";
import { errorNotification } from "utils/app.util";

function* updateReport(
  action: PayloadAction<{
    data: Report;
    callback?: (report: Report) => void;
  }>
) {
  try {
    const { data, callback } = action.payload;
    const { assetReportUpdate } = yield call(reportApi.assetReportUpdate, data);

    if (assetReportUpdate) {
      const { report } = assetReportUpdate;

      if (report) {
        yield put(reportActions.updateReportSuccess());
        callback && callback(report);
      }
    }
  } catch (_) {
    yield put(errorNotification("Update report failed"));
    yield put(reportActions.updateReportFailed());
  }
}

function* getAssetsFuzzyByName(
  action: PayloadAction<{
    lookup: string;
    onLoad?: (loading: boolean) => void;
  }>
) {
  const { onLoad, lookup } = action.payload;

  try {
    onLoad && onLoad(true);

    const { assetFuzzySearch } = yield call(reportApi.assetFuzzySearch, lookup);

    if (assetFuzzySearch) {
      const { results } = assetFuzzySearch;

      yield put(reportActions.getAssetsFuzzyByNameSuccess(results));
      onLoad && onLoad(false);
    }
  } catch (_) {
    onLoad && onLoad(false);
  }
}

function* getAssets() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { assetListGet } = yield call(reportApi.assetListGet);

    if (assetListGet) {
      const { assets } = assetListGet;
      yield put(reportActions.getAssetsSuccess(assets || []));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* createAsset(
  action: PayloadAction<{
    data: CreateAssetLinkParams | CreateAssetPseudoParams;
    type: AssetType.AssetTypesNetwork | AssetType.PseudoOrganisation;
    callback?: () => void;
  }>
) {
  try {
    const { data, type, callback } = action.payload;

    const apiCall =
      type === AssetType.AssetTypesNetwork
        ? reportApi.assetCreateLink
        : reportApi.assetCreatePseudo;

    const { assetCreateLink, assetCreatePseudo } = yield call(
      apiCall,
      data as any
    );

    const response =
      type === AssetType.AssetTypesNetwork
        ? assetCreateLink
        : assetCreatePseudo;

    if (response) {
      const { asset } = response;

      if (asset) {
        yield put(reportActions.getAssets());
        yield put(reportActions.createAssetSuccess());
      }
    }

    callback && callback();
  } catch (_) {
    yield put(errorNotification("Add new asset failed"));
    yield put(reportActions.createAssetFailed());
  }
}

function* updateAssetPseudo(
  action: PayloadAction<{
    data: CreateAssetPseudoParams & { assetId: string };
    callback?: () => void;
  }>
) {
  try {
    const { data, callback } = action.payload;

    const { assetUpdatePseudo } = yield call(reportApi.assetUpdatePseudo, data);

    if (assetUpdatePseudo) {
      const { asset } = assetUpdatePseudo;

      if (asset) {
        yield put(reportActions.updateAssetPseudoSuccess(asset));
      }
    }

    callback && callback();
  } catch (_) {
    yield put(errorNotification("Update asset failed"));
    yield put(reportActions.updateAssetPseudoFailed());
  }
}

function* deleteAsset(
  action: PayloadAction<{
    data: DeleteAssetParams;
    callback?: () => void;
  }>
) {
  try {
    const { data, callback } = action.payload;

    const apiCall =
      data.assetType === AssetType.AssetOrganisation
        ? reportApi.assetDeleteLink
        : reportApi.assetDeletePseudo;

    const { assetDeleteLink, assetDeletePseudo } = yield call(apiCall, data);

    const response =
      data.assetType === AssetType.AssetOrganisation
        ? assetDeleteLink
        : assetDeletePseudo;

    if (response) {
      const { assetId } = response;

      if (assetId) {
        yield put(reportActions.deleteAssetSuccess(assetId));
      }
    }

    callback && callback();
  } catch (_) {
    yield put(errorNotification("Delete asset failed"));
    yield put(reportActions.deleteAssetFailed());
  }
}

function* createReport(
  action: PayloadAction<{
    data: Report;
    callback?: (report: Report) => void;
  }>
) {
  try {
    const { data, callback } = action.payload;
    const { assetReportCreate } = yield call(reportApi.assetReportCreate, data);

    if (assetReportCreate) {
      const { report } = assetReportCreate;

      if (report) {
        yield put(reportActions.createReportSuccess());
        callback && callback(report);
      }
    }
  } catch (_) {
    yield put(errorNotification("Create report failed"));
    yield put(reportActions.createReportFailed());
  }
}

function* getReports() {
  try {
    yield put(globalActions.setPageLoading(true));

    const { assetReportListGet } = yield call(reportApi.assetReportListGet);

    if (assetReportListGet) {
      const { reports } = assetReportListGet;
      yield put(reportActions.getReportsSuccess(reports || []));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* getReport(action: PayloadAction<string>) {
  try {
    yield put(globalActions.setPageLoading(true));

    const { assetReportGet } = yield call(
      reportApi.assetReportGet,
      action.payload
    );

    if (assetReportGet) {
      const { report } = assetReportGet;
      yield put(reportActions.getReportSuccess(report));
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* deleteReport(
  action: PayloadAction<{ reportId: string; callback?: () => void }>
) {
  try {
    const { reportId, callback } = action.payload;

    const { assetReportDelete } = yield call(
      reportApi.assetReportDelete,
      reportId
    );

    if (assetReportDelete) {
      const { assetReportId } = assetReportDelete;
      yield put(reportActions.deleteReportSuccess(assetReportId));
      callback && callback();
    }
  } catch (_) {
    yield put(errorNotification("Delete report failed"));
    yield put(reportActions.deleteAssetFailed());
  }
}

export default function* reportSaga() {
  yield takeLatest(reportActions.updateReport.type, updateReport);
  yield takeLatest(
    reportActions.getAssetsFuzzyByName.type,
    getAssetsFuzzyByName
  );
  yield takeLatest(reportActions.getAssets.type, getAssets);
  yield takeLatest(reportActions.createAsset.type, createAsset);
  yield takeLatest(reportActions.deleteAsset.type, deleteAsset);
  yield takeLatest(reportActions.updateAssetPseudo.type, updateAssetPseudo);
  yield takeLatest(reportActions.createReport.type, createReport);
  yield takeLatest(reportActions.getReports.type, getReports);
  yield takeLatest(reportActions.getReport.type, getReport);
  yield takeLatest(reportActions.deleteReport.type, deleteReport);
}
