import { gql } from "graphql-request";
import { CustomFundraisingParams } from "models/fundraising.model";

import { graphqlRequest } from "utils/graphQL.util";

const fundraisingApi = {
  fundraisingProjectsGet(organisationId: string) {
    const variables = {
      organisationId,
    };

    const defaultQuery = gql`
      query FundraisingProjectsGet($organisationId: ID!) {
        fundraisingProjectsGet(organisationId: $organisationId) {
          ... on FundraisingProjectsSuccess {
            fundraisingProjects {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              status
              message
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
            }
          }
          ... on NoFundraisingProjectsFound {
            message
            resourceId
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(defaultQuery, variables);
  },

  fundraisingProjectGetADMIN(fundraisingProjectId: string) {
    const variables = {
      fundraisingProjectId,
    };

    const defaultQuery = gql`
      query FundraisingProjectGetADMIN($fundraisingProjectId: ID!) {
        fundraisingProjectGetADMIN(
          fundraisingProjectId: $fundraisingProjectId
        ) {
          ... on FundraisingProjectSuccess {
            fundraisingProject {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              status
              message
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
            }
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(defaultQuery, variables);
  },

  fundraisingProjectGet(fundraisingProjectId: string) {
    const variables = {
      fundraisingProjectId,
    };

    const defaultQuery = gql`
      query FundraisingProjectGet($fundraisingProjectId: ID!) {
        fundraisingProjectGet(fundraisingProjectId: $fundraisingProjectId) {
          ... on FundraisingProjectSuccess {
            fundraisingProject {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              status
              message
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
            }
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(defaultQuery, variables);
  },

  fundraisingProjectUpdate(
    fundraisingProjectId: string,
    input: CustomFundraisingParams
  ) {
    const variables = {
      fundraisingProjectId,
      input,
    };
    const defaultQuery = gql`
      mutation FundraisingProjectUpdate(
        $fundraisingProjectId: ID!
        $input: fundraisingProjectUpdateInput
      ) {
        fundraisingProjectUpdate(
          fundraisingProjectId: $fundraisingProjectId
          input: $input
        ) {
          ... on FundraisingProjectSuccess {
            fundraisingProject {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              status
              message
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
            }
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundraisingProjectCreate(
    organisationId: string,
    input: CustomFundraisingParams
  ) {
    const variables = {
      organisationId,
      input,
    };
    const defaultQuery = gql`
      mutation FundraisingProjectCreate(
        $organisationId: ID!
        $input: fundraisingProjectCreateInput
      ) {
        fundraisingProjectCreate(
          organisationId: $organisationId
          input: $input
        ) {
          ... on FundraisingProjectSuccess {
            fundraisingProject {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              status
              message
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
            }
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundraisingProjectDelete(id: string) {
    const variables = {
      fundraisingProjectId: id,
    };
    const defaultQuery = gql`
      mutation ($fundraisingProjectId: ID!) {
        fundraisingProjectDelete(fundraisingProjectId: $fundraisingProjectId) {
          ... on FundraisingProjectDeleteSuccess {
            fundraisingProjectId
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundraisingProjectAdmin(
    fundraisingProjectId: string,
    input: CustomFundraisingParams
  ) {
    const variables = {
      fundraisingProjectId,
      input,
    };

    const defaultQuery = gql`
      mutation FundraisingProjectADMIN(
        $fundraisingProjectId: ID!
        $input: fundraisingProjectADMINinput
      ) {
        fundraisingProjectADMIN(
          fundraisingProjectId: $fundraisingProjectId
          input: $input
        ) {
          ... on FundraisingProjectSuccess {
            fundraisingProject {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              status
              message
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
            }
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },

  fundraisingProjectListAdmin() {
    const defaultQuery = gql`
      query FundraisingProjectListADMIN {
        fundraisingProjectListADMIN {
          ... on FundraisingProjetListADMINsuccess {
            fundraisingProspects {
              fundraisingProject {
                id
                currentRound {
                  id
                  name
                  active
                }
                roundSize
                roundCloseDate
                status
                organisation {
                  id
                  status
                  displayName
                  currency {
                    id
                    symbol
                    name
                  }
                }
              }
            }
          }
          ... on NoFundraisingProjectsFound {
            message
            resourceId
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery);
  },

  fundraisingProjectSubmit(id: string) {
    const variables = {
      fundraisingProjectId: id,
    };

    const defaultQuery = gql`
      mutation FundraisingProjectSubmit($fundraisingProjectId: ID!) {
        fundraisingProjectSubmit(fundraisingProjectId: $fundraisingProjectId) {
          ... on FundraisingProjectSuccess {
            fundraisingProject {
              id
              currentRound {
                active
                id
                name
              }
              instrument {
                id
                name
                active
              }
              minTicketSize
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
              pitchDeckUrl
              posterUrl
              roundCloseDate
              roundSize
              valuation
              videoUrl
              contactEmail
              contactTel
              status
              message
              organisation {
                id
                status
                displayName
                currency {
                  id
                  symbol
                  name
                }
              }
            }
          }
          ... on FundraisingProjectError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;
    return graphqlRequest(defaultQuery, variables);
  },
};

export default fundraisingApi;
