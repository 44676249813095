import { gql } from "graphql-request";

import { GraphqlQuery } from "models/common.model";
import { graphqlRequest } from "utils/graphQL.util";

const commonApi = {
  productTypesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query ($active: Boolean) {
        productTypes(active: $active) {
          id
          name
          active
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  impactsGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query ($active: Boolean) {
        impactValues(active: $active) {
          id
          name
          active
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  businessTypesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query ($active: Boolean) {
        businessTypes(active: $active) {
          id
          name
          active
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  sectorsGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query ($active: Boolean) {
        marketSectors(active: $active) {
          id
          name
          active
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  countriesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query ($active: Boolean) {
        countries(active: $active) {
          id
          name
          alpha2Code
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  fundingRounds(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query ($active: Boolean) {
        fundingRounds(active: $active) {
          id
          name
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  fundingInstruments(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query ($active: Boolean) {
        fundingInstruments(active: $active) {
          id
          name
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },

  currenciesGet(query?: GraphqlQuery) {
    const variables = {
      active: true,
    };

    const defaultQuery = gql`
      query Currencies($active: Boolean) {
        currencies(active: $active) {
          id
          symbol
          name
        }
      }
    `;

    return graphqlRequest(query || defaultQuery, variables);
  },
};

export default commonApi;
