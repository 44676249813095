import styled from "styled-components";

import { Text, VerticalBox } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedFund } from "store/feed/feed.selector";
import { useState } from "react";
import { NewFeedsModal } from "./NewFeedsModals";

export const NewFeedsSection = () => {
  const { newFeeds } = useAppSelector(selectSelectedFund);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  return (
    <ListWrapper>
      {newFeeds.map((item) => (
        <NewFeed
          key={item.id}
          onClick={() => {
            setIsOpen(true);
            setSelectedId(item.id);
          }}
        >
          <Logo src={item.author.logoUrl}></Logo>
          <VerticalBox className="vertical-box">
            <Text size="lg" fontSize="14px" limit={1}>
              {item?.title}
            </Text>
            <Text size="md" fontSize="12px" limit={1}>
              {item?.description}
            </Text>
          </VerticalBox>
        </NewFeed>
      ))}
      <NewFeedsModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedId("");
        }}
        list={newFeeds}
        selectedId={selectedId}
      />
    </ListWrapper>
  );
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  overflow-y: auto;
`;

const NewFeed = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  background: #ebecef;
  cursor: pointer;

  .vertical-box {
    align-items: flex-start;
  }

  /* &::after {
    position: absolute;
    left: 6px;
    top: 22px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.secondary.main};
    transform: translateY(-50%);
    content: "";
  } */
`;

const Logo = styled.img`
  width: 44px;
  height: auto;
`;
