import { Box, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

import { useAppSelector } from "hooks/useHook";
import { Message as IMessage } from "models/chat.model";
import { selectSelectedChat, selectUserId } from "store/chat/chat.selector";
import { decrypt } from "utils/app.util";
import { getFirstMessageBatch, messagesNextBatch } from "utils/chat.util";
import { parseCodeObject } from "utils/firestore.util";
import { handleGroupedMessages } from "utils/system.util";
import { InvestorBox, Message } from ".";
import { ReachOutFundBox } from "./ReachOutFundsBox";
import { selectNamespaceId } from "store/global/global.selector";

const fMessageDate = (date: number) => {
  const now = dayjs();
  const targetDate = dayjs(date);

  if (targetDate.isSame(now, "day")) {
    return `Today, ${targetDate.format("HH:mm")}`;
  } else if (targetDate.isSame(now.subtract(1, "day"), "day")) {
    return `Yesterday, ${targetDate.format("HH:mm")}`;
  } else if (targetDate.isAfter(now.subtract(7, "day"))) {
    return targetDate.format("dddd, HH:mm");
  } else if (targetDate.isAfter(now.subtract(1, "year"))) {
    return targetDate.format("DD/MM, HH:mm");
  } else {
    return targetDate.format("DD/MM/YYYY, HH:mm");
  }
};

export const Messages = () => {
  const [messageList, setMessageList] = useState<IMessage[]>([]);
  const [lastDocument, setLastDocument] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);

  const messagesRef = useRef(messageList);
  const selectedChat = useAppSelector(selectSelectedChat);
  const userId = useAppSelector(selectUserId);
  const namespaceId = useAppSelector(selectNamespaceId);

  const groupMessages = handleGroupedMessages(
    messageList.map((m) => {
      const decryptedMessage = decrypt(m.message);

      return {
        ...m,
        message: decryptedMessage,
        ...parseCodeObject(decryptedMessage),
      };
    })
  );

  const updateMessages = (newMessageList: IMessage[]) => {
    messagesRef.current = newMessageList;
    setMessageList(newMessageList);
  };

  const nextDocuments = async () => {
    if (!lastDocument) return;
    if (!selectedChat?.id) return;

    const { messages, lastVisible } = await messagesNextBatch(
      namespaceId,
      selectedChat.id,
      lastDocument
    );

    if (!messages.length) {
      setHasMore(false);
      return;
    }

    setLastDocument(lastVisible);
    updateMessages([...messageList, ...messages]);
  };

  useEffect(() => {
    if (!selectedChat?.id) return;

    const unsub = getFirstMessageBatch(
      namespaceId,
      selectedChat.id,
      (querySnapshot) => {
        const messages: IMessage[] = [];
        querySnapshot.docChanges().forEach(({ type, doc }) => {
          const message = { ...doc.data(), id: doc.id } as IMessage;

          if (type === "added") {
            messages.push(message);
          }

          if (type === "modified") {
            const modifiedIndex = messagesRef.current.findIndex(
              (item) => item.id === doc.id
            );

            if (modifiedIndex !== -1) {
              messagesRef.current[modifiedIndex] = message;
            }
          }
        });

        if (!messages.length) {
          setHasMore(false);
          return;
        }

        if (messagesRef.current.length === 0) {
          setLastDocument(
            querySnapshot.docChanges()[querySnapshot.docChanges().length - 1]
              ?.doc
          );
        }

        updateMessages([...messages, ...messagesRef.current]);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat?.id]);

  return (
    <Wrapper id="messages-wrapper">
      <InfiniteScroll
        dataLength={messageList.length}
        next={nextDocuments}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          gap: "12px",
        }}
        inverse={true}
        hasMore={hasMore}
        loader={
          <Box display="flex" justifyContent="center" padding="16px 0px">
            <CircularProgress size="24px" color="secondary" />
          </Box>
        }
        scrollableTarget="messages-wrapper"
      >
        {groupMessages.map((messages: IMessage[], index) => {
          return (
            <GroupMessage key={index}>
              {messages.map((item, index) => {
                const { instrument, ticket, timeline } = item;
                const investData = { instrument, ticket, timeline };
                if (item.isChatInvest)
                  return (
                    <InvestorBox
                      key={item.id}
                      data={investData}
                      isAuthor={item.senderId === userId}
                    />
                  );

                if (item.isReachOutFunds)
                  return (
                    <ReachOutFundBox
                      key={item.id}
                      isAuthor={item.senderId === userId}
                    />
                  );

                return (
                  <Message
                    key={item.id}
                    message={item.message}
                    time={fMessageDate(item.createdAt)}
                    isAuthor={item.senderId === userId}
                    isSeen={item.isRead}
                    hideStatus={!!index}
                  />
                );
              })}
            </GroupMessage>
          );
        })}
      </InfiniteScroll>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - 133px);
  padding: 24px 32px;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

const GroupMessage = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
`;
