import { call, put, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { LIMIT_FIXED_DATA_FETCHING } from "constants/app.const";
import { CachedResName } from "models/cached.model";
import { SelectItem } from "models/common.model";
import { cachedActions } from "./cached.slice";

function* getFixedData(
  action: PayloadAction<{
    resName: CachedResName;
    updateAt?: number;
    callApi: () => void;
  }>
) {
  try {
    const { resName, updateAt, callApi } = action.payload;

    const now = new Date().getTime();
    const limitTime = (updateAt || 0) + LIMIT_FIXED_DATA_FETCHING;

    if (now < limitTime) return;

    const response: { [resName: string]: SelectItem[] } = yield call(callApi);
    const data = response[resName];

    yield put(cachedActions.getFixedDataSuccess({ resName, data }));
    // eslint-disable-next-line no-empty
  } catch (_) {}
}

export default function* cachedSaga() {
  yield takeEvery(cachedActions.getFixedData.type, getFixedData);
}
