import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const LeftArrowIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.08337 9.41675L0.25004 5.58341C0.166707 5.50008 0.107818 5.4098 0.0733738 5.31258C0.0383738 5.21536 0.020874 5.11119 0.020874 5.00008C0.020874 4.88897 0.0383738 4.7848 0.0733738 4.68758C0.107818 4.59036 0.166707 4.50008 0.25004 4.41675L4.08337 0.583414C4.23615 0.430637 4.4306 0.354248 4.66671 0.354248C4.90282 0.354248 5.09726 0.430637 5.25004 0.583414C5.40282 0.736192 5.47921 0.930637 5.47921 1.16675C5.47921 1.40286 5.40282 1.5973 5.25004 1.75008L2.00004 5.00008L5.25004 8.25008C5.40282 8.40286 5.47921 8.5973 5.47921 8.83341C5.47921 9.06953 5.40282 9.26397 5.25004 9.41675C5.09726 9.56952 4.90282 9.64591 4.66671 9.64591C4.4306 9.64591 4.23615 9.56952 4.08337 9.41675V9.41675Z"
        fill={color}
      />
    </svg>
  );
};
