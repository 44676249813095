import { LeftArrowIcon } from "assets/images/common/LeftArrowIcon";
import { useState } from "react";
import styled from "styled-components/macro";
import LeftMenu from "./LeftMenu";

const LeftSideBar = () => {
  const [isExpand, setIsExpand] = useState(true);
  const toggleSidebar = () => {
    setIsExpand(!isExpand);
  };

  return (
    <Wrapper className={isExpand ? "expand" : "collapse"}>
      <LeftMenu></LeftMenu>
      <ExpandButton onClick={toggleSidebar}>
        <LeftArrowIcon />
      </ExpandButton>
    </Wrapper>
  );
};

export default LeftSideBar;

const ExpandButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50% 0 0 50%;
  background-color: #d8d9df;
  & > svg {
    width: auto;
    height: 11px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 256px;
  background-color: white;
  transition: all 0.7s;
  box-shadow: 5px 3px 5px rgb(5 10 48 / 5%);
  overflow-y: auto;
  position: relative;

  &.collapse {
    width: 80px;

    .left-menu {
      padding-left: 16px;
      padding-right: 16px;
    }

    .flag-text {
      gap: 0px;

      & > .text {
        width: 0px;
        opacity: 0;
      }
    }

    .MuiCollapse-root {
      &::after {
        opacity: 0;
      }
    }

    .menu-item {
      min-width: 44px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`;
