import { InputHTMLAttributes } from "react";
import styled from "styled-components/macro";

export const NumberField: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  ...props
}) => <Input type="number" {...props} />;

const Input = styled.input`
  border: 1px solid #ebecef;
  border-radius: 16px;
  outline: none;
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  width: 100%;
  transition: all 0.7s;
  &.error {
    border-color: ${(props) => props.theme.palette.error.main};
  }
  &:hover {
    border-color: #d8d9df;
  }
`;
