import { ENV } from "constants/app.const";
import { GraphQLClient, Variables } from "graphql-request";
import { GraphqlQuery } from "models/common.model";
import { getFirebaseToken } from "./firebase.util";

const graphqlRequest = async (query: GraphqlQuery, variables?: Variables) => {
  const token = await getFirebaseToken();
  const graphQLClient = new GraphQLClient(ENV.GRAPHQL_URI as string, {
    headers: {
      authorization: `Bearer ${token}`,
      "x-app-key": ENV.GRAPHQL_KEY as string,
    },
  });
  return graphQLClient.request(query, variables);
};

export { graphqlRequest };
