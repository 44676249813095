// import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, dialogClasses } from "@mui/material";
import { useCallback, useState } from "react";
// import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components";
// import * as yup from "yup";

// import commonApi from "api/common.api";
import { Button, ButtonOutline, CheckBox } from "components";
// import { Combobox } from "components/Feature/Combobox";
import ConfirmModal from "components/Feature/ConfirmModal";
// import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
// import { WORKSPACE_TYPE } from "models/auth.model";
// import { SelectType } from "models/organisation.model";
// import { CreateWorkspaceParams } from "models/workspace.model";
import { selectAuthLoading } from "store/auth/auth.selector";
// import { selectCurrencies } from "store/cached/cached.selector";
// import { cachedActions } from "store/cached/cached.slice";
// import { selectOrganisation } from "store/organisation/organisation.selector";
// import {
//   selectActiveWorkspace,
//   selectWorkspaceLoading,
// } from "store/workspace/workspace.selector";
import { authActions } from "store/auth/auth.slice";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const UserSettingsModal = ({ isOpen, onClose }: Props) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [userDeleteRelatedData, setUserDeleteRelatedData] = useState(false);
  // const [selectedCurrency, setSelectedCurrency] = useState<SelectType | null>(
  //   null
  // );

  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectAuthLoading);

  const handleOnClose = useCallback(() => {
    if (loading) return;
    onClose();
  }, [loading, onClose]);

  // const {
  //   displayName: currentDisPlayName,
  //   type,
  //   defaultCurrency,
  // } = useAppSelector(selectActiveWorkspace);

  // const schema = yup.object().shape({
  //   displayName: yup.string().required(),
  // });

  // const {
  //   register,
  //   handleSubmit,
  //   setValue,
  //   reset,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  // const onSubmit = async (formData: FieldValues) => {
  //   const input = {
  //     ...formData,
  //     type,
  //   } as CreateWorkspaceParams;

  //   dispatch(
  //     workspaceActions.updateWorkspace({
  //       input,
  //       callback: () => {
  //         reset();
  //         setSelectedCurrency(null);
  //         onClose();
  //       },
  //     })
  //   );
  // };

  const handleDeleteUser = () => {
    setIsOpenConfirm(false);
    console.log("USER_DELETE");
    dispatch(
      authActions.userDelete({ deleteOwnerRelatedData: userDeleteRelatedData })
    );
  };

  return (
    <BaseDialog open={isOpen} onClose={handleOnClose} maxWidth="xl">
      <HeaderBox>User Settings</HeaderBox>

      {/* <Wrapper onSubmit={handleSubmit(onSubmit)}> */}
      <Wrapper>
        {/* {false && (
          <GroupTextField
            fieldName="Workspace name"
            name="displayName"
            placeholder="Your name, company name, etc..."
            formRef={register}
            error={errors.displayNAme?.message as string}
          />
        )} */}

        <ButtonOutline
          disabled={loading}
          loading={loading}
          className="delete-btn"
          onClick={() => setIsOpenConfirm(true)}
        >
          Delete user
        </ButtonOutline>

        <CheckBox
          disabled={loading}
          mainsize={22}
          label="Remove all related data where I'm the owner for a complete cleanup."
          checked={userDeleteRelatedData}
          onChange={(e) => {
            const checked = e.target.checked;
            setUserDeleteRelatedData(checked);
          }}
        />

        {false && (
          <FooterBox>
            <ButtonOutline onClick={onClose}>Cancel</ButtonOutline>
            <Button type="submit" loading={loading}>
              Update
            </Button>
          </FooterBox>
        )}
      </Wrapper>

      <ConfirmModal
        title="We thoroughly enjoyed your presence here!"
        subTitle="We're sad to see you considering deleting your account. If there's anything we can do to improve your experience or address any concerns, please let us know."
        warningText="Please be aware that all data will be deleted and cannot be recovered!"
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        callback={handleDeleteUser}
        confirmButtonText="Close account"
        cancelButtonText="Keep account:)"
      />
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 40px 40px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 20px 20px 0px;
  }
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: calc(100vw - 64px);
    padding: 20px;
  }

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  .delete-btn {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

const FooterBox = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid #ebecef;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }

  & button,
  .button-wrapper {
    width: 100%;
  }
`;
