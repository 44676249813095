const palette = {
  primary: {
    main: "#C9AF7F",
    dark: "#796A4D",
  },
  secondary: {
    main: "#373B59",
    light: "#6A6D84",
    dark: "#050A30",
  },
  error: {
    main: "#D25C54",
  },
  success: {
    main: "#3B864C",
  },
  primaryText: {
    main: "#373B59",
    light: "#6A6D84",
    dark: "#050A30",
  },
};

export default palette;
