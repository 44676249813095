import { IconButton, Popover } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRef, useState } from "react";
import styled from "styled-components";

import { ActionsIcon } from "assets/images/common";
import { Avatar, SectionWrapper } from "components";
import { Post } from "models/post.model";
import { MediaFrame } from "../../components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";

interface Props {
  data: Post;
  deletePost?: (id: string) => void;
  handleSelectPost?: (data: Post) => void;
  isGuestView?: boolean;
}

dayjs.extend(relativeTime);

export const UpdatePost = ({
  data,
  deletePost,
  handleSelectPost,
  isGuestView,
}: Props) => {
  const [open, setOpen] = useState(false);

  const buttonRef = useRef(null);
  const organisation = useAppSelector(selectOrganisation);
  const { title, body, attachment, createdAt, postedBy } = data;
  const displayName = isGuestView
    ? postedBy?.displayName
    : organisation.displayName;
  const logoUrl = isGuestView ? postedBy?.logoUrl : organisation.logoUrl;

  return (
    <SectionWrapper>
      <Container>
        <Header>
          <Avatar src={logoUrl} name={displayName} size={64} />
          <InfoWrapper>
            <Text className="name">{displayName}</Text>
            <Text className="sub">{dayjs(createdAt).fromNow()}</Text>
          </InfoWrapper>

          {!isGuestView && (
            <>
              <IconButton ref={buttonRef} onClick={() => setOpen(true)}>
                <ActionsIcon />
              </IconButton>
              <Popover
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={buttonRef.current}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                PaperProps={{
                  sx: {
                    width: "180px",
                    borderRadius: "4px",
                    boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
                  },
                }}
              >
                <PopoverWrapper>
                  <ActionItem
                    onClick={() => {
                      handleSelectPost && handleSelectPost(data);
                      setOpen(false);
                    }}
                  >
                    Edit
                  </ActionItem>
                  <ActionItem
                    onClick={() => {
                      deletePost && deletePost(data.id);
                      setOpen(false);
                    }}
                  >
                    Delete
                  </ActionItem>
                </PopoverWrapper>
              </Popover>
            </>
          )}
        </Header>

        <Title>{title}</Title>

        <Text>{body}</Text>

        {attachment && <MediaFrame url={attachment} />}

        {false && (
          <Footer>
            <Avatar
              src="https://bit.ly/kent-c-dodds"
              name="Antony Branco Lopes"
              size={48}
            />

            <InfoWrapper>
              <div className="name">Antony Branco Lopes</div>
              <div className="sub">CEO & Founder of Spectre Biotech</div>
            </InfoWrapper>
          </Footer>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Footer = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
  border-top: 1px solid #ebecef;

  .name {
    font-size: 16px !important;
  }

  .sub {
    font-size: 14px !important;
  }
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 700;
    font-size: 20px;
  }

  .sub {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 16px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 16px;
  font-weight: 600;
  word-break: break-all;
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
  word-break: break-all;
`;

const PopoverWrapper = styled.div`
  padding: 20px;
  background-color: white;
`;

const ActionItem = styled.div`
  width: 100%;
  padding: 6px 12px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.main};
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #ebecf0;
  }
`;
