import { useLocation } from "react-router-dom";

import { Text } from "components";
import { PATH } from "constants/app.const";
import { useAppSelector } from "hooks/useHook";
import { selectAppConfig } from "store/global/global.selector";
import styled from "styled-components/macro";
import GuestHeader from "./components/GuestHeader";
import { useTheme } from "@mui/material";
interface Props {
  children: React.ReactNode;
}

export const GuestLayout = ({ children }: Props) => {
  const theme = useTheme();
  const LIST_BACKGROUND = {
    [PATH.SIGN_UP]: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    [PATH.SIGN_IN]: `linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
  };
  const location = useLocation();
  const leftBackgroundColor = LIST_BACKGROUND[location.pathname];
  const appConfig = useAppSelector(selectAppConfig);
  const { brandUrl, brandLabel, description, title } = appConfig;

  return (
    <GuestLayoutWrapper>
      <LeftSection bgColor={leftBackgroundColor}>
        <LeftWrapper>
          <GuestHeader />
          <GuestInfo>
            <Text size="xxl" color="white">
              {title}
            </Text>
            <Text size="md" color="white">
              {description}
            </Text>
            <Link href={brandUrl} target="_blank">
              {brandLabel}
            </Link>
          </GuestInfo>
        </LeftWrapper>
      </LeftSection>
      <RightSection>
        <GuestHeader></GuestHeader>
        {children}
      </RightSection>
    </GuestLayoutWrapper>
  );
};

const Link = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-basis: 100%;
  background: ${(props: { bgColor: string }) =>
    props.bgColor || "linear-gradient(180deg, #373b59 0%, #050a30 100%)"};
  //background: linear-gradient(180deg, #373b59 0%, #050a30 100%);
  padding: 40px 80px;
  max-height: 100%;
  overflow-y: auto;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - 60px);
`;

const RightSection = styled.div`
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-basis: 100%;
  padding: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .guest-header {
    display: none;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 20px;
    .guest-header {
      display: flex;
      margin-bottom: 30px;
    }
  }
`;

const GuestInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const GuestLayoutWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  ${(props) => props.theme.breakpoints.down("md")} {
    background: linear-gradient(180deg, #373b59 0%, #050a30 100%);
    ${LeftSection} {
      display: none;
    }
  }
`;
