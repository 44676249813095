import styled from "styled-components";

import DownloadImage from "assets/images/common/download-mf.png";
import { Button } from "components";

export const DownloadBar = () => {
  return (
    <Wrapper>
      <img src={DownloadImage} />

      <div className="text">
        <div className="title">We forge trust between Startups & Investors</div>
        <div className="sub-title">
          Finalise the onboarding process and expose your company to our pool of
          investors on the platform!
        </div>
      </div>

      <Button>Mobile App is coming soon</Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 557px;
  padding: 24px;
  padding-top: 378px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(161, 140, 102, 0.1);
  background: linear-gradient(180deg, #373b59 0%, #050a30 100%, #050a30 100%);
  overflow: hidden;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    color: #fafbfc;

    .title {
      font-size: 20px;
      font-weight: 700;
    }

    .sub-title {
      font-size: 14px;
    }
  }

  .button-wrapper {
    width: 100%;

    button {
      width: 100%;
      background: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;
