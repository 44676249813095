import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import styled from "styled-components/macro";

interface Props {
  value: boolean;
  disabled?: boolean;
  label?: string;
  onchange?: (value: boolean) => void;
}

export const ToggleButton = ({ label, value, disabled, onchange }: Props) => {
  return (
    <Wrapper>
      {label && (
        <FormControlLabel
          control={
            <Switch
              disabled={disabled}
              checked={!!value}
              onChange={(event) => onchange && onchange(event.target.checked)}
            />
          }
          label={label}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FormGroup)``;
