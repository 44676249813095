/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Chat, Notification, User } from "models/chat.model";

export interface ChatState {
  selectedChat: Chat | undefined;
  selectedGuest: User | undefined;
  searchText: string;
  notificationList: Notification[];
  userId: string;
}
export const initialState: ChatState = {
  selectedChat: undefined,
  selectedGuest: undefined,
  searchText: "",
  notificationList: [],
  userId: "",
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSelectedChat(state, _action: PayloadAction<Chat | undefined>) {
      state.selectedChat = _action.payload;
    },
    setSelectedGuest(state, _action: PayloadAction<User | undefined>) {
      state.selectedGuest = _action.payload;
    },
    setSearchText(state, _action: PayloadAction<string>) {
      state.searchText = _action.payload;
    },
    setNotificationList(state, _action: PayloadAction<Notification[]>) {
      state.notificationList = _action.payload;
    },
    setUserId(state, _action: PayloadAction<string>) {
      state.userId = _action.payload;
    },
  },
});
export const {
  actions: chatActions,
  reducer: chatReducer,
  name: sliceKey,
} = chatSlice;
