import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const TriangleUpIcon = ({ color = "#C2C7CF" }: Props) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3753 0.999757C5.74052 0.707582 6.25948 0.707581 6.62469 0.999756L11.2739 4.71913C12.0121 5.30964 11.5945 6.5 10.6492 6.5H1.35078C0.405503 6.5 -0.0120516 5.30964 0.726086 4.71913L5.3753 0.999757Z"
        fill={color}
      />
    </svg>
  );
};
