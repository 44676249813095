import { CustomSelect } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { ProspectStatus } from "models/prospect.model";
import { globalActions } from "store/global/global.slice";
import { selectProspect } from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";

const ProspectStatusList = ["draft", "review", "published", "flagged"];

export const ProspectSelectStatus = () => {
  const dispatch = useAppDispatch();
  const { status, id } = useAppSelector(selectProspect);

  const handleSelect = (status: ProspectStatus) => {
    try {
      if (id) {
        dispatch(
          prospectActions.setProspectStatus({
            prospectSetStatusId: id,
            input: {
              status: status,
            },
          })
        );
      }
    } catch (err) {
      dispatch(
        globalActions.pushNotification({
          type: "error",
          message: "Update status failed",
        })
      );
    }
  };

  return (
    <Wrapper>
      <CustomSelect
        className="prospect-select-status"
        data={ProspectStatusList}
        selectedItem={status}
        onSelect={handleSelect}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .prospect-select-status {
    width: 205px;
  }
`;
