import { ProspectMultiSelectType } from "models/prospect.model";
import styled from "styled-components/macro";
import { LimitText } from "utils/system.util";

interface Props {
  data: ProspectMultiSelectType[];
  limit: number;
}

export const ListTag = ({ data, limit }: Props) => {
  const maxWord = 60;
  const renderData = () => {
    let total = 0;
    return data?.slice(0, limit).map((item, index) => {
      const wLength = (index + 1) * 4;
      if (index === limit - 1) {
        const itemLength = item?.name?.length || 0;
        const afterLength = total + itemLength + wLength;
        if (total > maxWord) return { ...item, name: "" };
        return {
          ...item,
          name:
            afterLength > maxWord
              ? LimitText(item.name, maxWord - afterLength - 3)
              : item.name,
        };
      }
      total += item.name?.length + (index + 1) * 4;
      return item;
    });
  };
  return (
    <Wrapper>
      <Tags>
        {[...renderData()]
          ?.filter((i) => i.name !== "...")
          ?.map((item, index) => (
            <TagItem key={index}>
              {item?.icon && item.icon}
              {item.name}
            </TagItem>
          ))}
      </Tags>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: center;
  }
`;

const TagItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f5f7;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 12px;
  border-radius: 8px;
  gap: 5px;
  padding: 5px 10px;
`;
