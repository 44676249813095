import { IconButton } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

import { NoticeIcon } from "assets/images/common";
import { Avatar } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectSelectedGuest } from "store/chat/chat.selector";
import { ChatDrawer } from "./ChatDrawer";

interface Props {
  openRateModal: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChatHeader = ({ openRateModal }: Props) => {
  const [open, setOpen] = useState(false);

  const selectedGuest = useAppSelector(selectSelectedGuest);

  return (
    <Wrapper>
      <InfoWrapper>
        <Avatar
          src={selectedGuest?.avatar}
          name={selectedGuest?.displayName}
          size={40}
        />

        <TextInfo>
          <div className="name">{selectedGuest?.displayName}</div>

          {selectedGuest?.isOnline && (
            <div className="status">
              <div className="status__icon" />
              Online
            </div>
          )}
        </TextInfo>
      </InfoWrapper>

      {false && (
        <IconButton className="info-button" onClick={() => setOpen(true)}>
          <NoticeIcon color="#606C82" />
        </IconButton>
      )}

      <ChatDrawer open={open} onClose={() => setOpen(false)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  height: 73px;
  padding: 16px 24px;
  border-bottom: 1px solid #ebecef;

  .info-button {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextInfo = styled.div`
  font-size: 14px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 600;
  }

  .status {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 12px;

    &__icon {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #58a369;
    }
  }
`;
