import { Chart } from "chart.js/auto";
import { CHART_BACKGROUND_COLOR } from "constants/app.const";
import { useEffect, useRef } from "react";
import styled from "styled-components";

interface Props {
  labels: string[];
  data: number[];
  othersTooltipData?: { [key: string]: number };
}

export const DoughnutChart = ({ labels, data, othersTooltipData }: Props) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"doughnut"> | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "doughnut",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: CHART_BACKGROUND_COLOR,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: "right",
              labels: {
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
                padding: 16,
              },
            },
            tooltip: {
              callbacks: {
                footer: (item) => {
                  if (item[0].label === "Others" && othersTooltipData) {
                    return Object.entries(othersTooltipData).map(
                      ([key, value]) => `${key}: ${value}`
                    );
                  }
                },
              },
            },
          },

          cutout: "65%",
        },
        plugins: [
          {
            id: "total",
            beforeDatasetsDraw(chart) {
              const { ctx } = chart;

              const data = chart.data.datasets[0].data as number[];
              if (!data.length) return;

              const total = data.reduce((a, b) => a + b, 0);

              ctx.save();

              ctx.font = "bolder 16px sans-serif";
              ctx.fillStyle = "#98A0AE";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText(
                "Total",
                chart.getDatasetMeta(0).data[0].x,
                chart.getDatasetMeta(0).data[0].y - 12
              );

              ctx.font = "bolder 24px sans-serif";
              ctx.fillStyle = "#373B59";
              ctx.fillText(
                `${total.toLocaleString()}`,
                chart.getDatasetMeta(0).data[0].x,
                chart.getDatasetMeta(0).data[0].y + 12
              );

              ctx.restore();
            },
          },
        ],
      });
    }
  }, [data, labels, othersTooltipData]);

  return (
    <Wrapper>
      <canvas ref={chartRef} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
