import { Divider } from "@mui/material";
import {
  FieldValues,
  UseFormRegister,
  UseFormReset,
  UseFormWatch,
} from "react-hook-form";
import styled from "styled-components";

import { DragDropField, Text } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch } from "hooks/useHook";
import { FileTypes } from "models/common.model";
import { globalActions } from "store/global/global.slice";
import {
  getUrlFileType,
  validateMaxSizeFile,
  validateVideoDurationTime,
} from "utils/system.util";
import { MediaFrame } from "../../components";

interface Props {
  name: string;
  error?: string;
  formRef?: UseFormRegister<FieldValues>;
  reset?: UseFormReset<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  handleImage: (image: File | undefined) => void;
}

export const AttachmentSection = ({
  name,
  error,
  formRef,
  reset,
  watch,
  handleImage,
}: Props) => {
  const dispatch = useAppDispatch();

  const url = watch ? watch(name) : "";

  const handleFileBrowser = async (file: File | undefined) => {
    if (file) {
      let validData = {
        isValid: true,
        errorMessage: "",
      };

      if (file.type.includes("video")) {
        validData = await validateVideoDurationTime(file);
      } else {
        validData = validateMaxSizeFile(file);
      }

      const { isValid, errorMessage } = validData;

      if (isValid) {
        handleImage(file);
      } else {
        handleImage(undefined);
        dispatch(
          globalActions.showNotificationModal({
            isShow: true,
            title: errorMessage,
            onSubmit: (onClose) => {
              onClose();
            },
          })
        );
      }
      return;
    }
  };

  return (
    <Wrapper>
      <Text size="lg">Attachment</Text>
      <GroupTextField
        fieldName="Link"
        placeholder="Video link (Vimeo or Youtube)"
        name={name}
        formRef={formRef}
        reset={reset}
        watch={watch}
        error={error}
        hasClear
      />
      <Divider
        sx={{
          borderWidth: "0.5px",
          borderColor: "#ebecef",
          color: "#7C8698",
        }}
      >
        OR
      </Divider>
      <DragDropField
        fieldName="Upload"
        label={`Upload a picture to illustrate your update.
SVG, PNG, JPG or GIF for photo.`}
        handleChange={(file) => handleFileBrowser(file)}
        types={["svg", "png", "jpg", "jpeg", "gif"]}
        url={
          getUrlFileType(url) !== FileTypes.UNKNOW &&
          url.includes("firebasestorage")
            ? url
            : undefined
        }
        handleRemoveUrl={() => {
          reset && reset({ [name]: "" });
        }}
      />

      {url && <MediaFrame url={url} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border: 1px solid #d8d9df;
  border-radius: 12px;
`;
