import { TablePagination } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { BasicTable, Text } from "components";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppNavigate, useAppSelector } from "hooks/useHook";
import { SortConfig, TBD } from "models/common.model";
import { Organisation } from "models/organisation.model";
import { selectOrganisations } from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { getLatestFundraising } from "utils/system.util";
import { fDate } from "utils/time.util";
import { StatusFlag } from "../Company/components";

const CONFIG = [
  {
    name: "Company name",
    width: "auto",
  },
  {
    name: "Released round",
    width: 170,
  },
  {
    name: "Latest round status",
    width: 180,
  },
  {
    name: "Status",
    width: 120,
    fieldName: "status",
    isSort: true,
  },
  {
    name: "Updated",
    width: 120,
    fieldName: "updatedAt",
    isSort: true,
  },
];

export const AdminOrganisationPage = () => {
  const [rawData, setRawData] = useState<Organisation[]>([]);
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const organisations = useAppSelector(selectOrganisations);

  const handleSort = (sort: SortConfig) => {
    setRawData(
      _.orderBy(
        (rawData?.length && rawData) || [],
        [sort.name],
        [sort.type || "asc"]
      )
    );
  };

  useEffect(() => {
    dispatch(organisationActions.getOrganisationsAdmin());
  }, [dispatch]);

  useEffect(() => {
    setRawData(organisations);
  }, [organisations]);

  useEffect(() => {
    const tempData = rawData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item) => {
        return {
          rowClick: () => navigate(`${PATH.ADMIN_COMPANY}/${item.id}/settings`),
          companyName: (
            <GroupCell>
              {item?.logoUrl ? (
                <Logo src={item.logoUrl} />
              ) : (
                <PlaceholderSpace />
              )}
              {"  "}
              {item.displayName}
            </GroupCell>
          ),
          releasedRoud: (
            <Text size="md">
              {getLatestFundraising(item.fundraisingProjects).currentRound
                ?.name || "__"}
            </Text>
          ),
          latestRoundStatus: (
            <StatusFlag status={item.fundraisingProjects[0]?.status} />
          ),
          status: <StatusFlag status={item.status} />,
          updated: fDate(Number(item.updatedAt), "DD/MM/YYYY"),
        };
      });
    setTableData(tempData);
  }, [navigate, page, rawData, rowsPerPage]);

  return (
    <Wrapper>
      <Helmet>
        <title>MyFoundry</title>
        <link rel="icon" href="/logo.png" />
      </Helmet>
      <BasicTable config={CONFIG} data={tableData} onSort={handleSort} />

      {rawData.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={rawData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            setPage(0);
          }}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  & > .MuiPaper-root {
    flex: 1;
  }
`;

const GroupCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;

const PlaceholderSpace = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background-color: #ebecef;
`;
