import _ from "lodash";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components";

import { FormProgress, FormSlice } from "components";
import ConfirmModal from "components/Feature/ConfirmModal";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import {
  selectOrganisation,
  selectOrganisationLoading,
  selectTrustScoreQuestions,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { ThankYouScreen, WelcomeScreen } from "./components";

export const MFForm = () => {
  const [step, setStep] = useState(0);
  const [score, setScore] = useState(0);
  const [answerCount, setAnswerCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const questions = useAppSelector(selectTrustScoreQuestions);
  const submitLoading = useAppSelector(selectOrganisationLoading);
  const { id } = useAppSelector(selectOrganisation);
  const showProgress = step > 0 && step < questions.length + 1;

  console.log("questions", id);

  const { setValue, getValues, handleSubmit } = useForm();

  const onSubmit = (data: FieldValues) => {
    const choises = _.map(data, (value, key) => ({
      trustScoreItemId: key,
      optionValue: value,
    }));

    dispatch(
      organisationActions.submitTrustScore({
        organisationId: id,
        input: {
          choises,
        },
        callback: (score) => {
          score && setScore(score);
          setStep((step) => step + 1);
        },
      })
    );
  };

  const handleNext = (newStep: number) => {
    if (step === questions.length) {
      handleSubmit(onSubmit)();
    } else {
      setStep(newStep);
    }
  };

  const handleSelect = (id: string, value: number) => {
    setValue(id, value);
    setAnswerCount(Object.keys(getValues()).length);
  };

  return (
    <Wrapper step={step}>
      <WelcomeScreen disabled={!id} onNext={() => setIsOpen(true)} />

      {questions.map((item, index) => (
        <FormSlice
          key={item.id}
          ordinalNumber={index + 1}
          data={item}
          isSubmit={step === questions.length}
          submitLoading={submitLoading}
          onSelect={(value) => handleSelect(item.id, value)}
          onNext={() => handleNext(index + 2)}
        />
      ))}

      <ThankYouScreen score={score} />

      {showProgress && (
        <FormProgress
          step={step}
          count={questions.length}
          completePercent={Math.round((answerCount / questions.length) * 100)}
          onChangeStep={(newStep) => setStep(newStep)}
        />
      )}

      <ConfirmModal
        title="Get MF Trust Score"
        subTitle="Please, note that you can submit the MF trust score only once. Make sure to review all the answers before submitting."
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        callback={() => {
          setStep(1);
          setIsOpen(false);
        }}
        confirmButtonText="Start now"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #f2f0ea;
  overflow: hidden;

  & > .form-item {
    transition: all 0.5s;
    transform: ${({ step }: { step: number }) => `translateY(-${step * 100}%)`};
  }
`;
