import { Box, CircularProgress } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Empty } from "components";
import { useAppSelector } from "hooks/useHook";
import { Chat } from "models/chat.model";
import { selectSelectedChat, selectUserId } from "store/chat/chat.selector";
import { getFirstRoomBatch, roomsNextBatch } from "utils/chat.util";
import { ChatListItem } from ".";
import { selectNamespaceId } from "store/global/global.selector";

enum ChatTab {
  ALL = "All chats",
  TEAM = "Team",
  INVESTORS = "Investors",
  OTHER = "Other",
}

const tabList = [ChatTab.ALL];

export const ChatList = () => {
  const [roomList, setRoomList] = useState<Chat[]>([]);
  const [lastDocument, setLastDocument] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(ChatTab.ALL);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();
  const roomsRef = useRef(roomList);
  const selectedChat = useAppSelector(selectSelectedChat);
  const userId = useAppSelector(selectUserId);
  const namespaceId = useAppSelector(selectNamespaceId);

  const updateRooms = (newRoomList: Chat[]) => {
    roomsRef.current = newRoomList;
    setRoomList(newRoomList);
  };

  const nextDocuments = async () => {
    if (!lastDocument) return;

    const { rooms, lastVisible } = await roomsNextBatch(
      namespaceId,
      userId,
      lastDocument
    );

    if (!rooms.length) {
      setHasMore(false);
      return;
    }

    setLastDocument(lastVisible);
    updateRooms([...roomList, ...rooms]);
  };

  useEffect(() => {
    const unsub = getFirstRoomBatch(namespaceId, userId, (querySnapshot) => {
      const rooms: Chat[] = [];
      querySnapshot.docChanges().forEach(({ type, doc }) => {
        const room = { ...doc.data(), id: doc.id } as Chat;

        if (type === "added") {
          rooms.push(room);
        }

        if (type === "modified") {
          const matchedRoom = roomsRef.current.find(
            (item) => item.id === doc.id
          );

          if (matchedRoom) {
            const modifiedIndex = roomsRef.current.findIndex(
              (item) => room.id === item.id
            );

            if (modifiedIndex !== -1) {
              roomsRef.current[modifiedIndex] = room;
            }

            if (matchedRoom.updatedAt === room.updatedAt) return;
            rooms.push(room);
          }
        }
      });

      if (!rooms.length) {
        setHasMore(false);
        return;
      }

      if (roomsRef.current.length === 0) {
        setLastDocument(
          querySnapshot.docChanges()[querySnapshot.docChanges().length - 1].doc
        );
      }

      updateRooms(_.uniqBy([...rooms, ...roomsRef.current], (i) => i.id));
    });

    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Wrapper>
      <TabList>
        {tabList.map((item, index) => (
          <TabItem
            key={index}
            className={item === activeTab ? "active" : undefined}
            length={tabList.length}
            onClick={() => setActiveTab(item)}
          >
            {item}
          </TabItem>
        ))}
      </TabList>

      {roomList.length > 0 ? (
        <ListWrapper id="rooms-wrapper">
          <InfiniteScroll
            dataLength={roomList.length}
            next={nextDocuments}
            hasMore={hasMore}
            loader={
              <Box display="flex" justifyContent="center" padding="16px 0px">
                <CircularProgress size="24px" color="secondary" />
              </Box>
            }
            scrollableTarget="rooms-wrapper"
          >
            {roomList.map((item) => (
              <ChatListItem
                key={item.id}
                data={item}
                isActive={selectedChat?.id === item.id}
                onClick={() => navigate(`/chats/${item.id}`)}
              />
            ))}
          </InfiniteScroll>
        </ListWrapper>
      ) : (
        <ListWrapper>
          <Empty
            title="No active conversations"
            subTitle="Your messages will appear here"
          />
        </ListWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* height: calc(100% - 64px); */
  height: calc(100%);
`;

const ListWrapper = styled.div`
  height: calc(100% - 38px);
  margin: 0px -16px;
  padding: 0px 16px;
  overflow-y: auto;

  .chat-list-item:last-child {
    border-bottom: none;
  }
`;

const TabList = styled.div`
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8d9df;
  height: 38px;
`;

const TabItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: ${({ length }: { length: number }) => `calc(100% / ${length})`};
  padding: 0px 12px;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;

  &.active {
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary.main};

    &::after {
      content: "";
      position: absolute;
      bottom: -16px;
      width: 100%;
      height: 2px;
      background: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;
