import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ZoomOutIcon = ({ color = "#fff" }: Props) => {
  return (
    <svg
      className="zoom-out-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M875.2 748.7H717.9c-14.5 0-26.2-11.7-26.2-26.2s11.7-26.2 26.2-26.2h157.4c14.5 0 26.2 11.7 26.2 26.2 0 14.4-11.8 26.2-26.3 26.2zM300.9 331.7H143.6c-14.5 0-26.2-11.7-26.2-26.2s11.7-26.2 26.2-26.2H301c14.5 0 26.2 11.7 26.2 26.2s-11.8 26.2-26.3 26.2z"
        fill={color}
      />
      <path
        d="M886.5 926.8L701 741.4c-10.2-10.2-10.2-26.8 0-37.1 10.2-10.2 26.8-10.2 37.1 0l185.5 185.5c10.2 10.2 10.2 26.8 0 37.1-10.3 10.2-26.9 10.2-37.1-0.1zM281.8 322.2L96.4 136.7c-10.2-10.2-10.2-26.8 0-37.1 10.2-10.2 26.8-10.2 37.1 0L319 285.1c10.2 10.2 10.2 26.8 0 37.1-10.3 10.2-27 10.2-37.2 0zM923.6 136.7L738.1 322.2c-10.2 10.2-26.8 10.2-37.1 0-10.2-10.2-10.2-26.8 0-37.1L886.5 99.6c10.2-10.2 26.8-10.2 37.1 0 10.2 10.3 10.2 26.9 0 37.1zM318.9 741.4L133.4 926.8c-10.2 10.2-26.8 10.2-37.1 0-10.2-10.2-10.2-26.8 0-37.1l185.5-185.5c10.2-10.2 26.8-10.2 37.1 0 10.2 10.3 10.2 26.9 0 37.2z"
        fill={color}
      />
      <path
        d="M144.2 748h157.4c14.5 0 26.2-11.7 26.2-26.2s-11.7-26.2-26.2-26.2H144.2c-14.5 0-26.2 11.7-26.2 26.2s11.7 26.2 26.2 26.2zM721 328.6h157.4c14.5 0 26.2-11.7 26.2-26.2s-11.7-26.2-26.2-26.2H721c-14.5 0-26.2 11.7-26.2 26.2-0.1 14.5 11.7 26.2 26.2 26.2z"
        fill={color}
      />
      <path
        d="M747.2 145.1v157.4c0 14.5-11.7 26.2-26.2 26.2s-26.2-11.7-26.2-26.2V145.1c0-14.5 11.7-26.2 26.2-26.2 14.4-0.1 26.2 11.7 26.2 26.2zM327.8 721.8v157.4c0 14.5-11.7 26.2-26.2 26.2s-26.2-11.7-26.2-26.2V721.8c0-14.5 11.7-26.2 26.2-26.2s26.2 11.7 26.2 26.2zM274.7 148.1v157.4c0 14.5 11.7 26.2 26.2 26.2s26.2-11.7 26.2-26.2V148.1c0-14.5-11.7-26.2-26.2-26.2s-26.2 11.8-26.2 26.2zM691.6 722.5v157.4c0 14.5 11.7 26.2 26.2 26.2s26.2-11.7 26.2-26.2V722.5c0-14.5-11.7-26.2-26.2-26.2-14.4-0.1-26.2 11.7-26.2 26.2z"
        fill={color}
      />
    </svg>
  );
};
