import { RefObject, useState } from "react";
import styled from "styled-components";

import { NavItem } from "models/common.model";
import { scrollIntoView } from "utils/system.util";

interface Props {
  navList: NavItem[];
  handleActiveSection?: (activeSection: RefObject<HTMLDivElement>) => void;
}

export const NavBar = ({ navList, handleActiveSection }: Props) => {
  const [active, setActive] = useState(navList[0].ref);

  const data = navList.filter((nav) => !nav.hidden);

  return (
    <Wrapper>
      {data.map((nav, index) => (
        <Item
          key={index}
          className={active === nav.ref ? "active" : undefined}
          length={data.length}
          onClick={() => {
            setActive(nav.ref);

            scrollIntoView(nav.ref);
            handleActiveSection && handleActiveSection(nav.ref);
          }}
        >
          {nav.title}
        </Item>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  border-bottom: 1px solid #d8d9df;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: center;
  }
`;

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: ${({ length }: { length: number }) => `calc(100% / ${length})`};
  padding: 0px 24px;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: fit-content;
    margin-bottom: 12px;
    padding: 6px 24px;

    &::after {
      display: none;
    }
  }

  &.active {
    font-weight: 600;
    color: ${(props) => props.theme.palette.text.primary};

    &::after {
      content: "";
      position: absolute;
      bottom: -16px;
      width: 100%;
      height: 2px;
      background: ${(props) => props.theme.palette.text.primary};
    }
  }
`;
