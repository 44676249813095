import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import { FundFieldInput, ImageEditor, SectionWrapper } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { selectSelectedFund } from "store/fund/fund.selector";
import { fundActions } from "store/fund/fund.slice";
import { globalActions } from "store/global/global.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { uploadFromBlobAsync } from "utils/firebase.util";

const stringYup = yup.string().nullable();

export const FundInfoSection = () => {
  const [logoImage, setLogoImage] = useState<Blob | undefined>(undefined);

  const dispatch = useAppDispatch();

  const { id: organisationId } = useAppSelector(selectOrganisation);
  const { id, logoUrl } = useAppSelector(selectSelectedFund);

  const handleUploadLogo = useCallback(
    async (logoImage: Blob) => {
      dispatch(globalActions.setPageLoading(true));

      try {
        const url = await uploadFromBlobAsync({
          blob: logoImage,
          name: "avatar",
          folder: `workspaces/${organisationId}/funds/${id}`,
        });

        const input = {
          logoUrl: url as string,
        };

        dispatch(
          fundActions.updateFund({
            fundId: id,
            input,
          })
        );

        dispatch(globalActions.setPageLoading(false));
      } catch (_) {
        dispatch(globalActions.setPageLoading(false));
        dispatch(
          globalActions.pushNotification({
            type: "error",
            message: "Update logo failed",
          })
        );
      }
    },
    [dispatch, id, organisationId]
  );

  useEffect(() => {
    if (logoImage) {
      handleUploadLogo(logoImage);
    }
  }, [handleUploadLogo, logoImage]);

  return (
    <SectionWrapper title="My fund">
      <Container>
        <AvatarWrapper>
          <ImageEditor
            className="avatar-upload"
            onSave={setLogoImage}
            url={logoUrl}
          />
        </AvatarWrapper>

        <FundFieldInput
          fieldName="Fund Name"
          name="displayName"
          placeholder="Set fund name"
          yupSchema={stringYup}
        />

        <FundFieldInput
          fieldType="text-area"
          fieldName="Description"
          name="description"
          placeholder="Type some words here..."
          yupSchema={stringYup}
        />
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const AvatarWrapper = styled.div`
  pointer-events: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "none" : "unset"};

  .avatar-upload {
    width: 96px;
    height: 96px;
    border-radius: 12px;
  }
`;
