import React from "react";
import styled from "styled-components/macro";

interface StyledProps {
  type?: "WHITE" | "GRAY";
}

interface Props extends StyledProps, React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  icon?: React.ReactNode;
}

export const FlagText = ({
  children,
  type = "GRAY",
  icon,
  ...props
}: Props) => {
  return (
    <Wrapper {...props} type={type} className="flag-text">
      {icon}
      <span className="text">{children}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
  gap: 8px;
  padding: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.7s;
  background-color: ${(props: StyledProps) =>
    props.type === "GRAY" ? "#F4F5F7" : "white"};

  svg {
    flex-shrink: 0;
  }

  .text {
    transition: all 0.4s;
    white-space: nowrap;
  }
`;
