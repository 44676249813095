import { Dialog } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

import { Button, CheckBox } from "components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AccreditedInvestorModal = ({ isOpen, onClose }: Props) => {
  const [step, setStep] = useState<1 | 2>(1);

  const handleClose = () => {
    onClose();
    setStep(1);
  };

  return (
    <BaseDialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <HeaderBox>
        {step === 1 && "Accredited Investor"}
        {step === 2 && "Investment Experience"}

        <Text>{step}/2</Text>
      </HeaderBox>

      <Wrapper>
        {step === 1 && (
          <>
            <TitleWrapper>
              <StrongText>
                Are you an accredited investor? An accredited investor needs to
                satisfy one of these requirements, please select one that
                applies to you. <span>*</span>
              </StrongText>
              <Text>
                Accredited investors meet standards defined by securities
                regulators which allow them to invest in certain privates
                securities offerings.
              </Text>
            </TitleWrapper>

            <CheckboxList>
              <CheckBox label="My individual income exceeded 100,000 € last year." />
              <CheckBox label="My net worth exceeds 250.000 € exclusive of the value of my primary residence." />
              <CheckBox label="I am a member of a network or syndicate of business angels and have been so for at least the last six months." />
              <CheckBox label="I have made more than one investment in an unlisted company in the last two years." />
              <CheckBox label="I am working, or have worked in the two years prior to the date below, in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises." />
              <CheckBox label="I am currently, or have been in the two years prior to the date below, a director of a company with an annual turnover of at least €1 million." />
            </CheckboxList>
          </>
        )}

        {step === 2 && (
          <>
            <TitleWrapper>
              <StrongText>
                Do you understand the risk profile of investing in unlisted
                securities such as unquoted private companies? <span>*</span>
              </StrongText>
              <Text>
                Investments unquoted private company are high risk and highly
                illiquid - it may not be possible to redeem your investment
                quickly. Do you understand the risks associated with investing
                in this type of structure approporoperately?
              </Text>

              <CheckBox label="I understand" />
              <CheckBox label="I require additional information" />
            </TitleWrapper>

            <TitleWrapper>
              <StrongText>
                Does the investment lifetime correspond with your investment
                time horizon?
              </StrongText>
              <Text>{`A typical unlisted company investment's lifetime is often 5 or
                more years. These are illiquid investments. You many not be able
                to exit an investments early or receive distributions as
                planned.`}</Text>

              <CheckBox label="Yes" />
              <CheckBox label="No" />
            </TitleWrapper>

            <TitleWrapper>
              <StrongText>
                Do you consider yourself to be an experienced investor and do
                you regularly make your own investment decisions? <span>*</span>
              </StrongText>

              <CheckBox label="Yes" />
              <CheckBox label="No" />
            </TitleWrapper>

            <TitleWrapper>
              <StrongText>
                Would any partial or total loss of your investment in unlisted
                companies have a materially detrimental effect on your standard
                of living?
              </StrongText>

              <CheckBox label="Yes" />
              <CheckBox label="No" />
            </TitleWrapper>
          </>
        )}
      </Wrapper>

      <FooterBox>
        <Text>*The field is mandatory.</Text>

        <Button onClick={() => setStep(2)}>
          {step === 1 ? "Next" : "Send"}
        </Button>
      </FooterBox>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 700px;
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;

  & > div {
    font-weight: 400;
  }
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: calc(100vw - 60px);
  }
`;

const FooterBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 0px 32px 32px;
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid #ebecef;

  .button-wrapper button {
    width: 216px;
  }

  & > div {
    font-size: 14px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 40px;
`;

const StrongText = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 16px;
  font-weight: 600;

  span {
    color: #d25c54;
  }
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .MuiFormControlLabel-label {
    color: #606c82;
    font-size: 16px;
  }
`;
