import styled from "styled-components";

import { NoticeIcon } from "assets/images/common";
import { Button, FlexBox, Text } from "components";

interface Props {
  text: string;
  icon?: React.ReactElement;
  buttonText?: string;
  hasButton?: boolean;
  handleClickButton?: () => void;
  backgroundColor?: string;
  color?: string;
}

export const Warning = ({
  text,
  icon,
  buttonText,
  hasButton,
  handleClickButton,
  backgroundColor = "#FBF9F5",
  color = "#A18C66",
}: Props) => {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <FlexBox>
        {icon || <NoticeIcon color="#A18C66" />}

        <Text size="sm" fontSize="16px" color={color}>
          {text}
        </Text>
      </FlexBox>

      {hasButton && <Button onClick={handleClickButton}>{buttonText}</Button>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 20px;
  border-radius: 4px;
  background: ${({ backgroundColor }: { backgroundColor: string }) =>
    backgroundColor};

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    padding: 16px;
  }

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  .button-wrapper {
    flex-shrink: 0;
    margin-left: auto;
  }
`;
