import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  color?: string;
}

export const SortIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 8C5.14444 8 4.98622 7.936 4.85867 7.808C4.73067 7.68044 4.66667 7.52222 4.66667 7.33333C4.66667 7.14444 4.73067 6.98622 4.85867 6.85867C4.98622 6.73067 5.14444 6.66667 5.33333 6.66667H6.66667C6.85556 6.66667 7.014 6.73067 7.142 6.85867C7.26956 6.98622 7.33333 7.14444 7.33333 7.33333C7.33333 7.52222 7.26956 7.68044 7.142 7.808C7.014 7.936 6.85556 8 6.66667 8H5.33333ZM0.666667 1.33333C0.477778 1.33333 0.319556 1.26956 0.192 1.142C0.0640001 1.014 0 0.855556 0 0.666667C0 0.477778 0.0640001 0.319333 0.192 0.191333C0.319556 0.0637779 0.477778 0 0.666667 0H11.3333C11.5222 0 11.6804 0.0637779 11.808 0.191333C11.936 0.319333 12 0.477778 12 0.666667C12 0.855556 11.936 1.014 11.808 1.142C11.6804 1.26956 11.5222 1.33333 11.3333 1.33333H0.666667ZM2.66667 4.66667C2.47778 4.66667 2.31933 4.60267 2.19133 4.47467C2.06378 4.34711 2 4.18889 2 4C2 3.81111 2.06378 3.65267 2.19133 3.52467C2.31933 3.39711 2.47778 3.33333 2.66667 3.33333H9.33333C9.52222 3.33333 9.68044 3.39711 9.808 3.52467C9.936 3.65267 10 3.81111 10 4C10 4.18889 9.936 4.34711 9.808 4.47467C9.68044 4.60267 9.52222 4.66667 9.33333 4.66667H2.66667Z"
        fill={color}
      />
    </svg>
  );
};
