import { CircularProgress } from "@mui/material";
import { ButtonHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  loading?: boolean;
  icon?: ReactNode;
}

export const Button: React.FC<Props> = ({
  children,
  loading,
  icon,
  ...props
}: Props) => {
  return (
    <Wrapper className="button-wrapper">
      <ButtonWrapper {...props}>
        <ButtonText className={loading ? "loading" : ""}>
          {icon}
          {children}
        </ButtonText>
        {loading && <LoadingAnimation size="20px" color="inherit" />}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.4s ease-in-out;
  &.loading {
    color: transparent;
  }
`;

const LoadingAnimation = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  color: white;
`;

const ButtonWrapper = styled.button`
  position: relative;
  padding: 0 40px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  height: 48px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.palette.secondary.dark},
    ${(props) => props.theme.palette.secondary.main},
    ${(props) => props.theme.palette.secondary.main},
    ${(props) => props.theme.palette.secondary.dark}
  );

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 14px;
    padding: 0px 16px;
  }

  border-radius: 4px;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
  }
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
