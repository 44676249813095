import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const InsightsIcon = ({ color = "#373B59" }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.51471 17.5707C8.81768 18.192 9.2497 18.6858 9.66927 19.1295H1.16667C0.563757 19.1295 0.075 18.6407 0.075 18.0378L0.0750369 1.16666C0.0750381 0.563755 0.563795 0.075 1.16671 0.075H14.5562C15.1591 0.075 15.6479 0.563756 15.6479 1.16667V10.2224C15.1726 10.1883 14.7093 10.1915 14.2612 10.2285C10.8072 10.5137 8.2431 12.8103 8.11159 15.5053C8.07102 16.3365 8.23909 17.0055 8.51471 17.5707ZM6.97425 13.4504H7.0247L7.04372 13.4037C8.09646 10.8163 10.8618 9.02808 14.0632 8.72801L14.1312 8.72164V8.65334V1.66667V1.59167H14.0562H1.6667H1.5917L1.5917 1.66667L1.59167 17.5378L1.59167 17.6128H1.66667H6.78884H6.89197L6.86019 17.5147C6.66655 16.917 6.55784 16.2282 6.59672 15.4314C6.60298 15.3031 6.61309 15.176 6.62692 15.0503L6.63608 14.9671H6.55237H4.02924C3.61042 14.9671 3.2709 14.6275 3.2709 14.2087C3.2709 13.7899 3.61042 13.4504 4.02924 13.4504H6.97425ZM11.1489 15.5167L11.1412 15.5402L11.1489 15.5636C11.5594 16.8117 12.8473 17.8223 14.5003 17.8223C16.1536 17.8223 17.4415 16.8116 17.8518 15.5639L17.8595 15.5404L17.8518 15.517C17.4413 14.2689 16.1533 13.2583 14.5003 13.2583C12.8471 13.2583 11.5591 14.2691 11.1489 15.5167ZM3.40833 4.99999C3.40833 4.58117 3.74785 4.24165 4.16667 4.24165H11.6853C12.1041 4.24165 12.4436 4.58117 12.4436 4.99999C12.4436 5.4188 12.1041 5.75832 11.6853 5.75832H4.16667C3.74785 5.75832 3.40833 5.4188 3.40833 4.99999ZM3.2709 9.59786C3.2709 9.17904 3.61042 8.83952 4.02924 8.83952H7.29968C7.7185 8.83952 8.05801 9.17904 8.05801 9.59786C8.05801 10.0167 7.7185 10.3562 7.29968 10.3562H4.02924C3.61042 10.3562 3.2709 10.0167 3.2709 9.59786ZM15.6057 15.4385C15.6057 16.0674 15.1085 16.5742 14.4989 16.5742C13.8893 16.5742 13.3921 16.0674 13.3921 15.4385C13.3921 14.8095 13.8893 14.3028 14.4989 14.3028C15.1085 14.3028 15.6057 14.8095 15.6057 15.4385ZM19.4239 15.5403C18.9907 17.6988 16.954 19.339 14.5003 19.339C12.0467 19.339 10.0104 17.6988 9.5768 15.5403C10.0099 13.3819 12.0467 11.7417 14.5003 11.7417C16.954 11.7417 18.9903 13.3819 19.4239 15.5403Z"
        fill={color}
        stroke="white"
        strokeWidth="0.15"
      />
    </svg>
  );
};
