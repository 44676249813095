import { useState } from "react";
import styled from "styled-components";

import { PlusIcon, ReportIcon } from "assets/images/common";
import EmptyImage from "assets/images/common/empty.png";
import {
  Button,
  ButtonOutline,
  Empty,
  PageTitle,
  SortFeature,
} from "components";
import { Combobox } from "components/Feature/Combobox";
import { SortItem } from "models/common.model";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AddNewAssetModal, AssetItem } from "../components";

const SORT_DATA: SortItem[] = [
  {
    id: "0",
    name: "Relevant",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "",
    order: "desc",
  },
];

const options = [{ label: "Last 7 days", value: "Last 7 days" }];

export const InsightsOpportunities = () => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <PageTitle>Opportunities</PageTitle>

      <Header>
        <ButtonGroup>
          <Button icon={<PlusIcon />} onClick={() => setOpen(true)}>
            Add new asset
          </Button>
          <ButtonOutline icon={<ReportIcon />}>Generate report</ButtonOutline>
        </ButtonGroup>

        <Combobox
          id="combobox-sort"
          freeSolo={false}
          options={options}
          onchange={() => null}
          value="Last 7 days"
        />
      </Header>

      <SortBar>
        <div className="result">No results</div>

        <SortFeature
          data={SORT_DATA}
          selectedItem={SORT_DATA[0]}
          onSelect={() => null}
        />
      </SortBar>

      <ListWrapper>
        {/* {[...Array(12)].map((_, index) => (
          <AssetItem key={index} />
        ))} */}
      </ListWrapper>

      {false && (
        <EmptyWrapper>
          <Empty
            imageUrl={EmptyImage}
            title="Start to add your first portfolio asset"
            subTitle="Add a new asset to build up your personal portfolio"
            maxWidth={340}
          />

          <Button
            icon={<PlusIcon color="#ffffff" />}
            onClick={() => setOpen(true)}
          >
            Add new asset
          </Button>
        </EmptyWrapper>
      )}

      <AddNewAssetModal
        isOpen={open}
        onClose={() => setOpen(false)}
        // handleOnSubmit={() => {}}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  min-height: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .MuiAutocomplete-root {
    min-width: 130px;

    .MuiOutlinedInput-root {
      height: 40px;
      font-size: 14px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;

  button {
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
  }
`;

const SortBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .result {
    color: #7c8698;
    font-size: 16px;
  }
`;

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
`;

const EmptyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;

  & > div {
    width: fit-content;
    height: fit-content;
  }

  button {
    height: 36px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
  }
`;
