import { IconButton } from "@mui/material";
import { DeleteIcon, EditIcon } from "assets/images/common";
import { ButtonOutline, Text } from "components";
import ConfirmModal from "components/Feature/ConfirmModal";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { ProspectTeamMember } from "models/prospect.model";
import { useState } from "react";
import {
  selectProspectId,
  selectProspectLoading,
  selectProspectTeamMembers,
} from "store/prospect/prospect.selector";
import { prospectActions } from "store/prospect/prospect.slice";
import styled from "styled-components/macro";
import { ProspectAddTeamMemberModal } from "./ProspectAddTeamMemberModal";

interface Props {
  isAdmin?: boolean;
}

export const TeamMember = ({ isAdmin }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [deleteMemberId, setDeleteMemberId] = useState<string | undefined>();
  const [selectedItem, setSelectedItem] = useState<ProspectTeamMember | null>(
    null
  );
  const teamMembers = useAppSelector(selectProspectTeamMembers);
  const prospectCustomTeamMemberId = useAppSelector(selectProspectId);
  const confirmLoading = useAppSelector(selectProspectLoading);

  const handleOpenAddModal = (item?: ProspectTeamMember) => {
    setSelectedItem(item || null);
    setIsOpenAddModal(true);
  };

  const handleDeleteTeamMember = () => {
    dispatch(
      prospectActions.customTeamMember({
        prospectCustomTeamMemberId,
        data: null,
        type: "DELETE",
        teamMemberId: deleteMemberId,
        callback: () => {
          setDeleteMemberId(undefined);
        },
        isAdmin: isAdmin,
      })
    );
  };

  return (
    <Wrapper>
      <Header>
        <Text size="xl" color="#050A30">
          Team Members
        </Text>
        <ButtonOutline onClick={() => handleOpenAddModal()}>
          Add new
        </ButtonOutline>
      </Header>
      <TeamWrapper>
        {teamMembers?.map((item, index) => (
          <Member key={index}>
            <MemberHeader>
              <IconWrapper onClick={() => handleOpenAddModal(item)}>
                <EditIcon />
              </IconWrapper>
              <IconWrapper onClick={() => setDeleteMemberId(item.id)}>
                <DeleteIcon />
              </IconWrapper>
              <MemberThumbnail src={item.photoUrl}></MemberThumbnail>
              <MemberTitle>
                <Text size="lg">{item.displayName}</Text>
                <Text size="md">{item.role}</Text>
              </MemberTitle>
            </MemberHeader>
            <Text size="md" color="#6A6D84">
              {item.bio}
            </Text>
          </Member>
        ))}
        <ConfirmModal
          title={"Are you sure to delete member?"}
          isOpen={!!deleteMemberId}
          onClose={() => setDeleteMemberId(undefined)}
          callback={handleDeleteTeamMember}
          loading={confirmLoading}
        />
        <ProspectAddTeamMemberModal
          data={selectedItem}
          prospectCustomTeamMemberId={prospectCustomTeamMemberId}
          isOpen={isOpenAddModal}
          onClose={() => setIsOpenAddModal(false)}
          isAdmin={isAdmin}
        />
      </TeamWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  }
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

const Member = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MemberHeader = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
`;

const MemberThumbnail = styled.img`
  width: 100px;
  height: 100px;
  background-color: #d8d9df;
`;

const MemberTitle = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: flex-start;

  div:nth-child(2) {
    font-style: italic;
  }
`;

const IconWrapper = styled(IconButton)`
  position: absolute;
  top: -35px;
  left: -10px;

  &:nth-child(2) {
    left: 25px;
  }

  svg {
    height: 22px;
    width: auto;
  }
`;
