import _ from "lodash";
import { NavLink, useLocation } from "react-router-dom";

import { LeftArrowIcon } from "assets/images/common";
import { PATH } from "constants/app.const";
import { MENU_DATA, MenuNode } from "constants/menu.const";
import styled from "styled-components/macro";
import { getBreadcrumbs } from "utils/system.util";
import { useAppSelector } from "hooks/useHook";
import { selectWorkspaceType } from "store/auth/auth.selector";

export const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const listNode = getBreadcrumbs(pathname);
  const workspaceType = useAppSelector(selectWorkspaceType);

  const menuList = MENU_DATA.filter((i) =>
    _.includes(i.workspaceType, workspaceType)
  );
  const companyId = _.includes(location.pathname, PATH.ADMIN_COMPANY)
    ? location.pathname.split("/")[3]
    : "";

  const getNode = (node: string) => {
    const listNode = node?.split("/");

    if (listNode && listNode.length === 2) {
      const parentNode =
        menuList.find((i) => _.includes(i.route(), listNode[0])) ||
        ({} as MenuNode);

      return (
        parentNode?.subs?.find((i) => _.includes(i.route(), listNode[1])) ||
        ({} as MenuNode)
      );
    }
    return (
      menuList.find((i) => _.includes(i.route(), node)) || ({} as MenuNode)
    );
  };

  return (
    <BreadcrumbsWrapper className="header-breadcrumbs">
      {listNode.map((item, index) =>
        getNode(item)?.name ? (
          <NavLink
            key={index}
            to={getNode(item)?.route({ companyId })}
            className={({ isActive }) => (isActive ? "active-link" : undefined)}
          >
            <LeftArrowIcon />
            <BreadcrumbNode>{getNode(item)?.name}</BreadcrumbNode>
          </NavLink>
        ) : null
      )}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    padding: 0 10px;
    border-radius: 16px;

    svg {
      height: 14px;
      width: auto;
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;
const BreadcrumbNode = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.secondary.main};
`;
