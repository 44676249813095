import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { WarningIcon } from "assets/images/common";
import { PageTitle, Warning } from "components";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { StatusIndicator } from "models/common.model";
import { selectSelectedFund } from "store/fund/fund.selector";
import { fundActions } from "store/fund/fund.slice";
import {
  selectOrganisation,
  selectOrganisationProgress,
} from "store/organisation/organisation.selector";
import { StatusBar, StatusFlag } from "../components";
import { FundInfoSection } from "./components";

export const CompanyFundDetailPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { fundId: fundParamsId } = useParams();

  const { status } = useAppSelector(selectSelectedFund);
  const { status: organisationStatus, message: organisationMessage } =
    useAppSelector(selectOrganisation);
  const organisationProgress = useAppSelector(selectOrganisationProgress);

  const isCompleteSettings = !organisationProgress.find(
    (item) => !item.isComplete
  );

  useEffect(() => {
    if (fundParamsId) {
      dispatch(fundActions.getFund({ fundId: fundParamsId }));
    }
  }, [dispatch, fundParamsId]);

  return (
    <Wrapper>
      <StatusBar status={status} />

      {organisationStatus === StatusIndicator.FLAGGED && isCompleteSettings && (
        <Warning
          icon={<WarningIcon />}
          text={`Your Company request was rejected by the moderator.${
            organisationMessage ? ` "${organisationMessage}"` : ""
          }`}
          backgroundColor="#fff1f0"
          color="#b4453d"
          hasButton
          buttonText="Complete Settings"
          handleClickButton={() => navigate(PATH.COMPANY_SETTINGS)}
        />
      )}
      {!isCompleteSettings && (
        <Warning
          text="Complete your Company Profile Settings before you open your fund."
          hasButton
          buttonText="Complete Settings"
          handleClickButton={() => navigate(PATH.COMPANY_SETTINGS)}
        />
      )}

      <Header>
        <PageTitle>Fund</PageTitle>

        <StatusFlag status={status} />
      </Header>

      <MainBox>
        <LeftBox>
          <FundInfoSection />
        </LeftBox>

        <RightBox></RightBox>
      </MainBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const MainBox = styled.div`
  display: grid;
  grid-template-columns: auto 352px;
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr;
  }
`;

const LeftBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
`;

const RightBox = styled.div`
  width: 352px;

  & > div {
    position: sticky;
    top: 20px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;
