import { gql } from "graphql-request";
import { GraphqlQuery, GraphqlResponse } from "models/common.model";
import { CreateWorkspaceParams, Workspace } from "models/workspace.model";
import { graphqlRequest } from "utils/graphQL.util";

const workspaceApi = {
  workspacesGet(query?: GraphqlQuery): Promise<GraphqlResponse<Workspace[]>> {
    const defaultQuery = gql`
      query {
        workspacesGet {
          id
          displayName
          type
          createdAt
          updatedAt
          defaultCurrency {
            id
            symbol
            name
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery);
  },

  workspaceGet(query?: GraphqlQuery): Promise<GraphqlResponse<Workspace>> {
    const defaultQuery = gql`
      query {
        workspaceGet {
          id
          displayName
          type
          createdAt
          updatedAt
          defaultCurrency {
            id
            symbol
            name
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery);
  },

  workspaceLogin(workspaceId: string, query?: GraphqlQuery) {
    const variables = {
      workspaceId,
    };
    const defaultQuery = gql`
      mutation ($workspaceId: ID!) {
        workspaceLogin(id: $workspaceId)
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  workspaceCreate(input: CreateWorkspaceParams, query?: GraphqlQuery) {
    const variables = {
      input,
    };
    const defaultQuery = gql`
      mutation ($input: workspaceCreateInput!) {
        workspaceCreate(input: $input) {
          id
          displayName
          type
          createdAt
          updatedAt
          defaultCurrency {
            id
            symbol
            name
          }
        }
      }
    `;
    return graphqlRequest(query || defaultQuery, variables);
  },

  workspaceUpdate(input: CreateWorkspaceParams) {
    const variables = {
      input,
    };

    const query = gql`
      mutation WorkspaceUpdate($input: workspaceUpdateInput) {
        workspaceUpdate(input: $input) {
          id
          displayName
          createdAt
          updatedAt
          type
          defaultCurrency {
            id
            symbol
            name
          }
        }
      }
    `;
    return graphqlRequest(query, variables);
  },

  workspaceRemove() {
    const query = gql`
      mutation Mutation {
        workpaceRemove
      }
    `;
    return graphqlRequest(query);
  },
};

export default workspaceApi;
