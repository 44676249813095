import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, dialogClasses } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";

import { Button, Text } from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { selectWorkspaceLoading } from "store/workspace/workspace.selector";
import { workspaceActions } from "store/workspace/workspace.slice";
import { WORKSPACE_TYPE } from "models/auth.model";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  type: WORKSPACE_TYPE;
}

export const AddWorkspaceModal = ({ isOpen, onClose, type }: Props) => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectWorkspaceLoading);

  const schema = yup.object().shape({
    displayName: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddWorkspace = (data: FieldValues) => {
    const { displayName } = data;
    try {
      dispatch(workspaceActions.createWorkspace({ displayName, type }));
      onClose();
      reset();
    } catch (err) {
      //throw err as Error;
    }
  };

  return (
    <BaseDialog open={isOpen} onClose={onClose}>
      <HeaderBox>
        <Text size="xl">Create your new {type} space</Text>
      </HeaderBox>
      <Wrapper onSubmit={handleSubmit(handleAddWorkspace)}>
        <GroupTextField
          fieldName="Space name"
          placeholder="Your name, company name, etc..."
          name="displayName"
          formRef={register}
          error={errors.displayName?.message as string}
        />
        <GroupTextField
          fieldName="Website Link"
          placeholder="https://..."
          name="link"
        />
        <Button type="submit" loading={submitLoading}>
          Get started
        </Button>
      </Wrapper>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 32px;
  }
`;

const HeaderBox = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 40px;
  padding-top: 30px;
  min-width: 460px;
  gap: 25px;
  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  & button[type="submit"] {
    margin-top: 10px;
  }
`;
