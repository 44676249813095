import styled from "styled-components/macro";
import { GroupText, Link, Text } from "components";

const SignUpHeader = () => {
  return (
    <SignUpHeaderWrapper>
      <Text size="xxl">Create An Account</Text>
      <GroupText>
        <Text size="md">Already have an account?</Text>
        <Link color="#C9AF7F" href="/sign-in">
          Log in
        </Link>
      </GroupText>
    </SignUpHeaderWrapper>
  );
};

const SignUpFooter = () => {
  return (
    <p>
      <InnerText>
        {"Protected by reCAPTCHA and subject to the Google "}
      </InnerText>
      <Link
        color="#C9AF7F"
        target="_blank"
        href="https://www.monacofoundry.com/privacy-policy"
      >
        {"Privacy Policy "}
      </Link>
      <InnerText>and</InnerText>
      <Link
        color="#C9AF7F"
        target="_blank"
        href="https://monacofoundry.notion.site/MYFOUNDRY-FREMIUM-TERMS-CONDITIONS-b1a296592ed742749026f082770e77ef"
      >
        {" "}
        Terms of Service.
      </Link>
    </p>
  );
};

export { SignUpHeader, SignUpFooter };

const SignUpHeaderWrapper = styled.div`
  margin-bottom: 30px;
`;

const InnerText = styled.span`
  color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props?.color};
  font-size: 14px;
`;
