import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const LinkedinIcon = ({ color = "#606C82" }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44444 0C1.10232 0 0 1.10232 0 2.44444L0 13.5556C0 14.8977 1.10232 16 2.44444 16H13.5556C14.8977 16 16 14.8977 16 13.5556V2.44444C16 1.10232 14.8977 0 13.5556 0L2.44444 0ZM2.44444 1.33333H13.5556C14.177 1.33333 14.6667 1.82301 14.6667 2.44444V13.5556C14.6667 14.177 14.177 14.6667 13.5556 14.6667H2.44444C1.82301 14.6667 1.33333 14.177 1.33333 13.5556V2.44444C1.33333 1.82301 1.82301 1.33333 2.44444 1.33333Z"
        fill={color}
      />
      <path
        d="M4.26627 4.26627C4.43675 4.09578 4.66798 4 4.90909 4C5.1502 4 5.38143 4.09578 5.55192 4.26627C5.7224 4.43675 5.81818 4.66798 5.81818 4.90909C5.81818 5.1502 5.7224 5.38143 5.55192 5.55192C5.38143 5.7224 5.1502 5.81818 4.90909 5.81818C4.66798 5.81818 4.43675 5.7224 4.26627 5.55192C4.09578 5.38143 4 5.1502 4 4.90909C4 4.66798 4.09578 4.43675 4.26627 4.26627Z"
        fill={color}
      />
      <path
        d="M4 6.90909C4 6.708 4.16255 6.54545 4.36364 6.54545H5.45455C5.65564 6.54545 5.81818 6.708 5.81818 6.90909V11.6364C5.81818 11.8375 5.65564 12 5.45455 12H4.36364C4.16255 12 4 11.8375 4 11.6364V6.90909Z"
        fill={color}
      />
      <path
        d="M6.54545 6.90909C6.54545 6.708 6.708 6.54545 6.90909 6.54545H8C8.20109 6.54545 8.36364 6.708 8.36364 6.90909V7.10582C8.75018 6.75855 9.25891 6.54545 9.81818 6.54545C11.0215 6.54545 12 7.524 12 8.72727V11.6364C12 11.8375 11.8375 12 11.6364 12H10.5455C10.3444 12 10.1818 11.8375 10.1818 11.6364V8.90909C10.1818 8.40764 9.77418 8 9.27273 8C8.77127 8 8.36364 8.40764 8.36364 8.90909V11.6364C8.36364 11.8375 8.20109 12 8 12H6.90909C6.708 12 6.54545 11.8375 6.54545 11.6364V6.90909Z"
        fill={color}
      />
    </svg>
  );
};
