import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const DownArrowIcon = ({ color = "#7C8698" }: Props) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55824 5.76036L5 5.18182L4.44176 5.76036C4.75007 6.07988 5.24993 6.07988 5.55824 5.76036ZM5 4.02473L1.34772 0.23964C1.03941 -0.0798798 0.53954 -0.0798798 0.231231 0.23964C-0.0770772 0.55916 -0.0770772 1.0772 0.231231 1.39672L4.44176 5.76036L5 5.18182L5.55824 5.76036L9.76877 1.39672C10.0771 1.0772 10.0771 0.55916 9.76877 0.23964C9.46046 -0.0798798 8.96059 -0.0798798 8.65228 0.23964L5 4.02473Z"
        fill={color}
      />
    </svg>
  );
};
