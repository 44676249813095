import { SectionWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";

export const AboutSection = () => {
  const { description } = useAppSelector(selectOrganisation);

  return (
    <SectionWrapper title="About">
      <Text size="sm" color="#6a6d84" fontSize="16px">
        {description}
      </Text>
    </SectionWrapper>
  );
};
