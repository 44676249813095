import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, dialogClasses } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";
import * as yup from "yup";

import { Button } from "components";
import { DragDropField } from "components/UI/Form/Input/DragDropField";
import { TextArea } from "components/UI/Form/Input/TextArea";

interface Props {
  selected: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ReachOutModal = ({ isOpen, selected, onClose }: Props) => {
  const [, setAttachment] = useState<File | undefined>();

  const schema = yup.object().shape({
    message: yup.string().required(),
  });

  const {
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
    reset();
    setAttachment(undefined);
  };

  return (
    <BaseDialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <HeaderBox>Reach out to {selected}</HeaderBox>

      <Wrapper>
        <TextArea
          fieldName="Message"
          name="message"
          placeholder="Type your message here..."
          minRows={3}
          maxLength={400}
          hasCount
          formRef={register}
          watch={watch}
          error={errors.message?.message as string}
        />

        <DragDropField
          fieldName="Attachment"
          label={`Upload a picture or a video to illustrate your update.
SVG, PNG, JPG or GIF for photo.
MOV, AVI, MP4, MKV for video`}
          handleChange={(file) => setAttachment(file)}
          types={[
            "svg",
            "png",
            "jpg",
            "jpeg",
            "gif",
            "mov",
            "avi",
            "mp4",
            "mkv",
          ]}
        />
      </Wrapper>

      <FooterBox>
        <Button type="submit" disabled={!watch("message")}>
          Send
        </Button>
      </FooterBox>
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 32px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: calc(100vw - 60px);
  }
`;

const FooterBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin: 0px 32px 32px;
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid #ebecef;

  & button,
  .button-wrapper {
    min-width: 216px;
  }
`;
