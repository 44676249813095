import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppCenterIcon } from "assets/images/common/AppCenterIcon";
import { LineText } from "components";
import { PATH, Roles } from "constants/app.const";
import { MenuNode, MENU_DATA as MENU_DATA_RAW } from "constants/menu.const";
import { useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { selectAuthMode, selectWorkspaceType } from "store/auth/auth.selector";
import { selectOrganisation } from "store/organisation/organisation.selector";
import styled from "styled-components/macro";
import { MenuItem } from "./MenuItem";

export const LeftMenu = () => {
  const [menuList, setMenuList] = useState<MenuNode[]>([]);
  const [expandSubMenu, setExpandSubMenu] = useState<MenuNode>();
  const location = useLocation();
  const workspaceType = useAppSelector(selectWorkspaceType);
  const authMode = useAppSelector(selectAuthMode);
  const { mfScore } = useAppSelector(selectOrganisation);

  const companyId = _.includes(location.pathname, PATH.ADMIN_COMPANY)
    ? location.pathname.split("/")[3]
    : "";

  const isShowItem = useCallback(
    (item: MenuNode) => {
      if (
        [AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(authMode)
      ) {
        if (item.userRoles.includes(Roles.admin)) {
          if (item.name === "Company") {
            return !!companyId;
          }

          return true;
        }

        return false;
      } else if (
        item.workspaceRoles.includes(Roles.owner) ||
        item.workspaceRoles.includes(Roles.editor)
      ) {
        if (workspaceType && item.workspaceType?.includes(workspaceType)) {
          if (item.name === "Company") {
            if (mfScore) {
              item.subs = item.subs?.filter((i) => i.name !== "MF Trust Score");
            }
          }

          return true;
        }
      }

      return false;
    },
    [authMode, companyId, mfScore, workspaceType]
  );

  const MENU_DATA = useMemo(() => {
    return MENU_DATA_RAW.filter((item) => {
      if (item?.forceHide) return false;
      return true;
    });
  }, []);

  useEffect(() => {
    const expandSubMenu = menuList.find((item) => !_.isEmpty(item.subs));

    setExpandSubMenu(expandSubMenu);
  }, [menuList]);

  useEffect(() => {
    const renderList = _.cloneDeep(MENU_DATA).filter((item) =>
      isShowItem(item)
    );

    setMenuList(renderList);
  }, [isShowItem, MENU_DATA]);

  return (
    <Wrapper className="left-menu">
      <MainBox>
        {menuList.map((item, index) => {
          return (
            <MenuItem
              key={index}
              item={item}
              isActive={
                _.includes(location.pathname, item.route({ companyId })) ||
                !!item.subs?.find((sub) =>
                  _.includes(location.pathname, sub.route({ companyId }))
                )
              }
              expandSubMenu={expandSubMenu}
              handleExpandSubMenu={(item) => setExpandSubMenu(item)}
            />
          );
        })}
      </MainBox>

      {![AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(
        authMode
      ) && (
        <FooterBox>
          <LineText />
          <MenuItem
            item={{
              name: "Marketplace",
              icon: AppCenterIcon,
              route: () => "/market-place",
              userRoles: [],
              workspaceRoles: [],
            }}
            isActive={location.pathname === "/market-place"}
          />
        </FooterBox>
      )}
    </Wrapper>
  );
};

export default LeftMenu;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: 40px;
  transition: all 0.7s;

  ${(props) => props.theme.breakpoints.down("md")} {
    height: calc(100vh - 65px);
  }
`;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const FooterBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding-bottom: 22px;
  & > div {
    width: 100%;
  }
`;
