import { IconButton, iconButtonClasses } from "@mui/material";
import { CloseIcon } from "assets/images/common";
import { Text } from "components";
import styled from "styled-components/macro";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children: React.ReactNode;
  onClose?: () => void;
}

export const NoticeBox = ({ title, children, onClose }: Props) => {
  return (
    <Wrapper className="notice-box">
      <IconButton onClick={onClose}>
        <CloseIcon color="#373B59" />
      </IconButton>

      <Text size="xl">{title}</Text>
      <Text size="md">{children}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #fff;
  border-radius: 32px;
  gap: 10px;

  .${iconButtonClasses.root} {
    position: absolute;
    right: 12px;
    top: 12px;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;
