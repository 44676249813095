import { gql } from "graphql-request";

import { MarketplaceItem } from "models/marketplace.model";
import { graphqlRequest } from "utils/graphQL.util";

export const marketplaceApi = {
  getMarketplace(): Promise<{ marketplaceGet: MarketplaceItem[] }> {
    const variables = {
      // sort: {
      //   order: "ASC",
      //   byKey: "group",
      // },
    };
    const query = gql`
      query MarketplaceGet($sort: sort) {
        marketplaceGet(sort: $sort) {
          id
          createdAt
          updatedAt
          displayName
          description
          buttonText
          linkUrl
          logoUrl
          status
          tags {
            id
            name
          }
          trendScore
          group
        }
      }
    `;

    return graphqlRequest(query, variables);
  },
};
