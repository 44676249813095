import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, dialogClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import commonApi from "api/common.api";
import { Button, ButtonOutline } from "components";
import { Combobox } from "components/Feature/Combobox";
import ConfirmModal from "components/Feature/ConfirmModal";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { WORKSPACE_TYPE } from "models/auth.model";
import { SelectType } from "models/organisation.model";
import { CreateWorkspaceParams } from "models/workspace.model";
import { selectWorkspaceType } from "store/auth/auth.selector";
import { selectCurrencies } from "store/cached/cached.selector";
import { cachedActions } from "store/cached/cached.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import {
  selectActiveWorkspace,
  selectWorkspaceLoading,
} from "store/workspace/workspace.selector";
import { workspaceActions } from "store/workspace/workspace.slice";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const WorkspaceSettingsModal = ({ isOpen, onClose }: Props) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<SelectType | null>(
    null
  );

  const dispatch = useAppDispatch();

  const fixedData = useAppSelector(selectCurrencies);
  const loading = useAppSelector(selectWorkspaceLoading);
  const workspaceType = useAppSelector(selectWorkspaceType);
  const {
    displayName: currentDisPlayName,
    type,
    defaultCurrency,
  } = useAppSelector(selectActiveWorkspace);
  const { id: organisationId } = useAppSelector(selectOrganisation);

  const schema = yup.object().shape({
    displayName: yup.string().required(),
  });
  const isFounder = workspaceType === WORKSPACE_TYPE.FOUNDER;
  const currencyOptions = fixedData.data.map((item) => ({
    ...item,
    label: `${item.name} (${item.symbol})`,
  }));

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData: FieldValues) => {
    const input = {
      ...formData,
      type,
    } as CreateWorkspaceParams;

    dispatch(
      workspaceActions.updateWorkspace({
        input,
        callback: () => {
          reset();
          setSelectedCurrency(null);
          onClose();
        },
      })
    );
  };

  const handleDeleteWorkspace = () => {
    dispatch(workspaceActions.deleteWorkspace({ organisationId }));
  };

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName: "currencies",
        callApi: commonApi.currenciesGet,
        updateAt: fixedData.updateAt,
      })
    );
  }, [dispatch, fixedData.updateAt]);

  useEffect(() => {
    setValue("displayName", currentDisPlayName);

    if (isFounder) {
      setValue("defaultCurrency", defaultCurrency);
      setSelectedCurrency(defaultCurrency);
    }
  }, [currentDisPlayName, defaultCurrency, isFounder, setValue]);

  return (
    <BaseDialog open={isOpen} onClose={onClose} maxWidth="xl">
      <HeaderBox>Workspace Settings</HeaderBox>

      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <GroupTextField
          fieldName="Workspace name"
          name="displayName"
          placeholder="Your name, company name, etc..."
          formRef={register}
          error={errors.displayNAme?.message as string}
        />

        {false && isFounder && (
          <Combobox
            fieldName="Default currency"
            id="combobox-default-currency"
            options={currencyOptions}
            placeholder="Fill default currency"
            onchange={(value) => {
              if (!value) return;
              setValue("defaultCurrency", { id: value.id });
              setSelectedCurrency(value);
            }}
            value={selectedCurrency}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              );
            }}
          />
        )}

        <ButtonOutline
          className="delete-btn"
          onClick={() => setIsOpenConfirm(true)}
        >
          Delete workspace
        </ButtonOutline>

        <FooterBox>
          <ButtonOutline onClick={onClose}>Cancel</ButtonOutline>
          <Button type="submit" loading={loading}>
            Update
          </Button>
        </FooterBox>
      </Wrapper>

      <ConfirmModal
        title="Are you sure you want to delete this workspace?"
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        callback={handleDeleteWorkspace}
        confirmButtonText="Yes"
      />
    </BaseDialog>
  );
};

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 40px 40px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 20px 20px 0px;
  }
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: calc(100vw - 64px);
    padding: 20px;
  }

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  .delete-btn {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

const FooterBox = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid #ebecef;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }

  & button,
  .button-wrapper {
    width: 100%;
  }
`;
