import styled from "styled-components";

import { UpIcon } from "assets/images/common";
import { LineChart, NoticeTooltip } from "components";

interface Props {
  title: string;
  value: number;
  raisingValue: number;
  fromTime: string;
}

export const MarketResearchItem = ({
  title,
  value,
  raisingValue,
  fromTime,
}: Props) => {
  return (
    <Wrapper>
      <Header>
        <div className="title">{title}</div>

        <NoticeTooltip
          placement="top-start"
          data={{
            title: `${title.split(" ")[0]}`,
            content: `Analyses the inbound and outbound backlinks from the website and their quality.`,
          }}
        />
      </Header>

      <div className="value">{value.toFixed(2)}</div>

      <RaisingWrapper>
        <RasingTag>
          <UpIcon />+{raisingValue.toFixed(2)}%
        </RasingTag>

        <div className="from-time">{fromTime}</div>
      </RaisingWrapper>

      <LineChart
        labels={["1", "2", "3", "4", "5", "6", "7", "8"]}
        data={[20, 25, 15, 17, 20, 5, 30, 34]}
        hideGrid
        hideTick
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px;
  border: 1px solid #d8d9df;
  border-radius: 16px;

  .value {
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 39px;
    font-weight: 700;
  }

  img {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .title {
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 20px;
    font-weight: 700;
  }
`;

const RaisingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  .from-time {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 12px;
  }
`;

const RasingTag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #fbf9f5;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 12px;
`;
