import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Divider,
  DrawerProps,
  IconButton,
  Accordion as MUIAccordion,
  Drawer as MUIDrawer,
  accordionDetailsClasses,
  accordionSummaryClasses,
  drawerClasses,
} from "@mui/material";
import {
  DownArrowIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "assets/images/common";

import { Avatar } from "components";

import styled from "styled-components";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ChatDrawer = ({ open, onClose }: Props) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Container>
        <Header>
          <InfoWrapper>
            <Avatar
              src="https://bit.ly/sage-adebayo"
              name="Bruno Valentin"
              size={72}
            />

            <TextInfo>
              <div className="name">Bruno Valentin</div>

              <div className="role">CEO & Founder of Spectre Biotech</div>

              <div className="status">
                <div className="status__icon" />
                Online
              </div>
            </TextInfo>
          </InfoWrapper>

          <Description>
            Neuropsychologist specialized in rehabilitation and brain-computer
            interfaces.
          </Description>

          <SocialBar>
            <IconButton>
              <LinkedinIcon />
            </IconButton>

            <IconButton>
              <InstagramIcon />
            </IconButton>

            <IconButton>
              <TwitterIcon />
            </IconButton>
          </SocialBar>
        </Header>

        <MainBox>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<DownArrowIcon />}>
              About
            </AccordionSummary>

            <AccordionDetails>
              <ListWrapper>
                <ListItem>
                  <div className="title">Website</div>
                  <div className="value">https://spectre-biotech.com</div>
                </ListItem>
                <ListItem>
                  <div className="title">Company location</div>
                  <div className="value">Nice, France</div>
                </ListItem>
                <ListItem>
                  <div className="title">Founded</div>
                  <div className="value">January 2019</div>
                </ListItem>
                <ListItem>
                  <div className="title">Industry</div>
                  <div className="value">Biotech</div>
                </ListItem>
                <ListItem>
                  <div className="title">Business model:</div>
                  <div className="value">B2B, B2C</div>
                </ListItem>
              </ListWrapper>
            </AccordionDetails>
          </Accordion>

          <Divider
            sx={{
              borderWidth: "0.5px",
              borderColor: "#ebecef",
            }}
          />

          <Accordion>
            <AccordionSummary expandIcon={<DownArrowIcon />}>
              <div>Files and media</div>
            </AccordionSummary>

            <AccordionDetails>Files and media</AccordionDetails>
          </Accordion>
        </MainBox>
      </Container>
    </Drawer>
  );
};

const Drawer = styled(({ ...props }: DrawerProps) => <MUIDrawer {...props} />)(
  () => ({
    [`& .${drawerClasses.paper}`]: {
      boxShadow: "none",
    },
  })
);

const Accordion = styled(({ ...props }: AccordionProps) => (
  <MUIAccordion disableGutters elevation={0} {...props} />
))((props) => ({
  "&::before": {
    display: "none",
  },
  [`& .${accordionSummaryClasses.root}`]: {
    padding: "0px",
  },
  [`& .${accordionSummaryClasses.content}`]: {
    color: props.theme.palette.secondary.main,
    fontSize: "16px",
    fontWeight: "700",
  },
  [`& .${accordionSummaryClasses.expandIconWrapper} svg`]: {
    width: "13px",
    height: "13px",
  },
  [`& .${accordionDetailsClasses.root}`]: {
    padding: "0px",
    paddingTop: "12px",
  },
}));

const Container = styled.div`
  width: 400px;
  padding: 24px 24px 0px;
`;

const Header = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #ebecef;
`;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const TextInfo = styled.div`
  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 20px;
    font-weight: 700;
  }

  .role {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 14px;
  }

  .status {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 12px;

    &__icon {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #58a369;
    }
  }
`;

const Description = styled.div`
  margin: 16px 0px 24px;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 16px;
`;

const SocialBar = styled.div`
  display: flex;
  gap: 24px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ListItem = styled.div`
  font-size: 16px;

  .title {
    margin-bottom: 4px;
    color: ${(props) => props.theme.palette.secondary.light};
  }

  .value {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;
