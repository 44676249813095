import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const SpanBox = styled.span`
  padding: 0;
  line-height: 0;
`;

export const HorizontalBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PageTitle = styled.div`
  color: ${(props) => props.theme.palette.primaryText.dark};
  font-size: 31px;
  font-weight: 700;
`;

export const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
