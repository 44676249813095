import { Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { BoltIcon, MarkIcon, SuccessIcon } from "assets/images/common";
import {
  Button,
  ButtonOutline,
  LinearProgressBar,
  NoticeTooltip,
  SectionWrapper,
  Text,
} from "components";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { WORKSPACE_TYPE } from "models/auth.model";
import { Feed } from "models/prospect.model";
import { selectWorkspaceType } from "store/auth/auth.selector";
import { selectFeedLoading } from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";
import { selectOrganisation } from "store/organisation/organisation.selector";
import {
  formatCurrency,
  getLatestFundraising,
  hasValue,
} from "utils/system.util";
import { fDate } from "utils/time.util";
import ContactFounderModal from "views/main/Feed/components/ContactFounderModal";
import { StatusIndicator } from "models/common.model";
import { selectAppConfig } from "store/global/global.selector";

interface GroupProps {
  gap?: string;
  pt?: string;
  pb?: string;
  borderBottom?: string;
}

interface FieldProps {
  direction?: "row" | "column";
}

export const TrustScoreBar = () => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    status,
    prevPartOf,
    leadInvestor,
    fundraisingProjects,
    bankAccMonitoring,
    mfScore,
    currency,
    ...rest
  } = useAppSelector(selectOrganisation);
  const { feedPromote } = useAppSelector(selectAppConfig);
  const workspaceType = useAppSelector(selectWorkspaceType);
  const feedLoading = useAppSelector(selectFeedLoading);

  const feed: Feed = rest as any;
  const isInvestor = workspaceType === WORKSPACE_TYPE.INVESTOR;
  const isProspect = feed.__typename === "Prospect";
  const { id, __typename, contactEmail, fav_person } = feed;

  const latestFundraising = getLatestFundraising(fundraisingProjects);
  const { currentRound, roundCloseDate, instrument, minTicketSize, roundSize } =
    isInvestor ? feed : latestFundraising;

  const customFavorite = (type: "create" | "remove") => {
    dispatch(
      feedActions.customFavorite({
        input: {
          to: {
            id: id,
            type: __typename,
          },
          from: "Person",
        },
        type,
      })
    );
  };

  return (
    <SectionWrapper>
      <Container>
        {(hasValue(latestFundraising) || isProspect) && (
          <Text size="xl" fontWeight={700}>
            Investment Details
          </Text>
        )}

        {(hasValue(latestFundraising) || isProspect) && (
          <Group gap="16px">
            {!!currentRound && (
              <Field>
                <Text size="md">Current round:</Text>
                <Text size="md">{currentRound.name}</Text>
              </Field>
            )}

            {!!roundCloseDate && (
              <Field>
                <Text size="md">Closes on:</Text>
                <Text size="md">{fDate(roundCloseDate, "MMM D, YYYY")}</Text>
              </Field>
            )}

            {!!instrument && (
              <Field>
                <Text size="md">Instrument:</Text>
                <Text size="md">
                  {instrument.map((i) => i.name).join(", ")}
                </Text>
              </Field>
            )}

            {!!minTicketSize && (
              <Field>
                <Text size="md">Min investment:</Text>
                <Text size="md">{formatCurrency(minTicketSize, currency)}</Text>
              </Field>
            )}
          </Group>
        )}

        {!!roundSize && (
          <Group>
            <Field>
              <Text size="md">Currenly raising:</Text>
              <Text size="md">{formatCurrency(roundSize, currency)}</Text>
            </Field>
          </Group>
        )}

        <Group gap="12px">
          <ScoreInfo>
            <Field>
              <Text size="md">MF Trust Score:</Text>
              <Text size="md">{Math.round(mfScore) || 0}/100</Text>
            </Field>

            <NoticeTooltip
              placement="top-start"
              data={{
                title: "MF Trust Score",
                content: `The Monaco Foundry Trust Score represents an indicative percentage number
              of the company's attractiveness to investors based on answers
              provided by the founders to our questionnaires, combined with analysis by
              the MF team of key factors such as, the team, the idea, the product, the
              marketing/distribution strategies, the attractiveness of the target market
              and the eventual financial results. These factors are weighted according
              to a proprietary algorithm.`,
              }}
            />
          </ScoreInfo>

          <LinearProgressBar value={mfScore || 0} />
        </Group>

        {!mfScore && !isInvestor && (
          <Group>
            <Button onClick={() => navigate(PATH.COMPANY_MF_SCORE)}>
              Get my MF Trust Score
            </Button>
          </Group>
        )}

        {leadInvestor && (
          <Group>
            <Field direction="column">
              <Text size="md">Lead Investor</Text>
              <Text size="md">{leadInvestor}</Text>
            </Field>
          </Group>
        )}

        {prevPartOf && (
          <Group>
            <Field direction="column">
              <Text size="md">Previously part of</Text>
              <Text size="md">{prevPartOf}</Text>
            </Field>
          </Group>
        )}

        {bankAccMonitoring && (
          <Group>
            <Field direction="column">
              <Text size="md">
                Bank account monitoring<sup>NEW</sup>
              </Text>
              <Text size="md">
                <FieldIcon>
                  <SuccessIcon />
                  Done
                </FieldIcon>
              </Text>
            </Field>
          </Group>
        )}

        {isInvestor && (
          <Stack spacing="16px">
            {contactEmail && (
              <Button
                icon={<BoltIcon />}
                onClick={() => setIsOpenAddModal(true)}
              >
                Contact Founder
              </Button>
            )}
            <ButtonOutline
              icon={<MarkIcon />}
              loading={feedLoading}
              onClick={() => customFavorite(fav_person ? "remove" : "create")}
            >
              {fav_person ? "Remove from" : "Add to"} favorites
            </ButtonOutline>

            <ContactFounderModal
              isOpen={isOpenAddModal}
              onClose={() => setIsOpenAddModal(false)}
            />
          </Stack>
        )}

        {feedPromote?.active && status === StatusIndicator.PUBLISHED && (
          <Button onClick={() => window.open(feedPromote?.value, "_blank")}>
            {"Promote your venture"}
          </Button>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  .button-wrapper {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap = "8px" }: GroupProps) => gap};
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebecef;

  &:first-child {
    padding-top: 16px;
  }

  &:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: ${({ direction }: FieldProps) => direction};
  gap: 8px;

  & > div:first-child {
    font-size: 16px;
    color: ${(props) => props.theme.palette.text.secondary};
    font-weight: 400;
    word-break: keep-all;
  }

  & > div:last-child {
    font-size: 16px;
    color: ${(props) => props.theme.palette.text.primary};
    font-weight: 600;
  }

  sup {
    margin-left: 4px;
    color: ${(props) => props.theme.palette.text.primary};

    font-size: 9px;
    font-weight: 600;
  }
`;

const ScoreInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const FieldIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
