import { CircularProgress } from "@mui/material";
import { getAuth } from "firebase/auth";
import _ from "lodash";
import { useEffect, useState } from "react";

import { ImageIcon, AdminPannelIcon } from "assets/images/common";
import { NoticeBox, Text } from "components";
import { AUTH_MODE, NEW_WORKSPACES } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE, WORKSPACE_TYPE } from "models/auth.model";
import { Workspace } from "models/workspace.model";
import { selectAuthLoading, selectAuthMode } from "store/auth/auth.selector";
import { authActions } from "store/auth/auth.slice";
import { selectPageLoading } from "store/global/global.selector";
import { selectWorkspaces } from "store/workspace/workspace.selector";
import { workspaceActions } from "store/workspace/workspace.slice";
import styled from "styled-components/macro";
import { LocalStorage } from "utils/localStorage.util";

const DEFAULT_LIMIT = 6;

export const MySpace = () => {
  const [focusWorkspace, setFocusWorkspace] = useState("");
  const authMode = useAppSelector(selectAuthMode);
  const dispatch = useAppDispatch();

  const workspacesData = useAppSelector(selectWorkspaces);
  const authLoading = useAppSelector(selectAuthLoading);
  const pageLoading = useAppSelector(selectPageLoading);
  const [renderWorkspace, setRenderWorkspace] = useState<Workspace[]>([]);
  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(true);
  const [workspaces, setWorkspaces] = useState<Workspace[]>(workspacesData);

  const handleRefreshTokenAfterLoginWorkspace = (
    type?: WORKSPACE_TYPE,
    isUpdate?: boolean
  ) => {
    getAuth()
      .currentUser?.getIdToken(true)
      .then(() => {
        dispatch(authActions.workspaceLoginSuccess({ type, isUpdate }));

        if (authMode === AUTH_MODE_TYPE.ADMIN_WORK_AS_USER) {
          LocalStorage.set(AUTH_MODE_TYPE.ADMIN_WORK_AS_WORKSPACE, AUTH_MODE);
        } else {
          LocalStorage.set(AUTH_MODE_TYPE.WORKSPACE, AUTH_MODE);
        }
      })
      .catch(function (err) {
        console.log(err as Error);
        //throw err as Error;
      });
  };

  const handleLoginWorkspace = (
    id: string,
    type?: WORKSPACE_TYPE,
    isUpdate?: boolean
  ) => {
    setFocusWorkspace(id);
    dispatch(
      authActions.workspaceLogin({
        id,
        callback: handleRefreshTokenAfterLoginWorkspace,
        type,
        isUpdate,
      })
    );
  };

  const getWorkspaceType = (type: WORKSPACE_TYPE) => {
    if (!type) return WORKSPACE_TYPE.FOUNDER;
    return type;
  };

  const navigateToAdminDashboard = () => {
    dispatch(authActions.userUpdateRole(AUTH_MODE_TYPE.ADMIN_OWNER));
  };

  const handleJoinWorkspace = (workspace: Workspace) => {
    if (workspace?.type === WORKSPACE_TYPE.ADMIN)
      return navigateToAdminDashboard();

    const newWorkspaces: string[] = LocalStorage.get(NEW_WORKSPACES) || [];
    handleLoginWorkspace(
      workspace.id,
      getWorkspaceType(workspace?.type),
      _.isEmpty(_.find(newWorkspaces, (i) => i === workspace.id))
    );
  };

  useEffect(() => {
    const adminWorkspaceArr = [];
    if (authMode === AUTH_MODE_TYPE.ADMIN_WORK_AS_USER) {
      const adminWorkspace = {
        id: "admin-workspace",
        displayName: "Admin Space",
        createdAt: "",
        updatedAt: "",
        type: WORKSPACE_TYPE.ADMIN,
      } as Workspace;
      adminWorkspaceArr.push(adminWorkspace);
    }
    setWorkspaces([...adminWorkspaceArr, ...workspacesData]);
  }, [workspacesData, authMode]);

  useEffect(() => {
    setRenderWorkspace(workspaces.slice(0, DEFAULT_LIMIT));
  }, [workspaces]);

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaces());
  }, [dispatch]);

  return (
    <Wrapper>
      {renderWorkspace.length > 0 && (
        <WorkspaceWrapper>
          <Text size="xl">My spaces</Text>

          <Workspaces>
            {renderWorkspace.map((item) => (
              <WorkspaceItem
                key={item.id}
                onClick={() => handleJoinWorkspace(item)}
              >
                <ImageWrapper>
                  {item?.type === WORKSPACE_TYPE.ADMIN ? (
                    <AdminPannelIcon
                      height="28px"
                      width="28px"
                      color={"#000000d5"}
                    />
                  ) : (
                    <ImageIcon />
                  )}
                </ImageWrapper>
                <Text className="line-clamp" size="lg">
                  {item.displayName}
                </Text>
                {authLoading && item.id === focusWorkspace && (
                  <LoadingWrapper>
                    <CircularProgress size="20px" color="secondary" />
                  </LoadingWrapper>
                )}
              </WorkspaceItem>
            ))}

            {workspaces.length > DEFAULT_LIMIT && (
              <WorkspaceItem
                className="more"
                onClick={() => {
                  renderWorkspace.length < workspaces.length
                    ? setRenderWorkspace(workspaces)
                    : setRenderWorkspace(workspaces.slice(0, DEFAULT_LIMIT));
                }}
              >
                <Text size="lg">
                  {renderWorkspace.length < workspaces.length
                    ? `+${workspaces.length - renderWorkspace.length} more`
                    : "Show less"}
                </Text>
              </WorkspaceItem>
            )}
          </Workspaces>
        </WorkspaceWrapper>
      )}

      {workspaces.length === 0 && isOpenWelcomeModal && (
        <NoticeWrapper>
          {!pageLoading && (
            <NoticeBox
              title="Welcome to MyFoundry 🎉"
              onClose={() => setIsOpenWelcomeModal(false)}
            >
              Select your Space template below and start to use the dashboard
              according to your needs. You may create several spaces if you have
              multiple activities.
            </NoticeBox>
          )}
        </NoticeWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 48px;
`;

const WorkspaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const NoticeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .notice-box {
    max-width: clamp(520px, 30vw, 648px);
  }
`;

const Workspaces = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: center;
  }
`;

const WorkspaceItem = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  gap: 20px;
  height: 90px;
  width: 320px;
  border-radius: 16px;
  border-radius: 16px;
  background-color: white;
  border: 1px solid #d8d9df;
  box-shadow: 0px 3px 5px rgba(5, 10, 48, 0.05);
  cursor: pointer;
  transition: all 0.5s;
  position: relative;

  &:hover {
    transform: scale(1.02);
    background-color: #fafbfc;
  }

  &.more {
    justify-content: center;
    width: 120px;
    padding: 12px;
    background: #fafbfc;
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .delete-btn {
    position: absolute;
    right: 8px;
    top: 8px;
    transition: all 0.5s;
    opacity: 0;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 35px;
  right: 14px;
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 4px;
  background: #f4f5f7;
`;
