import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const ActionsIcon = ({ color = "#606C82" }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1667 14.1547C10.1667 15.1743 9.38324 16.0009 8.41674 16.0009C7.45025 16.0009 6.66675 15.1743 6.66675 14.1547C6.66675 13.1351 7.45025 12.3086 8.41674 12.3086C9.38324 12.3086 10.1667 13.1351 10.1667 14.1547Z"
        fill={color}
      />
      <ellipse
        cx="8.41674"
        cy="8.00045"
        rx="1.74999"
        ry="1.84615"
        fill={color}
      />
      <ellipse
        cx="8.41674"
        cy="1.84615"
        rx="1.74999"
        ry="1.84615"
        fill={color}
      />
    </svg>
  );
};
