import { gql } from "graphql-request";

import {
  CreateOrganisationParams,
  SubmitFounderScoreParams,
  SubmitTrustScoreQuestionaryParams,
  UpdateOrganisationParams,
} from "models/organisation.model";
import { graphqlRequest } from "utils/graphQL.util";

const organisationApi = {
  organisationsGet() {
    const query = gql`
      query OrganisationsGet {
        organisationsGet {
          ... on OrganisationsSuccess {
            organisations {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              acceptMA
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on NoOrganisationsFound {
            message
            workspaceId
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query);
  },

  organisationGet(organisationId: string) {
    const variables = {
      organisationId,
    };

    const query = gql`
      query OrganisationGet($organisationId: ID!) {
        organisationGet(organisationId: $organisationId) {
          ... on OrganisationSuccess {
            organisation {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              acceptMA
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  organisationCreate(input: CreateOrganisationParams) {
    const variables = {
      input,
    };

    const query = gql`
      mutation ($input: organisationCreateInput) {
        organisationCreate(input: $input) {
          ... on OrganisationSuccess {
            organisation {
              id
              displayName
              status
            }
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  organisationUpdate(organisationId: string, input: UpdateOrganisationParams) {
    const variables = {
      organisationId,
      input,
    };

    const query = gql`
      mutation OrganisationUpdate(
        $organisationId: ID!
        $input: organisationUpdateInput
      ) {
        organisationUpdate(organisationId: $organisationId, input: $input) {
          ... on OrganisationSuccess {
            organisation {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              acceptMA
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  organisationSubmit(organisationId: string) {
    const variables = {
      organisationId,
    };

    const query = gql`
      mutation OrganisationSubmit($organisationId: ID!) {
        organisationSubmit(organisationId: $organisationId) {
          ... on OrganisationSuccess {
            organisation {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              acceptMA
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  organisationListAdmin() {
    const query = gql`
      query Organisations {
        organisationListADMIN {
          ... on OrganisationsSuccess {
            organisations {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on NoOrganisationsFound {
            message
            workspaceId
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query);
  },

  organisationAdmin(organisationId: string, input: UpdateOrganisationParams) {
    const variables = {
      organisationId,
      input,
    };

    const query = gql`
      mutation OrganisationADMIN(
        $organisationId: ID!
        $input: organisationADMINinput
      ) {
        organisationADMIN(organisationId: $organisationId, input: $input) {
          ... on OrganisationSuccess {
            organisation {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              acceptMA
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  organisationGetAdmin(organisationId: string) {
    const variables = {
      organisationId,
    };

    const query = gql`
      query OrganisationGetADMIN($organisationId: ID!) {
        organisationGetADMIN(organisationId: $organisationId) {
          ... on OrganisationSuccess {
            organisation {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              acceptMA
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  organisationUnPublish(organisationId: string) {
    const variables = {
      organisationId,
    };

    const query = gql`
      mutation OrganisationUnPublish($organisationId: ID!) {
        organisationUnPublish(organisationId: $organisationId) {
          ... on OrganisationSuccess {
            organisation {
              id
              createdAt
              updatedAt
              displayName
              description
              websiteUrl
              logoUrl
              slogan
              videoUrl
              posterUrl
              annualRevenue
              MRR
              bankAccMonitoring
              preFunded
              preFundedAmount
              leadInvestor
              prevPartOf
              zip
              city
              address
              mfScore
              mfScoreUrl
              acceptMA
              status
              message
              country {
                alpha2Code
                id
                name
              }
              sector {
                id
                name
              }
              impactValues {
                id
                name
              }
              businessType {
                id
                name
              }
              productType {
                name
                id
              }
              teamMembers {
                id
                displayName
                bio
                photoUrl
                role
                email
                order
              }
              competitors {
                id
                url
              }
              fundraisingProjects {
                id
                currentRound {
                  active
                  id
                  name
                }
                instrument {
                  id
                  name
                  active
                }
                minTicketSize
                pitchDeckUrl
                posterUrl
                roundCloseDate
                roundSize
                valuation
                videoUrl
                contactEmail
                contactTel
                acceptMA
                status
                message
              }
              currency {
                id
                symbol
                name
              }
            }
          }
          ... on OrganisationError {
            message
            resourceId
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  trustScoreQuestionaryGet() {
    const query = gql`
      query TrustScoreQuestionaryGet {
        trustScoreQuestionaryGet {
          ... on TrustScoreQuestionarySuccess {
            items {
              id
              question
              options {
                label
                value
              }
            }
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query);
  },

  trustScoreQuestionarySubmit(
    organisationId: string,
    input: SubmitTrustScoreQuestionaryParams
  ) {
    const variables = {
      organisationId,
      input,
    };

    const query = gql`
      mutation TrustScoreQuestionarySubmit(
        $organisationId: ID!
        $input: trustScoreQuestionaryInput
      ) {
        trustScoreQuestionarySubmit(
          organisationId: $organisationId
          input: $input
        ) {
          ... on TrustScoreSuccess {
            createdAt
            scale {
              score
              label
              id
            }
          }
          ... on TrustScoreError {
            message
          }
          ... on InsuffecientPermissionError {
            message
            resourceId
          }
          ... on NotAuthenticatedError {
            message
          }
        }
      }
    `;

    return graphqlRequest(query, variables);
  },

  foundersAssesmentGet() {
    const query = gql`
      query FoundersAssesmentGet {
        foundersAssesmentGet {
          ... on FounderAssesmentGetSuccess {
            assesment {
              id
              rating_scale
              scale
              scale_id
              questions
              time
              items {
                id
                text
                domain_id
                num
                rating_scale
                choices {
                  text
                  score
                  color
                }
              }
            }
          }
          ... on FoundersAssesmentError {
            message
            errorCode
          }
          ... on SystemError {
            message
            errorCode
          }
        }
      }
    `;

    return graphqlRequest(query);
  },

  foundersAssesmentSubmit(assesment: SubmitFounderScoreParams) {
    const variables = {
      assesment,
      options: {
        save: true,
      },
    };

    const query = gql`
      mutation AssesmentResolve(
        $assesment: assesmentInput!
        $options: assesmentResolveOptionInput
      ) {
        assesmentResolve(assesment: $assesment, options: $options) {
          ... on AssesmentResolveSuccess {
            scale_id
            result {
              id
              text
              score
              count
              rating_scale
              normalized_score
              feedback
            }
          }
          ... on AssesmentResolveError {
            message
            errorCode
          }
          ... on SystemError {
            message
            errorCode
          }
        }
      }
    `;
    return graphqlRequest(query, variables);
  },
};

export default organisationApi;
