import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const CreateIcon = ({ color = "#fff" }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7187 4.8125L12.7187 8.84757C12.4373 8.78372 12.1445 8.75 11.8437 8.75C11.6959 8.75 11.5499 8.75815 11.4062 8.77403L11.4062 5.46875L8.78125 5.46875C7.93556 5.46875 7.25 4.78319 7.25 3.9375L7.25 1.3125L3.09375 1.3125L3.09375 12.6875L7.90625 12.6875C7.90625 13.1477 7.9852 13.5895 8.1303 14L2.65625 14C2.173 14 1.78125 13.6082 1.78125 13.125L1.78125 0.875C1.78125 0.391751 2.173 3.42516e-08 2.65625 7.64986e-08L7.90625 5.35465e-07L12.7187 4.8125ZM4.58126 9.84375L9.12033 9.84375C8.73562 10.2123 8.42556 10.6581 8.21508 11.1563L4.58126 11.1563C4.21882 11.1563 3.92501 10.8624 3.92501 10.5C3.92501 10.1376 4.21882 9.84375 4.58126 9.84375ZM10.2063 4.15625L8.5625 2.51241L8.5625 3.9375C8.5625 4.05831 8.66044 4.15625 8.78125 4.15625L10.2063 4.15625ZM4.58126 7.21875C4.21882 7.21875 3.92501 7.51256 3.92501 7.875C3.92501 8.23744 4.21882 8.53125 4.58126 8.53125L8.51876 8.53125C8.8812 8.53125 9.17501 8.23744 9.17501 7.875C9.17501 7.51256 8.8812 7.21875 8.51876 7.21875L4.58126 7.21875ZM9 12.8C9 11.1458 10.3402 9.79999 11.9874 9.79999C13.6486 9.79999 15 11.1458 15 12.8C15 14.4542 13.6486 15.8 11.9874 15.8C10.3402 15.8 9 14.4542 9 12.8ZM12.5 11.5C12.5 11.2239 12.2761 11 12 11C11.7239 11 11.5 11.2239 11.5 11.5L11.5 12.25L10.75 12.25C10.4739 12.25 10.25 12.4739 10.25 12.75C10.25 13.0261 10.4739 13.25 10.75 13.25L11.5 13.25L11.5 14C11.5 14.2761 11.7239 14.5 12 14.5C12.2761 14.5 12.5 14.2761 12.5 14L12.5 13.25L13.25 13.25C13.5261 13.25 13.75 13.0261 13.75 12.75C13.75 12.4739 13.5261 12.25 13.25 12.25L12.5 12.25L12.5 11.5Z"
        fill={color}
      />
    </svg>
  );
};
