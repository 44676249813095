import { MarkIcon } from "assets/images/common";
import { Avatar, Button, ButtonOutline } from "components";
import { Country } from "country-state-city";
import styled from "styled-components";

const data = [
  {
    title: "Sector they invest in:",
    tags: ["Fashion", "E-commerce", "Internet", "IT", "Lifestyle"],
  },
  {
    title: "Impact they invest in:",
    tags: ["Responsible Consumption and Production", "Gender Equality"],
  },
  {
    title: "Raising now:",
    tags: ["FR", "DE", "IT", "ES"],
  },
];

interface Props {
  handleReachOut: (item: string) => void;
}

export const InvestorItem = ({ handleReachOut }: Props) => {
  return (
    <Wrapper>
      <Header>
        <Avatar
          src="https://bit.ly/ryan-florence"
          name="Maelle Gavet"
          size={64}
        />

        <InfoWrapper>
          <div className="name">Maelle Gavet</div>
          <div className="sub">CEO at Techstars, Investor</div>
        </InfoWrapper>
      </Header>

      {data.map((item, index) => (
        <Group key={index}>
          <div className="title">{item.title}</div>

          {item.title === "Raising now:" ? (
            <TagList>
              {item.tags.map((code, index) => (
                <Tag key={index}>
                  {item.title === "Raising now:" && (
                    <div>{Country.getCountryByCode(code)?.flag}</div>
                  )}

                  {Country.getCountryByCode(code)?.name}
                </Tag>
              ))}
            </TagList>
          ) : (
            <TagList>
              {item.tags.map((tag, index) => (
                <Tag key={index}>{tag}</Tag>
              ))}
            </TagList>
          )}
        </Group>
      ))}

      <Footer>
        <ButtonOutline icon={<MarkIcon />}>Follow</ButtonOutline>

        <Button onClick={() => handleReachOut("Maelle Gavet")}>
          Reach out
        </Button>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px 16px;
  border: 1px solid #d8d9df;
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebecef;
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 700;
    font-size: 20px;
  }

  .sub {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 16px;
  }
`;

const Group = styled.div`
  padding: 16px 0px;
  border-bottom: 1px solid #ebecef;

  .title {
    margin-bottom: 8px;
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 14px;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;

  .button-wrapper {
    width: 100%;

    button {
      background: ${(props) => props.theme.palette.secondary.dark};
    }
  }

  button {
    width: 100%;
    padding: 0px 16px;
  }
`;

const TagList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-height: 34px;
  overflow: hidden;
`;

const Tag = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  background: #f4f5f7;
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 12px;
  white-space: nowrap;
`;
