import { PdfView } from "components/Feature/PdfView";
import { useAppSelector } from "hooks/useHook";
import { FileTypes } from "models/common.model";
import { Prospect } from "models/prospect.model";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { selectFeedDetail } from "store/feed/feed.selector";
import { selectProspect } from "store/prospect/prospect.selector";
import styled from "styled-components";
import { getUrlFileType } from "utils/system.util";

interface Props {
  name: string;
  isFeed?: boolean;
}

export const ProspectMediaFrame = ({ name, isFeed }: Props) => {
  const prospect = useAppSelector(isFeed ? selectFeedDetail : selectProspect);
  const fieldData = prospect[name as keyof Prospect];
  const dataType = getUrlFileType(fieldData as string);

  if (!fieldData) return null;
  if (dataType === FileTypes.VIDEO)
    return (
      <VideoWrapper>
        <ReactPlayer
          url={fieldData as string}
          width={"100%"}
          height={"auto"}
          controls={true}
        />
      </VideoWrapper>
    );
  if (dataType === FileTypes.IMAGE) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Slider {...settings}>
        <ImageWrapper src={fieldData as string} />
      </Slider>
    );
  }

  if (dataType === FileTypes.IFRAME) {
    const viewUrl = (fieldData as string).replace("/view", "/preview");
    return (
      <iframe src={viewUrl} width="100%" height="480" allow="autoplay"></iframe>
    );
  }

  if (dataType === FileTypes.PDF) {
    return <PdfView url={fieldData as string} />;
  }

  return null;
};

const VideoWrapper = styled.div`
  display: flex;
  min-height: 400px;
  height: auto;
  width: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-height: 200px;
  }
`;
const ImageWrapper = styled.img`
  width: 100%;
  height: auto;
`;
