import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import {
  Button,
  CustomSelect,
  SectionWrapper,
  Text,
  TextArea,
} from "components";
import { NOTI_CODE } from "constants/firestore.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { StatusIndicator } from "models/common.model";
import { selectNamespaceId } from "store/global/global.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import {
  selectOrganisation,
  selectOrganisationLoading,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { successNotification } from "utils/app.util";
import { createChat } from "utils/chat.util";
import { db } from "utils/firebase.util";

export const AdminStatusSection = () => {
  const [chatLoading, setChatLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const namespaceId = useAppSelector(selectNamespaceId);
  const { id, displayName, logoUrl, status, message, fundraisingProjects } =
    useAppSelector(selectOrganisation);
  const loading = useAppSelector(selectOrganisationLoading);

  const schema = yup.object().shape({
    message: yup.string().nullable(true),
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangeStatus = async (newStatus: StatusIndicator) => {
    if (id && namespaceId) {
      if (newStatus === status) return;
      const userRef = doc(db, "users", namespaceId);
      const userData = await getDoc(userRef);

      if (!userData.exists()) {
        await setDoc(userRef, { id: namespaceId, namespaceId, type: "admin" });
      }

      const notificationRef = collection(db, "notifications");

      const handleUpdateFundingStatus = async () => {
        dispatch(
          fundraisingActions.updateFundraising({
            id: fundraisingProjects[0].id,
            input: {
              status: newStatus,
            },
            isAdmin: true,
            callback: async () => {
              await addDoc(notificationRef, {
                code: `${NOTI_CODE.REVIEW_FUND}__${namespaceId}__${fundraisingProjects[0].id}__${newStatus}`,
                receiverId: id,
                namespaceId: namespaceId,
                createdAt: new Date().valueOf(),
                isRead: false,
                unreadCount: 1,
              });
            },
          })
        );
      };

      dispatch(
        organisationActions.updateOrganisation({
          organisationId: id,
          input: {
            status: newStatus,
          },
          isAdmin: true,
          callback: async () => {
            await addDoc(notificationRef, {
              code: `${NOTI_CODE.REVIEW_ORG}__${namespaceId}__${id}__${newStatus}`,
              receiverId: id,
              namespaceId: namespaceId,
              createdAt: new Date().valueOf(),
              isRead: false,
              unreadCount: 1,
            });

            // When admin flag the org - it will turn into flag round (2)
            if (
              status === StatusIndicator.REVIEW &&
              fundraisingProjects[0]?.status === StatusIndicator.REVIEW &&
              newStatus === StatusIndicator.FLAGGED
            ) {
              handleUpdateFundingStatus();
            }

            // When admin publishes the org - it will turn into publishes round (4)
            if (
              newStatus === StatusIndicator.PUBLISHED &&
              fundraisingProjects[0]?.status === StatusIndicator.REVIEW
            ) {
              handleUpdateFundingStatus();
            }
          },
        })
      );
    }
  };

  const handleUpdateMessage = async (data: FieldValues) => {
    if (id) {
      if (data.message === message) return;

      const input = {
        message: data.message,
      };

      dispatch(
        organisationActions.updateOrganisation({
          organisationId: id,
          input,
          isAdmin: true,
          callback: () => {
            dispatch(successNotification("Message sent successfully"));
            reset(data);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (message) {
      setValue("message", message);
    }
  }, [message, setValue]);

  return (
    <SectionWrapper title="Review status">
      <Container>
        <Stack direction="row" alignItems="center" spacing="16px">
          <Text size="md" fontWeight={600}>
            Status
          </Text>

          <CustomSelect
            data={[
              StatusIndicator.REVIEW,
              StatusIndicator.PUBLISHED,
              StatusIndicator.FLAGGED,
            ]}
            selectedItem={status}
            onSelect={(status) => {
              handleChangeStatus(status as StatusIndicator);
            }}
          />
        </Stack>

        {status === StatusIndicator.FLAGGED && (
          <Form onSubmit={handleSubmit(handleUpdateMessage)}>
            <TextArea
              fieldName="Message"
              name="message"
              placeholder="Type message here..."
              minRows={3}
              maxLength={1400}
              formRef={register}
              hasCount
              watch={watch}
              error={errors?.message?.message as string}
            />
            <ButtonAndNotice>
              <Button type="submit" loading={loading} disabled={!isDirty}>
                Send
              </Button>
              <Text size="md" color="#3b864c">
                {!isDirty
                  ? "Message sent successfully!"
                  : "Ready to send new message!"}
              </Text>
            </ButtonAndNotice>
          </Form>
        )}

        <Button
          onClick={() =>
            createChat({
              namespaceId,
              user1: { id: namespaceId, type: "admin" },
              user2: { id, displayName, avatar: logoUrl, type: "founder" },
              handleLoading: (loading) => setChatLoading(loading),
              handleRoomId: (roomId) => navigate(`/chats/${roomId}`),
            })
          }
          loading={chatLoading}
        >
          Go to chat
        </Button>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Form = styled.form`
  .button-wrapper {
    margin-top: 12px;
    margin-left: auto;
  }
`;

const ButtonAndNotice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;
