import styled from "styled-components";

import { ProspectSort } from "components/Business/Prospect/ProspectSort";
import { SortItem } from "models/common.model";

const SORT_DATA: SortItem[] = [
  {
    id: "0",
    name: "Default",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "",
    order: "desc",
  },
  {
    id: "1",
    name: "Raising now",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundSize",
    order: "asc",
  },
  {
    id: "2",
    name: "Raising now",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundSize",
    order: "desc",
  },
  {
    id: "3",
    name: "Round closes soon",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundCloseDate",
    order: "asc",
  },
  {
    id: "4",
    name: "Round closes soon",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundCloseDate",
    order: "desc",
  },
];
export const SortSection = () => {
  return (
    <Wrapper>
      <div className="result">Showing 86 results</div>

      <ProspectSort data={SORT_DATA} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;

  .result {
    color: #7c8698;
    font-size: 16px;
  }
`;
