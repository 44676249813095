import { call, put, select, takeLatest, delay } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import workspaceApi from "api/workspace.api";
import {
  AUTH_MODE_TYPE,
  UserDeleteParams,
  WORKSPACE_TYPE,
} from "models/auth.model";
import { AppConfig } from "models/common.model";
import { Workspace } from "models/workspace.model";
import { selectAppConfig } from "store/global/global.selector";
import { workspaceActions } from "store/workspace/workspace.slice";
import { getUserRole } from "utils/app.util";
import { parseToken } from "utils/system.util";
import {
  AUTH_MODE,
  NEW_WORKSPACES,
  USER_TOKEN,
  WORKSPACE_ID,
  WORKSPACE_TYPE_KEY,
  WORKSPACE_UPDATE,
} from "../../constants/app.const";
import { LocalStorage } from "../../utils/localStorage.util";
import { selectAuthMode } from "./auth.selector";
import { authActions } from "./auth.slice";
import authApi from "api/auth.api";

function* userUpdateRole(action: PayloadAction<AUTH_MODE_TYPE>) {
  try {
    if (action.payload) {
      LocalStorage.set(action.payload, AUTH_MODE);
      yield put(authActions.userLoginSuccess(action.payload));
    }
  } catch (error) {
    yield put(authActions.userLoginFailed());
  }
}

function* userUpdateToken(action: PayloadAction<string | undefined>) {
  yield put(authActions.userUpdateTokenSuccess(action.payload));
  if (action.payload) {
    LocalStorage.set(action.payload, USER_TOKEN);
  } else {
    LocalStorage.remove(USER_TOKEN);
  }
}

function* userLogin(action: PayloadAction<string>) {
  try {
    if (action.payload) {
      const { id }: AppConfig = yield select(selectAppConfig);

      const tokenObject = parseToken(action.payload);
      const userRole = getUserRole(tokenObject, id);
      LocalStorage.set(userRole, AUTH_MODE);
      LocalStorage.set(action.payload, USER_TOKEN);
      yield put(authActions.userUpdateToken(action.payload));
      yield put(authActions.userLoginSuccess(userRole));
    }
  } catch (error) {
    yield put(authActions.userLoginFailed());
  }
}

function* userLogout() {
  try {
    yield put(authActions.userLogoutSuccess());
    LocalStorage.remove(AUTH_MODE);
    LocalStorage.remove(WORKSPACE_ID);
    LocalStorage.remove(USER_TOKEN);
  } catch (err) {
    //throw err as Error;
  }
}

function* userDelete(action: PayloadAction<UserDeleteParams>) {
  try {
    yield call(authApi.userDelete, action.payload);
    yield delay(3000);
    yield call(userLogout);
    yield put(authActions.userDeleteSuccess());
  } catch (err) {
    console.log(err);
    yield put(authActions.userDeleteError());
  }
}

function* workspaceLogin(
  action: PayloadAction<{
    id: string;
    type?: WORKSPACE_TYPE;
    isUpdate?: boolean;
    callback: (type?: WORKSPACE_TYPE, isUpdate?: boolean) => void;
  }>
) {
  try {
    const { workspaceLogin } = yield call(
      workspaceApi.workspaceLogin,
      action.payload.id
    );
    if (workspaceLogin) {
      action.payload.callback(action.payload.type, action.payload.isUpdate);
      LocalStorage.set(workspaceLogin, WORKSPACE_ID);
      LocalStorage.set(action.payload.type, WORKSPACE_TYPE_KEY);
      LocalStorage.set(action.payload.isUpdate, WORKSPACE_UPDATE);
    }
  } catch (error) {
    yield put(authActions.workspaceLoginFailed());
  }
}

function* workspaceLogout() {
  try {
    const authMode: AUTH_MODE_TYPE = yield select(selectAuthMode);
    const workspaceId = LocalStorage.get(WORKSPACE_ID);
    const newWorkspaces: string[] = LocalStorage.get(NEW_WORKSPACES) || [];
    const filteredWorkspaces = newWorkspaces.filter((i) => i !== workspaceId);
    if (filteredWorkspaces.length) {
      LocalStorage.set(filteredWorkspaces, NEW_WORKSPACES);
    } else {
      LocalStorage.remove(NEW_WORKSPACES);
    }

    if (
      [
        AUTH_MODE_TYPE.ADMIN_WORK_AS_WORKSPACE,
        AUTH_MODE_TYPE.ADMIN_OWNER,
      ].includes(authMode)
    ) {
      LocalStorage.set(AUTH_MODE_TYPE.ADMIN_WORK_AS_USER, AUTH_MODE);
    } else {
      LocalStorage.set(AUTH_MODE_TYPE.USER, AUTH_MODE);
    }

    LocalStorage.remove(WORKSPACE_ID);
    LocalStorage.remove(WORKSPACE_TYPE_KEY);

    yield put(workspaceActions.getWorkspaceSuccess({} as Workspace));
    yield put(authActions.workspaceLogoutSuccess());
  } catch (err) {
    //throw err as Error;
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.workspaceLogin.type, workspaceLogin);
  yield takeLatest(authActions.userUpdateToken.type, userUpdateToken);
  yield takeLatest(authActions.workspaceLogout, workspaceLogout);
  yield takeLatest(authActions.userLogin.type, userLogin);
  yield takeLatest(authActions.userUpdateRole.type, userUpdateRole);
  yield takeLatest(authActions.userLogout.type, userLogout);
  yield takeLatest(authActions.userDelete, userDelete);
}
