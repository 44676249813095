import { TablePagination } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
  WarningIcon,
} from "assets/images/common";
import { BasicTable, Button, PageTitle, Text, Warning } from "components";
import ConfirmModal from "components/Feature/ConfirmModal";
import { PATH } from "constants/app.const";
import {
  useAppDispatch,
  useAppNavigate,
  useAppParams,
  useAppSelector,
} from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { StatusIndicator, TBD } from "models/common.model";
import { selectAuthMode } from "store/auth/auth.selector";
import {
  selectFundraisingList,
  selectFundraisingLoading,
} from "store/fundraising/fundraising.selector";
import { fundraisingActions } from "store/fundraising/fundraising.slice";
import {
  selectOrganisation,
  selectOrganisationProgress,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { formatCurrency } from "utils/system.util";
import { StatusFlag } from "../components";

const CONFIG = [
  {
    name: "Round type",
    width: "auto",
  },
  {
    name: "Amount",
    width: "auto",
  },
  {
    name: "End date",
    width: 160,
  },
  {
    name: "Status",
    width: 160,
  },
  {
    name: "Action",
    width: 240,
  },
];

export const CompanyFundraising = () => {
  const [tableData, setTableData] = useState<TBD<unknown>[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteFundraisingId, setDeleteFundraisingId] = useState<
    string | undefined
  >();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { id: organisationParamsId } = useAppParams();
  const organisationProgress = useAppSelector(selectOrganisationProgress);
  const confirmLoading = useAppSelector(selectFundraisingLoading);
  const { id, message, status, fundraisingProjects, currency } =
    useAppSelector(selectOrganisation);
  const fundraisingList = useAppSelector(selectFundraisingList);
  const authMode = useAppSelector(selectAuthMode);
  const isAdmin = [AUTH_MODE_TYPE.ADMIN, AUTH_MODE_TYPE.ADMIN_OWNER].includes(
    authMode
  );

  const listData = useMemo(() => {
    if (isAdmin) {
      return fundraisingProjects || [];
    } else {
      return fundraisingList;
    }
  }, [fundraisingList, fundraisingProjects, isAdmin]);

  const handleCreateFundraising = () => {
    dispatch(
      fundraisingActions.createFundraising({
        id,
        callback: (id) => navigate(`${PATH.COMPANY_FUNDRAISING}/${id}`),
      })
    );
  };

  const handleDeleteFundraising = useCallback(() => {
    if (deleteFundraisingId) {
      dispatch(
        fundraisingActions.deleteFundraising({
          organisationId: id,
          fundraisingProjectId: deleteFundraisingId,
          callback: () => {
            setDeleteFundraisingId(undefined);
          },
        })
      );
    }
  }, [deleteFundraisingId, dispatch, id]);

  useEffect(() => {
    if (!isAdmin && organisationProgress.length) {
      setIsOpenModal(!!organisationProgress.find((item) => !item.isComplete));
    }
  }, [isAdmin, organisationProgress]);

  useEffect(() => {
    dispatch(fundraisingActions.getFundraisingList({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (isAdmin && organisationParamsId) {
      dispatch(
        organisationActions.getOrganisation({
          id: organisationParamsId,
          isAdmin,
        })
      );
    } else {
      dispatch(organisationActions.getCompanyOrganisation());
    }
  }, [dispatch, isAdmin, organisationParamsId]);

  useEffect(() => {
    const data = listData.map((item) => {
      return {
        roundType: <GroupCell>{item.currentRound?.name || "Empty"}</GroupCell>,
        amount: (
          <GroupCell>{formatCurrency(item.roundSize, currency)}</GroupCell>
        ),
        endDate: (
          <GroupCell>
            {item.roundCloseDate
              ? dayjs(item.roundCloseDate).format("DD/MM/YYYY")
              : "Empty"}
          </GroupCell>
        ),
        status: (
          <GroupCell>
            <StatusFlag status={item.status} />
          </GroupCell>
        ),
        action: (
          <GroupCell>
            <ActionWrapper
              onClick={() =>
                isAdmin
                  ? navigate(
                      `${PATH.ADMIN_COMPANY}/${id}/fundraising/${item.id}`
                    )
                  : navigate(`${PATH.COMPANY_FUNDRAISING}/${item.id}`)
              }
            >
              <EditIcon />
              <Text size="md">View more</Text>
            </ActionWrapper>

            {!isAdmin && (
              <ActionWrapper onClick={() => setDeleteFundraisingId(item.id)}>
                <DeleteIcon />
                <Text size="md">Delete</Text>
              </ActionWrapper>
            )}
          </GroupCell>
        ),
      };
    });

    setTableData(data);
  }, [listData, handleDeleteFundraising, navigate, isAdmin, id, currency]);

  return (
    <Wrapper>
      <Header>
        <PageTitle>Fundraising</PageTitle>

        {!isAdmin && (
          <Button
            icon={<PlusIcon color="#FFFFFF" />}
            onClick={handleCreateFundraising}
          >
            Create a new Funding round
          </Button>
        )}
      </Header>

      {status === StatusIndicator.FLAGGED && !isAdmin && (
        <Warning
          icon={<WarningIcon />}
          text={`Your Company request was rejected by the moderator.${
            message ? ` "${message}"` : ""
          }`}
          backgroundColor="#fff1f0"
          color="#b4453d"
          hasButton
          buttonText="Complete Settings"
          handleClickButton={() => navigate(PATH.COMPANY_SETTINGS)}
        />
      )}

      <BasicTable title="Funding rounds" config={CONFIG} data={tableData} />

      {listData.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={listData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            setPage(0);
          }}
        />
      )}

      <ConfirmModal
        title="Fill out Company Settings"
        subTitle="Fill out Company Settings to open your Funding round"
        confirmButtonText="Complete Settings"
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        callback={() => navigate(PATH.COMPANY_SETTINGS)}
      />

      <ConfirmModal
        title={"Are you sure to delete funding round?"}
        isOpen={!!deleteFundraisingId}
        onClose={() => setDeleteFundraisingId(undefined)}
        callback={handleDeleteFundraising}
        loading={confirmLoading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 100%;
  padding: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }

  & > .MuiPaper-root {
    flex: 1;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  .button-wrapper button {
    height: 40px;
    margin-left: auto;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const GroupCell = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: ${(props) => props.theme.palette.primaryText.light};
  font-size: 16px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: auto;
  }
  &:hover {
    text-decoration: underline;
  }
`;
