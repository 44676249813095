import {
  MESSAGE_CODE,
  MESSAGE_STRUCTS,
  NOTI_CODE,
  NOTI_STRUCTS,
} from "constants/firestore.const";

export const parseCodeObject = (code: string) => {
  const splitCode = code.split("__");
  const codeType = splitCode[0];

  switch (codeType) {
    case NOTI_CODE.REVIEW_ORG: {
      const structs = NOTI_STRUCTS[NOTI_CODE.REVIEW_ORG];
      return Object.fromEntries(
        structs.map((key, index) =>
          index === 0 ? ["isReviewOrg", true] : [key, splitCode[index]]
        )
      );
    }
    case NOTI_CODE.REVIEW_FUND: {
      const structs = NOTI_STRUCTS[NOTI_CODE.REVIEW_FUND];
      return Object.fromEntries(
        structs.map((key, index) =>
          index === 0 ? ["isReviewFund", true] : [key, splitCode[index]]
        )
      );
    }
    case NOTI_CODE.CHAT_DEFAULT: {
      const structs = NOTI_STRUCTS[NOTI_CODE.CHAT_DEFAULT];
      return Object.fromEntries(
        structs.map((key, index) =>
          index === 0 ? ["isChatDefault", true] : [key, splitCode[index]]
        )
      );
    }
    case NOTI_CODE.CHAT_INVEST: {
      const structs = NOTI_STRUCTS[NOTI_CODE.CHAT_INVEST];
      return Object.fromEntries(
        structs.map((key, index) =>
          index === 0 ? ["isChatInvest", true] : [key, splitCode[index]]
        )
      );
    }
    case MESSAGE_CODE.INVEST: {
      const structs = MESSAGE_STRUCTS[MESSAGE_CODE.INVEST];
      return Object.fromEntries(
        structs.map((key, index) =>
          index === 0 ? ["isChatInvest", true] : [key, splitCode[index]]
        )
      );
    }
    case NOTI_CODE.CHAT_REACH_OUT_FUNDS: {
      const structs = NOTI_STRUCTS[NOTI_CODE.CHAT_REACH_OUT_FUNDS];
      return Object.fromEntries(
        structs.map((key, index) =>
          index === 0 ? ["isReachOutFunds", true] : [key, splitCode[index]]
        )
      );
    }
    case MESSAGE_CODE.REACH_OUT_FUNDS: {
      const structs = MESSAGE_STRUCTS[MESSAGE_CODE.REACH_OUT_FUNDS];
      return Object.fromEntries(
        structs.map((key, index) =>
          index === 0 ? ["isReachOutFunds", true] : [key, splitCode[index]]
        )
      );
    }
    default: {
      return {};
    }
  }
};
