import { createSelector } from "@reduxjs/toolkit";
import { initialState, WorkspaceState } from "./workspace.slice";
import _ from "lodash";

const selectDomain = (state: { workspace: WorkspaceState }) =>
  state.workspace || initialState;

export const selectWorkspaceLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectActiveWorkspaces = createSelector(
  [selectDomain],
  (state) => state.activeWorkspace
);

export const selectWorkspaces = createSelector([selectDomain], (state) =>
  _.orderBy(state.workspaces, ["updatedAt"], ["desc"])
);

export const selectActiveWorkspace = createSelector(
  [selectDomain],
  (state) => state.activeWorkspace
);

export const selectActiveWorkspaceName = createSelector(
  [selectDomain],
  (state) => state.activeWorkspace?.displayName
);
