import { Stack } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styled from "styled-components";

import { LeftArrowIcon } from "assets/images/common";
import { Button, Text } from "components";

interface Props {
  step: number;
  count: number;
  completePercent: number;
  onChangeStep: (newStep: number) => void;
}

export const FormProgress = ({
  step,
  count,
  completePercent,
  onChangeStep,
}: Props) => {
  return (
    <Wrapper>
      <ProgressWrapper spacing="5px">
        <Text size="md">{completePercent}% completed</Text>
        <CSSLinearProgress variant="determinate" value={completePercent} />
      </ProgressWrapper>

      <Stack direction="row" spacing="2px">
        <Button onClick={() => onChangeStep(step - 1)} disabled={step === 1}>
          <LeftArrowIcon color="#ffffff" />
        </Button>

        <Button
          className="next-btn"
          onClick={() => onChangeStep(step + 1)}
          disabled={step === count}
        >
          <LeftArrowIcon className="right-arrow" color="#ffffff" />
        </Button>
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;

  .button-wrapper button {
    width: 30px;
    height: 30px;
    padding: 0px;
  }

  .next-btn svg {
    transform: rotate(180deg);
  }
`;

const ProgressWrapper = styled(Stack)`
  position: relative;
  width: 220px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: #ffffff;
`;

const CSSLinearProgress = styled(LinearProgress)({
  height: "5px",
  [`&.${linearProgressClasses.root}`]: {
    backgroundColor: "#EBECEF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#373b59",
  },
});
