import { ReactComponent as FacebookIcon } from "assets/images/common/facebook-icon.svg";
import { ReactComponent as GoogleIcon } from "assets/images/common/google-icon.svg";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useAppDispatch } from "hooks/useHook";
import { authActions } from "store/auth/auth.slice";
import styled from "styled-components/macro";
import { ButtonOutline } from "components";

const SignInSocial = () => {
  const dispath = useAppDispatch();
  const auth = getAuth();

  const signInWithGoogle = async () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then(async (response) => {
        if (response.user) {
          const userToken = await response.user.getIdToken();
          dispath(authActions.userLogin(userToken));
        }
      })
      .catch((err) => {
        console.log(err as Error);
        //throw err as Error;
      });
  };

  return (
    <Wrapper>
      <ButtonOutline startIcon={<GoogleIcon />} onClick={signInWithGoogle}>
        Sign in with Google
      </ButtonOutline>
      <ButtonOutline startIcon={<FacebookIcon />}>
        Sign in with Facebook
      </ButtonOutline>
    </Wrapper>
  );
};

export default SignInSocial;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > button {
    width: 100%;
  }
  margin-bottom: 30px;
`;
