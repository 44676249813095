import styled from "styled-components";

import welcomeImage from "assets/images/common/workspace-founder.png";
import { Button, Text } from "components";

interface Props {
  disabled?: boolean;
  onNext: () => void;
}

export const WelcomeScreen = ({ disabled, onNext }: Props) => {
  return (
    <Wrapper className="form-item">
      <img className="welcome-img" src={welcomeImage} />

      <Text size="lg" className="text">
        Do you want to strengthen your position? Improve your score! Answer our
        bespoke assessment to get your MF Trust score (85% of our investors
        filter the deal flow by MF Score). It will take you around 15 min to
        answer the assessment.
      </Text>

      <Button onClick={onNext} disabled={disabled}>
        {"Let's start!"}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 20px;

  .welcome-img {
    max-width: 100%;
  }

  .text {
    max-width: 800px;
    font-size: 16px;
    text-align: center;
  }
`;
