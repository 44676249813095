import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const InstagramIcon = ({ color = "#606C82" }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.84211 0C2.17532 0 0 2.17532 0 4.84211L0 11.1579C0 13.8243 2.17525 16 4.84211 16H11.1579C13.8244 16 16 13.8244 16 11.1579V4.84211C16 2.17525 13.8243 0 11.1579 0L4.84211 0ZM4.84211 1.26316H11.1579C13.1416 1.26316 14.7368 2.85802 14.7368 4.84211V11.1579C14.7368 13.1415 13.1415 14.7368 11.1579 14.7368H4.84211C2.85802 14.7368 1.26316 13.1416 1.26316 11.1579V4.84211C1.26316 2.85794 2.85794 1.26316 4.84211 1.26316ZM12.2105 2.94737C11.7453 2.94737 11.3684 3.32421 11.3684 3.78947C11.3684 4.25474 11.7453 4.63158 12.2105 4.63158C12.6758 4.63158 13.0526 4.25474 13.0526 3.78947C13.0526 3.32421 12.6758 2.94737 12.2105 2.94737ZM8 3.78947C5.68218 3.78947 3.78947 5.68218 3.78947 8C3.78947 10.3178 5.68218 12.2105 8 12.2105C10.3178 12.2105 12.2105 10.3178 12.2105 8C12.2105 5.68218 10.3178 3.78947 8 3.78947ZM8 5.05263C9.63502 5.05263 10.9474 6.36498 10.9474 8C10.9474 9.63502 9.63502 10.9474 8 10.9474C6.36498 10.9474 5.05263 9.63502 5.05263 8C5.05263 6.36498 6.36498 5.05263 8 5.05263Z"
        fill={color}
      />
    </svg>
  );
};
