import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import styled from "styled-components";

import { Avatar, SectionWrapper } from "components";
import { NewFeed } from "models/post.model";

interface Props {
  data: NewFeed;
}
dayjs.extend(relativeTime);

export const NewFeedItem = ({ data }: Props) => {
  const { title, description, createdAt, author } = data;
  const { displayName, logoUrl } = author;

  return (
    <SectionWrapper>
      <Container>
        <Header>
          <Avatar src={logoUrl} name={displayName} size={64} rounded="0px" />
          <InfoWrapper>
            <Text className="name">{displayName}</Text>
            <Text className="sub">{dayjs(createdAt).fromNow()}</Text>
          </InfoWrapper>
        </Header>

        <Title>{title}</Title>
        <Text>{description}</Text>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .name {
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: 700;
    font-size: 20px;
  }

  .sub {
    color: ${(props) => props.theme.palette.secondary.light};
    font-size: 16px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 16px;
  font-weight: 600;
  word-break: break-all;
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.secondary.light};
  font-size: 16px;
  word-break: break-all;
`;
