import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  color?: string;
}

export const DeleteIcon = ({ color = "#606C82" }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6875 5.64375C6.04994 5.64375 6.34375 5.93756 6.34375 6.3V10.5C6.34375 10.8624 6.04994 11.1562 5.6875 11.1562C5.32506 11.1562 5.03125 10.8624 5.03125 10.5V6.3C5.03125 5.93756 5.32506 5.64375 5.6875 5.64375ZM8.3125 5.64375C8.67494 5.64375 8.96875 5.93756 8.96875 6.3V10.5C8.96875 10.8624 8.67494 11.1562 8.3125 11.1562C7.95006 11.1562 7.65625 10.8624 7.65625 10.5V6.3C7.65625 5.93756 7.95006 5.64375 8.3125 5.64375ZM11.2875 4.1125V12.6875H2.7125V4.1125H11.2875ZM10.4125 0.361111C10.4694 0.463935 10.5 0.580389 10.5 0.7V2.8H13.3C13.6866 2.8 14 3.1134 14 3.5C14 3.8866 13.6866 4.2 13.3 4.2H12.6V13.3C12.6 13.4857 12.5263 13.6637 12.395 13.795C12.2637 13.9263 12.0857 14 11.9 14H2.1C1.91435 14 1.7363 13.9263 1.60503 13.795C1.47375 13.6637 1.4 13.4857 1.4 13.3V4.2H0.7C0.313401 4.2 0 3.8866 0 3.5C0 3.1134 0.313401 2.8 0.7 2.8H3.5V0.7C3.5 0.580389 3.53061 0.463934 3.5875 0.361111C3.61891 0.304339 3.65833 0.251723 3.70503 0.205025C3.75172 0.158328 3.80434 0.118909 3.86111 0.0875001C3.96393 0.030613 4.08039 0 4.2 0L9.8 0C9.91961 0 10.0361 0.030613 10.1389 0.0875001C10.1957 0.118909 10.2483 0.158328 10.295 0.205025C10.3417 0.251723 10.3811 0.304339 10.4125 0.361111ZM4.9 2.8V1.4H9.1V2.8H4.9Z"
        fill={color}
      />
    </svg>
  );
};
