import { call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { globalActions } from "store/global/global.slice";

import postApi from "api/post.api";
import { CustomPostParam, GetListPostParam } from "models/post.model";
import { errorNotification } from "utils/app.util";
import { deleteFolder, uploadFromBlobAsync } from "utils/firebase.util";
import { postActions } from "./post.slice";

function* getPosts(action: PayloadAction<GetListPostParam>) {
  try {
    yield put(globalActions.setPageLoading(true));
    const { postListGet } = yield call(postApi.getPosts, action.payload);

    if (postListGet) {
      const { posts } = postListGet;

      if (posts) {
        yield put(postActions.getPostsSuccess(posts));
      }
    }

    yield put(globalActions.setPageLoading(false));
  } catch (_) {
    yield put(globalActions.setPageLoading(false));
  }
}

function* createPost(
  action: PayloadAction<{
    organisationId: string;
    input: CustomPostParam;
    callback?: () => void;
  }>
) {
  try {
    const { organisationId, input, callback } = action.payload;

    const { postImage, ...data } = input;

    const { postCreate } = yield call(postApi.createPost, organisationId, data);

    if (postCreate) {
      const { post } = postCreate;

      if (post) {
        if (postImage) {
          const attachment: string = yield call(uploadFromBlobAsync, {
            blob: postImage,
            name: "post-image",
            folder: `workspaces/${organisationId}/posts/${post.id}/image`,
          });

          yield call(postApi.updatePost, post.id, {
            attachment,
          } as CustomPostParam);

          post.attachment = attachment;
        }

        yield put(postActions.createPostSuccess(post));
      }
    }

    callback && callback();
  } catch (_) {
    yield put(errorNotification("Create post failed"));
  }
}

function* updatePost(
  action: PayloadAction<{
    postId: string;
    input: CustomPostParam;
    callback?: () => void;
  }>
) {
  try {
    const { postId, input, callback } = action.payload;

    const { postUpdate } = yield call(postApi.updatePost, postId, input);

    if (postUpdate) {
      const { post } = postUpdate;

      if (post) {
        yield put(postActions.updatePostSuccess(post));
      }
    }

    callback && callback();
  } catch (_) {
    yield put(errorNotification("Update post failed"));
  }
}

function* deletePost(
  action: PayloadAction<{
    postId: string;
    organisationId: string;
    callback?: () => void;
  }>
) {
  try {
    const { postId, organisationId, callback } = action.payload;

    yield call(deleteFolder, `workspaces/${organisationId}/posts/${postId}`);
    const { postDelete } = yield call(postApi.deletePost, postId);

    if (postDelete) {
      const { postId } = postDelete;

      if (postId) {
        yield put(postActions.deletePostSuccess(postId));
      }
    }

    callback && callback();
  } catch (_) {
    yield put(errorNotification("Deleted post failed"));
  }
}

export default function* postSaga() {
  yield takeLatest(postActions.getPosts.type, getPosts);
  yield takeLatest(postActions.createPost.type, createPost);
  yield takeLatest(postActions.updatePost.type, updatePost);
  yield takeLatest(postActions.deletePost.type, deletePost);
}
