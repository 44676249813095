import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup as MuiFormGroup,
} from "@mui/material";
import styled from "styled-components/macro";

interface Props extends CheckboxProps {
  maincolor?: string;
  mainsize?: number;
  label?: string;
}

export const CheckBox = ({ label, ...props }: Props) => (
  <FormGroup className="form-group-checkbox">
    <FormControlLabel control={<CheckboxWrapper {...props} />} label={label} />
  </FormGroup>
);

const FormGroup = styled(MuiFormGroup)`
  margin-left: unset;
  margin-right: unset;
  .MuiFormControlLabel-label {
    font-size: 14px;
    user-select: none;
  }
  .MuiFormControlLabel-root {
    margin-left: unset;
    margin-right: unset;
  }
`;

const CheckboxWrapper = styled(MuiCheckbox)<Props>`
  color: ${(props) => props.theme.palette.secondary.main};

  &.MuiCheckbox-root {
    padding: 0px;
    margin-right: 6px;

    .MuiSvgIcon-root {
      width: ${(p) => p?.mainsize || 24}px;
      height: ${(p) => p?.mainsize || 24}px;
      fill: #d8d9df;
    }
  }
  &.Mui-checked {
    color: ${(p) => p?.maincolor || p.theme.palette.secondary.main};

    .MuiSvgIcon-root {
      fill: ${(p) => p?.maincolor || p.theme.palette.secondary.main};
    }
  }
  &.Mui-disabled {
    .MuiSvgIcon-root {
      fill: rgba(0, 0, 0, 0.38);
    }
  }
`;
