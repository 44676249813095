import styled from "styled-components";

import { Text } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import _ from "lodash";
import { useEffect } from "react";
import {
  selectCountFilter,
  selectFilterOptions,
} from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";
import { selectAppConfig } from "store/global/global.selector";

export const GlobalFilter = () => {
  const dispatch = useAppDispatch();
  const { namespaceFilter } = useAppSelector(selectAppConfig);
  const countFeed = useAppSelector(selectCountFilter);
  const { isGlobal } = useAppSelector(selectFilterOptions);
  const isActive = !_.isEmpty(isGlobal);

  const handleFilter = (state: boolean) => {
    dispatch(
      feedActions.filterFeed({
        filter: { isGlobal: state ? [] : ["apply"] },
        isApply: false,
      })
    );
  };

  useEffect(() => {
    dispatch(
      feedActions.getFeeds({
        id: "0",
        name: "Default",
        feedFilter: {
          types: ["Organisation"],
          namespace: isActive ? "Global" : "Own",
        },
        byKey: "",
        order: "desc",
      })
    );
  }, [isActive, dispatch]);

  useEffect(() => {
    if (namespaceFilter?.active && namespaceFilter?.value === "Global") {
      dispatch(
        feedActions.filterFeed({
          filter: { isGlobal: ["apply"] },
          isApply: false,
        })
      );
    }
  }, [namespaceFilter, dispatch]);

  if (!namespaceFilter?.active) return null;

  return (
    <Filter
      className={isActive ? "active" : undefined}
      onClick={() => handleFilter(isActive)}
    >
      <Text size="md">See Global Deal Flow</Text>
      {isActive && countFeed > 0 && <NumberBox>{countFeed}</NumberBox>}
    </Filter>
  );
};

const NumberBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #b4bac4;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 0 8px;
  background-color: #ffffff;
  border: 1px solid #d8d9df;
  gap: 6px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: #d8d9df2f;
  }

  ${NumberBox} {
    background: #ebecf0;
  }

  &.active {
    background-color: #d8d9df;

    ${NumberBox} {
      background-color: #b4bac4;
    }
  }
`;
