import { Popover } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import commonApi from "api/common.api";
import feedApi from "api/feed.api";
import prospectApi from "api/prospect.api";
import { DownArrowIcon, FilterIcon } from "assets/images/common";
import {
  Button,
  ButtonOutline,
  CheckBox,
  HorizontalBox,
  Text,
} from "components";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { FilterOptions } from "models/common.model";
import {
  ProspectMultiSelectType,
  TicketSizeRanges,
} from "models/prospect.model";
import {
  selectFundingInstruments,
  selectFundingRounds,
  selectTicketSizeRanges,
} from "store/cached/cached.selector";
import { cachedActions } from "store/cached/cached.slice";
import {
  selectCountFilter,
  selectFeeds,
  selectFilterOptions,
} from "store/feed/feed.selector";
import { feedActions } from "store/feed/feed.slice";
import styled from "styled-components/macro";
import _ from "lodash";

export const ProspectOtherFilter = () => {
  const dispatch = useAppDispatch();
  const popoverRef = useRef(null);
  const countFilter = useAppSelector(selectCountFilter);
  const [isOpen, setIsOpen] = useState(false);
  const [rounds, setRounds] = useState<ProspectMultiSelectType[]>([]);
  const [ticketSizeRanges, setTicketSizeRanges] = useState<TicketSizeRanges[]>(
    []
  );
  const [instruments, setInstruments] = useState<ProspectMultiSelectType[]>([]);
  const globalFilterOptions = useAppSelector(selectFilterOptions);
  const feeds = useAppSelector(selectFeeds);
  const fixedFundingRounds = useAppSelector(selectFundingRounds);
  const fixedFundingInstruments = useAppSelector(selectFundingInstruments);
  const fixedTicketSizeRanges = useAppSelector(selectTicketSizeRanges);

  const getFilterNumber = () =>
    Object.keys(globalFilterOptions).filter((key) => {
      if (!["currentRound", "instrument", "minTicketSize"].includes(key))
        return false;
      return globalFilterOptions[key]?.length || 0;
    }).length;

  const applyFilter = useCallback(
    (data: FilterOptions, isApply: boolean) => {
      dispatch(feedActions.filterFeed({ filter: data, isApply }));
    },
    [dispatch]
  );

  const selectCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    id: string
  ) => {
    const { checked } = event.target;
    const currentArr = globalFilterOptions[name];
    if (checked) {
      const tempOptions = {
        ...globalFilterOptions,
        [name]: [...currentArr, id],
      };
      applyFilter(tempOptions, false);
    } else {
      const tempOptions = {
        ...globalFilterOptions,
        [name]: currentArr.filter((i) => i !== id),
      };
      applyFilter(tempOptions, false);
    }
  };

  const selectSizeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    id: string
  ) => {
    const { checked } = event.target;
    const currentArr = globalFilterOptions[name];
    if (checked) {
      const item = ticketSizeRanges.find((i) => i.id === id);
      if (item) {
        const { from, to } = item;
        const value = `${from}-${to}-${id}`;
        const tempOptions = {
          ...globalFilterOptions,
          [name]: [...currentArr, value],
        };
        applyFilter(tempOptions, false);
      }
    } else {
      const tempOptions = {
        ...globalFilterOptions,
        [name]: currentArr.filter((i) => !i.includes(id)),
      };
      applyFilter(tempOptions, false);
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const tempOptions = { ...globalFilterOptions, [name]: [value || ""] };
    applyFilter(tempOptions, false);
  };

  const handleChangeTicketSize = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    const tempSize = [...globalFilterOptions["minTicketSizeBK"]];
    if (name === "minSize") tempSize[0] = value;
    if (name === "maxSize") tempSize[1] = value;
    const tempOptions = { ...globalFilterOptions, minTicketSizeBK: tempSize };
    applyFilter(tempOptions, false);
  };

  const clearAll = () => {
    const tempOptions = {
      currentRound: [],
      instrument: [],
      minTicketSize: [],
      minTicketSizeBK: ["", ""],
      prevPartOf: [""],
    };
    applyFilter(tempOptions, true);
  };

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName: "fundingRounds",
        callApi: commonApi.fundingRounds,
        updateAt: fixedFundingRounds.updateAt,
      })
    );
  }, [dispatch, fixedFundingRounds.updateAt]);

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName: "fundingInstruments",
        callApi: prospectApi.fundingInstruments,
        updateAt: fixedFundingInstruments.updateAt,
      })
    );
  }, [dispatch, fixedFundingInstruments.updateAt]);

  useEffect(() => {
    dispatch(
      cachedActions.getFixedData({
        resName: "ticketSizeRanges",
        callApi: feedApi.ticketSizeRangesGet,
        updateAt: fixedTicketSizeRanges.updateAt,
      })
    );
  }, [dispatch, fixedTicketSizeRanges.updateAt]);

  useEffect(() => {
    const roundsData = fixedFundingRounds.data.map((i) => ({
      ...i,
      label: i.name,
    }));

    const feedRounds = feeds
      .filter((i) => i.currentRound)
      .map((i) => i.currentRound);
    const rounds = roundsData.filter((round) =>
      feedRounds.find((feedRound) => feedRound.id === round.id)
    );

    setRounds(rounds);
  }, [feeds, fixedFundingRounds.data]);

  useEffect(() => {
    const instrumentsData = fixedFundingInstruments.data.map((i) => ({
      ...i,
      label: i.name,
    }));

    const feedInstruments = feeds
      .filter((i) => i.instrument)
      .map((i) => i.instrument);
    const instruments = instrumentsData.filter((instrument) =>
      _.flatten(feedInstruments).find(
        (feedInstrument) => feedInstrument?.id === instrument?.id
      )
    );

    setInstruments(instruments);
  }, [feeds, fixedFundingInstruments.data]);

  useEffect(() => {
    const ticketSizeRangesData = fixedTicketSizeRanges.data.map((i) => ({
      ...i,
      name: i.label,
    })) as TicketSizeRanges[];

    const feedTicketSizeRanges = feeds
      .filter((i) => i.minTicketSize)
      .map((i) => i.minTicketSize);
    const ranges = ticketSizeRangesData.filter((item) =>
      feedTicketSizeRanges.find(
        (feedSize) => feedSize >= item.from && feedSize <= item.to
      )
    );

    setTicketSizeRanges(ranges);
  }, [feeds, fixedTicketSizeRanges.data]);

  return (
    <Wrapper className={isOpen ? "edit-field" : "text-field"}>
      <div ref={popoverRef} onClick={() => setIsOpen(!isOpen)}>
        <Filter className={`${isOpen && "active"}`}>
          <FilterIcon />
          <Text size="md" className="title">
            More Filters
          </Text>
          {!!getFilterNumber() && <NumberBox>{getFilterNumber()}</NumberBox>}
          <DownArrowIcon />
        </Filter>
      </div>

      <PopoverWrapper
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: "180px",
            borderRadius: "16px",
            boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
          },
        }}
        anchorEl={popoverRef.current}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <SelectWrapper>
          <VerticalBox>
            <Text size="lg">Minimum ticket size</Text>
            {false && (
              <HorizontalBox>
                <GroupTextField
                  placeholder="Min €"
                  name="minSize"
                  onChange={handleChangeTicketSize}
                  value={globalFilterOptions["minTicketSizeBK"][0]}
                />
                <GroupTextField
                  placeholder="Max €"
                  name="maxSize"
                  onChange={handleChangeTicketSize}
                  value={globalFilterOptions["minTicketSizeBK"][1]}
                />
              </HorizontalBox>
            )}
            {ticketSizeRanges.map((item) => (
              <CheckBox
                key={item.id}
                label={item.name}
                id={item.id}
                checked={
                  !!globalFilterOptions["minTicketSize"].find((value) =>
                    value.includes(item.id)
                  )
                }
                onChange={(event) =>
                  selectSizeCheckBox(event, "minTicketSize", item.id)
                }
              />
            ))}
          </VerticalBox>

          <VerticalBox>
            <Text size="lg">Round of financing</Text>
            {rounds.map((item) => (
              <CheckBox
                key={item.id}
                label={item.name}
                id={item.id}
                checked={
                  !!globalFilterOptions["currentRound"].find(
                    (id) => id === item.id
                  )
                }
                onChange={(event) =>
                  selectCheckBox(event, "currentRound", item.id)
                }
              />
            ))}
          </VerticalBox>
          <VerticalBox>
            <Text size="lg">Instruments</Text>
            {instruments.map((item) => (
              <CheckBox
                key={item.id}
                label={item.name}
                id={item.id}
                checked={
                  !!globalFilterOptions["instrument"].find(
                    (id) => id === item.id
                  )
                }
                onChange={(event) =>
                  selectCheckBox(event, "instrument", item.id)
                }
              />
            ))}
          </VerticalBox>
          {false && (
            <VerticalBox>
              <Text size="lg">Previously part of</Text>
              <GroupTextField
                placeholder=""
                name="prevPartOf"
                onChange={handleChangeInput}
                value={globalFilterOptions["prevPartOf"][0]}
              />
            </VerticalBox>
          )}
        </SelectWrapper>
        <Footer>
          <ButtonOutline onClick={clearAll}>Clear all</ButtonOutline>
          <Button
            className={`${!countFilter && "hide-button"}`}
            onClick={() => {
              applyFilter(globalFilterOptions, true);
              setIsOpen(false);
            }}
          >{`Show ${countFilter} results`}</Button>
        </Footer>
      </PopoverWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  position: relative;

  &.edit-field {
    .display-text {
      color: transparent;
    }
  }
`;
const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NumberBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #b4bac4;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 0 8px;
  background-color: #ffffff;
  border: 1px solid #d8d9df;
  gap: 6px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: #d8d9df2f;
  }

  ${NumberBox} {
    background: #ebecf0;
  }

  &.active {
    background-color: #d8d9df;

    ${NumberBox} {
      background-color: #b4bac4;
    }
  }

  .title {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const PopoverWrapper = styled(Popover)`
  .MuiPaper-root {
    min-width: clamp(380px, calc(100vw - 40px), 380px);
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 1;
  background-color: white;
  color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 16px;
  padding: 20px 30px;
  box-shadow: 0px 0px 50px rgba(5, 10, 48, 0.1);
  height: 520px;
  max-height: clamp(380px, calc(100vh - 100px), 480px);
  overflow-y: auto;
  padding-bottom: 100px;

  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 12px;
  border-top: 1px solid #ebecef;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: rgb(5 10 48 / 15%) 3px 2px 20px 0px;
  z-index: 2;

  button {
    border-radius: 4px;
    padding: 0 20px;
    height: 40px;

    &.btn-select-all {
      background-color: #d8d9df;
      color: ${(props) => props.theme.palette.secondary.main};
    }

    &.hide-button {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }
  }
`;
