import { StepBar } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectProspect } from "store/prospect/prospect.selector";
import styled from "styled-components/macro";

const STEPS = ["Draft", "Review", "Published"];

export const StatusBar = () => {
  const { status } = useAppSelector(selectProspect);

  const getStepIndex = (status: string) => {
    switch (status) {
      case "draft":
        return 0;
      case "review":
        return 1;
      case "published":
        return 3;
    }

    return 0;
  };

  return (
    <SterBarWrapper>
      <StepBar activeStep={getStepIndex(status)} steps={STEPS} />
    </SterBarWrapper>
  );
};

const SterBarWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 16px;
`;
