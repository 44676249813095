import styled from "styled-components";
import * as yup from "yup";

import {
  FundraisingFieldInput,
  FundraisingPhoneNumberField,
  SectionWrapper,
} from "components";

export const ContactSection = () => {
  const stringYup = yup.string().nullable();

  return (
    <SectionWrapper title="Contacts for investors">
      <Container>
        <FundraisingFieldInput
          fieldName="Email"
          name="contactEmail"
          placeholder="example@gmail.com"
          yupSchema={stringYup.email("Field must be a valid email")}
        />

        <FundraisingPhoneNumberField
          fieldName="Phone"
          name="contactTel"
          placeholder="012345"
          yupSchema={stringYup}
        />
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
