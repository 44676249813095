import {
  Step,
  stepConnectorClasses,
  stepIconClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
} from "@mui/material";

interface Props {
  activeStep: number;
  steps: string[];
}

export const StepBar = ({ activeStep, steps }: Props) => {
  return (
    <StepBarWrapper activeStep={activeStep}>
      {steps.map((label) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </StepBarWrapper>
  );
};

const StepBarWrapper = styled(Stepper)`
  .${stepLabelClasses.label} {
    color: ${(props) => props.theme.palette.text.primary};
    font-weight: 500;
  }
  .${stepIconClasses.text} {
    font-weight: 600;
    fill: #fff;
  }
  .${stepConnectorClasses.line} {
    border-color: #ebecef;
  }
  .${stepLabelClasses.iconContainer} {
    &.Mui-active,
    &.Mui-completed {
      svg {
        color: ${(props) => props.theme.palette.secondary.main};
      }
    }
    &.Mui-disabled svg {
      color: #ebecef;
    }
  }
`;
