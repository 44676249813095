import commonApi from "api/common.api";
import { ClearFilterIcon, FilterIcon, SortIcon } from "assets/images/common";
import { FlagText, ProspectItemFilter, ProspectOtherFilter } from "components";
import { ProspectSort } from "components/Business/Prospect/ProspectSort";
import { useAppDispatch } from "hooks/useHook";
import { SortItem } from "models/common.model";
import { feedActions } from "store/feed/feed.slice";
import styled from "styled-components/macro";
import { FilterTags } from "../data/CardData";
import { FavoriteFilter } from "./FavoriteFilter";
import { GlobalFilter } from "./GlobalFilter";

const FILTER_DATA = [
  {
    title: "Sector",
    name: "sector",
    callApi: commonApi.sectorsGet,
    resName: "marketSectors",
    type: "MULTI_SELECT",
  },
  {
    title: "Impact",
    name: "impactValues",
    callApi: commonApi.impactsGet,
    resName: "impactValues",
    type: "MULTI_SELECT",
  },
  {
    title: "Country",
    name: "country",
    callApi: commonApi.countriesGet,
    resName: "countries",
    type: "MULTI_SELECT",
  },
  // {
  //   title: "Country",
  //   name: "country",
  //   callApi: prospectApi.countriesGet,
  //   resName: "countries",
  //   type: "ONE_SELECT",
  // },
  {
    title: "MF Score",
    name: "mfScore",
    callApi: () =>
      new Promise((res, rej) => {
        res(true);
        rej(false);
      }),
    resName: "mfScore",
    type: "ARRANGE",
  },
];

const SORT_DATA: SortItem[] = [
  {
    id: "0",
    name: "Default",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "",
    order: "desc",
  },
  {
    id: "1",
    name: "Raising now",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundSize",
    order: "asc",
  },
  {
    id: "2",
    name: "Raising now",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundSize",
    order: "desc",
  },
  {
    id: "3",
    name: "Round closes soon",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundCloseDate",
    order: "asc",
  },
  {
    id: "4",
    name: "Round closes soon",
    feedFilter: {
      types: ["Organisation"],
      namespace: "Own",
    },
    byKey: "roundCloseDate",
    order: "desc",
  },
];

export const FilterBar = () => {
  //const globalFilterOptions = useAppSelector(selecteFilterOptions);
  const dispatch = useAppDispatch();

  const clearFilter = () => {
    dispatch(feedActions.clearFilter());
  };

  return (
    <Wrapper>
      <Tags>
        {FILTER_DATA.map((item) => (
          <ProspectItemFilter key={item.name} {...item} />
        ))}
        <ProspectOtherFilter />
        <FavoriteFilter />
        <GlobalFilter />

        <FlagText type="WHITE" icon={<ClearFilterIcon />} onClick={clearFilter}>
          Clear all filters
        </FlagText>
        {false &&
          FilterTags.map((item) => (
            <FlagText key={item} type="WHITE">
              {item}
            </FlagText>
          ))}
        {false && (
          <FlagText type="WHITE" icon={<FilterIcon />}>
            More Filters
          </FlagText>
        )}
      </Tags>
      {false && (
        <FlagText type="WHITE" icon={<SortIcon />}>
          Sorting
        </FlagText>
      )}
      <ProspectSort data={SORT_DATA} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .flag-text {
    color: ${(props) => props.theme.palette.text.primary};
    box-shadow: 0px 3px 5px rgb(5 10 48 / 5%);
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }
  }
`;
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  .clear-filter-icon {
    height: 16px;
    width: auto;
  }

  .flag-text {
    gap: 5px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: center;
  }
`;
