import { useEffect, useState } from "react";
import styled from "styled-components";

import { PitchDeckIcon, VideoIcon } from "assets/images/common";
import { SectionWrapper, Text } from "components";
import { useAppSelector } from "hooks/useHook";
import { selectOrganisation } from "store/organisation/organisation.selector";
import { getLatestFundraising } from "utils/system.util";
import { MediaFrame } from "../../components";

export const PitchDeckSection = () => {
  const [mediaTab, setMediaTab] = useState<"video" | "deck">("video");
  const selectedOrganisation = useAppSelector(selectOrganisation);

  const { fundraisingProjects, __typename } = selectedOrganisation;
  let { videoUrl, pitchDeckUrl } = { videoUrl: "", pitchDeckUrl: "" };

  if (__typename === "Organisation") {
    const latestReleaseFundraising = getLatestFundraising(fundraisingProjects);
    videoUrl = latestReleaseFundraising.videoUrl;
    pitchDeckUrl = latestReleaseFundraising.pitchDeckUrl;
  } else {
    pitchDeckUrl = selectedOrganisation?.pitchDeckUrl || "";
  }

  useEffect(() => {
    if (!videoUrl && pitchDeckUrl) {
      setMediaTab("deck");
    }
  }, [pitchDeckUrl, videoUrl]);

  return videoUrl || pitchDeckUrl ? (
    <SectionWrapper title="Pitch Deck">
      <MediaWrapper>
        {videoUrl && mediaTab === "video" && <MediaFrame url={videoUrl} />}
        {pitchDeckUrl && mediaTab === "deck" && (
          <MediaFrame url={pitchDeckUrl} />
        )}
        <MediaTabList>
          {videoUrl && (
            <TextIconButton
              className={mediaTab === "video" ? "active" : undefined}
              onClick={() => setMediaTab("video")}
            >
              <VideoIcon />
              <Text size="md">Video Presentation</Text>
            </TextIconButton>
          )}
          {pitchDeckUrl && (
            <TextIconButton
              className={mediaTab === "deck" ? "active" : undefined}
              onClick={() => setMediaTab("deck")}
            >
              <PitchDeckIcon />
              <Text size="md">Pitch deck</Text>
            </TextIconButton>
          )}
        </MediaTabList>
      </MediaWrapper>
    </SectionWrapper>
  ) : null;
};

const MediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MediaTabList = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const TextIconButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: #a5a7b4;
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
  & > div {
    color: #a5a7b4;
  }
  & > svg {
    width: 20px;
    height: auto;
    path {
      fill: #a5a7b4;
    }
  }
  &.active {
    & > div {
      color: ${(props) => props.theme.palette.secondary.main};
    }
    & > svg path {
      fill: ${(props) => props.theme.palette.secondary.light};
    }
  }
`;
