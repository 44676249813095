import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { initialState, PostState } from "./post.slice";

const selectDomain = (state: { post: PostState }) => state.post || initialState;

export const selectPosts = createSelector([selectDomain], (state) =>
  _.orderBy(state.posts, ["updatedAt"], ["desc"])
);

export const selectPostLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
